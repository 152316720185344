import { useState } from "react";

export interface ModalDisclosure {
	isOpen: boolean;
	onClose: () => void;
	onOpen: () => void;
	onToggle: () => void;
}

export const useModalDisclosure = (initialValue?: boolean): ModalDisclosure => {
	const [isOpen, setIsOpen] = useState<boolean>(Boolean(initialValue));

	const onOpen = () => setIsOpen(true);
	const onClose = () => setIsOpen(false);
	const onToggle = () => setIsOpen(!isOpen);

	return {
		isOpen,
		onOpen,
		onClose,
		onToggle
	};
};
