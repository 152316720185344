import colors from "colors";
import { IconProps } from "./types";

export default function IcoFile({
	color = colors.neutral["low-pure-500"],
	size = "24"
}: IconProps) {
	return (
		<svg
			width={size}
			height={size}
			viewBox="0 0 40 40"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M22.8571 8V15.5H30M22.8571 8H10V32H30V15.5M22.8571 8L30 15.5M25.5556 23H14.4444M25.5556 28H14.4444M18.8889 18H14.4444"
				stroke={color}
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
}
