import { IconProps } from "./types";

export default function IcoPadLock({
	color = "#1D1D1D",
	size = "24"
}: IconProps) {
	return (
		<svg
			width={size}
			height={size}
			viewBox="0 0 56 56"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<rect width="56" height="56" rx="28" fill="#F0F0F0" />
			<path
				d="M32.2 25.9004V19.6004C32.2 17.2808 30.3196 15.4004 28 15.4004C25.6804 15.4004 23.8 17.2808 23.8 19.6004V25.9004M28 30.1004V34.3004M17.5 25.9004H38.5V40.6004H17.5V25.9004Z"
				stroke={color}
				strokeWidth="1.4"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
}
