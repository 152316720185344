import { Slide, ToastContainer } from "react-toastify";
import Routers from "router";

import { GoogleAnalytics4 } from "components";

import "App.scss";
import "react-toastify/dist/ReactToastify.css";
import "./styles/global.css";

export function App() {
	return (
		<>
			<GoogleAnalytics4 />
			<ToastContainer
				autoClose={5000}
				position="top-right"
				icon={false}
				closeButton={false}
				theme="dark"
				transition={Slide}
			/>
			<Routers />
		</>
	);
}
