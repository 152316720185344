import clsx from "clsx";

import { Button } from "components";

import React from "react";
import { Tooltip } from "react-tooltip";
import { SegmentedButtonProps } from "./types";

import "./styles.scss";

export default function SegmentedButton({
	segments,
	selected
}: SegmentedButtonProps) {
	return (
		<div className="flex flex-col md:flex-row button-segment-container">
			{segments.map(({ id, disabled, onClick, label, tooltip }) => {
				const isSelected = selected === id;
				const componentId = `segmented-button-${id}`;
				return (
					<React.Fragment key={id}>
						{tooltip && (
							<Tooltip anchorSelect={`#${componentId}`} place="bottom">
								{tooltip}
							</Tooltip>
						)}
						<Button
							id={componentId}
							hierarchy={isSelected ? "primary" : "secondary"}
							type="button"
							disabled={disabled}
							onClick={onClick}
							className={clsx("button-segment", {
								disabled,
								selected: isSelected
							})}
						>
							{label}
						</Button>
					</React.Fragment>
				);
			})}
		</div>
	);
}
