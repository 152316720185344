import {
	BackofficeFilterForm,
	FilterForm,
	FilterFormFooter,
	FilterFormHeader
} from "components/BackofficeFilterForm";
import Input from "components/Input";
import MultiSelect from "components/SelectForm/MultiSelect";
import { useFormik } from "formik";
import { DateTimeHelper, ErrorHelper } from "helpers";
import OptionHelper from "helpers/OptionHelper";
import { useCallback, useEffect } from "react";
import { toast } from "react-toastify";
import { ApplicationService } from "services/applicationService";
import { ApplicationFilterOptions } from "services/types";

export interface ApplicationRegistryFilters extends Record<string, unknown> {
	from: string;
	to: string;
	lastAnalysisStatuses?: string[];
	interventionTypes: string[];
	concessionaires: string[];
	responsibleGroups: string[];
}
const allGroups = true;

export default function BackofficeApplicationRegistryFilterForm({
	initialFilters,
	setFilters,
	clearFilters,
	isOpen,
	setIsOpen,
	filterOptions,
	setFilterOptions,
	isLoadingFilterOptions,
	setIsLoadingFilterOptions
}: Readonly<{
	initialFilters: ApplicationRegistryFilters;
	setFilters: (newFilters: ApplicationRegistryFilters) => void;
	clearFilters: () => void;
	isOpen: boolean;
	setIsOpen: (newIsOpen: boolean) => void;
	filterOptions: ApplicationFilterOptions | null;
	setFilterOptions: (newOptions: ApplicationFilterOptions) => void;
	isLoadingFilterOptions: boolean;
	setIsLoadingFilterOptions: (newIsLoadingFilterOptions: boolean) => void;
}>): JSX.Element {
	const formik = useFormik({
		initialValues: initialFilters,
		enableReinitialize: true,
		onSubmit: (newFilters) => {
			setFilters(newFilters);
			setIsOpen(false);
		}
	});
	const applicationService = ApplicationService.getInstance();

	const loadOptions = useCallback(() => {
		setIsLoadingFilterOptions(true);
		applicationService
			.getApplicationFilterOptions(allGroups)
			.then((responseData) => {
				setFilterOptions(responseData);
			})
			.catch((err) => toast.error(ErrorHelper.getResponseErrorMessage(err)))
			.finally(() => setIsLoadingFilterOptions(false));
	}, [applicationService, setFilterOptions, setIsLoadingFilterOptions]);
	useEffect(() => {
		if (filterOptions == null && !isLoadingFilterOptions) loadOptions();
	}, []);

	const {
		values,
		setFieldValue,
		handleChange,
		handleSubmit,
		submitForm,
		dirty,
		errors
	} = formik;
	const hasErrors = !!Object.keys(errors).length;
	const today = DateTimeHelper.currentDate();

	return (
		<BackofficeFilterForm
			isOpen={isOpen}
			onClickOutside={() => setIsOpen(false)}
		>
			<FilterFormHeader title="Filtros" onClose={() => setIsOpen(false)} />
			<FilterForm
				onSubmit={handleSubmit}
				isLoading={!filterOptions || isLoadingFilterOptions}
			>
				{filterOptions && (
					<>
						<div>Buscar por data de criação:</div>
						<fieldset className="flex flex-col sm:flex-row gap-4">
							<Input
								type="date"
								name="from"
								value={values.from}
								label="De: "
								onChange={handleChange}
								required={false}
								showOptionalIndicator={false}
								max={values.to ?? today}
							/>
							<Input
								type="date"
								name="to"
								value={values.to}
								label="Até: "
								onChange={handleChange}
								required={false}
								showOptionalIndicator={false}
								min={values.from}
								max={today}
							/>
						</fieldset>

						<MultiSelect
							label="Concessionárias"
							name="concessionaires"
							value={values.concessionaires}
							options={filterOptions.concessionaires}
							getOptionValue={OptionHelper.getValue}
							isClearable
							isSearchable
							isDisabled={false}
							setFieldValue={setFieldValue}
							asSimpleValues
						/>

						<MultiSelect
							label="Status da última análise"
							name="lastAnalysisStatuses"
							value={values.lastAnalysisStatuses}
							options={filterOptions.analysisStatuses}
							getOptionValue={OptionHelper.getValue}
							isClearable
							isSearchable
							isDisabled={false}
							setFieldValue={setFieldValue}
							asSimpleValues
						/>

						<MultiSelect
							label="Grupos responsáveis"
							name="responsibleGroups"
							value={values.responsibleGroups}
							options={filterOptions.groups}
							getOptionValue={OptionHelper.getValue}
							isClearable
							isSearchable
							isDisabled={false}
							setFieldValue={setFieldValue}
							asSimpleValues
						/>

						<MultiSelect
							label="Tipos de intervenção"
							name="interventionTypes"
							value={values.interventionTypes}
							options={filterOptions.interventionTypes.map(
								OptionHelper.stringToOption
							)}
							getOptionValue={OptionHelper.getValue}
							isClearable
							isSearchable
							isDisabled={false}
							setFieldValue={setFieldValue}
							asSimpleValues
						/>
					</>
				)}
			</FilterForm>
			<FilterFormFooter
				dirty={dirty}
				hasErrors={hasErrors}
				onClear={() => {
					formik.resetForm();
					clearFilters();
				}}
				onSubmit={submitForm}
			/>
		</BackofficeFilterForm>
	);
}
