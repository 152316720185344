import { LoggedInLayout } from "layouts";
import { LoggedUser } from "models/auth/LoggedUser";
import { Page404 } from "pages";
import {
	ApplicationRegistry,
	BackofficeHome,
	BackofficeLogin,
	BackofficeUserNotFound
} from "pages/Backoffice";
import BackofficeApplicationPage from "pages/Backoffice/ApplicationPage";
import { ApplicationPageTabs } from "pages/Backoffice/ApplicationPage/types";
import { Navigate, Route, Routes } from "react-router-dom";

export default function BackofficeRoutes({
	userTokenInfo
}: {
	userTokenInfo: LoggedUser | null;
}) {
	return (
		<Routes>
			<>
				{userTokenInfo?.hasBackofficePermission() && (
					<Route path="/" element={<LoggedInLayout />}>
						<Route path="/consulta" element={<ApplicationRegistry />} />
						<Route path="/" element={<BackofficeHome />} />
						<Route
							path="/solicitacao/:applicationId"
							element={<Navigate to={`./${ApplicationPageTabs.DETAILS}`} />}
						/>
						<Route
							path="/solicitacao/:applicationId/:selectedTab"
							element={<BackofficeApplicationPage />}
						/>
						<Route path="/*" element={<Page404 />} />
					</Route>
				)}
				<Route path="/sem-cadastro" element={<BackofficeUserNotFound />} />
				<Route path="/" element={<BackofficeLogin />} />
				<Route path="/*" element={<Navigate to="/backoffice/" />} />
			</>
		</Routes>
	);
}
