import IcoLoadingNeutral from "assets/icons/ico-loading-neutral.png";
import IcoLoadingPrimary from "assets/icons/ico-loading-primary.png";
import IcoLoadingSecundary from "assets/icons/ico-loading-secondary.png";
import { LoadingProps } from "./types";

import "./styles.scss";

export default function Loading({ type = "primary", size = 24 }: LoadingProps) {
	let image;

	switch (type) {
		case "primary":
			image = IcoLoadingPrimary;
			break;
		case "secondary":
			image = IcoLoadingSecundary;
			break;
		default:
			image = IcoLoadingNeutral;
			break;
	}

	return (
		<div className="container-loading">
			<img
				className="ico-loading"
				src={image}
				style={{ height: size, width: size }}
				alt="Carregando..."
			/>
		</div>
	);
}
