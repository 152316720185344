import { IcoWindows } from "assets/icons";
import favicon from "assets/icons/favicon.png";
import { LogoWhite } from "assets/images";
import { Button } from "components";
import { useMemo } from "react";
import { Helmet } from "react-helmet-async";
import { BackofficeAuthService } from "services/backofficeAuth";
import version from "systemversion.json";

export default function BackofficeLogin() {
	// eslint-disable-next-line react-hooks/exhaustive-deps
	const boAuthService = useMemo(BackofficeAuthService.getInstance, []);

	return (
		<>
			<Helmet>
				<title>{`${process.env.REACT_APP_TITLE} - Backoffice - Login`}</title>
				<link rel="shortcut icon" href={favicon} type="image/x-icon" />
				<link rel="icon" href={favicon} type="image/x-icon" />
			</Helmet>
			<div className="h-full w-full flex justify-center bg-neutral-low-pure-500">
				<div className="flex flex-col gap-16 mt-[20vh] items-center">
					<img src={LogoWhite} alt="logo-icon" className="logo" />

					<div className="bg-neutral-high-pure-50 p-10 w-[26.5rem] rounded-lg max-sm:w-[18rem] max-sm:py-6">
						<p className="text-2xl leading-5 font-semibold pb-4">Entrar</p>
						<p className="pb-6 text-sm">
							Acesse o sistema com o seu email corporativo.
						</p>
						<Button
							hierarchy="secondary"
							size="medium"
							leading={<IcoWindows />}
							className="w-full sm:mb-3 h-fit md:h-12"
							onClick={boAuthService.loginAD}
						>
							Continuar com Microsoft
						</Button>
					</div>
					{version && (
						<p className="text-xs text-neutral-high-pure-50">
							Versão {version.fullVersion}
						</p>
					)}
				</div>
			</div>
		</>
	);
}
