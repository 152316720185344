import { Logo } from "assets/images";
import { AuthHelper } from "helpers";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

export default function SignUpHeader() {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const handleLogout = () => {
		AuthHelper.logout(dispatch);
		navigate("/");
	};
	return (
		<header className="login-header border-b border-neutral-high-100 bg-neutral-high-pure-50 fixed top-0 left-0 right-0 z-10">
			<div className="flex flex-row w-full h-16 justify-between items-center">
				<div className="ml-8 w-52 max-sm:hidden">
					<Link to="/">
						<img src={Logo} alt="logo-icon" className="logo" />
					</Link>
				</div>
				<nav className="tab-wrapper ml-8 w-52">
					<p className="font-semibold text-lg max-sm:mr-8 max-sm:text-left">
						Faixa de Domínio
					</p>
				</nav>
				<div className="flex flex-row mr-8 h-full cursor-pointer">
					<div className="px-4 leading-[4rem]" onClick={handleLogout}>
						Sair
					</div>
					<div className="px-4 font-semibold border-b-4 leading-[4rem] h-full max-sm:hidden">
						Cadastrar
					</div>
				</div>
			</div>
		</header>
	);
}
