import { IcoInfoCircle } from "assets/icons";

export default function EmptyAnalysisContent(): JSX.Element {
	return (
		<div className="flex flex-col w-full h-full items-center justify-center">
			<div className="flex flex-col items-center justify-center gap-4">
				<div className="flex flex-col items-center justify-center p-3 bg-neutral-high-100 rounded-full">
					<IcoInfoCircle size="48" />
				</div>
				<div className="flex flex-col items-center justify-center">
					<strong>Clique em um item para ler</strong>
					<span className="text-sm">Nenhum item foi selecionado</span>
				</div>
			</div>
		</div>
	);
}
