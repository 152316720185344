import { Constants } from "appConstants";
import StatusTag from "components/StatusTag";
import {
	AnalysisEventInternalListItem,
	ApplicationEventType
} from "models/types";
import { ReactNode } from "react";
import { AnalysisStatusLabel } from "services/types";

const { ReadableUserRoleGroups } = Constants;

const internalTargetEvents = [
	ApplicationEventType.APTO_ANALISE,
	ApplicationEventType.DEVOLVIDO,
	ApplicationEventType.APROVADO,
	ApplicationEventType.APROVADO_RESSALVAS,
	ApplicationEventType.REPROVADO
];

const focalTargetEvents = [
	ApplicationEventType.RETORNA_PARA_FOCAL_APROVADO,
	ApplicationEventType.RETORNA_PARA_FOCAL_APROVADO_RESSALVAS,
	ApplicationEventType.RETORNA_PARA_FOCAL_APTO_ANALISE,
	ApplicationEventType.RETORNA_PARA_FOCAL_DEVOLVIDO,
	ApplicationEventType.RETORNA_PARA_FOCAL_REPROVADO
];

const internalTargetMapper: Record<Constants.ReadableUserRoleGroups, string> = {
	[ReadableUserRoleGroups.CENTRAL]: ReadableUserRoleGroups.ENGENHARIA,
	[ReadableUserRoleGroups.ENGENHARIA]: ReadableUserRoleGroups.CENTRAL,
	[ReadableUserRoleGroups.FOCAL]: ReadableUserRoleGroups.CENTRAL,
	[ReadableUserRoleGroups.SOLICITANTE]: ReadableUserRoleGroups.FOCAL // incluido por completude
};

function getTargetGroup(eventData: AnalysisEventInternalListItem): string {
	const { type, createdByGroup } = eventData;
	if (internalTargetEvents.includes(type)) {
		return internalTargetMapper[createdByGroup];
	}

	if (focalTargetEvents.includes(type)) {
		return Constants.ReadableUserRoleGroups.FOCAL;
	}

	return "";
}

const eventTitleMapper: {
	types: ApplicationEventType[];
	displayFunction: (eventData: AnalysisEventInternalListItem) => ReactNode;
}[] = [
	{
		types: [
			ApplicationEventType.ASSUMIR_RESPONSABILIDADE,
			ApplicationEventType.REMOVER_RESPONSABILIDADE,
			ApplicationEventType.ADICIONAR_COMENTARIO
		],
		displayFunction: (eventData) => (
			<strong className="text-base">
				{`${eventData.typeLabel} - ${eventData.createdByGroup}`}
			</strong>
		)
	},
	{
		types: [
			ApplicationEventType.APTO_ANALISE,
			ApplicationEventType.RETORNA_PARA_FOCAL_APROVADO,
			ApplicationEventType.DEVOLVIDO,
			ApplicationEventType.RETORNA_PARA_FOCAL_DEVOLVIDO,
			ApplicationEventType.APROVADO,
			ApplicationEventType.RETORNA_PARA_FOCAL_APROVADO,
			ApplicationEventType.APROVADO_RESSALVAS,
			ApplicationEventType.RETORNA_PARA_FOCAL_APROVADO_RESSALVAS,
			ApplicationEventType.REPROVADO,
			ApplicationEventType.RETORNA_PARA_FOCAL_REPROVADO
		],
		displayFunction: (eventData) => {
			const targetGroup: string = getTargetGroup(eventData);
			return (
				<>
					<strong className="text-base">{`Enviado de ${eventData.createdByGroup} para ${targetGroup}`}</strong>
					<StatusTag
						status={eventData.typeLabel as AnalysisStatusLabel}
						small
					/>
				</>
			);
		}
	},
	{
		types: [ApplicationEventType.RESPOSTA_PARA_SOLICITANTE],
		displayFunction: (eventData) =>
			eventData.applicationAnalysis && (
				<>
					<strong className="text-base">{eventData.typeLabel}</strong>
					<StatusTag status={eventData.applicationAnalysis.status} small />
				</>
			)
	}
];

function defaultTitleDisplay(
	eventData: AnalysisEventInternalListItem
): ReactNode {
	return <strong className="text-base">{eventData.typeLabel}</strong>;
}

function getEventTitle(eventData: AnalysisEventInternalListItem): ReactNode {
	const { type } = eventData;
	const titleMapper = eventTitleMapper.find(
		({ types }) => !!types.find((t) => t === type)
	);
	const displayFunction = titleMapper?.displayFunction ?? defaultTitleDisplay;
	return displayFunction(eventData);
}

const EventDisplayHelper = {
	defaultTitleDisplay,
	getEventTitle,
	getTargetGroup,
	internalTargetEvents,
	externalTargetEvents: focalTargetEvents,
	internalTargetMapper,
	eventTitleMapper
};

export default EventDisplayHelper;
