import { BaseModal } from "components";

import { ModalProps } from "./types";

export default function Modal({
	headerText,
	closeOnClick = true,
	onClose,
	children,
	...rest
}: ModalProps) {
	return (
		<BaseModal
			onClickOutsideModal={closeOnClick ? onClose : undefined}
			{...rest}
		>
			{headerText && (
				<h3 className="headline-3 text-neutral-low-pure-500 font-bold">
					{headerText}
				</h3>
			)}
			{children}
		</BaseModal>
	);
}
