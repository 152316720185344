const NameHelper = {
	abbreviateName(name: string) {
		if (!name) return name;

		const nameParticles = ["de", "da", "das", "do", "dos", "e"];
		const reducedName = name
			.split(" ")
			.filter(
				(slug) =>
					!nameParticles.find(
						(stopWord) => stopWord.toLowerCase() === slug.toLowerCase()
					)
			);

		const firstName = reducedName[0];
		if (reducedName.length === 1) return firstName;

		const lastName = reducedName[reducedName.length - 1];
		return `${firstName} ${lastName[0]}.`;
	}
};

export default NameHelper;
