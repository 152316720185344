import { Outlet } from "react-router-dom";

import { Footer, Header } from "components";

export default function DefaultLayout() {
	return (
		<>
			<Header />
			<div className="pt-16 sm:pt-24">
				<Outlet />
				<Footer />
			</div>
		</>
	);
}
