import { WrapperGeneral } from "components";
import { StepProps } from "./modules/Step/types";

import { Step } from "./modules";

import { strings } from "./strings";

import "./styles.scss";

export default function BoxHowItWorks() {
	return (
		<div
			className="container-common container-how-it-works pb-8 md:pb-[4.5rem] pt-10 md:pt-20"
			id="como-funciona"
		>
			<WrapperGeneral>
				<h2 className="headline-1 leading-7 text-neutral-low-pure-500 text-[22px]">
					Como funciona
				</h2>
				<h4 className="headline-4 text-neutral-low-400 mt-4 text-sm">
					Veja como é fácil utilizar o Faixa de Domínio
				</h4>
				<div className="list-steps">
					{strings.map((step: StepProps, idx: number) => (
						<Step {...step} key={`step-${idx}`} />
					))}
				</div>
			</WrapperGeneral>
		</div>
	);
}
