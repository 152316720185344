import { Outlet } from "react-router-dom";

import SignUpHeader from "components/SignUpHeader";

export default function SignUpLayout() {
	return (
		<>
			<SignUpHeader />
			<div className="pt-16">
				<Outlet />
			</div>
		</>
	);
}
