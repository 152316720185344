import clsx from "clsx";
import { AlertCard, SelectedDisplay, SelectForm } from "components";
import { ErrorHelper } from "helpers";
import { useDataModel } from "hooks/useDataModel";
import { HighwayConcession } from "models/HighwayConcession";
import { InterventionType } from "models/InterventionType";
import { InterventionTypeCategory } from "models/InterventionTypeCategory";
import {
	MutableRefObject,
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState
} from "react";
import { toast } from "react-toastify";
import { InterventionTypeService } from "services/interventionType";
import { StepLoading } from "../layout";
import { ApplicationFields, ApplicationFormik } from "../types";

const menuScrollOffset = 30;

export default function Intervention({
	formik,
	onGoBack
}: ApplicationFormik & {
	onGoBack: () => void;
}) {
	const { highwayConcession, interventionType, interventionTypeCategory } =
		formik.values;
	const interventionTypeService = useMemo(
		InterventionTypeService.getInstance,
		[]
	);
	const selectedHighway = useDataModel(highwayConcession, HighwayConcession);
	const selectedInterventionType = useDataModel(
		interventionType,
		InterventionType
	);
	const selectedInterventionTypeCategory: InterventionTypeCategory | null =
		useMemo(() => {
			return (
				selectedInterventionType?.categories?.find(
					(category) => category.id === interventionTypeCategory?.id
				) ?? null
			);
		}, [interventionTypeCategory, selectedInterventionType]);

	const [interventionTypes, setInterventionTypes] =
		useState<InterventionType[]>();
	const [isLoadingTypes, setIsLoadingTypes] = useState(false);

	useEffect(() => {
		if (!selectedHighway) {
			setInterventionTypes(undefined);
			return;
		}

		setIsLoadingTypes(true);
		interventionTypeService
			.listByHighwayConcession(selectedHighway.id)
			.then((response) => {
				setInterventionTypes(response);
			})
			.catch((e) => toast.error(ErrorHelper.getResponseErrorMessage(e)))
			.finally(() => setIsLoadingTypes(false));
	}, [selectedHighway?.id]);

	const resetCategory = () => {
		formik.setFieldValue(ApplicationFields.interventionTypeCategory, null);
	};

	const scrollableContainer = useRef<HTMLDivElement | null>(null);
	const typeSelectAnchor = useRef<HTMLDivElement | null>(null);
	const scrollToSelect = useCallback(
		(
			anchor: MutableRefObject<HTMLDivElement | null>,
			offset: number = menuScrollOffset
		) => {
			if (!anchor.current || !scrollableContainer.current) return;

			const anchorElement = anchor.current;
			const scrollableElement = scrollableContainer.current;
			const topPosition =
				anchorElement.getBoundingClientRect().top +
				scrollableElement.scrollTop -
				scrollableElement.getBoundingClientRect().top +
				offset;

			scrollableElement.scrollTo({
				top: topPosition,
				behavior: "smooth"
			});
		},
		[]
	);

	return (
		<div
			className="h-full overflow-y-auto flex flex-col items-center max-w-2xl"
			ref={scrollableContainer}
		>
			<div className="flex flex-col-reverse md:flex-col w-full">
				<div className="md:bg-neutral-high-200 w-full">
					<div className="my-4 md:mx-8 mx-6 rounded-lg p-4 md:pt-5 md:pb-6 bg-neutral-high-pure-50 flex items-center gap-4 border border-neutral-high-200">
						<SelectedDisplay
							text="Rodovia selecionada"
							selected={selectedHighway ? selectedHighway.toString() : ""}
							isIconVisible
							isEdit
							onGoBack={onGoBack}
						/>
					</div>
				</div>
				<h1 className="m-6 text-2xl font-semibold md:text-lg md:font-normal md:mb-4 md:mx-8">
					Selecione uma intervenção
				</h1>
			</div>
			{isLoadingTypes && <StepLoading />}
			<div className="w-full md:mb-10">
				{interventionTypes && !isLoadingTypes && (
					<div className="mx-8 my-4 flex flex-col gap-6" ref={typeSelectAnchor}>
						<SelectForm
							name={ApplicationFields.interventionType}
							setFieldValue={formik.setFieldValue}
							options={interventionTypes?.map((type) => type.toOption())}
							value={selectedInterventionType?.toOption()}
							label="Tipo de intervenção"
							tooltip="Algumas intervenções possuem características específicas que podem impactar no prazo de análise e na documentação necessária para abrir a solicitação."
							onChange={resetCategory}
							onMenuOpen={() => scrollToSelect(typeSelectAnchor)}
							menuPlacement="bottom"
							useOuterScroll
							menuPosition="absolute"
							error={formik.errors.interventionType}
							touched={formik.touched.interventionType}
						/>
						{selectedInterventionType && (
							<AlertCard>
								<div>{selectedInterventionType.description}</div>
								<div
									className={clsx({
										"mt-4": selectedInterventionType.description
									})}
								>
									Acesse o site{" "}
									<a
										href="https://rodovias.grupoccr.com.br/"
										target="_blank"
										className="underline"
										rel="noreferrer"
									>
										CCR Rodovias
									</a>
									{`, selecione sua concessionária e obtenha mais informações em "Serviços" > "Faixa de Domínio".`}
								</div>
							</AlertCard>
						)}
					</div>
				)}
				{!!selectedInterventionType?.categories?.length && !isLoadingTypes && (
					<div className="mx-8 my-4 flex flex-col gap-6">
						<SelectForm
							name={ApplicationFields.interventionTypeCategory}
							setFieldValue={formik.setFieldValue}
							options={selectedInterventionType?.categories?.map((type) =>
								type.toOption()
							)}
							value={selectedInterventionTypeCategory?.toOption()}
							label="Categoria"
							error={formik.errors.interventionTypeCategory}
							touched={formik.touched.interventionTypeCategory}
						/>
					</div>
				)}
			</div>
		</div>
	);
}
