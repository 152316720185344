import { IconProps } from "./types";

export default function IcoPlus({ color = "#1D1D1D", size = "24" }: IconProps) {
	return (
		<svg
			width={size}
			height={size}
			viewBox="0 0 24 25"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M21 12.7246L3 12.7246M12 21.7246L12 3.72461"
				stroke={color}
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
}
