import { Constants } from "appConstants";
import { last } from "./ArrayHelper";

const errorMessages = {
	noFileExt() {
		return "Tipo de arquivo não reconhecido";
	},
	wrongFileExt(acceptedFormats: string[]) {
		return `Arquivo deve ter um dos seguintes formatos: ${acceptedFormats.join(
			", "
		)}.`;
	},
	fileSizeTooBig(maxByteSize: number) {
		return `Tamanho do arquivo excede o máximo permitido (${
			maxByteSize / Constants.ONE_MB
		}MB)`;
	}
} as const;

export class FileValidationError extends Error {}

const FileHelper = {
	validateFile(
		file: File,
		acceptedFormats: string[] | undefined,
		maxByteSize: number | undefined
	): boolean {
		const { name: fileName, size } = file;

		const fileExt = last(fileName.split("."));
		if (!fileExt) {
			throw new FileValidationError(errorMessages.noFileExt());
		}

		if (
			acceptedFormats?.length &&
			!acceptedFormats.find(
				(format) => format.toLowerCase() === fileExt.toLowerCase()
			)
		) {
			throw new FileValidationError(
				errorMessages.wrongFileExt(acceptedFormats)
			);
		}

		if (maxByteSize && size > maxByteSize) {
			throw new FileValidationError(errorMessages.fileSizeTooBig(maxByteSize));
		}
		return true;
	},

	cloneFileList(fileList: FileList): File[] {
		const listClone = [];
		for (let i = 0; i < fileList.length; i += 1) {
			listClone.push(fileList[i]);
		}
		return listClone;
	}
};

export default FileHelper;
