import { BaseModel } from "./BaseModel";
import { RegulatoryAgency } from "./RegulatoryAgency";
import { ConcessionaireProps } from "./types";

export class Concessionaire extends BaseModel<ConcessionaireProps> {
	id: string;
	name: string;
	concessionStartDate: Date;
	concessionEndDate: Date;
	active: boolean;
	regulatoryAgency: RegulatoryAgency;

	constructor(data: ConcessionaireProps) {
		super(data);
		this.id = data.id;
		this.name = data.name;
		this.concessionStartDate = data.concessionStartDate;
		this.concessionEndDate = data.concessionEndDate;
		this.active = data.active;
		this.regulatoryAgency = new RegulatoryAgency(data.regulatoryAgency);
	}
}
