import { AnyAction, Dispatch } from "@reduxjs/toolkit";
import { Constants } from "appConstants";
import { setLogout } from "store/features/auth/slice";
import AuthTokenHelper from "./AuthTokenHelper";
import LocalStorageHelper from "./LocalStorageHelper";

const AuthHelper = {
	getLoginErrorStatus(p_status: number) {
		return String(
			Constants.loginStatus.find(
				(item: { label: number }) => item.label === p_status
			)?.value
		);
	},
	logout(dispatch: Dispatch<AnyAction>) {
		AuthTokenHelper.removeAuthToken();
		LocalStorageHelper.removeApplicationDraft();
		dispatch(setLogout());
	}
};

export default AuthHelper;
