import { IconProps } from "./types";

export default function IcoAdd(props: Readonly<IconProps>): JSX.Element {
	const { size = 24, color = "#1D1D1D" } = props;
	return (
		<svg
			width={size}
			height={size}
			viewBox="0 0 24 25"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M12 20.5L12 4.5M20 12.5L4 12.5"
				stroke={color}
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
}
