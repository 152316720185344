import { IconProps } from "./types";

export default function IcoWindows({ size = "32" }: IconProps) {
	return (
		<svg
			width={size}
			height={size}
			viewBox="0 0 32 33"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path d="M15.3684 4.09656H4V15.4649H15.3684V4.09656Z" fill="#F25022" />
			<path d="M15.3684 16.7281H4V28.0965H15.3684V16.7281Z" fill="#00A4EF" />
			<path d="M28 4.09656H16.6316V15.4649H28V4.09656Z" fill="#7FBA00" />
			<path d="M28 16.7281H16.6316V28.0965H28V16.7281Z" fill="#FFB900" />
		</svg>
	);
}
