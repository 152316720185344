import DocumentFolderInput from "components/DocumentFolderInput";
import {
	AnalysisEventDocument,
	AnalysisEventDocumentFolder,
	FileExtensionData
} from "services/types";
import EmptyAttachmentsContent from "./EmptyAttachmentsContent";
import EventDocumentList from "./EventDocumentList";

export default function AnalysisEventDocumentDisplay({
	assortedDocuments,
	documentFolders,
	fileExtensions
}: Readonly<{
	assortedDocuments: AnalysisEventDocument[];
	documentFolders: AnalysisEventDocumentFolder[];
	fileExtensions: FileExtensionData[];
}>): JSX.Element {
	const documentCount = assortedDocuments.length + documentFolders.length;
	if (documentCount === 0) return <EmptyAttachmentsContent />;

	return (
		<div className="flex flex-col w-full h-full">
			{!!assortedDocuments.length && (
				<div className="flex flex-col w-full pb-6 md:gap-3">
					<h3 className="text-base font-semibold h-[4.5rem] md:h-fit flex items-center">
						Anexos avulsos
					</h3>
					<EventDocumentList
						eventDocuments={assortedDocuments}
						allowExpansion={documentFolders.length > 0}
					/>
				</div>
			)}
			{!!documentFolders.length && (
				<div className="flex flex-col w-full pb-6 md:gap-3">
					<h3 className="text-base font-semibold h-[4.5rem] md:h-fit flex items-center">
						Anexos em pasta
					</h3>
					<div className="flex flex-col w-full gap-3">
						{documentFolders.map((documentFolder) => (
							<DocumentFolderInput
								key={documentFolder.folderTypeData.id}
								readonly
								folderName={documentFolder.folderTypeData.documentType.name}
								folderType={documentFolder.folderTypeData.id}
								value={documentFolder}
								fileExtensions={fileExtensions}
								initiallyExpanded={false}
							/>
						))}
					</div>
				</div>
			)}
		</div>
	);
}
