import colors from "colors";
import { IconProps } from "./types";

export default function IcoChat({
	color = colors.neutral["low-pure-500"],
	size = "24"
}: IconProps) {
	return (
		<svg
			width={size}
			height={size}
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M19.5 3H4.5C3.67157 3 3 3.67157 3 4.5V15C3 15.8284 3.67157 16.5 4.5 16.5H8.78571V21L13.9286 16.5H19.5C20.3284 16.5 21 15.8284 21 15V4.5C21 3.67157 20.3284 3 19.5 3Z"
				stroke={color}
				strokeMiterlimit="2.36797"
				strokeLinejoin="round"
			/>
		</svg>
	);
}
