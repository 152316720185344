import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";

interface ApplicationListFilters extends Record<string, unknown> {
	from: string;
	to: string;
	statuses: string[];
	interventionTypes: string[];
	concessionaires: string[];
	responsibleGroups: string[];
	analysts: string[];
}

export const initialStateBackofficeListFilters: ApplicationListFilters = {
	from: "",
	to: "",
	statuses: [],
	interventionTypes: [],
	concessionaires: [],
	responsibleGroups: [],
	analysts: []
};

export const backofficeListFiltersSlice = createSlice({
	name: "backofficeListFilters",
	initialState: initialStateBackofficeListFilters,
	reducers: {
		setListFilters: (state, action: PayloadAction<ApplicationListFilters>) => {
			state.from = action.payload.from;
			state.to = action.payload.to;
			state.statuses = action.payload.statuses;
			state.interventionTypes = action.payload.interventionTypes;
			state.concessionaires = action.payload.concessionaires;
			state.responsibleGroups = action.payload.responsibleGroups;
			state.analysts = action.payload.analysts;
		},
		resetListFilters: () => initialStateBackofficeListFilters
	}
});

export const { setListFilters, resetListFilters } =
	backofficeListFiltersSlice.actions;
export default backofficeListFiltersSlice.reducer;
