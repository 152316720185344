export const TailwindColSpan: Record<number, string> = {
	1: "col-span-1",
	2: "col-span-2",
	3: "col-span-3",
	4: "col-span-4",
	5: "col-span-5",
	6: "col-span-6",
	7: "col-span-7",
	8: "col-span-8",
	9: "col-span-9",
	10: "col-span-10",
	11: "col-span-11",
	12: "col-span-12"
} as const;

export const TailwindMaxMdColSpan: Record<number, string> = {
	1: "max-md:col-span-1",
	2: "max-md:col-span-2",
	3: "max-md:col-span-3",
	4: "max-md:col-span-4",
	5: "max-md:col-span-5",
	6: "max-md:col-span-6",
	7: "max-md:col-span-7",
	8: "max-md:col-span-8",
	9: "max-md:col-span-9",
	10: "max-md:col-span-10",
	11: "max-md:col-span-11",
	12: "max-md:col-span-12"
} as const;

export const TailwindGridCols: Record<number, string> = {
	1: "grid-cols-1",
	2: "grid-cols-2",
	3: "grid-cols-3",
	4: "grid-cols-4",
	5: "grid-cols-5",
	6: "grid-cols-6",
	7: "grid-cols-7",
	8: "grid-cols-8",
	9: "grid-cols-9",
	10: "grid-cols-10",
	11: "grid-cols-11",
	12: "grid-cols-12"
} as const;

export const TailwindMdGridCols: Record<number, string> = {
	1: "md:grid-cols-1",
	2: "md:grid-cols-2",
	3: "md:grid-cols-3",
	4: "md:grid-cols-4",
	5: "md:grid-cols-5",
	6: "md:grid-cols-6",
	7: "md:grid-cols-7",
	8: "md:grid-cols-8",
	9: "md:grid-cols-9",
	10: "md:grid-cols-10",
	11: "md:grid-cols-11",
	12: "md:grid-cols-12"
} as const;
