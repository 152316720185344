import { Constants } from "appConstants";
import { IcoChevronLeft } from "assets/icons";
import { Button, Checkbox, Input } from "components";
import { EulaSections } from "components/EulaModal";
import { cnpj, cpf } from "cpf-cnpj-validator";
import { useFormik } from "formik";
import { ErrorHelper, MaskHelper } from "helpers";
import { UserInfo } from "models/auth/UserInfo";
import { ReactNode } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { UserInfoService } from "services/userInfo";
import { RootState } from "store";
import { setLoggedIn } from "store/features/auth/slice";
import * as Yup from "yup";

function EulaButton({
	children,
	onClick
}: Readonly<{
	children: ReactNode;
	onClick: () => void;
}>): JSX.Element {
	return (
		<button
			className="p-0 border-0 underline inline text-wrap"
			onClick={(evt) => {
				evt.preventDefault();
				evt.stopPropagation();
				onClick();
			}}
		>
			{children}
		</button>
	);
}

export default function SignUpForm({
	isCompany,
	onGoBack,
	setSelectedEulaSection
}: Readonly<{
	isCompany: boolean;
	onGoBack: () => void;
	setSelectedEulaSection: (newSelectedSection: EulaSections) => void;
}>) {
	let { user } = useSelector((state: RootState) => state.auth);
	const userInfoService = new UserInfoService();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const initialValues = {
		name: "",
		number: "",
		document: "",
		acceptedTerms: false
	};

	const minContactNumber = 10;

	const validationSchema = Yup.object().shape({
		name: Yup.string().required("Campo obrigatório"),
		number: Yup.string()
			.required("Campo obrigatório")
			.min(minContactNumber, "Telefone inválido"),
		document: Yup.string()
			.required("Campo obrigatório")
			.test(
				"document-valid",
				`${isCompany ? "CNPJ" : "CPF"} inválido`,
				(value) => {
					if (isCompany) {
						return cnpj.isValid(value || "");
					}
					return cpf.isValid(value || "");
				}
			),
		acceptedTerms: Yup.boolean().oneOf([true], "Campo obrigatório")
	});

	const formik = useFormik({
		initialValues,
		validationSchema,
		onSubmit: (data) => {
			const { email, userId } = user || { email: "", userId: "" };
			const documentType: Constants.UserDocumentType = isCompany
				? Constants.UserDocumentType.CNPJ
				: Constants.UserDocumentType.CPF;
			const contactNumber = Number(data.number.replace(/[^0-9]/g, ""));
			const userInfo = new UserInfo({
				userId,
				email,
				documentType,
				contactNumber,
				...data
			});
			userInfoService
				.createUserInfo(userInfo)
				.then((response) => {
					toast.success("Informações preenchidas!");
					const responseData = response.data;
					const { userName, ...rest } = responseData;
					user = { name: userName, ...rest };

					dispatch(setLoggedIn({ user }));
					navigate("/");
				})
				.catch((error) => {
					toast.error(ErrorHelper.getResponseErrorMessage(error));
				});
		}
	});

	return (
		<div className="h-full p-10 flex flex-col w-[100vw] md:w-[26.5rem] gap-6">
			<div className="font-bold text-2xl flex items-center">
				<Button
					kind="icon"
					hierarchy="ghost"
					className="cursor-pointer mr-2 p-1      "
					onClick={() => {
						formik.resetForm();
						onGoBack();
					}}
				>
					<IcoChevronLeft size="28" />
				</Button>
				Cadastrar
			</div>
			<Input
				label={isCompany ? "Razão Social" : "Nome e sobrenome"}
				placeholder={isCompany ? "Ex.: CR Engenharia LTDA" : "Ex.: José Maria"}
				value={formik.values.name}
				onChange={formik.handleChange}
				name="name"
			/>
			<Input
				label={isCompany ? "CNPJ" : "CPF"}
				placeholder={isCompany ? "00.000.000/0000.00" : "000.000.000-00"}
				value={formik.values.document}
				error={formik.errors.document}
				touched={formik.touched.document}
				onChange={(e) => {
					const maskMethod = isCompany ? MaskHelper.cnpj : MaskHelper.cpf;
					formik.setFieldValue("document", maskMethod(e.target.value));
				}}
				name="document"
			/>
			<Input
				label="Telefone"
				placeholder="(00) 00000-0000"
				value={formik.values.number}
				onChange={(e) =>
					formik.setFieldValue("number", MaskHelper.phone(e.target.value))
				}
				error={formik.errors.number}
				touched={formik.touched.number}
				name="number"
			/>
			<Input label="Email" disabled value={user?.email ?? ""} />
			<div className="flex flex-col justify-between w-full">
				<Checkbox
					iconSize="24"
					name="acceptedTerms"
					id="acceptedTerms"
					label="Li e concordo com os"
					value="1"
					checked={formik.values.acceptedTerms}
					onChange={formik.handleChange}
				/>
				<p className="text-sm">
					<EulaButton
						onClick={() => setSelectedEulaSection(EulaSections.TERMS)}
					>
						Termos e condições
					</EulaButton>
					{", "}
					<EulaButton
						onClick={() => setSelectedEulaSection(EulaSections.PRIVACY)}
					>
						Políticas de Privacidade
					</EulaButton>
					{" e "}
					<EulaButton
						onClick={() => setSelectedEulaSection(EulaSections.COOKIES)}
					>
						Política de Cookies
					</EulaButton>
					{" da plataforma."}
				</p>
			</div>
			<Button
				hierarchy="primary"
				className="mt-2"
				onClick={formik.handleSubmit}
				disabled={!formik.isValid || !formik.dirty}
			>
				Cadastrar
			</Button>
		</div>
	);
}
