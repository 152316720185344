import PolicyContent, { ContentItem } from "./PolicyContent";
import { Item, Ordered } from "./helperComponents";

const fddAddress = (
	<span className="break-all mx-1">
		https://intervencoes-faixa-dominio.grupoccr.com.br/
	</span>
);

const usePolicyStructure: ContentItem[] = [
	{
		title: "",
		content: (
			<>
				<p>Bem-vindo ao {fddAddress}!</p>
				<p>
					A seguir apresentamos os Termos e Condições de Uso (“Termos”),
					documento que relaciona as principais regras que devem ser observadas
					por todos que acessam o{fddAddress} da CCR S.A. do Grupo CCR
					responsável pelo site ou utilizam suas funcionalidades (“Você”). Como
					condição para acesso e uso das funcionalidades do
					{fddAddress}, Você declara que fez a leitura completa e atenta das
					regras deste documento, tomando plena ciência de seu conteúdo. Se Você
					estiver de acordo com as regras apresentadas, deverá se manifestar
					neste sentido ao cadastrar-se no
					{fddAddress}, por meio da seleção da checkbox “Li, compreendi e
					concordo com os Termos de Uso do
					{fddAddress}”, demonstrando seu consentimento livre, expresso e
					informado. Caso Você discorde de alguma das previsões destes Termos,
					solicitamos que não acesse ou utilize os serviços do
					{fddAddress}.
				</p>
			</>
		)
	},
	{
		title: <span>1. FUNCIONALIDADES DO {fddAddress}</span>,
		content: (
			<Ordered>
				<Item>
					1.1. Funcionalidade. O{fddAddress} consiste em uma plataforma para que
					os usuários conheçam o Grupo CCR e/ou a concessionária, obtenham
					informações sobre as particularidades do negócio e acessem documentos
					relevantes. Além disso, os fornecedores podem fazer pré-cadastro de
					suas empresas para eventuais participações em processos
					concorrenciais, por exemplo.
				</Item>
				<Item>
					1.2. Modo de Funcionamento do
					{fddAddress}. Os usuários podem interagir com o Grupo CCR e/ou a
					concessionária por meio do envio de mensagens no Fale Conosco e
					Ouvidoria.
				</Item>
				<Item>
					1.3. Maioridade. O acesso e utilização do
					{fddAddress} é livre para todos, porém algumas funcionalidades como
					cadastros em determinados ambientes podem exigir atos praticados por
					maiores de 18 (dezoito) anos, conforme legislação em vigor.
					<Ordered>
						<Item>
							1.3.1. Nestes casos, os representantes legais deverão
							supervisionar e representar as crianças e adolescentes com idade
							inferior a 16 (dezesseis) anos e assistir os adolescentes em idade
							superior à 16 (dezesseis) anos e inferior à 18 (dezoito) anos,
							sempre que aplicável.
						</Item>
						<Item>
							1.3.2. A CCR S.A. poderá recusar, não aprovar, cancelar ou limitar
							quaisquer solicitações, caso Você não forneça dados corretos.
						</Item>
						<Item>
							1.3.3. O mesmo será aplicável durante a utilização do
							{fddAddress}, se o mesmo for utilizado de forma fraudulenta, bem
							como que tente ou viole estes Termos ou quaisquer legislações
							vigentes.
						</Item>
					</Ordered>
				</Item>
				<Item>
					1.4. Elegibilidade. A CCR S.A. poderá alterar os critérios de
					elegibilidade de acesso e utilização do
					{fddAddress} a qualquer momento e a seu critério sem a necessidade de
					comunicação ou aviso prévio à Você ou qualquer terceiro.
				</Item>
				<Item>
					1.5. E-mails. Ao fornecer seus dados no
					{fddAddress}, Você concorda que poderá receber no e-mail e/ou telefone
					informados no cadastro, mensagens com avisos e conteúdo publicitário
					de produtos ou serviços da CCR S.A. e de seus parceiros. Através do
					próprio
					{fddAddress} Você terá sempre a opção de não receber tais
					comunicações.
				</Item>
				<Item>
					1.6. Apresentação do
					{fddAddress}. O{fddAddress} e suas funcionalidades são apresentadas a
					Você na maneira como estão disponíveis, podendo passar por constantes
					aprimoramentos e atualizações.
					<Ordered>
						<Item>
							1.6.1. Neste caso a CCR S.A. se compromete a preservar a
							funcionalidade do
							{fddAddress} com esforços razoáveis, com Links não quebrados e
							utilizando Layout que respeita a usabilidade e navegabilidade,
							sempre que possível, bem como exibir as funcionalidades de maneira
							clara, completa, precisa e suficiente de modo que exista uma
							correta percepção do que está sendo praticado.
						</Item>
					</Ordered>
				</Item>
			</Ordered>
		)
	},
	{
		title: <span>2. USO DO {fddAddress}</span>,
		content: (
			<Ordered>
				<Item>
					2.1. Integridade do
					{fddAddress}. Você se compromete a não acessar áreas de programação do
					{fddAddress}, seu banco de dados, códigos fonte ou qualquer outro
					conjunto de dados disponíveis estes ambientes, bem como não realizar
					ou permitir engenharia reversa, nem traduzir, decompilar, copiar,
					modificar, reproduzir, alugar, sublicenciar, publicar, divulgar,
					transmitir, emprestar, distribuir ou, de outra maneira, dispor
					inapropriadamente das funcionalidades do
					{fddAddress}.
				</Item>
				<Item>
					2.2. Não mineração. É vedado a utilização de softwares de mineração de
					dados do {fddAddress}, de qualquer tipo ou espécie, além de outro aqui
					não tipificado que atue de modo similar.
				</Item>
				<Item>
					2.3. Indenização. Na incidência de danos à CCR S.A. ou a terceiros, o
					responsável se compromete a arcar com todas as obrigações de indenizar
					o sujeito lesado, inclusive aqueles cuja origem for de atos praticados
					por meio de sua Conta de Acesso, assumindo o polo passivo de ação
					judicial ou procedimento administrativo e requerendo a exclusão da CCR
					S.A., devendo arcar totalmente com as despesas e custas processuais
					atinentes, deixando-a livre de prejuízos e ônus.
				</Item>
			</Ordered>
		)
	},
	{
		title: "3. CONTEÚDO, DADOS SUBMETIDOS E PROPRIEDADE INTELECTUAL",
		content: (
			<Ordered>
				<Item>
					3.1. Conteúdo. Exceto se estabelecido em contrário por estes Termos,
					todas as informações, notícias, imagens, ilustrações, ícones,
					tecnologias, marcas e demais conteúdos audiovisuais ou sonoros,
					incluindo o software do
					{fddAddress}, disponibilizados em qualquer idioma ou forma, são de
					exclusiva propriedade da CCR S.A. ou licenciado por terceiros à CCR
					S.A. (“Conteúdo”). A utilização do
					{fddAddress} não representa qualquer presunção transferência ou
					licença dessa propriedade. Por essa razão, Você não deverá
					comercializar no todo ou em parte o Conteúdo por quaisquer meios,
					onerosamente ou não, sabendo que se o fizer poderá ser penalizado
					civil e penalmente pela CCR S.A. e/ou por qualquer terceiro titular do
					Conteúdo.
					<Ordered>
						<Item>
							3.1.1. A eventual remoção, bloqueio ou suspensão de qualquer
							Conteúdo ou funcionalidade do
							{fddAddress} em decorrência de alguma reclamação, deverá ser
							sempre compreendida como demonstração de boa-fé e intenção de
							solução amigável de conflitos, jamais como reconhecimento de culpa
							ou de qualquer infração pela CCR S.A. a direito de terceiro.
						</Item>
					</Ordered>
				</Item>
				<Item>
					3.2. Seus Dados. Você é e continuará sendo o titular e proprietário de
					todos os dados, informações, textos, imagens, mensagens ou qualquer
					outro material de qualquer forma for submetido e/ou compartilhado no
					{fddAddress} por Você (“Seus Dados”) bem como será o exclusivo
					responsável por eles, cabendo exclusivamente à Você o cuidado em
					respeitar a propriedade intelectual de terceiros ou quaisquer outros
					direitos conexos.
				</Item>
				<Item>
					3.3. Legalidade dos Seus Dados. A CCR S.A. não se obrigará a processar
					ou tratar quaisquer dos Seus Dados se houver razões para crer que tal
					processamento ou tratamento possa imputar à CCR S.A. qualquer infração
					de qualquer lei aplicável ou que o{fddAddress} esteja sendo utilizado,
					sob critério exclusivo da CCR S.A., para quaisquer fins ilegais,
					ilícitos ou contrários à moralidade.
				</Item>
				<Item>
					3.4. Ausência de garantias quanto a Seus Dados e ao Conteúdo. A CCR
					S.A. não garante a qualidade, coerência, integridade, forma,
					atualização ou fonte do Conteúdo ou de Seus Dados, pois não controla,
					verifica ou faz juízo deste Conteúdo e de Seus Dados. A CCR S.A. não é
					responsável, portanto, por quaisquer inveracidade, desatualização ou
					erro deste Conteúdo ou de Seus Dados, bem como não é responsável por
					quaisquer danos oriundos deste Conteúdo, a qualquer título.
					<Ordered>
						<Item>
							3.4.1. Você em nenhuma hipótese deverá tomar qualquer decisão,
							onerosa ou não, baseado integralmente no Conteúdo ou em qualquer
							funcionalidade do
							{fddAddress}
						</Item>
					</Ordered>
				</Item>
				<Item>
					3.5. Armazenamento. A CCR S.A. não presta e o{fddAddress} não
					constitui serviço de armazenamento, portanto a CCR S.A. não possui
					qualquer obrigação de armazenar, reter ou fornecer à Você ou a
					quaisquer terceiros cópia de Seus Dados ou qualquer Conteúdo.
				</Item>
				<Item>
					3.6. Inteligência algorítmica. O{fddAddress}, de forma impessoal e
					automática, poderá aplicar modelos estatísticos e inteligência
					algorítmica para indexar, filtrar e organizar o Conteúdo ou Seus
					Dados, gerados ou compartilhados por meio do
					{fddAddress}.
					<Ordered>
						<Item>
							3.6.1. Você se declara ciente que a CCR S.A., senão por seu
							exclusivo critério, não divulgará ou fornecerá à Você ou a
							qualquer terceiro quaisquer informações sistêmicas ou algorítmicas
							relacionadas ao
							{fddAddress}.
						</Item>
					</Ordered>
				</Item>
			</Ordered>
		)
	},
	{
		title: "4. SUA PRIVACIDADE E SEUS DADOS PESSOAIS",
		content: (
			<Ordered>
				<Item>
					4.1. Dados Pessoais. Caso Você trate ou transmita no âmbito do
					{fddAddress} informações relativas a uma pessoa física identificada ou
					identificável (“Dados Pessoais”), Você será o exclusivo responsável
					por coletar as autorizações necessárias perante o titular dos Dados
					Pessoais.
					<Ordered>
						<Item>
							4.1.1. A CCR S.A. monitorará, por meios adequados, sua própria
							conformidade com as respectivas obrigações de proteção de Dados
							Pessoais, caso aplicável.
						</Item>
					</Ordered>
				</Item>
				<Item>
					4.2. Registros. A CCR S.A. poderá registrar todas as atividades
					efetuadas por Você no
					{fddAddress}, incluindo dados de identificação, do dispositivo e da
					conexão utilizada (“Registros”).
				</Item>
				<Item>
					4.3. Finalidade da Coleta. Os Registros poderão ser utilizados com a
					finalidade de: (i) identificar Você e atendê-lo (ii) cumprir as
					obrigações destes Termos (iii) aperfeiçoar o{fddAddress}; (iv)
					resguardar direitos e obrigações relacionadas ao uso do
					{fddAddress}; e (v) cumprir ordem judicial e/ou de autoridade
					administrativa.
				</Item>
			</Ordered>
		)
	},
	{
		title: "5.	ISENÇÃO E LIMITAÇÕES DE RESPONSABILIDADE",
		content: (
			<Ordered>
				<Item>
					5.1. A CCR S.A. envida seus esforços para a manutenção da
					disponibilidade contínua e permanente do
					{fddAddress}. No entanto, pode ocorrer, eventualmente, alguma
					indisponibilidade temporária decorrente de manutenção necessária ou
					mesmo gerada por motivo de força maior, como desastres naturais,
					falhas nos sistemas de comunicação e acesso à Internet, ataques
					cibernético invasivos, ou quaisquer fatos de terceiro que fogem da
					esfera de vigilância e responsabilidade da CCR S.A..
					<Ordered>
						<Item>
							5.1.1. Se isso ocorrer, a CCR S.A. fará o que estiver ao seu
							alcance para restabelecer o acesso ao
							{fddAddress} o mais breve possível, dentro das limitações técnicas
							de seus serviços e serviços de terceiros, dos quais depende para
							ficar online. Por essa razão, Você fica ciente que não poderá
							pleitear indenização ou reparação de danos no caso de o
							{fddAddress} permanecer fora do ar, independente da motivação.
						</Item>
						<Item>
							5.1.2. Eventuais procedimentos de manutenção que acarretem a
							indisponibilidade do
							{fddAddress} por longos períodos serão informados por meio dos
							canais oficiais de comunicação da CCR S.A..
						</Item>
					</Ordered>
				</Item>
				<Item>
					5.2. Sem prejuízo de outras condições destes Termos, a CCR S.A. não se
					responsabiliza:
					<Ordered>
						<Item>
							i. Por quaisquer problemas, bugs, glitches ou funcionamentos
							indevidos que ocorrerem nos seus dispositivos e equipamentos;
						</Item>
						<Item>
							ii. Por qualquer dano direto ou indireto ocasionado por eventos de
							terceiros, a exemplo, mas não se limitando a ataque de hackers,
							falhas no sistema, no servidor ou na conexão à internet, inclusive
							por ações de softwares que possam, de algum modo, danificar seus
							ativos físicos ou lógicos em decorrência do acesso, utilização ou
							navegação no {fddAddress}, bem como a transferência de dados,
							arquivos, imagens, textos, áudios ou vídeos contidos no
							{fddAddress};
						</Item>
						<Item>
							iii. Pela sua navegação em quaisquer links externos
							disponibilizados no
							{fddAddress}, sendo seus deveres a leitura dos Termos e Condições
							de Uso e da Política de Privacidade do recurso acessado e agir
							conforme o determinado; e
						</Item>
						<Item>
							iv. Por verificar, controlar, aprovar ou garantir a adequação ou
							exatidão das informações ou dados disponibilizados em tais links,
							não sendo, portanto, responsáveis por prejuízos, perdas ou danos
							ocorridos pela visita de tais sites, cabendo ao interessado
							verificar a confiabilidade das informações e dados ali exibidos
							antes de tomar alguma decisão ou praticar algum ato.
						</Item>
					</Ordered>
				</Item>
			</Ordered>
		)
	},
	{
		title: "6.	OUTRAS DISPOSIÇÕES",
		content: (
			<Ordered>
				<Item>
					6.1. Canais de Atendimento. Você poderá utilizar os Canais de
					Comunicação disponibilizados no
					{fddAddress} toda vez que tiver alguma dúvida, sugestão ou reclamação,
					seja sobre o{fddAddress} ou sobre estes Termos.
				</Item>
				<Item>
					6.2. Atualização dos Termos. Estes Termos estão sujeitos a constante
					melhoria e aprimoramento. Assim, a CCR S.A. se reserva o direito de
					modificá-los a qualquer momento, conforme sua finalidade ou
					conveniência, tal qual para adequação e conformidade legal de
					disposição de lei ou norma que tenha força jurídica equivalente,
					incluindo de órgãos reguladores, ou seja, Você deverá verificá-lo
					sempre que efetuar o acesso no
					{fddAddress}.
					<Ordered>
						<Item>
							6.2.1. Ocorrendo atualizações nestes Termos, a CCR S.A. notificará
							Você pelas ferramentas disponíveis no
							{fddAddress} ou pelos meios de contato fornecidos por Você.
						</Item>
						<Item>
							6.2.2. Ao navegar pelo
							{fddAddress} e utilizar suas funcionalidades, Você aceita guiar-se
							pelos Termos que se encontram vigentes na data de acesso. Por
							isso, é recomendável que Você se mantenha atualizado das condições
							vigentes.
						</Item>
					</Ordered>
				</Item>
				<Item>
					6.3. Novação e renúncia. A tolerância do eventual descumprimento de
					quaisquer condições destes Termos não constituirá renúncia ou novação
					nem impedirá a CCR S.A. de exigir estas condições a qualquer tempo.
				</Item>
				<Item>
					6.4. Nulidade. Se alguma disposição destes Termos for julgada
					inaplicável ou sem efeito, o restante continuará a viger normalmente.
				</Item>
				<Item>
					6.5. Comunicação. Você reconhece que toda comunicação realizada por
					e-mail (ao endereço de e-mail informado no seu cadastro) é válida,
					eficaz e suficiente para a divulgação de qualquer assunto que se
					refira ao {fddAddress}.
				</Item>
				<Item>
					6.6. Data e hora. O{fddAddress} tem como base a data e horários
					oficiais de Brasília (UTC/GMT -03:00).
				</Item>
				<Item>
					6.7. Lei aplicável e foro. Estes Termos serão interpretados segundo a
					legislação brasileira, no idioma português, e fica eleito o foro da
					Comarca da comarca do seu domicílio (Você) para dirimir qualquer
					controvérsia, salvo se a legislação aplicável ressalvar
					especificamente outra competência pessoal, territorial ou funcional.
				</Item>
			</Ordered>
		)
	},
	{
		title: "7.	GLOSSÁRIO",
		content: (
			<Ordered>
				<Item>
					i. CCR S.A.: CCR S.A., pessoa jurídica de direito privado, com sede na
					Avenida Chedid Jafet, 222, Bloco B, 4º andar, Bairro Vila Olímpia,
					Município de São Paulo, Estado de São Paulo, inscrita no CNPJ/MF sob
					nº 02.846.056/0001-97, neste ato representada na forma prevista em seu
					Estatuto Social
				</Item>
				<Item>
					ii. {fddAddress}: Ferramenta online, disponibilizada pela CCR S.A.
					para o site consiste em uma plataforma para que os usuários conheçam o
					Grupo CCR e/ou a concessionária, obtenham informações sobre as
					particularidades do negócio e acessem documentos relevantes.
				</Item>
				<Item>
					iii. Anti-Spam: Sistema que evita correspondências não desejadas, como
					publicidade em massa, pelo bloqueio de mensagens ou as movendo para
					pasta específica.
				</Item>
				<Item>
					iv. Canais de Comunicação:
					<span className="break-all mx-1">
						https://www.grupoccr.com.br/contatos/
					</span>
				</Item>
				<Item>
					v. Layout: Conjunto compreendido entre aparência, design e fluxos do
					{fddAddress}.
				</Item>
				<Item>vi. Link: Terminologia para endereço de internet.</Item>
			</Ordered>
		)
	}
];

export default function TermsOfUse(): JSX.Element {
	return <PolicyContent content={usePolicyStructure} />;
}
