import { ApplicationStatus } from "models/types";
import {
	PersonTypeOptions,
	ProprietorDocumentType
} from "pages/NewApplication/steps/types";

const initialApplicationDescriptionData = {
	initialKilometer: undefined,
	initialMeter: undefined,
	initialUf: "",
	finalKilometer: undefined,
	finalMeter: undefined,
	finalUf: "",
	directions: []
};

const interventionApplicationMock = {
	status: ApplicationStatus.RASCUNHO,
	highwayConcession: null,
	interventionType: null,
	interventionTypeCategory: null,
	...initialApplicationDescriptionData,
	description: "",
	applicationProprietorData: {
		proprietorType: PersonTypeOptions.physical,

		proprietorAddressPostalCode: "",
		proprietorAddressState: "",
		proprietorAddressCity: "",
		proprietorAddressNeighborhood: "",
		proprietorAddressStreet: "",
		proprietorAddressNumber: "",
		proprietorAddressComplement: "",

		proprietorEmail: "",
		proprietorName: "",
		proprietorPhoneNumber: "",
		proprietorDocument: "",
		proprietorDocumentType: ProprietorDocumentType.CPF,
		proprietorBirthDate: "",
		proprietorCityRegistration: "",
		proprietorStateRegistration: "",
		proprietorContactName: "",

		applicantEmail: "",
		applicantName: "",
		applicantRole: "",
		applicantPhoneNumber: "",
		additionalContactEmail: ""
	},
	documents: []
};

export { interventionApplicationMock };
