import { Button, Container } from "components";

import clsx from "clsx";
import { HeaderInfoProps } from "./types";

import "./styles.scss";

export default function HeaderInfo({
	icon,
	title,
	subtitle,
	href,
	buttonText,
	className
}: HeaderInfoProps) {
	return (
		<div className="container-header-info">
			<Container
				className={clsx("stack--3 items-center text-center mx-auto", className)}
			>
				<div className="icon">{icon && icon}</div>
				<p className="headline-1">{title}</p>
				{subtitle && (
					<p className="headline-4 text-neutral-low-400">{subtitle}</p>
				)}
				{href && buttonText && (
					<a className="mt-8" href={href}>
						<Button hierarchy="primary">{buttonText}</Button>
					</a>
				)}
			</Container>
		</div>
	);
}
