import { StepProps } from "./types";

import "./styles.scss";

export default function Step({ title, description, image }: StepProps) {
	return (
		<div className="container-step">
			<img src={image} alt={title} />
			<div className="content">
				<h6 className="mb-1">{title}</h6>
				<p className="text-sm text-neutral-low-400">{description}</p>
			</div>
		</div>
	);
}
