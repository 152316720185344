import { ReactNode } from "react";

export function Td({
	children,
	...rest
}: { children: ReactNode } & Record<string, any>): JSX.Element {
	return (
		<td className="border border-neutral-high-200 md:p-1" {...rest}>
			{children}
		</td>
	);
}

export function Th({
	children,
	...rest
}: { children: ReactNode } & Record<string, any>): JSX.Element {
	return (
		<th className="border border-neutral-high-200 md:p-1" {...rest}>
			{children}
		</th>
	);
}

export function Ordered({ children }: { children: ReactNode }): JSX.Element {
	return <ol className="pl-1">{children}</ol>;
}

export function Item({ children }: { children: ReactNode }): JSX.Element {
	return <li className="py-1">{children}</li>;
}
