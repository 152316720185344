import { StringHelper } from "helpers";
import { OptionLike } from "helpers/OptionHelper";
import { BaseModel } from "./BaseModel";
import { Concessionaire } from "./Concessionaire";
import { Highway } from "./Highway";
import { HighwayConcessionData, HighwayProps } from "./types";

export class HighwayConcession extends BaseModel<HighwayConcessionData> {
	id: string;
	description: string;
	initialKilometer: number;
	finalKilometer: number;
	initialMeter: number;
	finalMeter: number;
	sumKilometer: number;
	initialUf: string;
	finalUf: string;
	imageUrl: string;
	concessionaire: Concessionaire;
	highway: Highway;

	constructor(data: HighwayConcessionData) {
		super(data);
		this.id = data.id;
		this.description = data.description;
		this.initialKilometer = data.initialKilometer;
		this.finalKilometer = data.finalKilometer;
		this.initialMeter = data.initialMeter;
		this.finalMeter = data.finalMeter;
		this.sumKilometer = data.sumKilometer;
		this.initialUf = data.initialUf;
		this.finalUf = data.finalUf;
		this.imageUrl = data.imageUrl;
		this.concessionaire = Concessionaire.getValueOrNew(data.concessionaire);
		this.highway = Highway.getValueOrNew(data.highway);
	}

	toString() {
		return `${this.highway.name} (${this.highway.code}) - ${this.concessionaire.name}`;
	}

	toShortString() {
		return `${this.highway.name} (${this.highway.code})`;
	}

	positionRepresentation(position: "initial" | "final") {
		if (position === "initial") {
			return `km ${
				this.initialKilometer
			} + ${StringHelper.formattedNumber3Digits(this.initialMeter)} m - ${
				this.initialUf
			}`;
		}
		return `km ${this.finalKilometer} + ${StringHelper.formattedNumber3Digits(
			this.finalMeter
		)} m - ${this.finalUf}`;
	}

	toOption = (): OptionLike => {
		return { ...this, label: this.toString(), value: this.id };
	};

	static createFromResponse(response: HighwayConcessionData) {
		if (!response) {
			return response;
		}

		const highwayData: HighwayProps = response.highway || {
			name: response.highwayName,
			code: response.highwayCode,
			ascendingDirection: response.highwayAscendingDirection,
			descendingDirection: response.highwayDescendingDirection
		};

		return new this({
			...response,
			highway: highwayData
		});
	}
}
