import { KeyboardEvent, ReactNode, useCallback } from "react";

export default function ClickableCard({
	children,
	onClick,
	className,
	...rest
}: {
	children: ReactNode;
	onClick: () => void;
	className: string;
	[x: string]: any;
}): JSX.Element {
	const onKeyDown = useCallback(
		(event: KeyboardEvent<HTMLImageElement>) => {
			if (event.key === "Enter" || event.key === " ") {
				onClick();
			}
		},
		[onClick]
	);

	return (
		<div
			role="button"
			tabIndex={0}
			onClick={onClick}
			onKeyDown={onKeyDown}
			className={className}
			{...rest}
		>
			{children}
		</div>
	);
}
