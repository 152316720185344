import { IconProps } from "./types";

export default function IcoInfoCircle({
	color = "#1D1D1D",
	size = "24"
}: IconProps) {
	return (
		<svg
			width={size}
			height={size}
			viewBox="0 0 24 25"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<circle cx="12" cy="12.0966" r="8" fill={color} stroke={color} />
			<path
				d="M12 11.0966L12 16.0966"
				stroke="white"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<circle cx="12" cy="8.09656" r="1" fill="white" />
		</svg>
	);
}
