import { Constants } from "appConstants";

export interface LoginProps {
	email: string;
	password: string;
}

export interface AccessProfileProps {
	id: number;
	name: string;
}

export enum FeatureFlag {
	CREATE_APPLICATION = "CREATE_APPLICATION"
}
export interface LoggedUserData {
	authorities: UserAuthorities[];
	userOrigin: UserOrigin;
	email: string;
	name: string;
	features: Record<FeatureFlag, boolean>;
}

export enum UserAuthorities {
	READ = "read",
	UPDATE = "update",
	CREATE = "create",
	ACESSO_SSO = "ROLE_ACESSO_SSO",
	SOLICITANTE = "ROLE_SOLICITANTE",

	ADMINISTRADOR = "ROLE_ADMINISTRADOR",
	ANALISTA = "ROLE_ANALISTA",
	GERENTE = "ROLE_GERENTE",
	SUPERVISOR = "ROLE_SUPERVISOR",

	ESTADUAL_SP = "ROLE_ESTADUAL_SP",
	FEDERAL = "ROLE_FEDERAL",

	CENTRAL = "ROLE_CENTRAL",
	FOCAL = "ROLE_FOCAL",
	ENGENHARIA = "ROLE_ENGENHARIA"
}

export enum UserOrigin {
	INTERNAL = "INTERNAL",
	EXTERNAL = "EXTERNAL"
}

export interface UserInfoProps {
	userId: string;
	email: string;
	name: string;
	contactNumber: number;
	document: string;
	documentType: Constants.UserDocumentType;
	acceptedTerms?: boolean;
}

export interface UserVerifyReturn extends Omit<UserInfoProps, "name"> {
	userName: string;
}
