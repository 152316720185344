import { IcoInfoCircle } from "assets/icons";

export default function AlertCard({
	children
}: {
	children: JSX.Element[] | JSX.Element | string;
}) {
	return (
		<div className="flex gap-4 bg-neutral-high-100 px-4 py-5 rounded-lg">
			<div>
				<IcoInfoCircle size="24" />
			</div>
			<div>{children}</div>
		</div>
	);
}
