import { IconProps } from "./types";

export default function IcoSearch({
	color = "#999999",
	size = "16"
}: Readonly<IconProps>) {
	return (
		<svg
			width={size}
			height={size}
			viewBox="0 0 16 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M11.4241 11.3536L13.9979 13.9968M7.5 1.99988C10.5376 1.99988 13 4.46232 13 7.49988C13 10.5374 10.5376 12.9999 7.5 12.9999C4.46243 12.9999 2 10.5374 2 7.49988C2 4.46232 4.46243 1.99988 7.5 1.99988Z"
				stroke={color}
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
}
