import { ApplicationFormData } from "pages/NewApplication/steps/types";

import { useCallback, useState } from "react";

function parseIfNotNull<T>(s: string | null): T | null {
	return s && JSON.parse(s);
}

const LocalStorageHelper = {
	// AADB2C
	aadb2cItemKey: "aadb2c_local_storage",
	applicationDraftKey: "application_draft",
	internalToken: "internal_token",

	setInternalToken(value: string) {
		localStorage.setItem(LocalStorageHelper.internalToken, value);
	},

	removeInternalToken() {
		localStorage.removeItem(LocalStorageHelper.internalToken);
	},

	getInternalToken() {
		return localStorage.getItem(LocalStorageHelper.internalToken);
	},

	setItemAADB2C(value: any) {
		localStorage.setItem(LocalStorageHelper.aadb2cItemKey, value);
	},
	getItemAADB2C() {
		return localStorage.getItem(LocalStorageHelper.aadb2cItemKey) || "";
	},
	removeItemAADB2C() {
		localStorage.removeItem(LocalStorageHelper.aadb2cItemKey);
	},

	getApplicationDraft(): ApplicationFormData | null {
		const dataString = localStorage.getItem(
			LocalStorageHelper.applicationDraftKey
		);
		return dataString && JSON.parse(dataString);
	},
	setApplicationDraft(draftData: ApplicationFormData): void {
		localStorage.setItem(
			LocalStorageHelper.applicationDraftKey,
			JSON.stringify(draftData)
		);
	},
	removeApplicationDraft(): void {
		localStorage.removeItem(LocalStorageHelper.applicationDraftKey);
	},

	useLocalStorageState<T>(
		key: string,
		toString: (value: T | null) => string = JSON.stringify,
		toValue: (stringifiedValue: string | null) => T | null = parseIfNotNull
	): [T | null, (newValue: T | null) => void] {
		const [stateValue, setStateValue] = useState<T | null>(() =>
			toValue(localStorage.getItem(key))
		);
		const stateSetter = useCallback(
			(newValue: T | null) => {
				const newValueString = toString(newValue);
				localStorage.setItem(key, newValueString);
				setStateValue(newValue);
			},
			[key, setStateValue]
		);
		return [stateValue, stateSetter];
	}
};

export default LocalStorageHelper;
