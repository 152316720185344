import { Button, Loading, Stepper } from "components";
import Drawer from "components/Drawer";
import { StepConfig } from "components/Stepper/types";
import {
	FormikErrors,
	FormikProps,
	FormikTouched,
	setNestedObjectValues,
	useFormik,
	validateYupSchema,
	yupToFormErrors
} from "formik";
import { ApplicationDataHelper, ErrorHelper, ValidationHelper } from "helpers";
import { HighwayConcession } from "models/HighwayConcession";
import { ApplicationStatus } from "models/types";
import { toast } from "react-toastify";

import { assignFieldValues } from "helpers/FieldHandlerHelper";
import useInterval from "hooks/useInterval";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import { MOBILE_MINIMUM_WIDTH, useIsMobile } from "hooks/useIsMobile";
import { Application } from "models/Application";
import { ApplicationDocumentService } from "services/applicationDocument";
import { ApplicationService } from "services/applicationService";
import { HighwayConcessionService } from "services/highwayConcession";
import {
	ApplicationFormDocumentFolder,
	DocumentFolderType,
	DocumentFolderTypesAndFileExtensions
} from "services/types";
import * as Yup from "yup";
import {
	ApplicationDataStep,
	ApplicationDocumentsStep,
	Highway,
	Intervention
} from "./steps";
import {
	applicationDataSectionsFields,
	applicationDescriptionDataSchema,
	applicationProprietorDataSchema
} from "./steps/ApplicationData";

import { applicationDocumentFolderSchema } from "./steps/ApplicationDocuments";
import ApplicationReview from "./steps/ApplicationReview";
import {
	ApplicationData,
	ApplicationFields,
	ApplicationFormData,
	ApplicationStep,
	PersonTypeOptions,
	ProprietorDocumentType
} from "./steps/types";

const AUTOSAVE_DELAY = 30 * 1000;

const initialApplicationDescriptionData = {
	initialKilometer: null,
	initialMeter: null,
	initialUf: null,
	finalKilometer: null,
	finalMeter: null,
	finalUf: null,
	description: null,
	directions: []
};

const initialValues: ApplicationFormData = {
	status: ApplicationStatus.RASCUNHO,
	highwayConcession: null,
	interventionType: null,
	interventionTypeCategory: null,
	...initialApplicationDescriptionData,
	applicationProprietorData: {
		proprietorType: PersonTypeOptions.physical,

		proprietorAddressPostalCode: null,
		proprietorAddressState: null,
		proprietorAddressCity: null,
		proprietorAddressNeighbourhood: null,
		proprietorAddressStreet: null,
		proprietorAddressNumber: null,
		proprietorAddressComplement: null,

		proprietorEmail: null,
		proprietorName: null,
		proprietorPhoneNumber: null,
		proprietorDocument: null,
		proprietorDocumentType: ProprietorDocumentType.CPF,
		proprietorBirthDate: null,
		proprietorCityRegistration: null,
		proprietorStateRegistration: null,
		proprietorContactName: null,

		applicantEmail: null,
		applicantName: null,
		applicantRole: null,
		applicantPhoneNumber: null,
		additionalContactEmail: null
	},
	documentFolders: {}
};

const applicationStepsDisplay: Record<ApplicationStep, StepConfig> = {
	[ApplicationStep.Highway]: {
		hideStepsIndicator: false
	},
	[ApplicationStep.Intervention]: {
		hideStepsIndicator: false
	},
	[ApplicationStep.ApplicationData]: {
		hideStepsIndicator: false
	},
	[ApplicationStep.ApplicationDocuments]: {
		hideStepsIndicator: false
	},
	[ApplicationStep.ApplicationReview]: {
		hideStepsIndicator: true,
		nextStepLabel: "Enviar solicitação"
	}
};

function mergeDocumentFoldersAndPreserveErrors(
	foldersWithErrors: Record<string, ApplicationFormDocumentFolder>,
	cleanFolders: Record<string, ApplicationFormDocumentFolder>
): Record<string, ApplicationFormDocumentFolder> {
	const dirtyFolders = Object.fromEntries(
		Object.entries(cleanFolders).map(
			([folderTypeId, newFolder]): [string, ApplicationFormDocumentFolder] => [
				folderTypeId,
				{
					...newFolder,
					documents: [
						...foldersWithErrors[folderTypeId].documents.filter(
							(doc) => !!doc.error
						),
						...newFolder.documents
					]
				}
			]
		)
	);

	const unsynchronizedFolders = Object.fromEntries(
		Object.entries(foldersWithErrors).map(
			([folderTypeId, folderWithError]): [
				string,
				ApplicationFormDocumentFolder
			] => {
				const newFolderWithErrorData = { ...folderWithError };
				delete newFolderWithErrorData.id;
				return [folderTypeId, newFolderWithErrorData];
			}
		)
	);
	return { ...unsynchronizedFolders, ...dirtyFolders };
}

const validateFinishedHighwayStep = (
	formik: FormikProps<ApplicationFormData>
) => !!formik.values.highwayConcession;
const validateFinishedInterventionStep = (
	formik: FormikProps<ApplicationFormData>
) => {
	const intervention = formik.values.interventionType;
	return intervention?.categories?.length
		? !!(intervention && formik.values.interventionTypeCategory)
		: !!intervention;
};
const validateFinishedDataStep = (
	errors: FormikErrors<ApplicationFormData>
) => {
	const errorsExceptDocuments = Object.keys(errors).reduce(
		(acc: string[], key: any) => {
			if (key !== "documentFolders") {
				acc.push(key);
			}
			return acc;
		},
		[]
	);
	return errorsExceptDocuments.length === 0;
};
const validateFinishedDocumentStep = (
	formik: FormikProps<ApplicationFormData>,
	hasDocumentUploadError = false,
	hasLoadedDocumentFolderTypes = false
) =>
	hasLoadedDocumentFolderTypes &&
	Object.keys(formik.errors).length === 0 &&
	Object.entries(formik.values.documentFolders)?.length > 0 &&
	!hasDocumentUploadError;

const isStepCompleted = (
	step: ApplicationStep,
	formik: FormikProps<ApplicationFormData>,
	hasDocumentUploadError = false,
	hasLoadedDocumentFolderTypes = false
): boolean => {
	if (step === ApplicationStep.Highway)
		return validateFinishedHighwayStep(formik);
	if (step === ApplicationStep.Intervention)
		return validateFinishedInterventionStep(formik);

	if (step === ApplicationStep.ApplicationData)
		return validateFinishedDataStep(formik.errors);

	if (step === ApplicationStep.ApplicationDocuments)
		return validateFinishedDocumentStep(
			formik,
			hasDocumentUploadError,
			hasLoadedDocumentFolderTypes
		);

	if (step === ApplicationStep.ApplicationReview) return true;
	return false;
};

export default function NewApplication({
	savedApplicationData,
	setSavedApplicationData
}: Readonly<{
	savedApplicationData: ApplicationFormData | null;
	setSavedApplicationData: (newData: ApplicationFormData | null) => void;
}>) {
	const navigate = useNavigate();
	const isMobile = useIsMobile(MOBILE_MINIMUM_WIDTH.SMALL);

	const [formikHasValidated, setFormikHasValidated] = useState<boolean>(false);

	const [isLoadingHighways, setIsLoadingHighways] = useState(false);
	const [isLoadingFolderTypes, setIsLoadingFolderTypes] = useState(false);

	const highwayConcessionService = useMemo(
		HighwayConcessionService.getInstance,
		[]
	);
	const [highways, setHighways] = useState<HighwayConcession[] | null>(null);

	useEffect(() => {
		if (highways === null) {
			setIsLoadingHighways(true);
			highwayConcessionService
				.listAll()
				.then((response) => {
					setHighways(response);
				})
				.catch((e) => toast.error(ErrorHelper.getResponseErrorMessage(e)))
				.finally(() => setIsLoadingHighways(false));
		}
	}, [highways]);

	const interventionApplicationService = useMemo(
		ApplicationService.getInstance,
		[]
	);
	const applicationDocumentService = useMemo(
		ApplicationDocumentService.getInstance,
		[]
	);
	const [preservedTouched, setPreservedTouched] = useState<
		FormikTouched<ApplicationFormData>
	>({});
	const [preservedErrors, setPreservedErrors] = useState<
		FormikErrors<ApplicationFormData>
	>({});

	const applicationId = savedApplicationData?.id;
	const [documentFolderTypesData, setDocumentFolderTypesData] =
		useState<DocumentFolderTypesAndFileExtensions | null>(null);

	const loadDocumentFolderTypes = useCallback(
		(_applicationId: string) => {
			setIsLoadingFolderTypes(true);
			applicationDocumentService
				.getDocumentFolderTypesByApplication(_applicationId)
				.then((responseData) => {
					if (Array.isArray(responseData.mandatoryDocumentFolderTypes)) {
						responseData.documentFolderTypes =
							responseData.documentFolderTypes.concat(
								responseData.mandatoryDocumentFolderTypes
							);
						responseData.mandatoryDocumentFolderTypes = [];
					}
					setDocumentFolderTypesData(responseData);
				})
				.finally(() => setIsLoadingFolderTypes(false));
		},
		[applicationDocumentService]
	);

	useEffect(() => {
		if (
			applicationId &&
			savedApplicationData?.highwayConcession?.id &&
			savedApplicationData.interventionType?.id
		) {
			loadDocumentFolderTypes(applicationId);
		}
	}, [
		applicationId,
		savedApplicationData?.highwayConcession?.id,
		savedApplicationData?.interventionType?.id,
		savedApplicationData?.interventionTypeCategory?.id
	]);

	const emptyDocumentFolders = useMemo<
		Record<string, ApplicationFormDocumentFolder>
	>(() => {
		if (!documentFolderTypesData) return {};
		return Object.fromEntries(
			documentFolderTypesData.documentFolderTypes.map(
				(folderType): [string, ApplicationFormDocumentFolder] => [
					folderType.id,
					{
						documents: [],
						interventionDocumentFolderType: folderType.id
					}
				]
			)
		);
	}, [documentFolderTypesData?.documentFolderTypes]);

	const initialDataWithDocumentFolders = useMemo<ApplicationFormData>(
		() => ({ ...initialValues, documentFolders: { ...emptyDocumentFolders } }),
		[emptyDocumentFolders]
	);
	const savedApplicationDataWithDocumentFolders =
		useMemo<ApplicationFormData | null>(
			() =>
				savedApplicationData && {
					...savedApplicationData,
					documentFolders: {
						...emptyDocumentFolders,
						...savedApplicationData?.documentFolders
					}
				},
			[savedApplicationData, emptyDocumentFolders]
		);

	const saveApplicationData = useCallback(
		async (data: ApplicationFormData) => {
			const apiCall = applicationId
				? interventionApplicationService.editDraftApplication(
						applicationId,
						data
				  )
				: interventionApplicationService.createDraftApplication(data);

			return apiCall
				.then((responseData) => {
					const newData =
						responseData instanceof Application
							? (responseData.props as ApplicationData)
							: responseData;
					const formattedResponseData =
						ApplicationDataHelper.formatResponseDataDocuments(newData);
					const formattedNewData: ApplicationFormData = {
						...data,
						...formattedResponseData,
						documentFolders: mergeDocumentFoldersAndPreserveErrors(
							data.documentFolders,
							formattedResponseData.documentFolders
						)
					};
					setSavedApplicationData(formattedNewData);
				})
				.catch((err) => {
					setSavedApplicationData({ ...data });
					throw err;
				});
		},
		[applicationId, setSavedApplicationData]
	);

	const [isCommitting, setIsCommitting] = useState<boolean>(false);
	const commitApplication = useCallback(
		async (data: ApplicationFormData) => {
			if (!applicationId) {
				return Promise.resolve();
			}
			setIsCommitting(true);
			return interventionApplicationService
				.commitApplication(applicationId, data)
				.then((response) => {
					setSavedApplicationData({
						...savedApplicationData,
						applicationProcessCode: response.application.applicationProcessCode
					});
					navigate("./sucesso");
				})
				.catch(() =>
					toast.error(
						"Erro ao enviar solicitação. Verifique os dados preenchidos."
					)
				)
				.finally(() => setIsCommitting(false));
		},
		[
			applicationId,
			interventionApplicationService,
			setSavedApplicationData,
			navigate
		]
	);

	const folderTypeById: Record<string, DocumentFolderType> = useMemo(
		() =>
			Object.fromEntries(
				(documentFolderTypesData?.documentFolderTypes ?? []).map(
					(folderType) => [folderType.id, folderType]
				)
			),
		[documentFolderTypesData?.documentFolderTypes]
	);

	const validationSchema = useMemo(
		() =>
			Yup.object().shape({
				highwayConcession: Yup.object()
					.nullable()
					.required("Campo obrigatório"),
				interventionType: Yup.object().nullable().required("Campo obrigatório"),
				interventionTypeCategory: Yup.object()
					.nullable()
					// eslint-disable-next-line func-names
					.test("notNull", "Campo obrigatório", function (val) {
						// eslint-disable-next-line react/no-this-in-sfc
						const { interventionType } = this.parent as ApplicationData;
						const categoryIsRequired = !!interventionType?.categories?.length;
						return !categoryIsRequired || val !== null;
					}),
				applicationProprietorData: applicationProprietorDataSchema,
				documentFolders: Yup.lazy((value) =>
					ValidationHelper.buildDocumentFoldersSchema(
						Object.keys(value),
						folderTypeById,
						applicationDocumentFolderSchema
					)
				),
				...applicationDescriptionDataSchema
			}),
		[folderTypeById]
	);

	const formik = useFormik({
		initialValues: savedApplicationDataWithDocumentFolders
			? {
					...initialDataWithDocumentFolders,
					...savedApplicationDataWithDocumentFolders
			  }
			: initialDataWithDocumentFolders,
		validate: (values: ApplicationFormData) => {
			try {
				// Now we can access context values with `this.options.context`
				const context = { values };
				validateYupSchema(values, validationSchema, true, context);
			} catch (err) {
				setFormikHasValidated(true);
				return yupToFormErrors(err);
			}
			setFormikHasValidated(true);
			return {};
		},
		enableReinitialize: true,
		validateOnMount: true,
		validateOnBlur: true,
		initialTouched: preservedTouched,
		initialErrors: preservedErrors,
		onSubmit: saveApplicationData
	});
	const { setFieldValue, dirty, validateForm } = formik;

	useEffect(() => {
		validateForm();
	}, [validateForm, validationSchema]);

	useEffect(() => {
		const validSavedFolders = Object.fromEntries(
			Object.entries(
				savedApplicationDataWithDocumentFolders?.documentFolders ?? {}
			).filter(([folderTypeId]) => folderTypeId in folderTypeById)
		);
		setFieldValue("documentFolders", {
			...initialDataWithDocumentFolders.documentFolders,
			...validSavedFolders
		});
	}, [folderTypeById]);

	const [showSubmitWarning, setShowSubmitWarning] = useState<boolean>(false);

	const initialStep: ApplicationStep = useMemo(() => {
		if (!isStepCompleted(ApplicationStep.Highway, formik))
			return ApplicationStep.Highway;
		if (!isStepCompleted(ApplicationStep.Intervention, formik))
			return ApplicationStep.Intervention;
		if (!isStepCompleted(ApplicationStep.ApplicationData, formik))
			return ApplicationStep.ApplicationData;
		return ApplicationStep.ApplicationDocuments;
	}, [formik.errors]);
	const [currentStep, setCurrentStep] = useState<ApplicationStep>();
	const handlePrev = useCallback(() => {
		if (!currentStep) {
			navigate(-1);
		} else {
			setCurrentStep(currentStep - 1);
		}
	}, [currentStep, navigate]);

	const saveDraftApplication = useCallback(() => {
		if (!formik.values.highwayConcession || !formik.values.interventionType)
			return Promise.resolve();
		const newPreservedErrors = { ...formik.errors };
		const newPreservedTouched = { ...formik.touched };
		return saveApplicationData(formik.values)
			.then(() => {
				setPreservedErrors(newPreservedErrors);
				setPreservedTouched(newPreservedTouched);
			})
			.catch((err: any) =>
				toast.error(ErrorHelper.getResponseErrorMessage(err))
			);
	}, [formik.errors, formik.touched, formik.values, saveApplicationData]);

	useInterval(() => {
		if (currentStep && currentStep >= ApplicationStep.Intervention && dirty) {
			saveDraftApplication();
		}
	}, AUTOSAVE_DELAY);

	useEffect(() => {
		formik.setTouched({});
	}, [currentStep]);

	const scrollToElement = (elementId: string) => {
		const section = document.querySelector(`#${elementId}`);
		if (!section) return;
		section.scrollIntoView({ behavior: "smooth", block: "start" });
	};

	const scrollToFirstDataFieldWithError = (
		errors: FormikErrors<ApplicationFormData>
	) => {
		const fieldsWithError = [
			...Object.keys(errors),
			...(errors.applicationProprietorData
				? Object.keys(errors.applicationProprietorData)
				: [])
		];
		Object.keys(applicationDataSectionsFields).every((section) => {
			const fields: string[] = applicationDataSectionsFields[section];
			return fields.every((field: string) => {
				if (fieldsWithError.includes(field)) {
					scrollToElement(`section-${section}`);
					return false;
				}
				return true;
			});
		});
	};

	const hasDocumentUploadError = useMemo<boolean>(
		() =>
			!!Object.values(formik.values.documentFolders).find(
				(documentFolder) =>
					!!documentFolder.documents.find((document) => !!document.error)
			),
		[formik.values.documentFolders]
	);

	const nextStepHandlers = useMemo<Record<ApplicationStep, () => void>>(() => {
		return {
			[ApplicationStep.Highway]: () => {
				if (validateFinishedHighwayStep(formik)) {
					setCurrentStep(currentStep! + 1);
				} else {
					formik.setFieldTouched(ApplicationFields.highwayConcession, true);
				}
			},
			[ApplicationStep.Intervention]: () => {
				if (validateFinishedInterventionStep(formik)) {
					setCurrentStep(currentStep! + 1);
				} else {
					formik.setFieldTouched(ApplicationFields.interventionType, true);
					formik.setFieldTouched(
						ApplicationFields.interventionTypeCategory,
						true
					);
				}
			},
			[ApplicationStep.ApplicationData]: async () => {
				const newErrors = await formik.validateForm();
				if (validateFinishedDataStep(newErrors)) {
					saveDraftApplication()
						.then(() => setCurrentStep(currentStep! + 1))
						.catch((err) =>
							toast.error(ErrorHelper.getResponseErrorMessage(err))
						);
				} else {
					await formik.setTouched(
						setNestedObjectValues<FormikTouched<ApplicationFormData>>(
							newErrors,
							true
						),
						false
					);
					scrollToFirstDataFieldWithError(newErrors);
					toast.warn("Preencha todos os campos obrigatórios para prosseguir");
				}
			},
			[ApplicationStep.ApplicationDocuments]: async () => {
				const newErrors = await formik.validateForm();
				const hasErrors = Object.keys(newErrors).length > 0;
				if (hasErrors || hasDocumentUploadError) {
					await formik.setTouched(
						setNestedObjectValues<FormikTouched<ApplicationFormData>>(
							newErrors,
							true
						)
					);
					const keysErrors = Object.keys(newErrors.documentFolders || {});
					const firstError = keysErrors.length > 0 ? keysErrors[0] : "";
					scrollToElement(`document-folder-${firstError}`);
					const errorMessage = hasErrors
						? "Preencha todos os arquivos obrigatórios para prosseguir"
						: "Verifique os arquivos anexados";
					toast.warn(errorMessage);
				} else if (!isMobile) {
					// Mobile doesn't have a review step
					try {
						formik.submitForm();
						setCurrentStep(currentStep! + 1);
					} catch (err) {
						toast.error(ErrorHelper.getResponseErrorMessage(err));
					}
				} else {
					setShowSubmitWarning(true);
				}
			},
			[ApplicationStep.ApplicationReview]: () => {
				commitApplication(formik.values);
			}
		};
	}, [currentStep, formik.values, formik.submitForm, formik.validateForm]);

	useEffect(() => {
		if (!currentStep && currentStep !== 0 && formikHasValidated) {
			setCurrentStep(initialStep);
		}
	}, [initialStep, formikHasValidated]);

	const handleNext =
		currentStep || currentStep === 0 ? nextStepHandlers[currentStep] : () => {};

	const resetHighwayDependantFields = useCallback(() => {
		setFieldValue(ApplicationFields.interventionType, null);
		setFieldValue(ApplicationFields.interventionTypeCategory, null);
		assignFieldValues(initialApplicationDescriptionData, setFieldValue);
		setFieldValue(`${ApplicationFields.applicationProprietorData}`, {
			...initialValues.applicationProprietorData
		});
		setFieldValue(ApplicationFields.documentFolders, {});
	}, [setFieldValue]);

	useEffect(() => {
		formik.setFieldValue(
			ApplicationFields.initialUf,
			formik.values.highwayConcession?.initialUf
		);
		formik.setFieldValue(
			ApplicationFields.finalUf,
			formik.values.highwayConcession?.finalUf
		);
	}, [
		formik.values.highwayConcession?.id,
		formik.values.initialUf,
		formik.values.finalUf
	]);

	return (
		<div className="flex flex-col h-full">
			{currentStep || currentStep === 0 ? (
				<>
					<Stepper
						title={
							currentStep === ApplicationStep.ApplicationReview
								? "Revisão da solicitação"
								: "Nova solicitação"
						}
						nextEnabled={!isCommitting}
						currentStep={currentStep}
						handleNext={handleNext}
						handlePrev={handlePrev}
						stepsConfig={applicationStepsDisplay}
					>
						<Highway
							formik={formik}
							highways={highways}
							isLoadingHighways={isLoadingHighways}
							onHighwayChange={resetHighwayDependantFields}
						/>
						<Intervention formik={formik} onGoBack={handlePrev} />
						<ApplicationDataStep formik={formik} />
						<ApplicationDocumentsStep
							formik={formik}
							documentFolderTypesData={documentFolderTypesData}
							isLoadingFolderTypes={isLoadingFolderTypes}
						/>
						<ApplicationReview
							formik={formik}
							documentFolderTypesData={documentFolderTypesData}
						/>
					</Stepper>
					<Drawer showDrawer={showSubmitWarning}>
						<div className="flex flex-col gap-4">
							<strong className="text-base">Atenção</strong>
							<p className="text-sm">
								Após o envio da solicitação não será possível alterar as
								informações. Orientamos que revise os dados inseridos antes de
								prosseguir.
							</p>
						</div>
						<Button
							hierarchy="primary"
							onClick={() => setShowSubmitWarning(false)}
						>
							Revisar dados
						</Button>
						<Button
							hierarchy="secondary"
							type="submit"
							onClick={() => commitApplication(formik.values)}
							isLoading={isCommitting}
						>
							Enviar solicitação
						</Button>
					</Drawer>
				</>
			) : (
				<div className="mt-6">
					<Loading />
				</div>
			)}
		</div>
	);
}
