import { InputHTMLAttributes } from "react";

type DetailItemProps = InputHTMLAttributes<HTMLInputElement> & {
	label: string;
	value?: string;
};

export default function DetailItem({
	label,
	value
}: DetailItemProps): JSX.Element {
	return (
		<div className="text-sm mx-4 flex justify-between border-b border-neutral-high-200 py-2">
			<p className="text-neutral-low-400 hyphens-auto">{label}</p>
			<p className="lg:w-[50%]">{value}</p>
		</div>
	);
}
