import { Constants } from "appConstants";
import { IcoArrowDown, IcoHint } from "assets/icons";
import ObjectHelper from "helpers/ObjectHelper";
import { OptionLike } from "helpers/OptionHelper";
import { useMemo } from "react";
import Select, {
	GroupBase,
	OptionProps as ReactSelectOptionProps,
	StylesConfig,
	components,
	createFilter,
	type DropdownIndicatorProps
} from "react-select";
import { Tooltip } from "react-tooltip";
import { SelectFormProps } from "./types";

import "./styles.scss";

// eslint-disable-next-line react/function-component-definition
export const ArrowDropdownIndicator: React.FC<DropdownIndicatorProps> = (
	props
) => {
	return (
		<components.DropdownIndicator {...props}>
			<IcoArrowDown color="#1d1d1d" />
		</components.DropdownIndicator>
	);
};

export const stylesSelect = <Opt, isMulti extends boolean>(
	short: boolean,
	useOuterScroll: boolean
): StylesConfig<Opt, isMulti, GroupBase<Opt>> => {
	return {
		control: (baseStyles) => ({
			...baseStyles,
			borderColor: "#DBDBDB",
			boxShadow: "none",
			"&:hover": {
				borderColor: "#999999"
			},
			padding: short ? "0 0" : "0.4rem 0.1rem",
			backgroundColor: "#FEFEFE"
		}),
		dropdownIndicator: (provided: any, state: any) => ({
			...provided,
			transform: state.selectProps.menuIsOpen && "rotate(180deg)"
		}),
		menuList: (baseStyles) => ({
			...baseStyles,
			maxHeight: useOuterScroll ? "unset" : "min(250px, 25vh)",
			overflow: useOuterScroll ? "visible" : "auto"
		})
	};
};

export const themeSelect = () => {
	return (theme: any) => ({
		...theme,
		borderRadius: 4,
		colors: {
			...theme.colors,
			primary25: "#DBDBDB",
			primary50: "#F0F0F0",
			primary: "black"
		}
	});
};

function getOptionWithValue<V>(
	options: OptionLike[],
	value: V,
	getOptVal?: (option: OptionLike) => string
) {
	if (value === undefined || value === null || !getOptVal) return value;
	const optionValue = options?.find((o) => getOptVal(o) === value);
	return optionValue;
}

export default function SelectForm<V>({
	isDisabled = false,
	isClearable = true,
	isRtl = false,
	name,
	defaultValue = null,
	label = "",
	tooltip,
	isLoading,
	options,
	placeholder = "Selecione",
	noOptionsMessage = "Nenhuma opção",
	value,
	setFieldValue,
	onChange,
	onMenuOpen,
	onMenuClose,
	getOptionValue,
	className = "",
	useOuterScroll = false,
	menuPosition = "fixed",
	error,
	touched,
	onBlur,
	isSearchable = true,
	searchFields,
	short = false,
	menuPlacement = "auto",
	tooltipId
}: Readonly<SelectFormProps<V>>): JSX.Element {
	const onSelectChange = useMemo(() => {
		return (newFieldValue: any) => {
			onChange?.(newFieldValue);
			const newValue =
				newFieldValue && getOptionValue
					? getOptionValue(newFieldValue)
					: newFieldValue;
			setFieldValue?.(name, newValue);
		};
	}, [setFieldValue]);

	const filterConfig: any = {
		ignoreCase: true,
		ignoreAccents: true,
		trim: true,
		matchFrom: "any",
		stringify: (option: ReactSelectOptionProps) => {
			const { data } = option || {};
			if (!searchFields) return option.label;

			const dataValues = searchFields
				.map((fieldName: string) =>
					ObjectHelper.getPropertyFromObject(data, fieldName)
				)
				.join(" ");
			return dataValues;
		}
	};

	const innerTooltipId = useMemo(
		() => tooltipId ?? `tooltip-${Math.trunc(Math.random() * 100000)}`,
		[]
	);

	if (!options) return <div />;

	return (
		<div className={`container ${className}`}>
			{label && (
				<div className="flex gap-1 items-center pb-1">
					<label className="text-sm" htmlFor={name}>
						{label}
					</label>
					{tooltip && (
						<>
							<span id={innerTooltipId}>
								<IcoHint size="18" />
							</span>
							<Tooltip
								anchorSelect={`#${innerTooltipId}`}
								opacity={1}
								style={{ zIndex: Constants.zIndexes.tooltip }}
							>
								<div className="max-w-[96vw] md:max-w-[30rem] text-wrap">
									{tooltip}
								</div>
							</Tooltip>
						</>
					)}
				</div>
			)}
			<Select
				className={`basic-single ${error && touched ? "error" : ""} ${
					short ? "short" : ""
				}`}
				classNamePrefix="select"
				name={name}
				menuPlacement={menuPlacement}
				minMenuHeight={200}
				menuPosition={menuPosition}
				defaultValue={defaultValue}
				isDisabled={isDisabled || !setFieldValue}
				isLoading={isLoading}
				isClearable={isClearable}
				isRtl={isRtl}
				value={getOptionWithValue(options, value, getOptionValue)}
				isSearchable={isSearchable}
				options={options}
				onChange={onSelectChange}
				onMenuOpen={onMenuOpen}
				captureMenuScroll={!useOuterScroll}
				components={{
					DropdownIndicator: ArrowDropdownIndicator,
					IndicatorSeparator: () => null
				}}
				styles={stylesSelect(short, useOuterScroll)}
				theme={themeSelect()}
				placeholder={placeholder}
				onMenuClose={onMenuClose}
				noOptionsMessage={() => noOptionsMessage}
				onBlur={onBlur}
				filterOption={createFilter(filterConfig)}
			/>
			{error && touched && (
				<div className="white-space--pre-line mt-1 text-xs">
					{Array.isArray(error) ? error.join("\n") : error}
				</div>
			)}
		</div>
	);
}
