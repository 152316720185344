import {
	ApplicationFields,
	ApplicationFormik,
	ApplicationProprietorDataFields
} from "../types";
import ApplicationDescriptionDataSection, {
	applicationDescriptionDataSchema
} from "./ApplicationDescriptionDataSection";
import ApplicationProprietorDataSection, {
	applicationProprietorDataSchema
} from "./ApplicationProprietorDataSection";

export const applicationDataSections = {
	initialLocation: "initialLocation",
	directions: "directions",
	finalLocation: "finalLocation",
	description: "description",
	proprietorData: "proprietorData",
	proprietorAddress: "proprietorAddress",
	proprietorContact: "proprietorContact",
	applicantContact: "applicantContact"
};

export const applicationDataSectionsFields = {
	[applicationDataSections.initialLocation]: [
		ApplicationFields.initialKilometer,
		ApplicationFields.initialMeter,
		ApplicationFields.initialUf
	],
	[applicationDataSections.directions]: [ApplicationFields.directions],
	[applicationDataSections.finalLocation]: [
		ApplicationFields.finalKilometer,
		ApplicationFields.finalMeter,
		ApplicationFields.finalUf
	],
	[applicationDataSections.description]: [ApplicationFields.description],
	[applicationDataSections.proprietorData]: [
		ApplicationProprietorDataFields.proprietorType,
		ApplicationProprietorDataFields.proprietorName,
		ApplicationProprietorDataFields.proprietorDocument,
		ApplicationProprietorDataFields.proprietorStateRegistration,
		ApplicationProprietorDataFields.proprietorCityRegistration,
		ApplicationProprietorDataFields.proprietorBirthDate
	],
	[applicationDataSections.proprietorAddress]: [
		ApplicationProprietorDataFields.proprietorAddressPostalCode,
		ApplicationProprietorDataFields.proprietorAddressState,
		ApplicationProprietorDataFields.proprietorAddressCity,
		ApplicationProprietorDataFields.proprietorAddressNeighbourhood,
		ApplicationProprietorDataFields.proprietorAddressStreet,
		ApplicationProprietorDataFields.proprietorAddressNumber,
		ApplicationProprietorDataFields.proprietorAddressComplement
	],
	[applicationDataSections.proprietorContact]: [
		ApplicationProprietorDataFields.proprietorContactName,
		ApplicationProprietorDataFields.proprietorPhoneNumber,
		ApplicationProprietorDataFields.proprietorEmail
	],
	[applicationDataSections.applicantContact]: [
		ApplicationProprietorDataFields.applicantName,
		ApplicationProprietorDataFields.applicantRole,
		ApplicationProprietorDataFields.applicantPhoneNumber,
		ApplicationProprietorDataFields.applicantEmail,
		ApplicationProprietorDataFields.additionalContactEmail
	]
};

export default function ApplicationDataStep({
	formik
}: Readonly<ApplicationFormik>): JSX.Element {
	return (
		<div className="flex flex-col overflow-y-auto p-8 gap-8 h-full max-w-2xl">
			<ApplicationDescriptionDataSection formik={formik} />
			<ApplicationProprietorDataSection formik={formik} />
		</div>
	);
}

export { applicationDescriptionDataSchema, applicationProprietorDataSchema };
