import { AxiosError } from "axios";
import { AuthHelper } from "helpers";
import { store } from "store";

export function logOutUserIfTokenInvalid(error: AxiosError): any {
	const originalRequest = error.config;

	if (originalRequest == null) return Promise.reject(error);

	if (error.response?.status === 401) {
		const { data } = error.response;
		if ((data as any).message === "INVALID_TOKEN") {
			AuthHelper.logout(store.dispatch);
			window.location.href = "/";
			return Promise.reject(error);
		}
	}

	return Promise.reject(error);
}
