import { IcoInfoCircle } from "assets/icons";

export default function EmptyAttachmentsContent(): JSX.Element {
	return (
		<div className="flex flex-col w-full h-full items-center justify-center">
			<div className="flex flex-col items-center justify-center gap-4">
				<div className="flex flex-col items-center justify-center p-3 bg-neutral-high-100 rounded-full">
					<IcoInfoCircle size="48" />
				</div>
				<div className="flex flex-col items-center justify-center">
					<strong>Não há anexos nessa mensagem</strong>
				</div>
			</div>
		</div>
	);
}
