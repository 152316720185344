import { Td, Th } from "./helperComponents";
import PolicyContent, { ContentItem } from "./PolicyContent";

const cookiePolicyStructure: ContentItem[] = [
	{
		title: "1. Introdução",
		content: (
			<>
				<p>
					A CCR S.A utiliza cookies para melhorar o desempenho e a sua
					experiência ao utilizar nosso site.
				</p>
				<p>
					Nós procuramos, assim, explicar de forma transparente como, quando e
					porque utilizamos cookies.
				</p>
				<p>
					Ao acessar o nosso site, você autoriza o uso cookies nos termos desta
					Política.
				</p>
				<p>
					Caso não concorde com o uso de cookies dessa forma, você poderá: (i)
					ajustar as configurações de seu navegador para não permitir o uso de
					cookies ou (ii) não acessar o nosso site. Lembramos que desabilitar o
					uso de cookies poderá impactar na sua experiência ao navegar em nosso
					site.
				</p>
				<p>
					Para quaisquer questões ou dúvidas sobre esta Política de Cookies,
					você poderá entrar em contato conosco por meio do endereço eletrônico:
					<br />
					<span className="break-all">
						<u>encarregado.dadospessoais@grupoccr.com.br.</u>
					</span>
				</p>
			</>
		)
	},
	{
		title: "2. O que são cookies?",
		content: (
			<p>
				Cookies são arquivos digitais com pequenos fragmentos de dados (e
				geralmente com um identificador único), que são armazenados em seu
				dispositivo por meio do navegador ou aplicativo e que guardam
				informações relacionadas com as suas preferências.
			</p>
		)
	},
	{
		title: "3. Para que servem os cookies?",
		content: (
			<>
				<p>
					Os cookies servem para aprimorar a sua experiência, tanto em termos de
					performance como em termos de usabilidade, uma vez que os conteúdos
					disponibilizados serão direcionados às suas necessidades e
					expectativas.
				</p>
				<p>
					Os cookies permitem que nosso site memorize informações sobre a sua
					visita, o seu idioma preferido, a sua localização, a recorrência das
					suas sessões e outras variáveis que nós consideramos relevantes para
					tornar sua experiência muito mais eficiente.
				</p>
				<p>
					Os cookies também poderão ser utilizados para compilar estatísticas
					anônimas e agregadas que permitem entender como os usuários utilizam e
					interagem com nosso site, bem como para aprimorar suas estruturas e
					conteúdo. Por serem estatísticas anônimas, não podemos identificá-lo
					pessoalmente por meio desses dados.
				</p>
				<p>
					A utilização de cookies é algo comum em qualquer site atualmente. O
					seu uso não prejudica de forma alguma os dispositivos (computadores,
					smartphones, tablets, etc.) em que são armazenados.
				</p>
			</>
		)
	},
	{
		title: "4. Que tipo de cookies utilizamos?",
		content: (
			<>
				<p>
					Nós utilizamos um tipo de cookies em nosso site: cookies de
					funcionalidades.
				</p>
				<ul>
					<li>
						<strong>Cookies de Funcionalidade:</strong> são utilizados para
						assegurar a disponibilização de funcionalidades adicionais no site
						ou para guardar as preferências definidas pelo usuário, sempre que
						utilizar o mesmo dispositivo.
					</li>
				</ul>
			</>
		)
	},
	{
		title: "5. É possível controlar ou excluir cookies?",
		content: (
			<>
				<p>
					A maioria dos navegadores de Internet é configurada para aceitar
					automaticamente os cookies.
				</p>
				<p>
					Você pode alterar as configurações para bloquear o uso de cookies ou
					alertá-lo quando um cookie estiver sendo enviado para seu dispositivo.
					Existem várias formas de gerenciar cookies.
				</p>
				<p>
					Ao desabilitar os cookies, você talvez não consiga visitar certas
					áreas de uma página nossa ou talvez não receba informações
					personalizadas quando visitar uma página.
				</p>
				<p>
					Caso você utilize dispositivos diferentes para acessar o nosso site
					(computador, smartphone, tablet, etc.), deverá assegurar-se de que
					cada navegador de cada dispositivo esteja ajustado para atender às
					suas preferências em relação aos cookies.
				</p>
				<p>
					Para que você possa, de forma simples e intuitiva, gerir suas
					preferências quanto aos cookies a partir do seu navegador, poderá
					utilizar um dos links abaixo:
				</p>
				<ul>
					<li>
						Para mais informações sobre “Navegação Privada” e gestão de cookies
						no browser Firefox,{" "}
						<a
							target="_blank"
							rel="noreferrer"
							href="https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences"
						>
							<u>clique aqui</u>
						</a>
						;
					</li>
					<li>
						Para mais informações sobre “Navegação como Incógnito” e gestão de
						cookies no browser Chrome,{" "}
						<a
							target="_blank"
							rel="noreferrer"
							href="https://support.google.com/chrome/answer/95647?hl=en"
						>
							<u>clique aqui</u>
						</a>
						;
					</li>
					<li>
						Para mais informações sobre “Navegação Privada” e gestão de cookies
						a partir do browser Internet Explorer,{" "}
						<a
							target="_blank"
							rel="noreferrer"
							href="https://support.microsoft.com/pt-pt/help/17442/windows-internet-explorer-delete-manage-cookies"
						>
							<u>clique aqui</u>
						</a>
						;
					</li>
					<li>
						Para mais informações sobre “Navegação Privada” e gestão de cookies
						a partir do browser Safari,{" "}
						<a
							target="_blank"
							rel="noreferrer"
							href="https://support.apple.com/pt-br/guide/safari/sfri11471/mac"
						>
							<u>clique aqui</u>
						</a>
						;
					</li>
					<li>
						Para mais informações sobre “Navegação Privada” e gestão de cookies
						no browser Opera,{" "}
						<a
							target="_blank"
							rel="noreferrer"
							href="https://help.opera.com/en/latest/security-and-privacy/#privateWindow"
						>
							<u>clique aqui</u>
						</a>
						.
					</li>
				</ul>
			</>
		)
	},
	{
		title: "6. Informações detalhadas sobre os cookies",
		content: (
			<>
				<p>
					Apresentamos abaixo os detalhes sobre o uso dos cookies em nosso site,
					bem como as finalidades de tratamento dos dados que os justificam.
				</p>
				<p>
					<strong>6.1 Cookies de Funcionalidade</strong>
				</p>
				<table>
					<thead className="relative">
						<tr>
							<Th>Nome do Cookie</Th>
							<Th>Provedor</Th>
							<Th>Validade</Th>
							<Th>Finalidade</Th>
						</tr>
					</thead>
					<tbody>
						<tr className="text-center">
							<Td>Device</Td>
							<Td>CCR</Td>
							<Td>1 dia</Td>
							<Td>Identificação se o dispositivo é desktop ou mobile</Td>
						</tr>
					</tbody>
				</table>
				<p>Atualização: abril de 2024.</p>
			</>
		)
	}
];

export default function CookiePolicy(): JSX.Element {
	return <PolicyContent content={cookiePolicyStructure} />;
}
