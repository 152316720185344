import { IconProps } from "./types";

export default function IcoBuilding({
	color = "#1D1D1D",
	size = "24"
}: IconProps) {
	return (
		<svg
			width={size}
			height={size}
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M4 20H6.5M6.5 20V4H17.5V20M6.5 20H17.5M17.5 20H20M10 15H14V20H10V15Z"
				stroke={color}
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
}
