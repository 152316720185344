import { IcoSearch } from "assets/icons";
import colors from "colors";
import Label from "components/Label";
import InputHelper from "helpers/InputHelper";
import { InputHTMLAttributes } from "react";
import "./styles.scss";

type BaseInputHTMLProps = Omit<InputHTMLAttributes<HTMLInputElement>, "value">;

type InputProps = BaseInputHTMLProps & {
	label?: string;
	error?: string | Array<string>;
	className?: string;
	touched?: boolean;
	value?: string | readonly string[] | number | undefined | null;
	short?: boolean;
	inputClassName?: string;
	showOptionalIndicator?: boolean;
};

const hasValue = (input: any) => input !== null && input !== undefined;

export default function Input({
	label,
	placeholder,
	type,
	name,
	value,
	required = true,
	showOptionalIndicator = true,
	disabled,
	error,
	touched,
	onChange,
	className = "",
	short = false,
	inputClassName,
	...rest
}: InputProps) {
	const typeProps: any = {};
	if (type === "number") {
		typeProps.onKeyDown = InputHelper.onlyNumbers;
	}

	return (
		<div className={`container relative rounded-lg ${className}`}>
			{label && (
				<Label
					showOptionalIndicator={showOptionalIndicator}
					isRequired={!!required}
				>
					{label}
				</Label>
			)}
			<div className="flex flex-row items-center justify-stretch">
				<input
					type={type}
					name={name}
					value={hasValue(value) ? value : ""}
					placeholder={placeholder}
					onChange={onChange}
					disabled={disabled}
					className={`input-component ${inputClassName ?? ""} ${
						error && touched
							? "bg-feedback-negative-high-50 border-feedback-negative-pure-500 focus-visible:outline-feedback-negative-pure-500"
							: ""
					} ${short ? "short" : ""}`}
					required={required}
					{...typeProps}
					{...rest}
				/>
				{type === "search" && (
					<div className="flex flex-row items-center justify-center absolute right-4 b-[50%]">
						<IcoSearch color={colors.neutral["low-pure-500"]} />
					</div>
				)}
			</div>
			{error && touched && (
				<div className="white-space--pre-line mt-1 text-xs">
					{Array.isArray(error) ? error.join("\n") : error}
				</div>
			)}
		</div>
	);
}
