import { IcoCheck, IcoClose, IcoFeedbackWarning, IcoUser } from "assets/icons";
import colors from "colors";
import { BaseModal, Button, Modal, ModalFooter } from "components";

export function ResponsibleUserButton({
	isCurrentUserResponsible,
	hasResponsibleUser,
	isApplicationInBOReadOnlyStatus,
	responsibleUserName,
	onClick
}: {
	isCurrentUserResponsible: boolean | undefined;
	hasResponsibleUser: boolean | undefined;
	isApplicationInBOReadOnlyStatus: boolean | undefined;
	responsibleUserName: string | undefined;
	onClick: () => void;
}) {
	if (
		isCurrentUserResponsible === undefined ||
		hasResponsibleUser === undefined ||
		isApplicationInBOReadOnlyStatus === undefined
	)
		return null;

	return !isApplicationInBOReadOnlyStatus ? (
		<div className="flex">
			{isCurrentUserResponsible ? (
				<span className="flex flex-row items-center bg-neutral-high-pure-50 px-4 py-2 cursor-default rounded-[4px]">
					<IcoCheck />
					<span className="ml-2 text-sm">Você é o responsável</span>
				</span>
			) : !hasResponsibleUser ? (
				<Button
					type="button"
					kind="default"
					size="small"
					hierarchy="primary"
					onClick={onClick}
				>
					Tornar-se responsável
				</Button>
			) : (
				responsibleUserName && (
					<span className="flex flex-row items-center bg-neutral-high-pure-50 px-4 py-2 cursor-default rounded-[4px]">
						<IcoUser size="16" />
						<span className="ml-2 text-sm">
							Responsável: {responsibleUserName}
						</span>
					</span>
				)
			)}
		</div>
	) : null;
}

export function ConfirmResponsibleModal({
	isOpen,
	onClose,
	onClickConfirm,
	isLoadingConfirm
}: {
	isOpen: boolean;
	onClose: () => void;
	onClickConfirm: () => void;
	isLoadingConfirm: boolean;
}) {
	return (
		<BaseModal
			isOpen={isOpen}
			onClickOutsideModal={onClose}
			contentClassName="rounded-lg"
			bodyClassName="p-6 gap-6"
		>
			<div className="flex justify-between items-center">
				<h3 className="headline-3 text-neutral-low-pure-500 font-bold">
					Deseja tornar-se responsável?
				</h3>
				<Button hierarchy="ghost" kind="icon" onClick={onClose}>
					<IcoClose />
				</Button>
			</div>
			<div className="pb-4">
				Ao confirmar, você se tornará o responsável dessa solicitação e deverá
				realizar um registro.
			</div>
			<ModalFooter>
				<Button hierarchy="secondary" size="medium" onClick={onClose}>
					Cancelar
				</Button>
				<Button
					hierarchy="primary"
					size="medium"
					onClick={onClickConfirm}
					isLoading={isLoadingConfirm}
				>
					Confirmar
				</Button>
			</ModalFooter>
		</BaseModal>
	);
}

export function CancelNewRecordModal({
	isOpen,
	onClose,
	onClickConfirm
}: {
	isOpen: boolean;
	onClose: () => void;
	onClickConfirm: () => void;
}) {
	return (
		<Modal
			isOpen={isOpen}
			onClose={onClose}
			contentClassName="rounded-lg md:max-w-xl"
			bodyClassName="p-8"
		>
			<div className="flex flex-col gap-7">
				<h1 className="text-2xl font-semibold">Deseja cancelar?</h1>
				<p>Ao confirmar, essa ação não poderá ser desfeita.</p>
			</div>
			<ModalFooter>
				<Button hierarchy="secondary" onClick={onClose}>
					Não
				</Button>
				<Button
					hierarchy="primary"
					className="bg-feedback-negative-pure-500 border-feedback-negative-pure-500"
					onClick={onClickConfirm}
				>
					Confirmar
				</Button>
			</ModalFooter>
		</Modal>
	);
}

export function DownloadWarningModal({
	isOpen,
	onClose
}: {
	isOpen: boolean;
	onClose: () => void;
}) {
	return (
		<BaseModal
			isOpen={isOpen}
			onClickOutsideModal={onClose}
			contentClassName="rounded w-[312px] p-4 bg-feedback-alert-soft top-[86px] -translate-y-0"
			bodyClassName="p-0 gap-0 text-sm/6"
		>
			<div className="flex justify-between items-center">
				<div className="flex gap-2 items-center">
					<div className="p-1">
						<IcoFeedbackWarning
							size="16"
							color={colors["feedback-alert"].alerts}
						/>
					</div>
					<h6 className="headline-6 text-neutral-low-pure-500 font-semibold">
						Atenção!
					</h6>
				</div>
				<Button
					className="bg-feedback-alert-soft"
					hierarchy="ghost"
					kind="icon"
					size="small"
					onClick={onClose}
				>
					<IcoClose size="16" />
				</Button>
			</div>
			<div>
				Processo em andamento. Aguarde a conclusão antes de sair desta tela.
			</div>
		</BaseModal>
	);
}
