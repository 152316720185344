import { IcoArrowRight, IcoBuilding, IcoUser } from "assets/icons";
import colors from "colors";

export default function SelectType({
	setIsCompany
}: Readonly<{
	setIsCompany: React.Dispatch<React.SetStateAction<boolean>>;
}>) {
	return (
		<div className="flex flex-col gap-6 p-10 w-[100vw] sm:w-[26.5rem]">
			<h1 className="font-bold text-2xl">Vamos continuar?</h1>
			<p>
				Para utilizar nosso sistema é necessário informar seus dados cadastrais.
			</p>
			<div className="flex flex-col gap-4">
				<button
					className="rounded-lg border border-neutral-low-pure-500 p-4 cursor-pointer"
					onClick={() => {
						setIsCompany(false);
					}}
				>
					<div className="border-b border-neutral-high-100 flex w-full pb-4 justify-between">
						<div className="flex gap-4">
							<IcoUser />
							<p className="text-sm font-bold">Para você</p>
						</div>
						<IcoArrowRight color={colors.neutral["low-pure-500"]} />
					</div>
					<p className="text-sm pt-4 text-left">
						Informe seu nome, CPF e telefone.
					</p>
				</button>
				<button
					className="rounded-lg border border-neutral-low-pure-500 p-4 cursor-pointer"
					onClick={() => {
						setIsCompany(true);
					}}
				>
					<div className="border-b border-neutral-high-100 flex w-full pb-4 justify-between">
						<div className="flex gap-4">
							<IcoBuilding />
							<p className="text-sm font-bold">Para sua empresa</p>
						</div>
						<IcoArrowRight color={colors.neutral["low-pure-500"]} />
					</div>
					<p className="text-sm pt-4 text-left">
						Informe sua razão social, CNPJ e telefone.
					</p>
				</button>
			</div>
		</div>
	);
}
