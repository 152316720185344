import { HeaderInfo } from "components";

import { IcoHeaderInfoWarning } from "assets/icons";
import favicon from "assets/icons/favicon.png";
import { Helmet } from "react-helmet-async";

export default function Page404() {
	return (
		<>
			<Helmet>
				<title>{`${process.env.REACT_APP_TITLE} - Página não encontrada`}</title>
				<link rel="shortcut icon" href={favicon} type="image/x-icon" />
				<link rel="icon" href={favicon} type="image/x-icon" />
			</Helmet>
			<HeaderInfo
				buttonText="Voltar à Home"
				href="/"
				icon={<IcoHeaderInfoWarning />}
				subtitle="Parece que essa página foi removida ou não existe"
				title="Página não encontrada"
			/>
		</>
	);
}
