import { Constants } from "appConstants";
import { Input, SelectForm, TextArea } from "components";
import DocumentFolderInput from "components/DocumentFolderInput";
import { DateTimeHelper } from "helpers";
import { HighwayConcession } from "models/HighwayConcession";
import { InterventionType } from "models/InterventionType";
import { InterventionTypeCategory } from "models/InterventionTypeCategory";
import { useMemo, useState } from "react";
import {
	ApplicationFormDocumentFolder,
	DocumentFolderType,
	DocumentFolderTypesAndFileExtensions
} from "services/types";
import {
	CollapsibleSectionHeader,
	Section,
	SectionContent,
	SectionItem
} from "../layout";
import {
	ApplicationDirectionPosition,
	ApplicationFields,
	ApplicationFormik,
	ApplicationInterventionDirection,
	InterventionDirectionValue,
	PersonTypeOptions
} from "../types";
import {
	CheckboxDisplay,
	DataPairList,
	KeyPairList,
	ReviewSectionHeader,
	crossMultiplySets,
	directionOptionToLabel,
	directionOptionToValue
} from "./layout";
import "./styles.scss";

function ApplicationReviewDescriptionSection({
	formik
}: Readonly<ApplicationFormik>): JSX.Element {
	const { values, errors, touched } = formik;
	const { highwayConcession, interventionType, interventionTypeCategory } =
		values;

	const selectedHighway: HighwayConcession | null = useMemo(() => {
		return highwayConcession
			? new HighwayConcession({ ...highwayConcession })
			: null;
	}, [highwayConcession]);
	const selectedInterventionType: InterventionType | null = useMemo(() => {
		return interventionType
			? new InterventionType({ ...interventionType })
			: null;
	}, [interventionType]);
	const selectedInterventionTypeCategory: InterventionTypeCategory | null =
		useMemo(() => {
			return interventionTypeCategory
				? new InterventionTypeCategory({ ...interventionTypeCategory })
				: null;
		}, [interventionTypeCategory]);

	const applicationDescriptionDataPairs = useMemo<DataPairList>(() => {
		return [
			{ label: "Rodovia selecionada", value: selectedHighway?.toShortString() },
			{ label: "Tipo de intervenção", value: selectedInterventionType?.name },
			selectedInterventionTypeCategory && {
				label: "Categoria",
				value: selectedInterventionTypeCategory?.name
			},
			{ label: "Concessionária", value: selectedHighway?.concessionaire.name },
			{
				label: "Sentido da rodovia",
				value: selectedHighway?.highway.directionRepresentation
			},
			{
				label: "km inicial",
				value: selectedHighway?.positionRepresentation(
					ApplicationDirectionPosition.initial
				)
			},
			{
				label: "km final",
				value: selectedHighway?.positionRepresentation(
					ApplicationDirectionPosition.final
				)
			}
		];
	}, [
		selectedHighway,
		selectedInterventionType,
		selectedInterventionTypeCategory
	]);

	const [highwayInitialDirectionOptions, highwayFinalDirectionOptions] =
		useMemo<
			[ApplicationInterventionDirection[], ApplicationInterventionDirection[]]
		>(() => {
			if (!highwayConcession) return [[], []];
			const highwayDirectionOptions = [
				{ direction: highwayConcession?.highway.ascendingDirection },
				{ direction: highwayConcession?.highway.descendingDirection },
				{ direction: InterventionDirectionValue.CANTEIRO_CENTRAL }
			];
			return [
				crossMultiplySets(
					[{ isInitialDirection: true }],
					highwayDirectionOptions
				),
				crossMultiplySets(
					[{ isInitialDirection: false }],
					highwayDirectionOptions
				)
			];
		}, [highwayConcession]);

	return (
		<Section>
			<ReviewSectionHeader title="Dados da solicitação" />
			<SectionContent>
				<SectionItem>
					<KeyPairList data={applicationDescriptionDataPairs} />
				</SectionItem>
			</SectionContent>

			<SectionContent
				title="Informe o km, metro e UF da intervenção"
				hint="O intervalo de onde deseja a intervenção"
			>
				<SectionItem colSpan={12}>
					<span className="md:font-semibold text-sm">Inicial</span>
				</SectionItem>

				<SectionItem colSpan={4}>
					<Input
						type="number"
						name={ApplicationFields.initialKilometer}
						label="km inicial"
						value={values.initialKilometer}
						error={errors?.initialKilometer}
						touched={touched?.initialKilometer}
						min={0}
						disabled
					/>
				</SectionItem>
				<SectionItem colSpan={4}>
					<Input
						type="number"
						name={ApplicationFields.initialMeter}
						label="metro inicial"
						value={values.initialMeter}
						error={errors?.initialMeter}
						touched={touched?.initialMeter}
						min={0}
						max={999}
						disabled
					/>
				</SectionItem>
				<SectionItem colSpan={4}>
					<SelectForm
						name={ApplicationFields.initialUf}
						label="UF inicial"
						value={values.initialUf}
						error={errors?.initialUf}
						touched={touched?.initialUf}
						options={Constants.federalUnitCodeOptions}
						getOptionValue={(option) => option.value}
						placeholder=""
						isDisabled
					/>
				</SectionItem>

				<SectionItem colSpan={12}>
					<span className="md:font-semibold text-sm text-neutral-low-400">
						Informe o sentido da pista inicial
					</span>
				</SectionItem>

				<SectionItem colSpan={12}>
					<CheckboxDisplay
						name={ApplicationFields.directions}
						selectedOptions={values.directions}
						options={highwayInitialDirectionOptions}
						optionToValue={directionOptionToValue}
						optionToLabel={directionOptionToLabel}
						disabled
					/>
				</SectionItem>
			</SectionContent>

			<SectionContent>
				<SectionItem colSpan={12}>
					<span className="md:font-semibold text-sm">Final</span>
				</SectionItem>

				<SectionItem colSpan={4}>
					<Input
						type="number"
						name={ApplicationFields.finalKilometer}
						label="km final"
						value={values.finalKilometer}
						error={errors?.finalKilometer}
						touched={touched?.finalKilometer}
						disabled
					/>
				</SectionItem>
				<SectionItem colSpan={4}>
					<Input
						type="number"
						name={ApplicationFields.finalMeter}
						label="metro final"
						value={values.finalMeter}
						error={errors?.finalMeter}
						touched={touched?.finalMeter}
						min={0}
						max={999}
						disabled
					/>
				</SectionItem>
				<SectionItem colSpan={4}>
					<SelectForm
						name={ApplicationFields.finalUf}
						label="UF final"
						value={values.finalUf}
						error={errors?.finalUf}
						touched={touched?.finalUf}
						options={Constants.federalUnitCodeOptions}
						getOptionValue={(option) => option.value}
						placeholder=""
						isDisabled
					/>
				</SectionItem>

				<SectionItem colSpan={12}>
					<span className="md:font-semibold text-sm text-neutral-low-400">
						Informe o sentido da pista final
					</span>
				</SectionItem>

				<SectionItem colSpan={12}>
					<CheckboxDisplay
						name={ApplicationFields.directions}
						selectedOptions={values.directions}
						options={highwayFinalDirectionOptions}
						optionToValue={directionOptionToValue}
						optionToLabel={directionOptionToLabel}
						disabled
					/>
				</SectionItem>
			</SectionContent>

			<SectionContent>
				<SectionItem colSpan={12}>
					<span className="md:font-semibold text-sm text-neutral-low-400">
						Descrição
					</span>
				</SectionItem>
				<SectionItem colSpan={12}>
					<TextArea
						label={ApplicationFields.description}
						name={ApplicationFields.description}
						placeholder="Descreva detalhes da solicitação"
						value={values.description}
						maxLength={400}
						error={errors?.description}
						touched={touched?.description}
						disabled
					/>
				</SectionItem>
			</SectionContent>
		</Section>
	);
}

function ApplicationReviewProprietorSection({
	formik
}: Readonly<ApplicationFormik>): JSX.Element {
	const { values } = formik;
	const { applicationProprietorData: sectionData } = values;

	const applicationProprietorDataPairs = useMemo<DataPairList>(() => {
		if (sectionData.proprietorType === PersonTypeOptions.juridical)
			return [
				{ label: "Perfil", value: "Pessoa jurídica" },
				{
					label: "Razão social",
					value: sectionData.proprietorName
				},
				{ label: "CNPJ", value: sectionData.proprietorDocument },
				{
					label: "Inscrição Estadual",
					value: sectionData.proprietorStateRegistration
				},
				{
					label: "Inscrição Municipal",
					value: sectionData.proprietorCityRegistration
				}
			];
		return [
			{ label: "Perfil", value: "Pessoa física" },
			{
				label: "Nome completo",
				value: sectionData.proprietorName
			},
			{ label: "CPF", value: sectionData.proprietorDocument },
			{
				label: "Data de nascimento",
				value: sectionData.proprietorBirthDate
					? DateTimeHelper.formatDateString(sectionData.proprietorBirthDate)
					: ""
			}
		];
	}, [sectionData]);

	const applicationProprietorAddressDataPairs = useMemo<DataPairList>(() => {
		return [
			{ label: "CEP", value: sectionData.proprietorAddressPostalCode },
			{ label: "Estado", value: sectionData.proprietorAddressState },
			{ label: "Cidade", value: sectionData.proprietorAddressCity },
			{ label: "Bairro", value: sectionData.proprietorAddressNeighbourhood },
			{ label: "Endereço", value: sectionData.proprietorAddressStreet },
			{ label: "Número", value: sectionData.proprietorAddressNumber },
			{ label: "Complemento", value: sectionData.proprietorAddressComplement }
		];
	}, [sectionData]);

	const applicationProprietorContactDataPairs = useMemo<DataPairList>(() => {
		return [
			sectionData.proprietorType === PersonTypeOptions.juridical
				? { label: "Nome do contato", value: sectionData.proprietorContactName }
				: null,
			{ label: "Telefone", value: sectionData.proprietorPhoneNumber },
			{ label: "E-mail", value: sectionData.proprietorEmail }
		];
	}, [sectionData]);

	const applicationApplicantDataPairs = useMemo<DataPairList>(() => {
		return [
			{ label: "Nome", value: sectionData.applicantName },
			{
				label: "Qual o seu papel na intervenção",
				value: sectionData.applicantRole
			},
			{ label: "Telefone", value: sectionData.applicantPhoneNumber },
			{ label: "E-mail", value: sectionData.applicantEmail },
			sectionData.additionalContactEmail
				? {
						label: "E-mail adicional",
						value: sectionData.additionalContactEmail
				  }
				: null
		];
	}, [sectionData]);

	return (
		<Section>
			<ReviewSectionHeader title="Dados do proprietário" />

			<SectionContent>
				<SectionItem>
					<KeyPairList data={applicationProprietorDataPairs} />
				</SectionItem>
			</SectionContent>

			<SectionContent title="Endereço cadastral">
				<SectionItem>
					<KeyPairList data={applicationProprietorAddressDataPairs} />
				</SectionItem>
			</SectionContent>

			<SectionContent title="Dados para contato">
				<SectionItem>
					<KeyPairList data={applicationProprietorContactDataPairs} />
				</SectionItem>
			</SectionContent>

			<SectionContent title="Contato direto para tratar a solicitação">
				<SectionItem>
					<KeyPairList data={applicationApplicantDataPairs} />
				</SectionItem>
			</SectionContent>
		</Section>
	);
}

function FolderVolumeReviewSection({
	title,
	documentFolders,
	documentFolderTypes
}: Readonly<{
	title: string;
	documentFolders: Record<string, ApplicationFormDocumentFolder>;
	documentFolderTypes: DocumentFolderType[];
}>): JSX.Element {
	const [expanded, setExpanded] = useState<boolean>(false);
	return (
		<Section>
			<CollapsibleSectionHeader
				title={title}
				expanded={expanded}
				setExpanded={setExpanded}
			/>
			{expanded && (
				<div className="w-full flex flex-col items-center gap-4 mt-4 text-sm">
					{documentFolderTypes
						.filter(
							(folderType) =>
								(documentFolders[folderType.id]?.documents.length ?? 0) > 0
						)
						.map((folderType) => (
							<DocumentFolderInput
								key={folderType.id}
								readonly
								folderName={folderType.documentType.name}
								folderType={folderType.id}
								minDocumentCount={folderType.minAmountDocuments}
								value={documentFolders[folderType.id]}
								fileExtensions={null}
							/>
						))}
				</div>
			)}
		</Section>
	);
}

function ApplicationReviewDocumentsSection({
	formik,
	documentFolderTypesData
}: Readonly<
	ApplicationFormik & {
		documentFolderTypesData: DocumentFolderTypesAndFileExtensions | null;
	}
>): JSX.Element {
	const { values } = formik;
	const { documentFolders } = values;

	const firstVolumeDocumentFolderTypes = useMemo(
		() =>
			documentFolderTypesData?.documentFolderTypes.filter(
				(folderType) => folderType.rootFolder === 1
			) ?? [],
		[documentFolderTypesData]
	);

	const secondVolumeDocumentFolderTypes = useMemo(
		() =>
			documentFolderTypesData?.documentFolderTypes.filter(
				(folderType) => folderType.rootFolder === 2
			) ?? [],
		[documentFolderTypesData]
	);

	return (
		<>
			{!!firstVolumeDocumentFolderTypes.length && (
				<FolderVolumeReviewSection
					title="Volume 1 - Relatórios técnicos e documentos de identificação"
					documentFolderTypes={firstVolumeDocumentFolderTypes}
					documentFolders={documentFolders}
				/>
			)}
			{!!secondVolumeDocumentFolderTypes.length && (
				<FolderVolumeReviewSection
					title="Volume 2 - Projeto e cronograma"
					documentFolderTypes={secondVolumeDocumentFolderTypes}
					documentFolders={documentFolders}
				/>
			)}
		</>
	);
}

export default function ApplicationReview({
	formik,
	documentFolderTypesData
}: Readonly<
	ApplicationFormik & {
		documentFolderTypesData: DocumentFolderTypesAndFileExtensions | null;
	}
>): JSX.Element {
	return (
		<div className="flex flex-col overflow-y-auto p-8 gap-8 h-full max-w-2xl">
			<ApplicationReviewDescriptionSection formik={formik} />
			<ApplicationReviewProprietorSection formik={formik} />
			<ApplicationReviewDocumentsSection
				formik={formik}
				documentFolderTypesData={documentFolderTypesData}
			/>
		</div>
	);
}
