import AsideOverlay from "components/AsideOverlay";
import Button from "components/Button";
import Checkbox from "components/Checkbox";
import Input from "components/Input";
import { useFormik } from "formik";
import useStabilizedState from "hooks/useStabilizedState";
import React, { useMemo } from "react";
import { DocumentFolderType } from "services/types";

export default function DocumentFolderTypeSelector({
	isOpen,
	setIsOpen,
	initialValues,
	documentFolderTypes,
	mandatoryDocumentFolderTypes,
	onSubmit
}: Readonly<{
	isOpen: boolean;
	setIsOpen: (newIsOpen: boolean) => void;
	initialValues: Record<string, boolean>;
	documentFolderTypes: DocumentFolderType[];
	mandatoryDocumentFolderTypes: DocumentFolderType[];
	onSubmit: (newValues: Record<string, boolean>) => void;
}>): JSX.Element {
	const {
		unstableValue: unstableSearchText,
		stableValue: stableSearchText,
		setValue: setSearchText
	} = useStabilizedState("");

	const formik = useFormik({
		initialValues,
		enableReinitialize: true,
		onSubmit: (newVals) => {
			onSubmit(newVals);
			setIsOpen(false);
		}
	});

	const { mandatoryDisplayOptions, displayedOptions } = useMemo(() => {
		const sortedMandatoryDocumentFolderTypes = mandatoryDocumentFolderTypes
			.filter((folderType) =>
				folderType.documentType.name
					.toLowerCase()
					.includes(stableSearchText.toLowerCase())
			)
			.sort((folderTypeA, folderTypeB) => {
				const aBeforeB =
					folderTypeA.documentType.name <= folderTypeB.documentType.name;
				return aBeforeB ? -1 : 1;
			});

		const sortedDocumentFolderTypes = documentFolderTypes
			.filter((folderType) =>
				folderType.documentType.name
					.toLowerCase()
					.includes(stableSearchText.toLowerCase())
			)
			.sort((folderTypeA, folderTypeB) => {
				const aBeforeB =
					folderTypeA.documentType.name <= folderTypeB.documentType.name;
				return aBeforeB ? -1 : 1;
			});

		return {
			mandatoryDisplayOptions: sortedMandatoryDocumentFolderTypes,
			displayedOptions: sortedDocumentFolderTypes
		};
	}, [stableSearchText, documentFolderTypes, mandatoryDocumentFolderTypes]);

	const { values, setFieldValue, handleSubmit, dirty } = formik;
	return (
		<AsideOverlay
			isOpen={isOpen}
			onClickOutside={() => setIsOpen(false)}
			from="right"
		>
			<form
				className="w-full h-full flex flex-col items-center"
				onSubmit={handleSubmit}
			>
				<header className="w-full h-18 flex flex-col p-3 py-6 md:p-6 justify-center border-b border-neutral-high-200">
					<h2 className="font-semibold md:text-lg text-base">
						Selecione os anexos que deseja adicionar
					</h2>
				</header>
				<div className="flex flex-col items-center overflow-y-auto scrollbar-width--thin scrollbar-gutter--stable w-full grow p-3 md:p-6 gap-4">
					<search className="w-full flex items-center justify-stretch">
						<Input
							name="folderTypeSearchText"
							value={unstableSearchText}
							onChange={(evt) => setSearchText(evt.target.value)}
							type="search"
							placeholder="Busque por nome do documento..."
							required={false}
						/>
					</search>
					<fieldset className="w-full flex flex-col gap-2">
						{[...mandatoryDisplayOptions, ...displayedOptions].map(
							(folderType, index) => {
								const isMandatory = mandatoryDisplayOptions.some(
									(mandatoryOption) => mandatoryOption.id === folderType.id
								);
								const fieldValue = isMandatory
									? true
									: values[folderType.id] ?? false;
								return (
									<React.Fragment key={folderType.id}>
										{index > 0 && (
											<div className="w-full h-[1px] bg-neutral-high-200" />
										)}
										<Checkbox
											id={folderType.id}
											label={folderType.documentType.name}
											value={folderType.id}
											name={folderType.id}
											onChange={() => {
												setFieldValue(folderType.id, !fieldValue);
											}}
											checked={fieldValue}
											iconSize="20"
											labelSpanClassName="text-sm md:text-base"
											disabled={isMandatory}
										/>
									</React.Fragment>
								);
							}
						)}
					</fieldset>
				</div>
				<footer className="w-full flex flex-col md:flex-row justify-end gap-3 px-6 py-3 md:p-6 border-t border-neutral-high-200">
					<Button
						type="button"
						hierarchy="secondary"
						onClick={() => setIsOpen(false)}
					>
						Fechar
					</Button>
					<Button type="submit" hierarchy="primary" disabled={!dirty}>
						Confirmar seleção
					</Button>
				</footer>
			</form>
		</AsideOverlay>
	);
}
