import { IcoTrash } from "assets/icons";
import colors from "colors";
import Button from "components/Button";
import DocumentFolderInput from "components/DocumentFolderInput";
import TextArea from "components/TextArea";
import { useFormik, validateYupSchema, yupToFormErrors } from "formik";
import { ErrorHelper, ValidationHelper } from "helpers";
import { ApplicationEventType } from "models/types";
import { useCallback, useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import { ApplicationService } from "services/applicationService";
import {
	AnalysisEventData,
	AnalysisReplyDocumentFolder,
	DocumentFolderType,
	DocumentFolderTypesAndFileExtensions
} from "services/types";
import * as Yup from "yup";
import { ContentBoxMode } from "..";
import AnalysisEventContentLayout, {
	AnalysisEventContentTab
} from "./AnalysisEventContentLayout";
import DocumentFolderTypeSelector from "./DocumentFolderTypeSelector";
import ReplyDiscardConfirmModal from "./ReplyDiscardConfirmModal";

interface AnalysisReplyFormContentProps {
	applicationId: string;
	analysisEvent: AnalysisEventData;
	setContentMode: (newMode: ContentBoxMode) => void;
	selectedTab: AnalysisEventContentTab;
	setSelectedTab: (newSelectedTab: AnalysisEventContentTab) => void;
	documentFolderTypesData: DocumentFolderTypesAndFileExtensions | null;
}

interface AnalysisReplyFormData {
	description: string;
	documents: never[];
	documentFolders: Record<string, AnalysisReplyDocumentFolder>;
}

const initialReplyData: AnalysisReplyFormData = {
	description: "",
	documents: [],
	documentFolders: {}
};

const documentValidationSchema = Yup.object().shape({
	name: Yup.string().required(),
	blobName: Yup.string().required()
});

const analysisReplyDocumentFolderValidationSchema = Yup.object()
	.shape({
		interventionDocumentFolderType: Yup.string().required(),
		documents: Yup.array().of(documentValidationSchema)
	})
	.required();

export default function AnalysisReplyFormContent({
	applicationId,
	analysisEvent,
	setContentMode,
	selectedTab,
	setSelectedTab,
	documentFolderTypesData
}: Readonly<AnalysisReplyFormContentProps>): JSX.Element {
	const title = `RE: ${analysisEvent.title}`;
	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
	const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
	const applicationService = useMemo(ApplicationService.getInstance, []);

	const [isFolderSelectorOpen, setIsFolderSelectorOpen] =
		useState<boolean>(false);
	const [selectedFolderTypeIds, setSelectedFolderTypeIds] = useState<
		Record<string, boolean>
	>(() => {
		const initialSelected: Record<string, boolean> = {};
		documentFolderTypesData?.mandatoryDocumentFolderTypes.forEach((option) => {
			initialSelected[option.id] = true;
		});
		return initialSelected;
	});
	const folderTypesById = useMemo(
		() =>
			documentFolderTypesData
				? Object.fromEntries(
						[
							...documentFolderTypesData.mandatoryDocumentFolderTypes,
							...documentFolderTypesData.documentFolderTypes
						].map((folderType) => [folderType.id, folderType])
				  )
				: {},
		[documentFolderTypesData]
	);

	const selectedDocumentFolders = useMemo<DocumentFolderType[]>(() => {
		if (!documentFolderTypesData) {
			return [];
		}
		return [
			...documentFolderTypesData.mandatoryDocumentFolderTypes,
			...documentFolderTypesData.documentFolderTypes
		].filter((folderType) => !!selectedFolderTypeIds[folderType.id]);
	}, [documentFolderTypesData, selectedFolderTypeIds]);

	const submitReply = useCallback(
		(values: AnalysisReplyFormData) => {
			setIsSubmitting(true);
			applicationService
				.registerEvent(applicationId, {
					event: {
						type: ApplicationEventType.DEVOLUTIVA_DO_SOLICITANTE,
						title,
						description: values.description
					},
					documents: [],
					documentFolders: Object.values(values.documentFolders)
				})
				.then(() => {
					window.location.reload();
				})
				.catch((err) => {
					toast.error(ErrorHelper.getResponseErrorMessage(err));
				})
				.finally(() => setIsSubmitting(false));
		},
		[applicationService, applicationId, setContentMode, setIsSubmitting]
	);

	const validationSchema = useMemo(() => {
		return Yup.object().shape({
			description: Yup.string().required(),
			documentFolders: Yup.lazy(() =>
				ValidationHelper.buildDocumentFoldersSchema(
					selectedDocumentFolders.map((folderType) => folderType.id),
					folderTypesById,
					analysisReplyDocumentFolderValidationSchema,
					true
				)
			)
		});
	}, [folderTypesById, selectedDocumentFolders]);

	const formik = useFormik({
		initialValues: initialReplyData,
		validate(values) {
			try {
				validateYupSchema(values, validationSchema, true);
			} catch (err) {
				return yupToFormErrors(err);
			}
			return {};
		},
		onSubmit: submitReply,
		validateOnMount: true,
		validateOnBlur: true,
		enableReinitialize: true
	});

	const {
		values,
		errors,
		touched,
		setFieldValue,
		handleChange,
		setFieldTouched
	} = formik;

	const allFoldersError =
		typeof errors.documentFolders === "string" ? errors.documentFolders : null;

	const hasDocumentsWithError = useMemo(
		() =>
			!!Object.values(values.documentFolders).find(
				(docFolder) => !!docFolder.documents.find((doc) => !!doc.error)
			),
		[values.documentFolders]
	);

	const disableSubmit =
		values.description.length === 0 ||
		hasDocumentsWithError ||
		isSubmitting ||
		!!Object.keys(errors).length;

	useEffect(() => {
		const newDocumentFolders = Object.fromEntries(
			selectedDocumentFolders.map(
				(folderType): [string, AnalysisReplyDocumentFolder] => [
					folderType.id,
					values.documentFolders[folderType.id] ?? {
						interventionDocumentFolderType: folderType.id,
						documents: []
					}
				]
			)
		);
		setFieldValue("documentFolders", newDocumentFolders);
	}, [selectedDocumentFolders]);

	const displayedTabs = useMemo(
		() =>
			[
				AnalysisEventContentTab.description,
				selectedDocumentFolders.length > 0
					? AnalysisEventContentTab.attachments
					: undefined
			].filter((i) => !!i) as AnalysisEventContentTab[],
		[selectedDocumentFolders.length]
	);

	return (
		<>
			<AnalysisEventContentLayout
				asForm
				displayedTabs={displayedTabs}
				selectedTab={selectedTab}
				setSelectedTab={setSelectedTab}
				onSubmit={(evt: SubmitEvent) => {
					evt.preventDefault();
					formik.submitForm();
				}}
				header={
					<div className="flex flex-row items-center min-h-14 h-fit justify-between w-full">
						<h2 className="font-semibold text-base md:text-xl overflow-wrap--anywhere">
							{title}
						</h2>
					</div>
				}
				description={
					<TextArea
						label=""
						placeholder="Digite sua mensagem"
						maxLength={500}
						name="description"
						touched={touched.description}
						error={errors.description && touched.description}
						textError={errors.description}
						value={values.description}
						onChange={handleChange}
						className="md:text-base text-sm"
					/>
				}
				documents={
					<div className="flex flex-col w-full pb-6 md:gap-6">
						<h3 className="text-base font-semibold h-[4.5rem] md:h-fit flex items-center">
							Anexos em pasta
						</h3>
						<div className="flex flex-col gap-3 w-full">
							{allFoldersError && (
								<div className="w-full p-3 flex rounded-lg flex-row bg-feedback-negative-high-50 text-feedback-negative-pure-500 text-sm">
									{allFoldersError}
								</div>
							)}
							{documentFolderTypesData &&
								!!selectedDocumentFolders.length &&
								selectedDocumentFolders.map((folderType) => {
									return (
										<DocumentFolderInput
											fileExtensions={documentFolderTypesData.fileExtensions}
											folderName={folderType.documentType.name}
											folderType={folderType.id}
											value={values.documentFolders[folderType.id] ?? null}
											errors={errors.documentFolders?.[folderType.id]}
											expectedFormats={folderType.expectedFormatsText}
											setValue={(newFolder) => {
												const fieldKey = `documentFolders.${newFolder.interventionDocumentFolderType}`;
												setFieldTouched(fieldKey, true);
												return setFieldValue(fieldKey, newFolder);
											}}
											minDocumentCount={folderType.minAmountDocuments}
											touched={touched.documentFolders?.[folderType.id]}
											key={folderType.id}
										/>
									);
								})}
						</div>
					</div>
				}
				footer={
					<div className="w-full h-full flex md:flex-row flex-col justify-between gap-3 items-center">
						<Button
							hierarchy="ghost"
							kind="icon"
							onClick={() => setIsModalOpen(true)}
							className="hidden md:flex"
						>
							<IcoTrash color={colors["feedback-negative"]["pure-500"]} />
						</Button>
						<div className="flex gap-3 flex-col-reverse md:flex-row w-full md:w-fit">
							<Button
								type="button"
								hierarchy="secondary"
								className="w-full md:w-fit"
								onClick={() => setIsFolderSelectorOpen(true)}
							>
								Selecionar anexos
							</Button>
							<Button
								type="submit"
								hierarchy="primary"
								disabled={disableSubmit}
								className="w-full md:w-fit"
							>
								Enviar resposta
							</Button>
						</div>

						<Button
							hierarchy="secondary"
							onClick={() => setIsModalOpen(true)}
							className="flex gap-2 md:hidden border-feedback-negative-pure-500 h-12 w-full"
						>
							<IcoTrash color={colors["feedback-negative"]["pure-500"]} />
							<span className="text-feedback-negative-pure-500">
								Descartar resposta
							</span>
						</Button>
					</div>
				}
			/>
			<ReplyDiscardConfirmModal
				isModalOpen={isModalOpen}
				primaryAction={() => {
					setContentMode(ContentBoxMode.VIEW);
					setSelectedTab(AnalysisEventContentTab.description);
				}}
				setIsModalOpen={setIsModalOpen}
			/>
			{documentFolderTypesData && (
				<DocumentFolderTypeSelector
					documentFolderTypes={documentFolderTypesData.documentFolderTypes}
					mandatoryDocumentFolderTypes={
						documentFolderTypesData.mandatoryDocumentFolderTypes
					}
					initialValues={selectedFolderTypeIds}
					isOpen={isFolderSelectorOpen}
					setIsOpen={setIsFolderSelectorOpen}
					onSubmit={(newSelectedFolderTypes) => {
						setSelectedFolderTypeIds(newSelectedFolderTypes);
						const selectedFoldersCount = Object.values(
							newSelectedFolderTypes
						).filter((i) => !!i).length;
						setSelectedTab(
							selectedFoldersCount > 0
								? AnalysisEventContentTab.attachments
								: AnalysisEventContentTab.description
						);
					}}
				/>
			)}
		</>
	);
}
