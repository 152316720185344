export default function ToastWithTitle({
	title,
	description
}: {
	title: string;
	description: string;
}) {
	return (
		<div>
			<div className="font-body-semibold-small">{title}</div>
			<div className="font-body-regular-extra-small">{description}</div>
		</div>
	);
}
