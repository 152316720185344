import { useMediaQuery } from "react-responsive";

// Based on https://tailwindcss.com/docs/responsive-design
export const MOBILE_MINIMUM_WIDTH = {
	SMALL: "640px",
	MEDIUM: "768px",
	LARGE: "1024px",
	EXTRA_LARGE: "1280px"
};

export const useIsMobile = (
	minimumWidth: string = MOBILE_MINIMUM_WIDTH.MEDIUM
) => {
	const isMobile = useMediaQuery({
		query: `(max-width: ${minimumWidth})`
	});
	return isMobile;
};
