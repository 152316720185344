import { App } from "App";
import React from "react";
import ReactDOM from "react-dom/client";
import { HelmetProvider } from "react-helmet-async";
import { Provider } from "react-redux";
import {
	createBrowserRouter,
	createRoutesFromElements,
	Route,
	RouterProvider
} from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";

import { persistor, store } from "store";

const root = ReactDOM.createRoot(
	document.getElementById("root") as HTMLElement
);

const router = createBrowserRouter(
	createRoutesFromElements(<Route path="*" element={<App />} />)
);

root.render(
	<React.StrictMode>
		<Provider store={store}>
			<HelmetProvider>
				<PersistGate loading={null} persistor={persistor}>
					<RouterProvider router={router} />
				</PersistGate>
			</HelmetProvider>
		</Provider>
	</React.StrictMode>
);
