import { BaseModel } from "./BaseModel";
import { HighwayProps } from "./types";

function convertDirection(direction: number) {
	switch (direction) {
		case 1:
			return "Norte";
		case 2:
			return "Sul";
		case 3:
			return "Leste";
		case 4:
			return "Oeste";
		case 5:
			return "Interno";
		case 6:
			return "Externo";
		default:
			return "";
	}
}
export class Highway extends BaseModel<HighwayProps> {
	id: string;
	name: string;
	code: string;
	jurisdiction: string;
	ascendingDirection: number;
	descendingDirection: number;

	constructor(data: HighwayProps) {
		super(data);
		this.id = data.id;
		this.name = data.name;
		this.code = data.code;
		this.jurisdiction = data.jurisdiction;
		this.ascendingDirection = data.ascendingDirection;
		this.descendingDirection = data.descendingDirection;
	}

	get fullName() {
		return `${this.name} (${this.code})`;
	}

	directionName(direction: "ascending" | "descending") {
		if (direction === "ascending") {
			return convertDirection(this.ascendingDirection);
		}
		return convertDirection(this.descendingDirection);
	}

	get directionRepresentation() {
		return `${this.directionName("ascending")}/${this.directionName(
			"descending"
		)}`;
	}
}
