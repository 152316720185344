import { IcoClose, IcoDownload, IcoTrash } from "assets/icons";
import clsx from "clsx";
import Button from "components/Button";
import { useMemo } from "react";
import { Tooltip } from "react-tooltip";
import { DocumentLike } from "services/types";

interface DocumentChipProps<T extends DocumentLike> {
	downloadDocument?: (blobName: string) => void;
	removeDocument?: () => void;
	cancelUpload?: () => void;
	eventDocument: T;
	uploadError?: string;
	isLoading?: boolean;
	isWaiting?: boolean;
	showChipLabel?: boolean;
}

export default function DocumentChip<T extends DocumentLike>({
	downloadDocument,
	eventDocument,
	removeDocument,
	cancelUpload,
	uploadError,
	isLoading,
	isWaiting,
	showChipLabel
}: Readonly<DocumentChipProps<T>>): JSX.Element {
	const chipLabel = useMemo(() => {
		if (uploadError) return "Erro no upload";
		if (isLoading) return "Carregando...";
		if (isWaiting) return "Em espera";
		return "Arquivo anexado";
	}, [uploadError, isLoading, isWaiting]);

	const anchorClassName = useMemo(
		() =>
			`${uploadError ? "error-" : ""}document-chip-${eventDocument.name.replace(
				/[\s.]/g,
				"-"
			)}`,
		[uploadError, eventDocument.name]
	);

	return (
		<>
			<div
				className={`${anchorClassName} flex justify-between items-center gap-3 px-3 py-2 border rounded-lg border-neutral-high-200`}
			>
				<div className="flex flex-col justify-center gap-1 max-w-[calc(100%-2.25rem)] w-full">
					<span className="text-sm font-semibold text-nowrap text-ellipsis overflow-hidden">
						{eventDocument.name}
					</span>
					{showChipLabel && (
						<span
							className={clsx(
								"text-xs text-nowrap text-ellipsis overflow-hidden",
								{
									"text-feedback-negative-pure-500": !!uploadError
								}
							)}
							aria-label={chipLabel}
						>
							{chipLabel}
						</span>
					)}
				</div>
				<div className="flex items-center justify-end gap-1">
					{downloadDocument && (
						<Button
							onClick={() => downloadDocument(eventDocument.blobName)}
							className="p-0"
							type="button"
							kind="icon"
							hierarchy="ghost"
						>
							<IcoDownload size="16" />
						</Button>
					)}
					{removeDocument && (
						<Button
							onClick={() => removeDocument()}
							className="p-0"
							type="button"
							kind="icon"
							hierarchy="ghost"
						>
							<IcoTrash size="16" />
						</Button>
					)}
					{isLoading && (
						<div className="border-2 border-neutral-high-100 rounded-full h-6 w-6 border-t-neutral-low-pure-500 animate-spinning" />
					)}
					{cancelUpload && (
						<Button
							onClick={() => cancelUpload()}
							className="p-0"
							type="button"
							kind="icon"
							hierarchy="ghost"
						>
							<div className="border-2 border-neutral-high-100 rounded-full h-6 w-6 flex items-center justify-center">
								<IcoClose size="16" />
							</div>
						</Button>
					)}
				</div>
			</div>
			{uploadError && (
				<Tooltip anchorSelect={`.${anchorClassName}`}>{uploadError}</Tooltip>
			)}
		</>
	);
}
