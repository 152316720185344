import { Checkbox } from "components";
import { InputHTMLAttributes, ReactNode } from "react";
import {
	ApplicationDirectionPosition,
	ApplicationInterventionDirection,
	DirectionLabelsRecord
} from "../types";

export function ReviewSectionHeader({
	title,
	description
}: Readonly<{
	title: string;
	description?: string;
}>): JSX.Element {
	return (
		<div className="flex flex-col gap-3">
			<h2 className="text-base font-semibold">{title}</h2>
			{description && <p> {description} </p>}
		</div>
	);
}

export interface DataPair {
	label: string;
	value?: ReactNode;
}

export type DataPairList = (DataPair | null)[];

interface KeyPairListProps {
	data: DataPairList;
}

export function directionOptionToValue(
	option: ApplicationInterventionDirection
) {
	return `${
		option.isInitialDirection
			? ApplicationDirectionPosition.initial
			: ApplicationDirectionPosition.final
	}${option.direction}`;
}

export function directionOptionToLabel(
	option: ApplicationInterventionDirection
) {
	return DirectionLabelsRecord[option.direction];
}

export function crossMultiplySets<LT, RT>(
	leftSet: LT[],
	rightSet: RT[]
): (LT & RT)[] {
	return leftSet.flatMap((leftItem) =>
		rightSet.map((rightItem) => ({ ...leftItem, ...rightItem }))
	);
}

export function CheckboxDisplay<T>({
	selectedOptions,
	options,
	optionToValue,
	optionToLabel,
	name,
	disabled
}: Readonly<
	{
		selectedOptions: T[];
		options: T[];
		optionToValue: (option: T) => string;
		optionToLabel: (option: T) => string;
	} & InputHTMLAttributes<HTMLInputElement>
>): JSX.Element {
	return (
		<fieldset className="flex flex-col md:flex-row gap-4 justify-start items-center w-full">
			{options.map((option) => {
				const value = optionToValue(option);
				const label = optionToLabel(option);
				return (
					<Checkbox
						key={value}
						name={name ?? ""}
						id={value}
						label={label}
						value={value}
						checked={
							!!selectedOptions.find(
								(selectedOpt) => optionToValue(selectedOpt) === value
							)
						}
						disabled={disabled}
					/>
				);
			})}
		</fieldset>
	);
}

export function KeyPairList({ data }: Readonly<KeyPairListProps>): JSX.Element {
	const filteredData: DataPair[] = data.filter((item) => item) as DataPair[];
	return (
		<ol className="flex flex-col">
			{filteredData.map((item: DataPair, idx) => (
				<li
					key={`${item.label}-${idx}`}
					className="text-sm flex md:flex-row flex-col justify-between md:border-b border-neutral-high-200 py-2"
				>
					<span className="text-neutral-low-400 hyphens-auto">
						{item.label}
					</span>
					<span className="lg:w-[50%]">{item.value}</span>
				</li>
			))}
		</ol>
	);
}
