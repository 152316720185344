import { Constants } from "appConstants";
import { IcoAttachment } from "assets/icons";
import { Button, Loading, Pagination } from "components";
import EventDetailsModal from "components/EventDetailsModal";
import { DateTimeHelper, ErrorHelper, EventDisplayHelper } from "helpers";
import usePagination from "hooks/usePagination";
import { AnalysisEventInternalListItem } from "models/types";
import { useCallback, useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import { ApplicationDocumentService } from "services/applicationDocument";
import { ApplicationService } from "services/applicationService";
import { TabComponentProps } from "../../types";

function EventHistoryCard({
	eventData,
	applicationDocumentService
}: Readonly<{
	eventData: AnalysisEventInternalListItem;
	applicationDocumentService?: ApplicationDocumentService;
}>): JSX.Element {
	const { documents, description } = eventData;
	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
	const shouldTruncate = eventData.description.length > 400;
	const displayedDescription = shouldTruncate
		? `${eventData.description.slice(0, 400)}...`
		: description;
	const hasDocuments = !!documents.length;
	const hasExpansion = shouldTruncate || hasDocuments;

	const eventTitle = EventDisplayHelper.getEventTitle(eventData);

	return (
		<div
			key={eventData.id}
			className="flex flex-row items-stretch gap-4 w-full"
		>
			<aside className="flex flex-col gap-1 p-1 pb-0 items-center">
				<div className="w-2 h-2 rounded-full bg-neutral-low-pure-500" />
				<div className="w-[1px] bg-neutral-low-pure-500 grow" />
			</aside>
			<div className="flex flex-col border border-neutral-high-200 rounded-lg grow mb-4 p-6 gap-4 text-sm">
				<div className="flex flex-col gap-1 grow">
					<header className="flex flex-col md:flex-row items-start md:items-center justify-between gap-1">
						<div className="flex flex-col md:flex-row items-start md:items-center gap-2">
							{eventTitle}
						</div>
						{hasDocuments && (
							<div className="flex items-center gap-2">
								<IcoAttachment size="24" />
								<span>contém anexo</span>
							</div>
						)}
					</header>
					<div className="flex flex-col text-neutral-low-400">
						<span>{`Responsável: ${eventData.createdBy.name}`}</span>
						<span>{`Criada em: ${DateTimeHelper.formatDate(
							new Date(eventData.createdAt),
							Constants.date.LOCALE_DATETIME_WITH_SECONDS_FORMAT
						)}`}</span>
						{eventData.applicationAnalysis && (
							<span>{`Protocolo: ${eventData.applicationAnalysis.analysisCode}`}</span>
						)}
					</div>
				</div>
				<div className="text-neutral-low-300">{displayedDescription}</div>
				{hasExpansion && (
					<footer>
						<Button hierarchy="secondary" onClick={() => setIsModalOpen(true)}>
							<span className="text-sm">Mostrar detalhes</span>
						</Button>
					</footer>
				)}
			</div>
			{hasExpansion && (
				<EventDetailsModal
					eventData={eventData}
					isOpen={isModalOpen}
					onClose={() => setIsModalOpen(false)}
					applicationDocumentService={applicationDocumentService}
				/>
			)}
		</div>
	);
}

export default function ApplicationPageHistory(
	props: Readonly<TabComponentProps>
) {
	const { application } = props;
	const applicationService = useMemo(ApplicationService.getInstance, []);
	const applicationDocumentService = useMemo(
		ApplicationDocumentService.getInstance,
		[]
	);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [applicationAnalysisEvents, setApplicationAnalysisEvents] = useState<
		AnalysisEventInternalListItem[] | null
	>(null);

	const pagination = usePagination();

	const loadApplicationAnalysisEvents = useCallback(() => {
		if (!application) return;
		setIsLoading(true);
		applicationService
			.getApplicationEvents(
				application.id,
				pagination.page,
				pagination.pageItemCount
			)
			.then(({ totalPages, content: responseData }) => {
				pagination.setPageCount(totalPages);
				setApplicationAnalysisEvents(responseData);
			})
			.catch((err) => toast.error(ErrorHelper.getResponseErrorMessage(err)))
			.finally(() => setIsLoading(false));
	}, [
		application?.id,
		pagination.page,
		pagination.setPageCount,
		pagination.pageItemCount,
		applicationService
	]);

	useEffect(() => {
		if (!isLoading) {
			loadApplicationAnalysisEvents();
		}
	}, [loadApplicationAnalysisEvents]);

	if (isLoading) {
		return (
			<div className="bg-neutral-high-pure-50 rounded-lg flex flex-col justify-start grow overflow-hidden">
				<div className="h-full content-center">
					<Loading size={50} />
				</div>
			</div>
		);
	}

	return (
		<div className="bg-neutral-high-pure-50 rounded-lg flex flex-col justify-start grow overflow-hidden">
			<div className="flex flex-col items-center w-full grow p-6 mr-6 overflow-auto overflow-wrap--anywhere scrollbar-gutter--stable scrollbar-width--thin">
				{applicationAnalysisEvents?.map((eventData) => (
					<EventHistoryCard
						key={eventData.id}
						eventData={eventData}
						applicationDocumentService={applicationDocumentService}
					/>
				))}
			</div>
			<footer className="flex flex-row items-center justify-between">
				<Pagination
					currentPage={pagination.page}
					onPageChange={(selectedPage, pageSize) => {
						pagination.setPage(selectedPage);
						pagination.setPageItemCount(pageSize);
					}}
					pageSize={pagination.pageItemCount}
					totalPages={pagination.pageCount}
				/>
			</footer>
		</div>
	);
}
