const SectionHelper = {
	splitHash(hash: string) {
		return hash.split("#")[1];
	},

	goToSection(section: string, offset: number) {
		setTimeout(() => {
			const element = document.getElementById(section);
			if (element) {
				const y = element.getBoundingClientRect().top + window.scrollY - offset;
				document.body.scrollTo({ top: y, behavior: "smooth" });
			}
		}, 200);
	}
};

export default SectionHelper;
