import "./styles.scss";

type LabelProps = {
	children: React.ReactNode;
	isRequired: boolean;
	noMargin?: boolean;
	className?: string;
	showOptionalIndicator?: boolean;
};
export default function Label({
	children,
	isRequired,
	noMargin = false,
	className,
	showOptionalIndicator = true
}: Readonly<LabelProps>) {
	return (
		<div
			className={`label flex flex-row gap-2 ${noMargin ? "" : "margin"} ${
				className ?? ""
			}`}
		>
			<span>{children}</span>
			{!isRequired && showOptionalIndicator && (
				<span className="optional">(Opcional)</span>
			)}
		</div>
	);
}
