import clsx from "clsx";
import { BackButton, Pagination } from "components";
import AnalysisEventContent, {
	ContentBoxMode
} from "components/AnalysisEventContent";
import { ReplyDiscardConfirmModal } from "components/AnalysisEventContent/modules";
import AnalysisListItem from "components/AnalysisListItem";
import { ErrorHelper } from "helpers";
import usePagination from "hooks/usePagination";
import { Application } from "models/Application";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { ApplicationDocumentService } from "services/applicationDocument";
import { ApplicationService } from "services/applicationService";
import {
	ApplicationAnalysisData,
	DocumentFolderTypesAndFileExtensions
} from "services/types";

export default function ApplicationAnalyses(): JSX.Element {
	const { applicationId } = useParams();
	const location = useLocation();
	const prevRoute = location.state?.prevRoute ?? "/";
	const applicationService = useMemo(ApplicationService.getInstance, []);
	const pagination = usePagination();
	const [applicationAnalyses, setApplicationAnalyses] = useState<
		ApplicationAnalysisData[] | null
	>(null);

	const applicationDocumentService = ApplicationDocumentService.getInstance();
	const [documentFolderTypesData, setDocumentFolderTypesData] =
		useState<DocumentFolderTypesAndFileExtensions | null>(null);
	const loadDocumentFolderData = useCallback(() => {
		if (!applicationId) return;
		applicationDocumentService
			.getDocumentFolderTypesByApplication(applicationId)
			.then((responseData) => setDocumentFolderTypesData(responseData));
	}, [applicationId]);

	useEffect(() => {
		loadDocumentFolderData();
	}, [loadDocumentFolderData]);

	const [, setIsLoading] = useState<boolean>(false);
	const loadAnalyses = useCallback(() => {
		if (!applicationId) return;
		applicationService
			.getAnalysesList(
				applicationId,
				pagination.stablePage,
				pagination.pageItemCount
			)
			.then((response) => {
				setApplicationAnalyses(response.content);
				pagination.setPageCount(response.totalPages);
			})
			.catch((err) => toast.error(ErrorHelper.getResponseErrorMessage(err)))
			.finally(() => setIsLoading(false));
	}, [applicationId, pagination.stablePage, pagination.pageItemCount]);

	useEffect(() => {
		loadAnalyses();
	}, [loadAnalyses]);

	const applicationProcessCode = useMemo(
		() => applicationAnalyses?.find((i) => !!i)?.applicationProcessCode ?? "",
		[applicationAnalyses]
	);

	const [selectedEventId, setSelectedEventId] = useState<string | null>(null);
	const [selectedAnalysis, selectedEvent] = useMemo(() => {
		const newSelectedAnalysis = applicationAnalyses?.find(
			(analysis) =>
				!!analysis.events.find(
					(analysisEvent) => analysisEvent.id === selectedEventId
				)
		);
		const newSelectedEvent = newSelectedAnalysis?.events.find(
			(analysisEvent) => analysisEvent.id === selectedEventId
		);
		return [newSelectedAnalysis ?? null, newSelectedEvent ?? null];
	}, [applicationAnalyses, selectedEventId]);

	const isLatestEventSelected =
		selectedEventId === applicationAnalyses?.[0].events[0].id;
	const allowReply =
		isLatestEventSelected &&
		!!selectedAnalysis?.analysisEndDatetime &&
		!Application.isInFinalStatus(selectedAnalysis.applicationStatus);

	const [contentMode, setContentMode] = useState<ContentBoxMode>(
		ContentBoxMode.VIEW
	);
	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

	const getModalPrimaryAction = useCallback(
		(newEventId?: string) => () => {
			if (newEventId) setSelectedEventId(newEventId);
			setContentMode(ContentBoxMode.VIEW);
			setIsModalOpen(false);
		},
		[]
	);
	const modalPrimaryActionRef = useRef<CallableFunction | null>(null);

	const handleSelectedEventChange = useCallback(
		(newEventId: string) => {
			if (contentMode === ContentBoxMode.VIEW) {
				setSelectedEventId(newEventId);
				return;
			}
			setIsModalOpen(true);
			modalPrimaryActionRef.current = getModalPrimaryAction(newEventId);
		},
		[contentMode]
	);

	return (
		<div className="flex flex-col w-full h-full">
			<header className="flex justify-between items-center py-2 md:py-6 px-6 bg-neutral-high-pure-50 md:bg-transparent">
				<div className="flex md:flex-row md:items-center gap-1 my-1 w-full">
					<BackButton prevRoute={prevRoute} />
					<h1 className="font-semibold text-base md:text-xl flex items-center">
						Análises do processo
						{applicationProcessCode && ` - ${applicationProcessCode}`}
					</h1>
				</div>
				{/* <div className="hidden md:flex">
					<Button type="button" kind="chip" hierarchy="secondary">
						<IcoFilter color="#1d1d1d" />
						<span>Filtrar</span>
					</Button>
				</div> */}
			</header>
			<div className="flex gap-6 md:pb-6 md:px-6 w-full grow overflow-y-auto">
				<section
					className={clsx(
						selectedAnalysis ? "hidden md:flex" : "flex",
						"flex flex-col md:border border-neutral-high-200 md:rounded-lg bg-neutral-high-pure-50 overflow-hidden",
						"h-full w-full md:w-[calc(40%-1.5rem)]"
					)}
				>
					<ol className="flex flex-col grow gap-6 w-full px-6 py-3 md:py-6 overflow-y-auto overflow-x-hidden scrollbar-width--thin">
						{applicationAnalyses?.map((analysis) => (
							<li key={analysis.id}>
								<AnalysisListItem
									analysis={analysis}
									selectAnalysisEvent={handleSelectedEventChange}
									selectedEventId={selectedEventId}
								/>
							</li>
						))}
					</ol>
					<footer className="sticky bottom-0 w-full flex flex-row justify-stretch items-center border-t border-neutral-high-200 bg-neutral-high-pure-50">
						<Pagination
							currentPage={pagination.page}
							onPageChange={(page, value) => {
								pagination.setPage(page);
								pagination.setPageItemCount(Number(value));
							}}
							pageSize={pagination.pageItemCount}
							totalPages={pagination.pageCount}
							initialPageIndex={0}
						/>
					</footer>
				</section>
				<section
					className={clsx(
						selectedAnalysis ? "flex" : "hidden md:flex",
						"md:border border-t border-neutral-high-200 md:rounded-lg bg-neutral-high-pure-50",
						"h-full w-full md:w-[60%] overflow-hidden"
					)}
				>
					{applicationId && (
						<AnalysisEventContent
							key={selectedEvent?.id}
							applicationId={applicationId}
							analysis={selectedAnalysis}
							analysisEvent={selectedEvent}
							contentMode={contentMode}
							setContentMode={setContentMode}
							setSelectedEventId={setSelectedEventId}
							allowReply={allowReply}
							documentFolderTypesData={documentFolderTypesData}
						/>
					)}
					<ReplyDiscardConfirmModal
						isModalOpen={isModalOpen}
						primaryAction={() => modalPrimaryActionRef.current?.()}
						setIsModalOpen={setIsModalOpen}
					/>
				</section>
			</div>
		</div>
	);
}
