import { IcoArrowDown } from "assets/icons";
import clsx from "clsx";
import Button from "components/Button";
import React, { useCallback, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";

enum Section {
	ACTIVITIES = "ACTIVITIES",
	REGISTRY = "REGISTRY",
	USERS = "USERS",
	REPORTS = "REPORTS"
}

interface LeafSection {
	id: Section;
	label: string;
	route: string;
	enabled: boolean;
}

interface ParentSection {
	label: string;
	subSections: LeafSection[];
}

type SectionsData = Array<ParentSection | LeafSection>;

const backofficeSectionsData: SectionsData = [
	{
		label: "Solicitações",
		subSections: [
			{
				id: Section.REGISTRY,
				label: "Consulta",
				route: "/backoffice/consulta",
				enabled: true
			},
			{
				id: Section.ACTIVITIES,
				label: "Atividades",
				route: "/backoffice",
				enabled: true
			}
		]
	},
	{
		id: Section.USERS,
		label: "Usuários",
		route: "/backoffice/usuarios",
		enabled: false
	},
	{
		id: Section.REPORTS,
		label: "Relatórios",
		route: "/backoffice/relatorios",
		enabled: false
	}
];

function findSectionByRoute(pathname: string): LeafSection | undefined {
	return backofficeSectionsData
		.flatMap((s) => ("subSections" in s ? s.subSections : s))
		.find((s) => s.enabled && pathname === s.route);
}

const initialSectionData = findSectionByRoute("/backoffice")!;

const SingleSectionLink = React.memo(
	({
		sectionData,
		isSelected,
		goToSection
	}: Readonly<{
		sectionData: LeafSection;
		isSelected: boolean;
		goToSection: (sectionRoute: string) => void;
	}>): JSX.Element => {
		return (
			<button
				onClick={() => goToSection(sectionData.route)}
				className={clsx(
					"h-full flex flex-row justify-center items-center border-neutral-low-pure-500",
					"px-4 md:px-6 relative",
					isSelected && "font-semibold"
				)}
			>
				<span>{sectionData.label}</span>
				<div
					className={clsx(
						"w-full h-1 bg-neutral-low-pure-500 absolute bottom-0",
						isSelected ? "block" : "hidden"
					)}
				/>
			</button>
		);
	}
);

const ParentSectionLink = React.memo(
	({
		sectionData,
		currentSection,
		goToSection
	}: Readonly<{
		sectionData: ParentSection;
		currentSection: Section;
		goToSection: (sectionRoute: string) => void;
	}>): JSX.Element => {
		const isSelected = !!sectionData.subSections.find(
			(s) => s.id === currentSection
		);
		return (
			<div className="group/parent relative flex flex-col h-full">
				<div
					className={clsx(
						"h-full flex flex-row gap-4 justify-center items-center border-neutral-low-pure-500",
						"px-4 md:px-6 relative",
						isSelected && "font-semibold"
					)}
				>
					<span>{sectionData.label}</span>
					<span className="transition group-hover/parent:rotate-[180deg]">
						<IcoArrowDown />
					</span>
					<div
						className={clsx(
							"w-full h-1 bg-neutral-low-pure-500 absolute bottom-0",
							isSelected ? "block" : "hidden"
						)}
					/>
				</div>
				<div className="relative h-0 w-full z-10">
					<div className="absolute w-full h-fit pt-4 hidden group-hover/parent:flex flex-col z-10">
						<div
							className={clsx(
								"rounded-lg overflow-hidden",
								"absolute w-full flex flex-col z-10",
								"drop-l border border-neutral-high-100"
							)}
						>
							{sectionData.subSections
								.filter((s) => s.enabled)
								.map((s, index) => (
									<Button
										key={s.id}
										hierarchy={
											s.id === currentSection ? "primary" : "secondary"
										}
										onClick={() =>
											s.id !== currentSection && goToSection(s.route)
										}
										className={clsx(
											"rounded-none border-0 justify-start border-neutral-high-100",
											index !== 0 && "border-t-2"
										)}
									>
										{s.label}
									</Button>
								))}
						</div>
					</div>
				</div>
			</div>
		);
	}
);

export default function BackofficeNavigation(): JSX.Element {
	const location = useLocation();
	const navigate = useNavigate();

	const currentSectionData = useMemo<LeafSection>(
		() => findSectionByRoute(location.pathname) ?? initialSectionData,
		[location.pathname]
	);

	const goToSection = useCallback(
		(sectionRoute: string) => {
			navigate(sectionRoute);
		},
		[navigate]
	);

	return (
		<nav className="h-full flex flex-row justify-center items-center">
			{backofficeSectionsData.map((sectionData) => {
				const isLeaf = !("subSections" in sectionData);
				if (isLeaf) {
					const isSelected = sectionData.id === currentSectionData.id;
					return (
						sectionData.enabled && (
							<SingleSectionLink
								key={sectionData.id}
								sectionData={sectionData}
								isSelected={isSelected}
								goToSection={goToSection}
							/>
						)
					);
				}
				const isEnabled = sectionData.subSections.find((s) => s.enabled);
				return (
					isEnabled && (
						<ParentSectionLink
							key={sectionData.label}
							sectionData={sectionData}
							currentSection={currentSectionData.id}
							goToSection={goToSection}
						/>
					)
				);
			})}
		</nav>
	);
}
