import { IconProps } from "./types";

export default function IcoRadio({
	size = "16",
	color = "#1D1D1D",
	filled = false
}: IconProps & { filled?: boolean }) {
	return (
		<svg
			width={size}
			height={size}
			viewBox="0 0 16 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<circle cx="8" cy="8" r="6" stroke={color} />
			<circle
				cx="8"
				cy="8"
				r="4"
				fill={filled ? color : "transparent"}
				style={{ transition: "fill 0.1s ease-in-out" }}
			/>
		</svg>
	);
}
