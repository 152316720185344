import { IconProps } from "./types";

export default function IcoCheckbox({
	color = "#1D1D1D",
	size = "16",
	checked = false
}: Readonly<IconProps & { checked?: boolean }>): JSX.Element {
	return (
		<svg
			width={size}
			height={size}
			viewBox="0 0 16 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<rect
				x="2"
				y="2"
				width="12"
				height="12"
				rx="2"
				fill={checked ? color : "transparent"}
				stroke={color}
				style={{ transition: "all 0.15s ease-in-out" }}
			/>
			<path
				d="M4 8.3L7.2 11L12 5"
				stroke={checked ? "white" : "transparent"}
				strokeLinecap="round"
				strokeLinejoin="round"
				style={{ transition: "all 0.15s ease-in-out" }}
			/>
		</svg>
	);
}
