import Button from "components/Button";
import Modal from "components/Modal";
import ModalFooter from "components/ModalFooter";

interface ReplyDiscardConfirmModalProps {
	isModalOpen: boolean;
	setIsModalOpen: (newValue: boolean) => void;
	primaryAction: () => void;
}

export default function ReplyDiscardConfirmModal({
	isModalOpen,
	setIsModalOpen,
	primaryAction
}: Readonly<ReplyDiscardConfirmModalProps>): JSX.Element {
	return (
		<Modal
			isOpen={isModalOpen}
			onClose={() => setIsModalOpen(false)}
			contentClassName="rounded-lg md:max-w-xl"
			bodyClassName="p-8"
		>
			<div className="flex flex-col gap-7">
				<h1 className="text-2xl font-semibold">Deseja excluir sua resposta?</h1>
				<p> Ao clicar em excluir essa ação não poderá ser desfeita</p>
			</div>
			<ModalFooter>
				<div className="w-full h-full flex md:flex-row gap-4 flex-col items-stretch justify-end">
					<Button hierarchy="secondary" onClick={() => setIsModalOpen(false)}>
						Não
					</Button>
					<Button
						hierarchy="primary"
						className="bg-feedback-negative-pure-500 border-feedback-negative-pure-500"
						onClick={() => primaryAction()}
					>
						Excluir
					</Button>
				</div>
			</ModalFooter>
		</Modal>
	);
}
