/* eslint-disable new-cap */
import { BaseModel } from "models/BaseModel";
import { ObjectWithAnyValues } from "utils/types";
import { get, post, postWithResponse, put, remove } from "./axios";
import { BaseListResponse } from "./types";

export class BaseServices<
	D extends ObjectWithAnyValues,
	T extends BaseModel<D>
> {
	model: new (json: any) => T;

	constructor(model: any) {
		this.model = model;
	}

	async put(
		url: string,
		data: ObjectWithAnyValues,
		params: ObjectWithAnyValues = {}
	): Promise<D> {
		return put(url, data, params);
	}

	async edit(
		url: string,
		data: ObjectWithAnyValues,
		params: ObjectWithAnyValues = {}
	): Promise<D> {
		return this.put(url, data, params);
	}

	async post(
		url: string,
		data: ObjectWithAnyValues,
		params: ObjectWithAnyValues = {}
	): Promise<any> {
		return post(url, data, { params });
	}

	async postWithResponse(
		url: string,
		data: ObjectWithAnyValues,
		params: ObjectWithAnyValues = {}
	): Promise<any> {
		return postWithResponse(url, data, { params });
	}

	async create(url: string, data: ObjectWithAnyValues): Promise<T> {
		return this.post(url, data).then((response) => new this.model(response));
	}

	async listPaginatedRecords<R = any>(
		url: string,
		params: { page: number } & Record<string, unknown>
	): Promise<BaseListResponse<R>> {
		return get(url, {
			params: { ...params, page: params.page - 1 },
			paramsSerializer: {
				indexes: null
			}
		});
	}

	async listPaginatedModels(
		url: string,
		params: { page: number } & Record<string, unknown>
	): Promise<BaseListResponse> {
		return this.listPaginatedRecords(url, params).then(
			(response: BaseListResponse) => ({
				...response,
				content: this.createResourcesFromListResponse(response.content)
			})
		);
	}

	async list(url: string, params?: any): Promise<T[]> {
		const response = get(url, params).then((resp) => {
			const resources = this.createResourcesFromListResponse(resp);
			return resources;
		});
		return response;
	}

	async get<RD = any>(url: string, params: any = {}): Promise<RD> {
		return get<RD>(url, params);
	}

	async retrieve(url: string): Promise<T> {
		return get(url).then((data) =>
			BaseModel.createFromResponse(data, this.model)
		);
	}

	async delete(url: string, data: any = {}): Promise<any> {
		return remove(url, { data });
	}

	createResourcesFromListResponse(results: any[]): T[] {
		return results.map((data) =>
			BaseModel.createFromResponse(data, this.model)
		);
	}
}
