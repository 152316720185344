import clsx from "clsx";
import Button from "components/Button";
import { ButtonProps } from "components/Button/types";
import {
	ChangeEvent,
	DragEvent,
	InputHTMLAttributes,
	ReactNode,
	useRef
} from "react";

type SimpleSpread<L, R> = R & Pick<L, Exclude<keyof L, keyof R>>;

export type MultiFileInputProps = SimpleSpread<
	InputHTMLAttributes<HTMLInputElement>,
	{
		handleDrop: (evt: DragEvent) => void;
		handleInputChange: (evt: ChangeEvent<HTMLInputElement>) => void;
		children?: ReactNode;
		wrapperClassName?: string;
		buttonClassName?: string;
		buttonChildrenClassName?: string;
		buttonHierarchy?: ButtonProps["hierarchy"];
	}
>;

function preventDefaultHandler(evt: DragEvent) {
	evt.preventDefault();
}

export default function MultiFileInput({
	name,
	handleDrop,
	handleInputChange,
	disabled,
	required,
	children,
	wrapperClassName,
	buttonClassName,
	buttonChildrenClassName,
	buttonHierarchy = "secondary"
}: Readonly<MultiFileInputProps>): JSX.Element {
	const inputRef = useRef<HTMLInputElement | null>(null);

	return (
		<div className={clsx("flex flex-row items-center gap-3", wrapperClassName)}>
			<Button
				className={clsx("flex gap-2 w-full", buttonClassName)}
				type="button"
				hierarchy={buttonHierarchy}
				onClick={() => {
					inputRef.current?.click();
				}}
				onDragOver={preventDefaultHandler}
				onDrop={handleDrop}
				disabled={disabled}
				childrenWrapperClassName={buttonChildrenClassName}
			>
				{children}
			</Button>
			<input
				name={name}
				ref={inputRef}
				type="file"
				multiple
				value=""
				onChange={handleInputChange}
				disabled={disabled}
				required={required}
				hidden
			/>
		</div>
	);
}
