import {
	ApplicationData,
	ApplicationFormData,
	PersonTypeOptions
} from "pages/NewApplication/steps/types";

const ApplicationDataHelper = {
	isJuridical: (application: ApplicationData | ApplicationFormData) => {
		return (
			application?.applicationProprietorData?.proprietorType ===
			PersonTypeOptions.juridical
		);
	},
	formatResponseDataDocuments: (
		applicationData: ApplicationData
	): ApplicationFormData => {
		return {
			...applicationData,
			documentFolders: Object.fromEntries(
				applicationData.documentFolders.map((folder) => [
					folder.interventionDocumentFolderType,
					folder
				])
			)
		};
	}
};

export default ApplicationDataHelper;
