import { IcoArrowLeft, IcoArrowRight } from "assets/icons";
import colors from "colors";
import Button from "components/Button";
import { useEffect, useState } from "react";
import "./styles.scss";

type PaginationProps = {
	totalPages: number;
	currentPage: number;
	onPageChange: (selectedPage: number, pageSize: number) => void;
	pageSize: number;
	initialPageIndex?: number;
};

const INITIAL_PAGE = 1;

export default function Pagination({
	totalPages,
	currentPage,
	onPageChange,
	pageSize
}: Readonly<PaginationProps>) {
	const [currentSelectedPage, setCurrentSelectedPage] = useState(currentPage);
	useEffect(() => {
		setCurrentSelectedPage(currentPage);
	}, [currentPage]);

	return (
		<div className="table-pagination">
			<span className="page-size-selector md:flex hidden">
				<select
					value={pageSize}
					onChange={(event) => {
						setCurrentSelectedPage(INITIAL_PAGE);
						onPageChange(INITIAL_PAGE, +event.target.value);
					}}
				>
					<option>5</option>
					<option>10</option>
					<option>20</option>
				</select>
				Linhas por página
			</span>
			<div className="page-num-selector">
				<Button
					hierarchy="ghost"
					kind="icon"
					onClick={() => {
						setCurrentSelectedPage(currentSelectedPage - 1);
						onPageChange(currentSelectedPage - 1, pageSize);
					}}
					disabled={currentSelectedPage <= INITIAL_PAGE}
				>
					<IcoArrowLeft color={colors.neutral["low-pure-500"]} size="20" />
				</Button>
				<div className="page-selector">
					<input
						className="page-number-input"
						type="number"
						max={totalPages}
						min={1}
						step={1}
						value={currentSelectedPage.toString()}
						onChange={(event) => {
							const value = +event.target.value;
							if (value > totalPages || value < INITIAL_PAGE) {
								setCurrentSelectedPage(INITIAL_PAGE);
							} else {
								setCurrentSelectedPage(value);
							}
						}}
						onKeyDown={(event) => {
							if (event.key === "Enter") {
								if (
									currentSelectedPage >= INITIAL_PAGE &&
									currentSelectedPage <= totalPages
								) {
									onPageChange(currentSelectedPage, pageSize);
								}
							}
						}}
					/>
					<span>de {`${totalPages}`}</span>
				</div>
				<Button
					hierarchy="ghost"
					kind="icon"
					onClick={() => {
						setCurrentSelectedPage(currentSelectedPage + 1);
						onPageChange(currentSelectedPage + 1, pageSize);
					}}
					disabled={currentSelectedPage >= totalPages}
				>
					<IcoArrowRight color={colors.neutral["low-pure-500"]} size="20" />
				</Button>
			</div>
		</div>
	);
}
