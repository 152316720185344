import { BaseModel } from "./BaseModel";
import { RegulatoryAgencyProps } from "./types";

export class RegulatoryAgency extends BaseModel<RegulatoryAgencyProps> {
	id: string;
	name: string;
	code: string;

	constructor(data: RegulatoryAgencyProps) {
		super(data);
		this.id = data.id;
		this.name = data.name;
		this.code = data.code;
	}
}
