import { HowItWorks1, HowItWorks2, HowItWorks3 } from "assets/images";

export const strings = [
	{
		title: "Cadastre na plataforma",
		description:
			"Crie uma conta para acompanhar o andamento de suas solicitações.",
		image: HowItWorks1
	},
	{
		title: "Solicite uma intervenção",
		description:
			"Selecione uma rodovia, preencha a solicitação de intervenção na plataforma.",
		image: HowItWorks2
	},
	{
		title: "Acompanhe a solicitação",
		description:
			"Você será notificado toda vez que sua solicitação tiver alguma atualização.",
		image: HowItWorks3
	}
];
