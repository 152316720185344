import { IconProps } from "./types";

export default function IcoMinus({
	color = "#7835E3",
	size = "24"
}: Readonly<IconProps & { checked?: boolean }>): JSX.Element {
	return (
		<svg
			width={size}
			height={size}
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<rect
				x="3"
				y="3"
				width="18"
				height="18"
				rx="3"
				fill={color}
				stroke={color}
				style={{ transition: "all 0.15s ease-in-out" }}
			/>

			<path
				d="M17.5 11H6.5C6.21875 11 6 11.25 6 11.5V12.5C6 12.7812 6.21875 13 6.5 13H17.5C17.75 13 18 12.7812 18 12.5V11.5C18 11.25 17.75 11 17.5 11Z"
				style={{ transition: "all 0.15s ease-in-out" }}
				fill="white"
			/>
		</svg>
	);
}
