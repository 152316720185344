import { IconProps } from "./types";

export default function IcoFolder({
	color = "#0D0D0D",
	size = "24"
}: IconProps) {
	return (
		<svg
			width={size}
			height={size}
			viewBox="0 0 24 25"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M3 20.2246V5.22461H9.75L12.375 8.22461H21V20.2246H3Z"
				stroke={color}
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
}
