import { IcoRadio } from "assets/icons";
import { Fragment, InputHTMLAttributes } from "react";

export interface RadioOption {
	label: React.ReactNode;
	value: string;
}

type RadioInputProps = InputHTMLAttributes<HTMLInputElement> & {
	label?: string;
	error?: string | Array<string>;
	className?: string;
	touched?: boolean;
	options: RadioOption[];
};

export default function RadioInput({
	name,
	label,
	value,
	onChange,
	options,
	className,
	touched,
	error
}: RadioInputProps): JSX.Element {
	return (
		<fieldset
			className={`container ${className} flex flex-row gap-6 justify-start align-center`}
		>
			{label && <span>{label}</span>}
			{options.map((option) => (
				<Fragment key={option.value}>
					<input
						id={`${name}-${option.value}`}
						name={name}
						value={option.value}
						onChange={onChange}
						type="radio"
						checked={option.value === value}
						hidden
					/>
					<label
						htmlFor={`${name}-${option.value}`}
						className={`flex flex-row gap-2 justify-start flex-items--center cursor-pointer ${
							error && touched
								? "bg-feedback-negative-high-50 border-feedback-negative-pure-500 focus-visible:outline-feedback-negative-pure-500"
								: ""
						}`}
					>
						<IcoRadio filled={option.value === value} />
						<span>{option.label}</span>
					</label>
				</Fragment>
			))}
			{error && touched && (
				<div className="white-space--pre-line mt-1 text-xs">
					{Array.isArray(error) ? error.join("\n") : error}
				</div>
			)}
		</fieldset>
	);
}
