import { Constants } from "appConstants";
import { baseConfig } from "config";
import CookieHelper from "./CookieHelper";
import LocalStorageHelper from "./LocalStorageHelper";

const AuthTokenHelper = {
	setAuthToken(value: string) {
		CookieHelper.setCookies(Constants.ACCESS_TOKEN_COOKIE, value);
		LocalStorageHelper.setInternalToken(value);
	},

	getCookieToken() {
		return CookieHelper.getCookies(Constants.ACCESS_TOKEN_COOKIE);
	},

	getAuthToken() {
		const cookieToken = this.getCookieToken();
		if (cookieToken) return cookieToken;
		const localStorageToken = LocalStorageHelper.getInternalToken();
		return localStorageToken;
	},

	removeAuthToken() {
		// remove subdomain cookie
		CookieHelper.removeCookie(Constants.ACCESS_TOKEN_COOKIE, {
			domain: baseConfig.baseURLDomain
		});
		CookieHelper.removeCookie(Constants.ACCESS_TOKEN_COOKIE);
		LocalStorageHelper.removeInternalToken();
	}
};

export default AuthTokenHelper;
