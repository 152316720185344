import AuthTokenHelper from "helpers/AuthTokenHelper";
import { jwtDecode } from "jwt-decode";
import { LoggedUser } from "models/auth/LoggedUser";
import { LoggedUserData } from "models/auth/types";

function parseJwt(token: string | undefined | null): LoggedUserData | null {
	if (!token) return null;
	return jwtDecode(token);
}

export default function useUserToken(): LoggedUser | null {
	const token: string | null | undefined = AuthTokenHelper.getAuthToken();
	const tokenInfo: LoggedUserData | null = parseJwt(token);
	return tokenInfo ? new LoggedUser(tokenInfo) : null;
}
