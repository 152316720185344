import clsx from "clsx";
import { ReactNode } from "react";

export enum AnalysisEventContentTab {
	description = "description",
	attachments = "attachments"
}

interface AnalysisEventContentLayoutProps extends Record<string, unknown> {
	header: ReactNode;
	description: ReactNode;
	documents: ReactNode;
	footer: ReactNode;
	selectedTab: AnalysisEventContentTab;
	displayedTabs: AnalysisEventContentTab[];
	setSelectedTab: (newSelectedTab: AnalysisEventContentTab) => void;
	asForm?: boolean;
}

const contentTabsMap: Record<
	AnalysisEventContentTab,
	{ label: string; key: AnalysisEventContentTab }
> = {
	[AnalysisEventContentTab.attachments]: {
		label: "Anexos",
		key: AnalysisEventContentTab.attachments
	},
	[AnalysisEventContentTab.description]: {
		label: "Mensagem",
		key: AnalysisEventContentTab.description
	}
};

export default function AnalysisEventContentLayout({
	description,
	documents,
	footer,
	header,
	asForm = false,
	selectedTab,
	setSelectedTab,
	displayedTabs,
	...rest
}: Readonly<AnalysisEventContentLayoutProps>): JSX.Element {
	const WrapperNode = asForm ? "form" : "div";

	return (
		<WrapperNode
			className="flex flex-col w-full grow overflow-y-auto"
			{...rest}
		>
			{displayedTabs.length > 1 && (
				<div className="flex flex-row items-center border-b border-neutral-high-200 min-h-12 md:min-h-14 px-6 gap-4">
					{displayedTabs.map((tabKey) => {
						const tabData = contentTabsMap[tabKey];
						const selected = selectedTab === tabData.key;
						return (
							<button
								type="button"
								key={tabData.key}
								className={clsx(
									"h-full flex items-end justify-center py-2 border-b-2 transition-all ease-in-out px-2 min-w-[7rem]",
									selected
										? "border-neutral-low-pure-500"
										: "border-transparent"
								)}
								onClick={() => setSelectedTab(tabData.key)}
							>
								<span
									className={clsx(
										"text-neutral-low-400 transition-all ease-in-out font-body-regular-small",
										{
											"text-neutral-low-pure-500 font-body-semibold-small":
												selected
										}
									)}
								>
									{tabData.label}
								</span>
							</button>
						);
					})}
				</div>
			)}

			<header
				className={clsx(
					"py-4 md:py-6 px-6 flex justify-start items-center border-0 md:border-b border-neutral-high-200",
					{ hidden: selectedTab !== AnalysisEventContentTab.description }
				)}
			>
				{header}
			</header>
			<div
				className={clsx(
					"px-6 md:px-9 pb-5 md:py-7 flex flex-col grow gap-2",
					"md:overflow-auto scrollbar-width--thin overflow-wrap--break-word",
					{ hidden: selectedTab !== AnalysisEventContentTab.description }
				)}
			>
				{description}
			</div>

			<div
				className={clsx(
					"px-6 pb-7 md:py-7 flex flex-col justify-start grow gap-3",
					"border-neutral-high-200 md:overflow-auto scrollbar-width--thin",
					{ hidden: selectedTab !== AnalysisEventContentTab.attachments }
				)}
			>
				{documents}
			</div>

			{footer && (
				<footer className="flex items-center w-full md:px-3 px-6 py-3 justify-end sticky bottom-0 border-t border-neutral-high-200 bg-neutral-high-pure-50">
					{footer}
				</footer>
			)}
		</WrapperNode>
	);
}
