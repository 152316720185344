import favicon from "assets/icons/favicon.png";
import EulaModal, { EulaSections } from "components/EulaModal";
import { motion } from "framer-motion";
import { useState } from "react";
import { Helmet } from "react-helmet-async";
import { SelectType, SignUpForm } from "./modules";

export default function SignUp() {
	const [isCompany, setIsCompany] = useState<boolean>(false);
	const [typeSelected, setTypeSelected] = useState<boolean>(false);
	const [isEulaModalOpen, setIsEulaModalOpen] = useState<boolean>(false);
	const [selectedEulaSection, setSelectedEulaSection] = useState<EulaSections>(
		EulaSections.COOKIES
	);

	return (
		<>
			<Helmet>
				<title>{`${process.env.REACT_APP_TITLE} - Cadastro`}</title>
				<link rel="shortcut icon" href={favicon} type="image/x-icon" />
				<link rel="icon" href={favicon} type="image/x-icon" />
			</Helmet>
			<div className="w-full h-full flex justify-center">
				<div
					className={`w-[100vw] sm:w-[26.5rem] bg-neutral-high-pure-50 my-10 overflow-hidden transition-all duration-500 rounded-lg flex ${
						typeSelected
							? "max-h-[44rem] sm:max-h-[42rem]"
							: "max-h-[32rem] sm:max-h-[28rem]"
					}`}
				>
					<motion.div
						animate={typeSelected ? "hide" : "show"}
						variants={{
							show: {
								x: 0,
								transition: {
									ease: "easeOut",
									duration: 0.3
								}
							},
							hide: {
								x: "-50%"
							}
						}}
						transition={{ ease: "easeInOut", duration: 0.2 }}
						className="flex"
					>
						<SelectType
							setIsCompany={(value) => {
								setTypeSelected(true);
								setIsCompany(value);
							}}
						/>
						<SignUpForm
							isCompany={isCompany}
							onGoBack={() => {
								setTypeSelected(false);
							}}
							setSelectedEulaSection={(newSection) => {
								setSelectedEulaSection(newSection);
								setIsEulaModalOpen(true);
							}}
						/>
					</motion.div>
				</div>
			</div>
			<EulaModal
				displayedPolicy={selectedEulaSection}
				isOpen={isEulaModalOpen}
				onClose={() => setIsEulaModalOpen(false)}
			/>
		</>
	);
}
