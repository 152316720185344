export interface OptionLike {
	label: React.ReactNode;
	value: string;
}

const OptionHelper = {
	stringToOption(s: string): OptionLike {
		return {
			label: s,
			value: s
		};
	},
	getValue(o: OptionLike) {
		return o.value;
	}
};

export default OptionHelper;
