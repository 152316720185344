import { Constants } from "appConstants";
import { useState } from "react";
import useStabilizedState from "./useStabilizedState";

export interface PaginationTools {
	page: number;
	stablePage: number;
	setPage: (newPage: number) => void;
	pageCount: number;
	setPageCount: (newPage: number) => void;
	pageItemCount: number;
	setPageItemCount: (newPageItemCount: number) => void;
}

export default function usePagination(
	initialPage?: number,
	initialPageItemCount?: number
): PaginationTools {
	const [pageItemCount, setPageItemCount] = useState<number>(
		initialPageItemCount ?? 10
	);

	const [pageCount, setPageCount] = useState<number>(
		Constants.INITIAL_PAGE_INDEX
	);

	const {
		setValue: setPage,
		unstableValue: page,
		stableValue: stablePage
	} = useStabilizedState<number>(
		initialPage ?? Constants.INITIAL_PAGE_INDEX,
		750
	);

	return {
		page,
		stablePage,
		setPage,
		pageCount,
		setPageCount,
		pageItemCount,
		setPageItemCount
	};
}
