import { ErrorHelper } from "helpers";
import FileHelper, { FileValidationError } from "helpers/FileHelper";
import {
	ChangeEvent,
	DragEvent,
	useCallback,
	useEffect,
	useState
} from "react";
import { StorageService } from "services/storageService";
import { DocumentLike } from "services/types";

export interface DocumentUpload {
	name: string;
	promise: Promise<DocumentLike>;
}

export interface UploadRestrictions {
	maxFileCount?: number;
	acceptedFormats?: string[];
	maxByteSize?: number;
}

export default function useFileUploadQueue(
	uploadedDocuments: DocumentLike[],
	setUploadedDocuments: (newValue: DocumentLike[]) => void,
	{ maxFileCount, acceptedFormats, maxByteSize }: UploadRestrictions
) {
	const validateFile = useCallback(
		(fileObject: File) => {
			FileHelper.validateFile(fileObject, acceptedFormats, maxByteSize);
		},
		[acceptedFormats, maxByteSize]
	);

	const [notUploadedDocuments, setNotUploadedDocuments] = useState<File[]>([]);
	const [documentUploads, setDocumentUploads] = useState<DocumentUpload[]>([]);
	const [showFileCountLimit, setShowFileCountLimit] = useState<boolean>(false);
	const [uploadProgress, setUploadProgress] = useState<number>(0);

	const [isConsuming, setIsConsuming] = useState<boolean>(false);

	const consumeFromDocumentQueue = useCallback(() => {
		setIsConsuming(true);
		const [fileToUpload, ...remaningNotUploadedDocuments] =
			notUploadedDocuments;
		setNotUploadedDocuments(remaningNotUploadedDocuments);

		const uploadPromise = StorageService.uploadFile(fileToUpload, (evt) => {
			const { loaded, total } = evt;
			if (!loaded || !total) return;
			const percent = 100 * Math.round(loaded / total);
			setUploadProgress(percent);
		}).then((response) => ({
			blobName: response.filename,
			name: fileToUpload.name
		}));
		const documentUpload = {
			name: fileToUpload.name,
			promise: uploadPromise
		};
		setDocumentUploads([...documentUploads, documentUpload]);

		uploadPromise
			.then((documentData) => {
				setUploadedDocuments([...uploadedDocuments, documentData]);
			})
			.catch((err) =>
				setUploadedDocuments([
					...uploadedDocuments,
					{
						blobName: fileToUpload.name,
						name: fileToUpload.name,
						error: ErrorHelper.getFileUploadErrorMessage(err)
					}
				])
			)
			.finally(() => {
				setDocumentUploads(
					documentUploads.filter((docUp) => docUp.promise !== uploadPromise)
				);
				setIsConsuming(false);
				setUploadProgress(0);
			});
	}, [notUploadedDocuments, uploadedDocuments, documentUploads]);

	const handleNewFiles = useCallback(
		(newFiles: File[]) => {
			setShowFileCountLimit(false);
			const currentFileCount =
				uploadedDocuments.length +
				documentUploads.length +
				notUploadedDocuments.length;
			const newFileCount = newFiles?.length ?? 0;
			if (maxFileCount && newFileCount + currentFileCount > maxFileCount) {
				setShowFileCountLimit(true);
				return;
			}

			const newDocuments = newFiles.map((file) => {
				try {
					validateFile(file);
					return { file };
				} catch (fileValidationError) {
					return {
						file,
						error: (fileValidationError as FileValidationError).message
					};
				}
			});

			const newDocumentsToUpload = newDocuments
				.filter((doc) => !doc.error)
				.map((doc) => doc.file);
			setNotUploadedDocuments([
				...notUploadedDocuments,
				...newDocumentsToUpload
			]);

			const invalidDocuments = newDocuments
				.filter((doc) => doc.error)
				.map((doc) => ({
					name: doc.file.name,
					blobName: doc.file.name,
					error: doc.error
				}));
			setUploadedDocuments([...uploadedDocuments, ...invalidDocuments]);
		},
		[uploadedDocuments, notUploadedDocuments, isConsuming, maxFileCount]
	);

	const handleInputChange = useCallback(
		(evt: ChangeEvent<HTMLInputElement>) => {
			const newFiles = evt.target.files
				? FileHelper.cloneFileList(evt.target.files)
				: [];
			handleNewFiles(newFiles);
			evt.target.files = null;
		},
		[handleNewFiles]
	);

	const handleDrop = useCallback(
		(evt: DragEvent) => {
			evt.preventDefault();
			const newFiles = evt.dataTransfer?.files
				? FileHelper.cloneFileList(evt.dataTransfer.files)
				: [];
			handleNewFiles(newFiles);
		},
		[handleNewFiles]
	);

	useEffect(() => {
		if (!isConsuming && notUploadedDocuments.length > 0) {
			consumeFromDocumentQueue();
		}
	}, [notUploadedDocuments, isConsuming]);

	return {
		handleDrop,
		handleInputChange,
		handleNewFiles,
		notUploadedDocuments,
		setNotUploadedDocuments,
		documentUploads,
		setDocumentUploads,
		isConsuming,
		showFileCountLimit,
		setShowFileCountLimit,
		uploadProgress
	};
}
