import { IcoLogout } from "assets/icons";
import { CommonUserData } from "components/LoggedInHeader";
import { AuthHelper } from "helpers";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

export default function Account({
	userData,
	baseUrl
}: Readonly<{ userData?: CommonUserData; baseUrl: string }>) {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const handleLogout = () => {
		AuthHelper.logout(dispatch);
		navigate(baseUrl);
	};

	return (
		<div className="group/menu flex gap-2 items-center cursor-default max-sm:hidden h-full min-w-[10rem]">
			<div className="border-2 h-8 w-8 rounded-full flex flex-row items-center justify-center">
				<div className="text-center leading-4">{userData?.name?.[0]}</div>
			</div>
			<div className="flex flex-col gap-1 mr-4">
				<div className="text-xs leading-3 font-semibold">{userData?.name}</div>
				<div className="text-[0.625rem] leading-[0.625rem]">
					{userData?.email}
				</div>
			</div>

			<div className="hidden group-hover/menu:block absolute top-[7rem] w-52 z-10">
				<button
					className="w-full bg-neutral-high-pure-50 mt-2 mr-8 py-3 px-4 rounded-lg shadow-xl flex gap-1 hover:bg-neutral-high-100 cursor-pointer"
					onClick={handleLogout}
				>
					<IcoLogout size="24" />
					<div>Sair</div>
				</button>
			</div>
		</div>
	);
}
