import clsx from "clsx";
import { ExternalUserStatusLabels } from "models/types";
import "./styles.scss";

export default function ApplicationStatusLabel({ status }: { status: string }) {
	return (
		<div
			className={clsx("status-label", {
				"status-analysis": status === ExternalUserStatusLabels.ANDAMENTO,
				"status-concluded": status === ExternalUserStatusLabels.CONCLUIDO,
				"status-cancelled": status === ExternalUserStatusLabels.CANCELADO,
				"status-new-message":
					status === ExternalUserStatusLabels.MENSAGEM_RECEBIDA
			})}
		>
			{status}
		</div>
	);
}
