import { IconProps } from "./types";

export default function IcoFeedbackWarning({
	color = "#1D1D1D",
	size = "24"
}: IconProps) {
	return (
		<svg
			width={size}
			height={size}
			viewBox="0 0 128 128"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<circle
				cx="64"
				cy="64"
				r="64"
				transform="rotate(180 64 64)"
				fill={color}
			/>
			<path
				d="M67.0665 26L66.7438 79.8826H61.2125L60.8899 26H67.0665ZM63.9782 100C62.5953 100 61.4123 99.5237 60.4289 98.5711C59.4763 97.5877 59 96.4047 59 95.0218C59 93.6697 59.4917 92.502 60.475 91.5187C61.4584 90.5353 62.6261 90.0437 63.9782 90.0437C65.3303 90.0437 66.498 90.5353 67.4813 91.5187C68.4647 92.502 68.9563 93.6697 68.9563 95.0218C68.9563 95.9437 68.7259 96.7888 68.2649 97.557C67.8347 98.2945 67.2355 98.8937 66.4672 99.3547C65.7297 99.7849 64.9 100 63.9782 100Z"
				fill="white"
			/>
		</svg>
	);
}
