import { IcoFileAnalysis, IcoFolder } from "assets/icons";
import clsx from "clsx";
import colors from "colors";
import { Accordion, BackButton, Button, Loading } from "components";
import { FolderDisplayItem } from "components/FolderDisplay";
import { ErrorHelper } from "helpers";
import { useIsMobile } from "hooks/useIsMobile";
import { Application } from "models/Application";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { ApplicationDocumentService } from "services/applicationDocument";
import { ApplicationService } from "services/applicationService";
import { ApplicationFormDocumentFolder, RootFolder } from "services/types";
import ApplicationDetailData from "./modules/ApplicationDetailData";
import CancelApplicationModal from "./modules/CancelApplicationModal";
import "./styles.scss";

type ApplicationDetailParams = {
	applicationId: string;
};

export default function ApplicationDetail() {
	const navigate = useNavigate();
	const isMobile = useIsMobile();
	const [application, setApplication] = useState<Application>();
	const [loading, setLoading] = useState(true);
	const [cancelModalOpen, setCancelModalOpen] = useState(false);
	const applicationService = useMemo(ApplicationService.getInstance, []);
	const applicationDocumentService = useMemo(
		ApplicationDocumentService.getInstance,
		[]
	);
	const { applicationId } = useParams<
		keyof ApplicationDetailParams
	>() as ApplicationDetailParams;

	const navigateToMessages = useCallback(
		() =>
			navigate("./analises", {
				state: { prevRoute: `/solicitacao/${applicationId}` }
			}),
		[navigate, applicationId]
	);

	const fetchApplication = async () => {
		setLoading(true);
		try {
			const response = await applicationService.retrieveMyApplication(
				applicationId
			);
			setApplication(response);
		} catch (error) {
			toast.error(ErrorHelper.getResponseErrorMessage(error));
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		fetchApplication();
	}, [applicationId]);

	const applicationDetails = application?.applicationDetails ?? [];
	const applicantDetails = application?.applicantDetails ?? [];
	const volumeOneFolders = useMemo(
		() =>
			application?.documentFolders.filter(
				(folder) => folder?.rootFolder === RootFolder.VOLUME_1
			),
		[application?.documentFolders]
	);

	const volumeTwoFolders = useMemo(
		() =>
			application?.documentFolders.filter(
				(folder) => folder?.rootFolder === RootFolder.VOLUME_2
			),
		[application?.documentFolders]
	);

	const folderSection = (
		key: string,
		folders?: ApplicationFormDocumentFolder[]
	) => {
		return (
			<div
				className="document-folder-section-container px-4 py-2"
				key={`container-${key}`}
			>
				{folders && folders.length > 0 ? (
					folders.map((folder) => (
						<FolderDisplayItem
							key={folder.id}
							folder={folder}
							applicationDocumentService={applicationDocumentService}
							columns={[
								{
									name: (
										<div className="flex flex-column gap-2 items-center font-body-regular-small">
											<IcoFolder />
											{folder.documentTypeName}
										</div>
									),
									size: 12
								}
							]}
						/>
					))
				) : (
					<div className="font-body-regular-extra-small text-neutral-low-400">
						Nenhuma pasta deste volume anexada.
					</div>
				)}
			</div>
		);
	};

	const accordionContentSections = useMemo(() => {
		if (!application) return [];
		return [
			{
				title: "Detalhes da solicitação",
				items: [
					{
						title: "Dados da intervenção",
						value: "application-details",
						content: (
							<div className="data-container">
								<ApplicationDetailData applicationData={applicationDetails} />
								<div className="description-field">
									<div className="description-title">Descrição:</div>
									<div className="description-content overflow-wrap--anywhere">
										{application?.description}
									</div>
								</div>
							</div>
						)
					},
					{
						title: "Dados do proprietário",
						value: "applicant-details",
						content: (
							<div className="data-container">
								<ApplicationDetailData applicationData={applicantDetails} />
							</div>
						)
					}
				]
			},
			{
				title: "Documentos",
				items: [
					{
						title:
							"Volume 1 - Relatórios técnicos e documentos de identificação",
						value: "volume-1",
						content: folderSection("volume-1", volumeOneFolders)
					},
					{
						title: "Volume 2 - Projeto e cronograma",
						value: "volume-2",
						content: folderSection("volume-2", volumeTwoFolders)
					}
				]
			}
		];
	}, [application]);

	const openCancelModal = () => setCancelModalOpen(true);

	return (
		<div className="application-detail-page">
			<div className="application-detail-header">
				<div className="application-back-title">
					{!isMobile && <BackButton prevRoute="/" />}
					<div className="application-title">
						Processo
						{!!application && ` - ${application?.applicationProcessCode}`}
					</div>
				</div>
				{!isMobile && (
					<div className="flex gap-6">
						{!application?.isInFinalStatus() && (
							<Button
								kind="default"
								hierarchy="secondary"
								size="small"
								className="bg-transparent hover:bg-neutral-high-200 text-feedback-negative-pure-500 border-feedback-negative-pure-500"
								disabled={loading}
								onClick={openCancelModal}
							>
								Cancelar
							</Button>
						)}
						<Button
							kind="default"
							hierarchy="primary"
							size="small"
							onClick={navigateToMessages}
							disabled={loading}
						>
							<IcoFileAnalysis
								size="16"
								color={colors.neutral["high-pure-50"]}
							/>
							Exibir análises
						</Button>
					</div>
				)}
			</div>
			{loading || !application ? (
				<Loading />
			) : (
				<div
					className={clsx("application-detail-content", {
						"pb-36": isMobile && !application?.isInFinalStatus(),
						"pb-20": isMobile && application?.isInFinalStatus()
					})}
				>
					<Accordion
						sections={accordionContentSections}
						defaultValue={["application-details"]}
					/>
				</div>
			)}
			{isMobile && (
				<div className="button-footer">
					<Button
						kind="default"
						hierarchy="primary"
						size="small"
						onClick={navigateToMessages}
					>
						Exibir análises
					</Button>
					{!application?.isInFinalStatus() && (
						<Button
							hierarchy="secondary"
							size="small"
							disabled={loading}
							onClick={openCancelModal}
						>
							Cancelar solicitação
						</Button>
					)}
				</div>
			)}
			<CancelApplicationModal
				applicationId={applicationId}
				isOpen={cancelModalOpen}
				onClose={() => setCancelModalOpen(false)}
			/>
		</div>
	);
}
