import { HighwayConcession } from "models/HighwayConcession";
import { HighwayConcessionData } from "models/types";
import { BaseServices } from "./baseServices";

export class HighwayConcessionService extends BaseServices<
	HighwayConcessionData,
	HighwayConcession
> {
	constructor() {
		super(HighwayConcession);
	}

	async listAll() {
		return this.list("highway-concessions");
	}

	static getInstance() {
		return new HighwayConcessionService();
	}
}
