import { AxiosPromise } from "axios";
import { UserInfo } from "models/auth/UserInfo";
import { UserInfoProps, UserVerifyReturn } from "models/auth/types";
import { BaseServices } from "./baseServices";

export class UserInfoService extends BaseServices<UserInfoProps, UserInfo> {
	constructor() {
		super(UserInfo);
	}

	verifyUser(): AxiosPromise<UserVerifyReturn> {
		return super.postWithResponse("users", {}, {});
	}

	async createUserInfo(data: UserInfo): Promise<any> {
		return super.postWithResponse("users/user-info", data);
	}

	static getInstance() {
		return new UserInfoService();
	}
}
