export function last<T>(iterable: T[]): T | undefined {
	const { length } = iterable;
	if (length === 0) return undefined;
	return iterable[length - 1];
}

export function immutableReplace<T>(
	iterable: T[],
	newElement: T,
	oldElementIndex: number
): T[] {
	const [head, tail] = [
		iterable.slice(0, oldElementIndex),
		iterable.slice(oldElementIndex + 1)
	];
	return [...head, newElement, ...tail];
}
