import { IconProps } from "./types";

export default function IcoUpload({
	color = "#1D1D1D",
	size = "24"
}: Readonly<IconProps>): JSX.Element {
	return (
		<svg
			width={size}
			height={size}
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M12 15L12 3M12 3L18 8.4M12 3L6 8.4M21 15.0059V21.0059H3L3 15.0059"
				stroke={color}
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
}
