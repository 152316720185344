import { baseConfig } from "config";
import { ErrorHelper } from "helpers";
import AuthTokenHelper from "helpers/AuthTokenHelper";
import { LoggedUser } from "models/auth/LoggedUser";
import { LoggedUserData } from "models/auth/types";
import { toast } from "react-toastify";
import { BaseServices } from "./baseServices";

export class ExternalAuthService extends BaseServices<
	LoggedUserData,
	LoggedUser
> {
	constructor() {
		super(LoggedUser);
	}
	async loginB2C() {
		AuthTokenHelper.removeAuthToken();

		const client_id = baseConfig.b2cClientId;
		const redirect = `${window.location.protocol}//${window.location.host}`;
		const b2c_url = baseConfig.b2cUrl;
		const loginURL = `${b2c_url}/oauth2/v2.0/authorize?p=B2C_1_SUSI&client_id=${client_id}&nonce=defaultNonce&scope=openid&response_type=id_token&prompt=login&redirect_uri=${redirect}/confirm-login`;

		window.location.assign(loginURL);
	}

	async logout() {
		return this.get("auth/logout/").catch((error) =>
			toast.error(ErrorHelper.getResponseErrorMessage(error))
		);
	}
}
