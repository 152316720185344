import { DefaultMap, EmptyMap } from "assets/images";
import { AlertCard, DetailItem, SelectForm } from "components";
import { useDataModel } from "hooks/useDataModel";
import { HighwayConcession } from "models/HighwayConcession";
import { useRef } from "react";
import { StepLoading } from "../layout";
import { ApplicationFields, ApplicationFormik } from "../types";

export default function Highway({
	formik,
	highways,
	onHighwayChange,
	isLoadingHighways
}: ApplicationFormik & {
	highways: HighwayConcession[] | null;
	isLoadingHighways: boolean;
	onHighwayChange: (e: any) => void;
}) {
	const { highwayConcession } = formik.values;

	const selectedHighway = useDataModel(highwayConcession, HighwayConcession);

	const selectContainerRef = useRef<HTMLDivElement | null>(null);

	return (
		<div className="relative h-full flex">
			<div
				className="max-md:col-span-full overflow-y-auto overflow-x-hidden w-full"
				ref={selectContainerRef}
			>
				<div className="m-6 flex flex-col gap-6">
					<div className="flex flex-col w-full">
						<h1 className="mb-6 md:mt-4 md:mb-4 text-2xl font-semibold md:text-lg md:font-normal ">
							Selecione uma rodovia
						</h1>
						{isLoadingHighways && <StepLoading />}
						{!isLoadingHighways && (
							<SelectForm
								options={highways?.map((type) => type.toOption())}
								name={ApplicationFields.highwayConcession}
								label="Rodovia"
								value={selectedHighway?.toOption()}
								onChange={onHighwayChange}
								setFieldValue={formik.setFieldValue}
								useOuterScroll
								menuPosition="absolute"
								menuPlacement="bottom"
								onMenuClose={() =>
									selectContainerRef.current?.scroll({
										top: 0
									})
								}
								placeholder="Busque uma rodovia ou uma concessionária"
								noOptionsMessage="Nenhuma rodovia ou concessionária encontrada"
								error={formik.errors.highwayConcession}
								touched={formik.touched.highwayConcession}
							/>
						)}
					</div>
					{selectedHighway && (
						<>
							<div>
								<DetailItem
									label="Agência reguladora"
									value={selectedHighway?.concessionaire.regulatoryAgency.code}
								/>
								<DetailItem
									label="Concessionária"
									value={selectedHighway?.concessionaire.name}
								/>
								<DetailItem
									label="Km inicial"
									value={selectedHighway?.positionRepresentation("initial")}
								/>
								<DetailItem
									label="Km final"
									value={selectedHighway?.positionRepresentation("final")}
								/>
							</div>
							{selectedHighway?.description && (
								<AlertCard>{selectedHighway?.description}</AlertCard>
							)}
						</>
					)}
				</div>
			</div>
			<div className="max-md:hidden bg-neutral-high-pure-50 overflow-hidden align-center flex w-full justify-end">
				<img
					src={
						selectedHighway ? selectedHighway.imageUrl || EmptyMap : DefaultMap
					}
					alt={selectedHighway?.highway.name || "Imagem da rodovia"}
					className="object-right w-full"
				/>
			</div>
		</div>
	);
}
