import PolicyContent, { ContentItem } from "./PolicyContent";
import { Td, Th } from "./helperComponents";

const fddAddress = (
	<span className="break-all mx-1">
		<u>https://intervencoes-faixa-dominio.grupoccr.com.br/</u>
	</span>
);

const privacyPolicyStructure: ContentItem[] = [
	{
		title: "",
		content: (
			<>
				<p>
					A presente Política contém informações sobre o tratamento de dados
					pessoais realizado pela CCR S.A (“nós” ou “CCR S.A”). Aqui, você
					encontrará informações sobre a forma como seus dados pessoais são
					utilizados, e, se desejar mais detalhes sobre seu uso, pode entrar em
					contato com nosso DPO, que responderá as dúvidas não cobertas por esta
					Política.
				</p>
				<p>
					<strong>CCR S.A.</strong>
				</p>
				<p>
					Avenida Chedid Jafet, 222, Bloco B, 4º andar, Bairro Vila Olímpia,
					Município de São Paulo, Estado de São Paulo
				</p>
				<p>CNPJ/MF sob nº 02.846.056/0001-97</p>
				<p>
					<strong>O DPO da CCR é: </strong>
					<br />
					Fabio Aparecido Odoni
					<br />
					<span className="break-all">
						encarregado.dadospessoais@grupoccr.com.br
					</span>
				</p>
				<p>
					Como condição para acesso e uso das funcionalidades exclusivas no
					{fddAddress}, Você declara que fez a leitura completa e atenta desta
					Política, estando plenamente ciente, conferindo, assim, sua livre e
					expressa concordância com os termos aqui estipulados, incluindo a
					coleta dos Dados aqui mencionados, bem como sua utilização para os
					fins abaixo especificados. Caso Você não esteja de acordo com as
					disposições desta Política, Você deverá descontinuar o seu acesso ou
					uso do
					{fddAddress}.
				</p>
				<p>
					<strong>
						NOTA ESPECIAL PARA CRIANÇAS E ADOLESCENTES COM IDADE INFERIOR A 16
						ANOS
					</strong>
				</p>
				<p>
					Por favor, não se registre no
					{fddAddress} caso tenha menos de 16 anos.
				</p>
				<p>
					<strong>NOTA ESPECIAL PARA OS REPRESENTANTES LEGAIS</strong>
				</p>
				<p>
					Apesar de proibirmos o registro de crianças e adolescentes com idade
					inferior a 16 anos, os pais devem supervisionar as atividades online
					dos seus filhos menores de idade. As atividades de adolescentes
					maiores de 16 anos e menores de 18 anos devem ser assistidas pelos
					pais ou representantes legais.
				</p>
			</>
		)
	},
	{
		title: "O que são dados pessoais?",
		content: (
			<p>
				Dado pessoal diz respeito a qualquer informação relativa a uma pessoa
				que possa ser utilizada para identificá-la, tanto individualmente quanto
				a partir de uma análise que envolva vários tipos de informação.
			</p>
		)
	},
	{
		title: "Como coletamos dados?",
		content: (
			<>
				<p>
					Os Dados, incluindo Dados Pessoais, poderão ser coletados quando Você
					os submete ou quando Você interage com o{fddAddress}.
				</p>
				<p>
					<strong>BASE DE DADOS</strong>
				</p>
				<p>
					{" "}
					A base de dados formada por meio da coleta de Dados é de nossa
					propriedade e está sob nossa responsabilidade, sendo que seu uso,
					acesso e compartilhamento, quando necessários, serão feitos dentro dos
					limites e propósitos dos negócios descritos nesta Política.
				</p>
			</>
		)
	},
	{
		title: "Quais dados nós utilizamos?",
		content: (
			<>
				<div>
					<table>
						<thead className="relative">
							<tr>
								<Th>O que coletamos?</Th>
								<Th>Para que coletamos?</Th>
							</tr>
							<tr>
								<Th colSpan={2}>Dados cadastrais</Th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<Td> Nome </Td>
								<Td rowSpan={6}>
									<ol>
										<li>Identificar você.</li>
										<li>
											Cumprir as obrigações decorrentes do uso dos nossos
											serviços.
										</li>
										<li>
											Ampliar nosso relacionamento, informar Você sobre
											novidades, funcionalidades, conteúdos, notícias e demais
											eventos que consideramos relevantes para Você.
										</li>
										<li>
											Enriquecer sua experiência conosco e promover nossos
											produtos e serviços.
										</li>
										<li>Cumprir nossas obrigações legais e regulatórias.</li>
									</ol>
								</Td>
							</tr>
							<tr>
								<Td>E-mail</Td>
							</tr>
							<tr>
								<Td>Telefone</Td>
							</tr>
							<tr>
								<Td>RG, CPF e CNH</Td>
							</tr>
							<tr>
								<Td>Empresa</Td>
							</tr>
							<tr>
								<Td>Mensagem</Td>
							</tr>
						</tbody>
						<thead className="relative">
							<tr>
								<Th colSpan={2}>Dados de identificação digital</Th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<Td>Endereço IP e Porta Lógica de Origem</Td>
								<Td rowSpan={7}>
									<ol>
										<li>Identificar você</li>
										<li>
											Cumprir com obrigações legais de manutenção de registros
											estabelecidas pelo Marco Civil da Internet - Lei
											12.965/2014.
										</li>
										<li>Cumprir nossas obrigações legais e regulatórias.</li>
									</ol>
								</Td>
							</tr>
							<tr>
								<Td>Dispositivo (versão do sistema operacional) </Td>
							</tr>
							<tr>
								<Td>Geolocalização</Td>
							</tr>
							<tr>
								<Td>Registros de data e horário cada ação que Você realizar</Td>
							</tr>
							<tr>
								<Td>Quais telas Você acessou</Td>
							</tr>
							<tr>
								<Td>ID da sessão</Td>
							</tr>
							<tr>
								<Td>Cookies</Td>
							</tr>
						</tbody>
					</table>
				</div>
				<br />
				<p>
					<strong>DADOS NECESSÁRIOS</strong>
				</p>
				<p>
					Muitos de nossos serviços dependem diretamente de alguns Dados
					informados na tabela acima, principalmente Dados cadastrais. Caso você
					opte por não fornecer alguns desses Dados, podemos ficar
					impossibilitados de prestar total ou parcialmente nossos serviços à
					Você.
				</p>
				<p>
					<strong>ATUALIZAÇÃO E VERACIDADE DOS DADOS </strong>
				</p>
				<p>
					Você é o único responsável pela precisão, veracidade ou falta dela em
					relação aos Dados que você fornece ou pela sua desatualização. Fique
					atento pois é de sua responsabilidade garantir a exatidão ou mantê-los
					atualizados. Da mesma forma, Nós não somos obrigados a processar ou
					tratar quaisquer dos seus Dados se houver razões para crer que tal
					processamento ou tratamento possa nos imputar qualquer infração de
					qualquer lei aplicável, ou se você estiver utilizando o{fddAddress}
					para quaisquer fins ilegais, ilícitos ou contrários à moralidade.
				</p>
			</>
		)
	},
	{
		title: "A CCR compartilha meus dados pessoais com terceiros?",
		content: (
			<>
				<p>
					Nós não compartilhamos seus dados pessoais com terceiros exceto nos
					casos descritos abaixo:
				</p>
				<ol>
					<li>
						Quando tivermos obtido o seu consentimento para o compartilhamento
						de seus dados pessoais;
					</li>
					<li>Com empresas afiliadas dentro do grupo da CCR;</li>
					<li>
						Com prestadores de serviço e parceiros de negócio da CCR, que
						realizem o tratamento de dados pessoais em nome da CCR;
					</li>
					<li>
						Com prestadores de serviço e parceiros de negócio da CCR, no
						contexto do contrato de concessão, além de outras condutas ilícitas
						com risco de dano elevado, no interesse da CCR e da proteção de
						terceiros.
					</li>
					<li>
						Com a Administração Pública, em cumprimento a obrigações legais e
						contratuais, inclusive no contexto de contratos de concessão,
						permissão ou autorização;
					</li>
					<li>
						Com autoridades policiais conforme requerido pela lei ou quando
						razoavelmente necessário para proteger os direitos, a propriedade
						e/ou a segurança do Usuário, de terceiros e/ou da CCR; e
					</li>
					<li>
						Com autoridades judiciais, administrativas ou governamentais
						competentes, sempre que houver determinação legal, requerimento,
						requisição ou ordem judicial;
					</li>
					<li>
						Quando necessário às atividades comerciais e aos serviços prestados
						por Nós por meio do
						{fddAddress};
					</li>
					<li>
						De forma automática, em caso de movimentações societárias, como
						fusão, aquisição e incorporação.
					</li>
				</ol>
				<p>
					<strong>PARCEIROS COMERCIAIS</strong>
				</p>
				<p>
					Nós possuímos parceiros comerciais que, eventualmente, podem oferecer
					serviços por meio de funcionalidades ou sites que podem ser acessados
					a partir do {fddAddress}. Os Dados fornecidos por Você a estes
					parceiros serão de responsabilidade destes, estando assim sujeitos às
					suas próprias práticas de coleta e uso de dados.
				</p>
				<p>
					Caso empresas terceirizadas realizem o Tratamento em nosso nome de
					quaisquer Dados Pessoais que coletamos, as mesmas respeitarão as
					condições aqui estipuladas e as normas de segurança da informação,
					obrigatoriamente.
				</p>
				<p>
					<strong>ANONIMIZAÇÃO DE DADOS</strong>
				</p>
				<p>
					Para as finalidades de pesquisas de inteligência de mercado,
					divulgação de dados à imprensa e realização de propagandas, os dados
					fornecidos por Você serão compartilhados de forma anonimizada, isto é,
					de forma que não possibilite a sua identificação.
				</p>
			</>
		)
	},
	{
		title: "Como protegemos seus dados e como você também poderá protegê-los.",
		content: (
			<>
				<p>
					É muito importante que Você proteja seus Dados contra acesso não
					autorizado ao seu computador, conta ou senha, além de se certificar de
					sempre clicar em “sair” ao encerrar sua navegação em um computador
					compartilhado. Também é muito importante que Você saiba que nós nunca
					enviaremos mensagens eletrônicas solicitando confirmação de dados ou
					com anexos que possam ser executados (extensões: .exe, .com, entre
					outros) ou ainda links para eventuais downloads.
				</p>
				<p>
					Internamente, os Dados Pessoais coletados são acessados somente por
					profissionais devidamente autorizados, respeitando os princípios de
					proporcionalidade, necessidade e relevância para os objetivos do nosso
					negócio, além do compromisso de confidencialidade e preservação da sua
					privacidade nos termos desta Política.
				</p>
				<p>
					Quando Você utilizar o{fddAddress}, Você poderá ser conduzido, via
					link a outros portais ou plataformas, que poderão coletar suas
					informações e ter sua própria Política de Tratamento de Dados.
				</p>
				<p>
					Caberá a Você ler a Políticas de Privacidade e Tratamento de Dados de
					tais portais ou plataformas fora do nosso ambiente, sendo de sua
					responsabilidade aceitá-la ou rejeitá-la. Nós não somos responsáveis
					pelas Políticas de Privacidade e Tratamento de Dados de terceiros e
					nem pelo conteúdo de quaisquer websites conteúdos ou serviços ligados
					à ambientes que não os nossos.
				</p>
			</>
		)
	},
	{
		title:
			"Como controladores dos seus dados pessoais temos como responsabilidades:",
		content: (
			<ol>
				<li>Registrar as atividades de tratamento;</li>
				<li>Adotar medidas de segurança técnicas e administrativas;</li>
				<li>Garantir a segurança da informação;</li>
				<li>Atender aos direitos dos titulares;</li>
				<li>Cooperar com a Autoridade Nacional de Proteção de Dados;</li>
				<li>Garantir o atendimento aos princípios e bases legais da LGPD.</li>
			</ol>
		)
	},
	{
		title: "Por quanto tempo a CCR mantém meus dados pessoais?",
		content: (
			<>
				<p>
					Nos mantemos os seus dados pelo período necessário ao cumprimento das
					finalidades elencadas nesta Política de Privacidade, incluindo para o
					cumprimento de obrigações legais e contratuais no âmbito de contratos
					de concessão, permissão ou autorização. Para informações específicas
					sobre períodos de retenção de dados, Você pode entrar em contato
					conosco através do nosso canal de comunicação com o encarregado para a
					proteção de dados pessoais enviando um e-mail para:{" "}
					<span className="break-all">
						encarregado.dadospessoais@grupoccr.com.br
					</span>
				</p>
				<p>
					Os Dados Pessoais coletados e os registros de atividades são
					armazenados em ambiente seguro e controlado em nossos servidores
					locais.
				</p>
				<p>
					Caso Você solicite a exclusão de seus Dados Pessoais, pode ocorrer que
					os Dados precisem ser mantidos por período superior ao pedido de
					exclusão, nos termos do artigo 16 da Lei Geral de Proteção de Dados
					Pessoais, para (i) cumprimento de obrigação legal ou regulatória, (ii)
					estudo por órgão de pesquisa, e (iii) transferência a terceiro
					(respeitados os requisitos de tratamento de dados dispostos na mesma
					Lei). Em todos os casos mediante a anonimização dos Dados Pessoais,
					desde que possível.
				</p>
				<p>
					Além disso, alguns dados são armazenados por períodos maiores para
					fins de auditoria, segurança, controle de fraudes, proteção ao crédito
					e preservação de direitos, poderemos permanecer com o histórico de
					registro de seus Dados por prazo maior nas hipóteses que a lei ou
					norma regulatória assim estabelecer ou para preservação de direitos.
				</p>
				<p>
					Findos o prazo de manutenção e a necessidade legal, os Dados Pessoais
					serão excluídos com uso de métodos de descarte seguro, ou utilizados
					de forma anonimizada para fins estatísticos.
				</p>
			</>
		)
	},
	{
		title: "Meus dados pessoais são transferidos para outros países?",
		content: (
			<p>
				Os Dados coletados serão armazenados em nossos servidores localizados no
				Brasil, bem como em ambiente de uso de recursos ou servidores na nuvem
				(cloud computing), o que poderá exigir uma transferência e/ou
				processamento destes Dados fora do Brasil.
			</p>
		)
	},
	{
		title: "Como posso exercer meus direitos enquanto titular?",
		content: (
			<ol>
				<li>
					A Lei Geral de Proteção de Dados lhe garante direitos relacionados aos
					seus dados pessoais que são:
				</li>
				<li>
					confirmação da existência de tratamento dos seus dados pessoais;
				</li>
				<li>acesso aos seus dados pessoais;</li>
				<li>
					correção de dados pessoais incompletos, inexatos ou desatualizados;
				</li>
				<li>
					anonimização, bloqueio ou eliminação de dados desnecessários,
					excessivos ou tratados em desconformidade com o disposto na LGPD, se
					aplicável;
				</li>

				<li>
					portabilidade dos dados pessoais para outro fornecedor, sujeito à
					regulamentação da Autoridade Nacional (ANPD);
				</li>
				<li>
					solicitação de apagamento ou anonimização dos dados pessoais tratados
					com base no seu consentimento, exceto quando a lei autorizar a
					manutenção destes dados por outro fundamento;
				</li>
				<li>
					informações sobre as entidades públicas e privadas com as quais Nós
					tenhamos realizado o uso compartilhado dos seus dados pessoais;
				</li>
				<li>
					informações sobre a possibilidade de não consentir com o tratamento
					dos seus dados pessoais e as consequências de tal ação; e
				</li>
				<li>revogação de seu consentimento.</li>
			</ol>
		)
	},
	{
		title: "A CCR usa cookies?",
		content: (
			<>
				<p>
					A CCR pode utilizar essa tecnologia em seu site, coletando informações
					automaticamente, como dados de navegação e IP do dispositivo. Essas
					informações serão utilizadas para melhorar sua experiência e para que
					possamos avaliar o bom funcionamento das funcionalidades do portal.
					Cabe a Você configurar o seu navegador de Internet caso deseje
					bloqueá-los. Nesta hipótese, algumas funcionalidades que oferecemos
					poderão ser limitadas. Todas as tecnologias utilizadas respeitarão
					sempre a legislação vigente e os termos desta Política.
				</p>
				<p>
					<i>
						Nós não utilizamos nenhum tipo de decisão unicamente automatizada
						que impacte Você.
					</i>
				</p>
			</>
		)
	},
	{
		title: "Informações sobre nossa comunicação digital",
		content: (
			<>
				<p>
					Você reconhece que toda comunicação realizada por e-mail (aos
					endereços informados no seu cadastro), SMS, aplicativos de comunicação
					instantânea ou qualquer outra forma digital, também são válidas,
					eficazes e suficiente para a divulgação de qualquer assunto que se
					refira aos serviços que prestamos, aos seus Dados, bem como às
					condições de sua prestação ou a qualquer outro assunto nele abordado,
					sendo exceção apenas o que essa Política prever como tal.
				</p>
				<p>
					Para otimizar e melhorar nossa comunicação, quando enviamos um e-mail
					para Você podemos receber uma notificação quando eles são abertos,
					desde que esta possibilidade esteja disponível. É importante você
					ficar atento, pois os e-mails são enviados somente pelo domínio:
					@grupoccr.com.br.
				</p>
				<p>
					Em caso de qualquer dúvida com relação às disposições constantes desta
					Política de Privacidade e Tratamento de Dados, você poderá entrar em
					contato por meio do Canal de Comunicação
					<br />
					E-mail:{" "}
					<span className="break-all">
						encarregado.dadospessoais@grupoccr.com.br.
					</span>
				</p>
			</>
		)
	},
	{
		title: "Algumas informações sobre essa política",
		content: (
			<>
				<p>
					<strong>ALTERAÇÃO DO TEOR E ATUALIZAÇÃO.</strong>
				</p>
				<p>
					A fim de melhorar seus serviços, a CCR pode atualizar esta Política,
					conforme a finalidade ou necessidade, tal qual para adequação e
					conformidade legal de disposição de lei ou norma que tenha força
					jurídica equivalente, cabendo a Você verificá-la sempre que efetuar o
					acesso ao {fddAddress} ou utilizar nossos serviços.
				</p>
				<p>
					Ocorrendo atualizações neste documento e que demandem nova coleta de
					consentimento, Você será notificado por meio dos canais de contatos
					que Você informar.
				</p>
				<p>Esta política de privacidade é efetiva a partir de abril de 2024.</p>
			</>
		)
	}
];

export default function PrivacyPolicy(): JSX.Element {
	return <PolicyContent content={privacyPolicyStructure} />;
}
