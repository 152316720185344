import { Constants } from "appConstants";
import { ApplicationData } from "pages/NewApplication/steps/types";
import { AnalysisEventDocument, AnalysisStatusLabel } from "services/types";
import { ObjectWithAnyValues } from "utils/types";

export interface HighwayConcessionData {
	id: string;
	description: string;
	initialKilometer: number;
	finalKilometer: number;
	initialMeter: number;
	finalMeter: number;
	sumKilometer: number;
	initialUf: string;
	finalUf: string;
	imageUrl: string;
	concessionaire: ConcessionaireProps;
	highway: HighwayProps;
	highwayName?: string;
	highwayCode?: string;
	highwayAscendingDirection?: number;
	highwayDescendingDirection?: number;
}

export interface HighwayProps {
	id: string;
	name: string;
	code: string;
	jurisdiction: string;
	ascendingDirection: number;
	descendingDirection: number;
}

export interface ConcessionaireProps {
	id: string;
	name: string;
	concessionStartDate: Date;
	concessionEndDate: Date;
	active: boolean;
	regulatoryAgency: RegulatoryAgencyProps;
}

export interface RegulatoryAgencyProps {
	id: string;
	name: string;
	code: string;
}

export interface InterventionTypeCategoryData {
	id: string;
	name: string;
}

export interface InterventionTypeData {
	id: string;
	name: string;
	description: string;
	detailsUrl: string;
	jurisdictionId: string;
	categories?: InterventionTypeCategoryData[];
}

export interface ApplicationEventData {
	id: string;
	application: ApplicationData;
	type: ApplicationEventType;
	description: string;
	title: string;
	isPublic: boolean;
	analysis: ObjectWithAnyValues; // TODO: Create ApplicationAnalysis model
	createdByGroup: string;
	createdAt: string;
	createdBy: string;
}

export interface AnalysisEventInternalListItem {
	id: string;
	application: ApplicationData;
	type: ApplicationEventType;
	typeLabel: string;
	description: string;
	title: string;
	createdAt: string;
	createdByGroup: Constants.ReadableUserRoleGroups;
	createdBy: {
		id: string;
		name: string;
	};
	applicationAnalysis: null | {
		id: string;
		analysisCode: string;
		status: AnalysisStatusLabel;
	};
	documents: AnalysisEventDocument[];
}

export enum ApplicationStatus {
	RASCUNHO = "RASCUNHO",
	FOCAL_PENDENTE = "FOCAL_PENDENTE",
	FOCAL_ANDAMENTO = "FOCAL_ANDAMENTO",
	CENTRAL_PENDENTE = "CENTRAL_PENDENTE",
	CENTRAL_ANDAMENTO = "CENTRAL_ANDAMENTO",
	ENGENHARIA_PENDENTE = "ENGENHARIA_PENDENTE",
	ENGENHARIA_ANDAMENTO = "ENGENHARIA_ANDAMENTO",
	SOLICITANTE_PENDENTE = "SOLICITANTE_PENDENTE",
	SOLICITANTE_RESPOSTA = "SOLICITANTE_RESPOSTA",
	CANCELADO = "CANCELADO",
	CONCLUIDO = "CONCLUIDO"
}

export const ApplicationFinalStatus = [
	ApplicationStatus.CANCELADO,
	ApplicationStatus.CONCLUIDO
];

export enum InternalUserStatusLabel {
	RASCUNHO = "Rascunho",
	PENDENTE = "Pendente",
	ANDAMENTO = "Em andamento",
	MENSAGEM_ENVIADA = "Mensagem enviada",
	MENSAGEM_RECEBIDA = "Mensagem recebida",
	CANCELADO = "Cancelado",
	CONCLUIDO = "Concluído"
}

export const ApplicationStatusInternalLabel: Record<
	ApplicationStatus,
	InternalUserStatusLabel
> = {
	RASCUNHO: InternalUserStatusLabel.RASCUNHO,
	FOCAL_PENDENTE: InternalUserStatusLabel.PENDENTE,
	FOCAL_ANDAMENTO: InternalUserStatusLabel.ANDAMENTO,
	CENTRAL_PENDENTE: InternalUserStatusLabel.PENDENTE,
	CENTRAL_ANDAMENTO: InternalUserStatusLabel.ANDAMENTO,
	ENGENHARIA_PENDENTE: InternalUserStatusLabel.PENDENTE,
	ENGENHARIA_ANDAMENTO: InternalUserStatusLabel.ANDAMENTO,
	SOLICITANTE_PENDENTE: InternalUserStatusLabel.MENSAGEM_ENVIADA,
	SOLICITANTE_RESPOSTA: InternalUserStatusLabel.MENSAGEM_RECEBIDA,
	CANCELADO: InternalUserStatusLabel.CANCELADO,
	CONCLUIDO: InternalUserStatusLabel.CONCLUIDO
} as const;

export enum ExternalUserStatusLabels {
	RASCUNHO = "Rascunho",
	ANDAMENTO = "Em andamento",
	MENSAGEM_RECEBIDA = "Mensagem recebida",
	CANCELADO = "Cancelado",
	CONCLUIDO = "Concluído"
}

export const ApplicationStatusExternalLabel: Record<
	ApplicationStatus,
	ExternalUserStatusLabels
> = {
	RASCUNHO: ExternalUserStatusLabels.RASCUNHO,
	FOCAL_PENDENTE: ExternalUserStatusLabels.ANDAMENTO,
	FOCAL_ANDAMENTO: ExternalUserStatusLabels.ANDAMENTO,
	CENTRAL_PENDENTE: ExternalUserStatusLabels.ANDAMENTO,
	CENTRAL_ANDAMENTO: ExternalUserStatusLabels.ANDAMENTO,
	ENGENHARIA_PENDENTE: ExternalUserStatusLabels.ANDAMENTO,
	ENGENHARIA_ANDAMENTO: ExternalUserStatusLabels.ANDAMENTO,
	SOLICITANTE_PENDENTE: ExternalUserStatusLabels.MENSAGEM_RECEBIDA,
	SOLICITANTE_RESPOSTA: ExternalUserStatusLabels.ANDAMENTO,
	CANCELADO: ExternalUserStatusLabels.CANCELADO,
	CONCLUIDO: ExternalUserStatusLabels.CONCLUIDO
} as const;

export enum ApplicationEventType {
	APTO_ANALISE = "APTO_ANALISE",
	DEVOLVIDO = "DEVOLVIDO",
	APROVADO = "APROVADO",
	APROVADO_RESSALVAS = "APROVADO_RESSALVAS",
	REPROVADO = "REPROVADO",

	RETORNA_PARA_FOCAL_APTO_ANALISE = "RETORNA_PARA_FOCAL_APTO_ANALISE",
	RETORNA_PARA_FOCAL_DEVOLVIDO = "RETORNA_PARA_FOCAL_DEVOLVIDO",
	RETORNA_PARA_FOCAL_APROVADO = "RETORNA_PARA_FOCAL_APROVADO",
	RETORNA_PARA_FOCAL_APROVADO_RESSALVAS = "RETORNA_PARA_FOCAL_APROVADO_RESSALVAS",
	RETORNA_PARA_FOCAL_REPROVADO = "RETORNA_PARA_FOCAL_REPROVADO",

	SOLICITACAO_CRIADA = "SOLICITACAO_CRIADA",
	DEVOLUTIVA_DO_SOLICITANTE = "DEVOLUTIVA_DO_SOLICITANTE",
	ASSUMIR_RESPONSABILIDADE = "ASSUMIR_RESPONSABILIDADE",
	ADICIONAR_COMENTARIO = "ADICIONAR_COMENTARIO",
	REMOVER_RESPONSABILIDADE = "REMOVER_RESPONSABILIDADE",
	RESPOSTA_PARA_SOLICITANTE = "RESPOSTA_PARA_SOLICITANTE",
	SOLICITACAO_CANCELADA = "SOLICITACAO_CANCELADA",
	SOLICITACAO_CONCLUIDA = "SOLICITACAO_CONCLUIDA"
}

export enum AnalysisStatusCode {
	EM_ANDAMENTO = "EM_ANDAMENTO",
	APROVADO = "APROVADO",
	APROVADO_RESSALVAS = "APROVADO_RESSALVAS",
	REPROVADO = "REPROVADO",
	CANCELADO = "CANCELADO"
}

export enum AnalysisStatusCodeRequest {
	APROVADO = "APROVADO",
	APROVADO_RESSALVAS = "APROVADO_RESSALVAS",
	REPROVADO = "REPROVADO"
}
