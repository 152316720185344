const ObjectHelper = {
	// usage: getPropertyFromObject(obj, 'property.subproperty')
	getPropertyFromObject: (obj: any, prop: string): any => {
		if (typeof obj === undefined) return undefined;

		const index = prop.indexOf(".");
		if (index > -1) {
			const strBeforeDot = prop.substring(0, index);
			const strAfterDot = prop.substring(index + 1);
			return ObjectHelper.getPropertyFromObject(obj[strBeforeDot], strAfterDot);
		}

		return obj[prop];
	}
};

export default ObjectHelper;
