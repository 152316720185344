import { IconProps } from "./types";

export default function IcoCheck({
	color = "#1D1D1D",
	size = "14"
}: Readonly<IconProps>): JSX.Element {
	return (
		<svg
			width={size}
			height={size}
			viewBox="0 0 14 10"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M1 5.4L5.8 9L13 1"
				stroke={color}
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
}
