import { IconProps } from "./types";

export default function IcoArrowLeft({
	color = "#1D1D1D",
	size = "16"
}: IconProps) {
	return (
		<svg
			width={size}
			height={size}
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M20 12L4 12M4 12L12 4M4 12L12 20"
				stroke={color}
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
}
