import { isNaN } from "formik";
import { KeyboardEvent } from "react";

const InputHelper = {
	onlyNumbers: (event: KeyboardEvent<HTMLInputElement>) => {
		// Use this function onKeyDown of Input
		const otherAllowedKeys = [
			"Backspace",
			"Delete",
			"ArrowLeft",
			"ArrowRight",
			"ArrowUp",
			"ArrowDown",
			"Tab",
			"Home",
			"End"
		];
		const isOtherAllowed = otherAllowedKeys.includes(event.code);
		const isNumber = !isNaN(Number(event.key));
		if (!(isOtherAllowed || isNumber)) {
			event.preventDefault();
		}
	}
};

export default InputHelper;
