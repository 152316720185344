import { InterventionType } from "models/InterventionType";
import { InterventionTypeData } from "models/types";
import { BaseServices } from "./baseServices";

export class InterventionTypeService extends BaseServices<
	InterventionTypeData,
	InterventionType
> {
	constructor() {
		super(InterventionType);
	}

	async listByHighwayConcession(highwayConcessionId: string) {
		return this.list(`intervention-type/${highwayConcessionId}`);
	}

	static getInstance() {
		return new InterventionTypeService();
	}
}
