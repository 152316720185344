import { ApplicationDataHelper } from "helpers";
import ErrorHelper from "helpers/ErrorHelper";
import LocalStorageHelper from "helpers/LocalStorageHelper";
import { useModalDisclosure } from "hooks/useModalDisclosure";
import { useCallback, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { ApplicationService } from "services/applicationService";

export interface CurrentDraftResults {
	isLoading: boolean;
	isOpen: boolean;
	onClose: () => void;
	onOpen: () => void;
	checkExistingDraft: () => Promise<any>;
	deleteDraft: () => void;
	goToNewApplication: () => void;
}

const APPLICATION_PATH = "/solicitacao";

export const useCurrentDraft = (): CurrentDraftResults => {
	const location = useLocation();
	const navigate = useNavigate();
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const { isOpen, onOpen, onClose } = useModalDisclosure();
	const applicationService = useMemo(ApplicationService.getInstance, []);

	const goToNewApplication = useCallback(() => {
		onClose();
		const alreadyOnApplicationPage = location.pathname === APPLICATION_PATH;
		if (alreadyOnApplicationPage) navigate(0);
		else navigate(APPLICATION_PATH);
	}, [location.pathname, navigate]);

	const checkExistingDraft = useCallback(() => {
		if (isLoading) return Promise.resolve();
		setIsLoading(true);
		return applicationService
			.getCurrentDraft()
			.then((response) => {
				const hasDraft = response.status === 200;
				if (hasDraft) {
					const formattedData =
						ApplicationDataHelper.formatResponseDataDocuments(response.data);
					LocalStorageHelper.setApplicationDraft(formattedData);
					onOpen();
				} else {
					LocalStorageHelper.removeApplicationDraft();
					goToNewApplication();
				}
			})
			.catch((e) => {
				toast.error(ErrorHelper.getResponseErrorMessage(e));
			})
			.finally(() => setIsLoading(false));
	}, [onOpen]);

	const deleteDraft = useCallback(() => {
		if (isLoading) return;
		setIsLoading(true);
		applicationService
			.deleteDraft()
			.then(() => {
				LocalStorageHelper.removeApplicationDraft();
				goToNewApplication();
			})
			.catch((e) => {
				toast.error(ErrorHelper.getResponseErrorMessage(e));
			})
			.finally(() => setIsLoading(false));
	}, []);

	return {
		isLoading,
		isOpen,
		onOpen,
		onClose,
		checkExistingDraft,
		deleteDraft,
		goToNewApplication
	};
};
