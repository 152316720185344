import Button from "components/Button";
import { ErrorHelper } from "helpers";
import { useEffect, useMemo, useState } from "react";

import { IcoClose } from "assets/icons";
import Loading from "components/Loading";
import { toast } from "react-toastify";
import { ApplicationDocumentService } from "services/applicationDocument";
import {
	AnalysisEventData,
	AnalysisEventDocument,
	AnalysisEventDocumentFolder,
	ApplicationAnalysisData,
	DocumentFolderTypesAndFileExtensions
} from "services/types";
import {
	AnalysisEventContentLayout,
	AnalysisEventDocumentDisplay,
	AnalysisReplyFormContent,
	EmptyAnalysisContent
} from "./modules";
import { AnalysisEventContentTab } from "./modules/AnalysisEventContentLayout";

export enum ContentBoxMode {
	REPLY = "REPLY",
	VIEW = "VIEW"
}

interface AnalysisEventContentProps {
	applicationId: string;
	analysis: ApplicationAnalysisData | null;
	analysisEvent: AnalysisEventData | null;
	contentMode: ContentBoxMode;
	setContentMode: (newMode: ContentBoxMode) => void;
	setSelectedEventId: (newEventId: string | null) => void;
	documentFolderTypesData: DocumentFolderTypesAndFileExtensions | null;
	allowReply?: boolean;
}

export default function AnalysisEventContent({
	applicationId,
	analysis,
	analysisEvent,
	contentMode,
	setContentMode,
	setSelectedEventId,
	documentFolderTypesData,
	allowReply = false
}: Readonly<AnalysisEventContentProps>): JSX.Element {
	const applicationDocumentService = useMemo(
		ApplicationDocumentService.getInstance,
		[]
	);
	const [contentSelectedTab, setContentSelectedTab] =
		useState<AnalysisEventContentTab>(AnalysisEventContentTab.description);
	const [replySelectedTab, setReplySelectedTab] =
		useState<AnalysisEventContentTab>(AnalysisEventContentTab.description);
	const isEmpty = analysis === null || analysisEvent === null;
	const [eventDocuments, setEventDocuments] = useState<
		AnalysisEventDocument[] | null
	>(null);
	const [isLoadingDocuments, setIsLoadingDocuments] = useState<boolean>(
		eventDocuments === null
	);

	useEffect(() => {
		if (!isEmpty) {
			setIsLoadingDocuments(true);
			applicationDocumentService
				.listAnalysisEventDocuments(applicationId, analysisEvent.id)
				.then((data) => setEventDocuments(data))
				.catch((err) => {
					toast.error(ErrorHelper.getResponseErrorMessage(err));
				})
				.finally(() => setIsLoadingDocuments(false));
		}
	}, [analysis, analysisEvent]);

	const {
		populatedDocumentFolders,
		mandatoryDocumentFolders,
		assortedDocuments
	} = useMemo(() => {
		const newAssortedDocuments =
			eventDocuments?.filter(
				(document) => !document.interventionDocumentFolderType
			) ?? [];
		const folderDocuments =
			eventDocuments?.filter(
				(document) => !!document.interventionDocumentFolderType
			) ?? [];
		const newDocumentFolders =
			documentFolderTypesData?.documentFolderTypes
				.map((folderType): AnalysisEventDocumentFolder => {
					return {
						folderTypeData: folderType,
						rootFolder: folderType.rootFolder,
						interventionDocumentFolderType: folderType.id,
						documents: folderDocuments.filter(
							(doc) => doc.interventionDocumentFolderType === folderType.id
						)
					};
				})
				.filter((docFolder) => docFolder.documents.length > 0) ?? [];
		const newMandatoryDocumentFolders =
			documentFolderTypesData?.mandatoryDocumentFolderTypes
				.map((folderType): AnalysisEventDocumentFolder => {
					return {
						folderTypeData: folderType,
						rootFolder: folderType.rootFolder,
						interventionDocumentFolderType: folderType.id,
						documents: folderDocuments.filter(
							(doc) => doc.interventionDocumentFolderType === folderType.id
						)
					};
				})
				.filter((docFolder) => docFolder.documents.length > 0) ?? [];
		return {
			populatedDocumentFolders: newDocumentFolders,
			mandatoryDocumentFolders: newMandatoryDocumentFolders,
			assortedDocuments: newAssortedDocuments
		};
	}, [documentFolderTypesData, eventDocuments]);

	const documentsCount =
		assortedDocuments.length +
		populatedDocumentFolders.length +
		mandatoryDocumentFolders.length;
	const displayedTabs: AnalysisEventContentTab[] = [
		AnalysisEventContentTab.description,
		documentsCount > 0 ? AnalysisEventContentTab.attachments : undefined
	].filter((i) => !!i) as AnalysisEventContentTab[];

	if (isEmpty) return <EmptyAnalysisContent />;

	if (isLoadingDocuments)
		return (
			<div className="flex w-full h-full items-center justify-center">
				<Loading size={48} />
			</div>
		);

	if (contentMode === ContentBoxMode.REPLY) {
		return (
			<AnalysisReplyFormContent
				key={`${applicationId}-${contentMode}`}
				selectedTab={replySelectedTab}
				setSelectedTab={setReplySelectedTab}
				applicationId={applicationId}
				analysisEvent={analysisEvent}
				setContentMode={setContentMode}
				documentFolderTypesData={documentFolderTypesData}
			/>
		);
	}

	return (
		<AnalysisEventContentLayout
			key={`${applicationId}-${contentMode}`}
			displayedTabs={displayedTabs}
			selectedTab={contentSelectedTab}
			setSelectedTab={setContentSelectedTab}
			header={
				<div className="flex flex-row w-full min-h-14 h-fit items-center justify-between">
					<h2 className="font-semibold text-base md:text-xl overflow-wrap--anywhere">
						{analysisEvent.title}
					</h2>
					<Button
						hierarchy="ghost"
						size="small"
						kind="icon"
						className="bg-transparent hover:bg-neutral-high-200 md:hidden flex inline-block w-10"
						onClick={() => setSelectedEventId(null)}
					>
						<IcoClose />
					</Button>
				</div>
			}
			description={
				<div className="w-full h-full border md:border-0 border-neutral-high-100 rounded p-2">
					{analysisEvent.description.split("\n").map((paragraph, index) => (
						<p key={index} className="md:text-base text-sm">
							{paragraph}
						</p>
					))}
				</div>
			}
			documents={
				!!documentsCount &&
				documentFolderTypesData && (
					<AnalysisEventDocumentDisplay
						assortedDocuments={assortedDocuments}
						documentFolders={[
							...mandatoryDocumentFolders,
							...populatedDocumentFolders
						]}
						fileExtensions={documentFolderTypesData.fileExtensions}
					/>
				)
			}
			footer={
				allowReply && (
					<Button
						onClick={() => {
							setContentMode(ContentBoxMode.REPLY);
							setReplySelectedTab(AnalysisEventContentTab.description);
						}}
						className="w-full md:w-fit"
					>
						Responder
					</Button>
				)
			}
		/>
	);
}
