interface TogglableProps {
	label: React.ReactNode;
	children: React.ReactNode;
	show: boolean;
	setShow: (newShow: boolean) => void;
	behavior?: "hide" | "prune";
}

export default function Togglable({
	label,
	children,
	show,
	setShow,
	behavior = "hide"
}: Readonly<TogglableProps>): JSX.Element {
	return (
		<div className="flex w-full h-full">
			{!show && <button onClick={() => setShow(true)}>{label}</button>}
			{(behavior !== "prune" || show) && (
				<div
					className={`w-full h-full ${show ? "" : "display-none"}`}
					hidden={!show}
				>
					{children}
				</div>
			)}
		</div>
	);
}
