const FilterHelper = {
	hasValue(v: unknown, allowNull = false): boolean {
		if (Array.isArray(v)) return !!v.length;

		if (v === undefined) return false;

		if (v === "") return false;

		if (!allowNull && v === null) return false;

		return true;
	},
	stringifyValues<T>(
		filterValue: T[],
		valueGetter: (item: T) => string
	): string[] {
		return filterValue.map((v) => (typeof v === "string" ? v : valueGetter(v)));
	}
};

export default FilterHelper;
