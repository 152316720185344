import { Loading } from "components";
import { DateTimeHelper } from "helpers";
import ApplicationDetailData, {
	FieldsSection
} from "pages/ApplicationDetail/modules/ApplicationDetailData";
import { useMemo } from "react";
import { TabComponentProps } from "../../types";
import "./styles.scss";

export default function ApplicationPageDetails(props: TabComponentProps) {
	const { application } = props;

	const applicationDetails = useMemo((): FieldsSection[] => {
		if (!application) return [];
		const defaultDetails = [...application.applicationDetails];
		const backofficeDetails = [
			{
				title: "",
				nameValueFields: [
					{
						name: "Processo",
						value: application?.applicationProcessCode
					},
					{
						name: "Data do envio",
						value:
							application?.issuedAt &&
							DateTimeHelper.formatDate(
								new Date(application.issuedAt),
								"dd/MM/yyyy"
							)
					},
					{
						name: "Prazo para análise",
						value:
							application?.analysisLimitDatetime &&
							DateTimeHelper.formatDateString(
								application.analysisLimitDatetime
							),
						condition: !!application?.analysisLimitDatetime
					},
					{
						name: "Agência reguladora",
						value: application?.regulatoryAgencyName
					}
				]
			}
		];
		return [...backofficeDetails, ...defaultDetails];
	}, [application]);

	return !application ? (
		<Loading />
	) : (
		<div className="flex flex-1 flex-wrap md:flex-nowrap gap-6 pb-5">
			<div className="section-container">
				<div className="section-name">Detalhes da solicitação</div>
				<div className="px-8 pb-1">
					<ApplicationDetailData applicationData={applicationDetails} />
				</div>
				<div className="description-field">
					<div className="description-title">Descrição:</div>
					<div className="description-content overflow-wrap--anywhere">
						{application.description}
					</div>
				</div>
			</div>
			<div className="section-container">
				<div className="section-name">Dados do proprietário</div>
				<div className="px-8 pb-1">
					<ApplicationDetailData
						applicationData={application.applicantDetails}
					/>
				</div>
			</div>
		</div>
	);
}
