import { Constants } from "appConstants";
import { addMinutes, format, parse, subMinutes } from "date-fns";
import { fromZonedTime, toZonedTime } from "date-fns-tz";

const DateTimeHelper = {
	currentDate(formatString: string = Constants.date.DEFAULT_DATE_FORMAT) {
		return format(new Date(), formatString);
	},

	formatDate(
		date: Date,
		formatString: string = Constants.date.LOCALE_DATETIME_FORMAT
	) {
		return format(date, formatString);
	},

	isValidDate(date: Date) {
		return date instanceof Date && !Number.isNaN(date.getTime());
	},

	addMinutesToTime(
		time: Date | string,
		minutes: number,
		targetFormat = "HH:mm"
	) {
		return String(
			format(
				addMinutes(parse(String(time), "HH:mm:ss", new Date()), minutes),
				targetFormat
			)
		);
	},

	addAlmostADay(date: Date): Date {
		return addMinutes(date, 23 * 60 + 59);
	},

	subAlmostADay(date: Date): Date {
		return subMinutes(date, 23 * 60 + 59);
	},

	parseDateWithoutTime(value: string) {
		return parse(value, "yyyy-MM-dd", new Date());
	},

	formatDateString(
		dateString: string,
		formatString: string = Constants.date.LOCALE_DATE_FORMAT
	) {
		const [relevant] = dateString.split("T");
		const dateObject = this.parseDateWithoutTime(relevant);
		return this.formatDate(dateObject, formatString);
	},

	toUtcDate(localDate: Date): Date {
		return fromZonedTime(
			localDate,
			Intl.DateTimeFormat().resolvedOptions().timeZone
		);
	},

	fromUtcDate(utcDate: Date): Date {
		return toZonedTime(
			utcDate,
			Intl.DateTimeFormat().resolvedOptions().timeZone
		);
	}
};

export default DateTimeHelper;
