import * as Yup from "yup";

import { Constants } from "appConstants";
import { IcoPlus } from "assets/icons";
import { Input, SelectForm } from "components";
import RadioInput from "components/RadioInput";
import Togglable from "components/Togglable";
import { cnpj, cpf } from "cpf-cnpj-validator";
import { ApplicationDataHelper, DateTimeHelper, MaskHelper } from "helpers";
import {
	FieldSetter,
	assignFieldValues,
	useBlurHandler,
	useChangeHandler,
	usePrefixedSetter
} from "helpers/FieldHandlerHelper";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import AddressService from "services/addressService";
import { AddressDataResponse } from "services/types";
import { RootState } from "store";
import { AnyObject } from "yup/lib/types";
import { applicationDataSections } from ".";
import { Section, SectionContent, SectionHeader, SectionItem } from "../layout";
import {
	ApplicationFields,
	ApplicationFormik,
	ApplicationProprietorDataFields,
	PersonTypeOptions,
	ProprietorDocumentType
} from "../types";

const placeholders = {
	tel: "(00) 00000-0000",
	email: "email@gmail.com",
	role: "Ex.: Advogado, Engenheiro...",
	postalCode: "00000-000",
	physicalPersonDocument: "000.000.000-00",
	juridicalPersonDocument: "00.000.000/0000-00",
	stateRegistration: "000.000.000.000",
	cityRegistration: "0.000.000-0",
	date: "dia/mês/ano"
} as const;

const applicationProprietorDataPrefix =
	ApplicationFields.applicationProprietorData;

const errorMessages = Constants.ErrorMessages;

function requiredIfPhysicalPerson(
	this: Yup.TestContext<AnyObject>,
	val: Date | undefined | null
): boolean {
	const { [ApplicationProprietorDataFields.proprietorType]: proprietorType } =
		this.parent;
	const isJuridicalPerson = proprietorType === PersonTypeOptions.juridical;
	return isJuridicalPerson || !!val;
}

function requiredIfJuridicalPerson(
	this: Yup.TestContext<AnyObject>,
	val: string | undefined | null
): boolean {
	const { [ApplicationProprietorDataFields.proprietorType]: proprietorType } =
		this.parent;
	const isPhysicalPerson = proprietorType === PersonTypeOptions.physical;
	return isPhysicalPerson || !!val;
}

function mustBeCnpjIfJuridical(
	this: Yup.TestContext<AnyObject>,
	val: string | undefined | null
): boolean {
	const { [ApplicationProprietorDataFields.proprietorType]: proprietorType } =
		this.parent;
	const isJuridicalPerson = proprietorType === PersonTypeOptions.juridical;
	const cnpjRegex = /\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2}/;

	return !isJuridicalPerson || Boolean(val?.match(cnpjRegex));
}

function mustBeCpfIfPhysical(
	this: Yup.TestContext<AnyObject>,
	val: string | undefined | null
): boolean {
	const { [ApplicationProprietorDataFields.proprietorType]: proprietorType } =
		this.parent;
	const isJuridicalPerson = proprietorType === PersonTypeOptions.juridical;
	const cpfRegex = /\d{3}\.\d{3}\.\d{3}-\d{2}/;

	return isJuridicalPerson || Boolean(val?.match(cpfRegex));
}

function mustNotBePlaceholderDocument(
	this: Yup.TestContext<AnyObject>,
	val: string | undefined | null
): boolean {
	const { [ApplicationProprietorDataFields.proprietorType]: proprietorType } =
		this.parent;
	const isJuridicalPerson = proprietorType === PersonTypeOptions.juridical;
	if (isJuridicalPerson) {
		return cnpj.isValid(val || "");
	}
	return cpf.isValid(val || "");
}

function dateBeforeToday(
	this: Yup.TestContext<AnyObject>,
	value: Date | undefined | null
) {
	if (!value) return true;
	return value <= new Date();
}

export const applicationProprietorDataSchema = Yup.object().shape({
	proprietorType: Yup.string().nullable().required(errorMessages.requiredField),
	proprietorAddressPostalCode: Yup.string()
		.nullable()
		.required(errorMessages.requiredField),
	proprietorAddressState: Yup.string()
		.nullable()
		.required(errorMessages.requiredField),
	proprietorAddressCity: Yup.string()
		.nullable()
		.required(errorMessages.requiredField),
	proprietorAddressNeighbourhood: Yup.string()
		.nullable()
		.required(errorMessages.requiredField),
	proprietorAddressStreet: Yup.string()
		.nullable()
		.required(errorMessages.requiredField),
	proprietorAddressNumber: Yup.string()
		.nullable()
		.required(errorMessages.requiredField),
	proprietorAddressComplement: Yup.string().nullable(),

	proprietorName: Yup.string().nullable().required(errorMessages.requiredField),
	proprietorPhoneNumber: Yup.string()
		.nullable()
		.test(
			"len",
			"É necessário preencher ao menos 10 dígitos",
			(val) => !!val?.length && val.length >= 14
		)
		.required(errorMessages.requiredField),
	proprietorEmail: Yup.string()
		.nullable()
		.email(errorMessages.validEmail)
		.required(errorMessages.requiredField),
	proprietorDocument: Yup.string()
		.nullable()
		.required(errorMessages.requiredField)
		.test(
			"mustBeCpfIfPhysical",
			errorMessages.mustBeCpfIfPhysical,
			mustBeCpfIfPhysical
		)
		.test(
			"mustBeCnpjIfJuridical",
			errorMessages.mustBeCnpjIfJuridical,
			mustBeCnpjIfJuridical
		)
		.test(
			"mustNotBePlaceholderDocument",
			errorMessages.mustNotBePlaceholderDocument,
			mustNotBePlaceholderDocument
		),
	proprietorDocumentType: Yup.string()
		.nullable()
		.required(errorMessages.requiredField),
	proprietorBirthDate: Yup.date()
		.nullable()
		.test("beforeToday", errorMessages.beforeToday, dateBeforeToday)
		.test(
			"requiredIfPhysicalPerson",
			errorMessages.requiredField,
			requiredIfPhysicalPerson
		),
	proprietorCityRegistration: Yup.string().nullable(),
	proprietorStateRegistration: Yup.string().nullable(),
	proprietorContactName: Yup.string()
		.nullable()
		.test(
			"requiredIfJuridicalPerson",
			errorMessages.requiredField,
			requiredIfJuridicalPerson
		),

	applicantEmail: Yup.string()
		.nullable()
		.email(errorMessages.validEmail)
		.required(errorMessages.requiredField),
	applicantName: Yup.string().nullable().required(errorMessages.requiredField),
	applicantRole: Yup.string().nullable().required(errorMessages.requiredField),
	applicantPhoneNumber: Yup.string()
		.nullable()
		.test(
			"len",
			"É necessário preencher ao menos 10 dígitos",
			(val) => !!val?.length && val.length >= 14
		)
		.required(errorMessages.requiredField),
	additionalContactEmail: Yup.string()
		.nullable()
		.email(errorMessages.validEmail)
});

export default function ApplicationProprietorDataSection({
	formik
}: Readonly<ApplicationFormik>): JSX.Element {
	const { applicationProprietorData: values } = formik.values;
	const errors = formik.errors?.applicationProprietorData;
	const touched = formik.touched?.applicationProprietorData;

	const setApplicationFieldValue = usePrefixedSetter(
		applicationProprietorDataPrefix,
		formik.setFieldValue
	);
	const handleChange = useChangeHandler(setApplicationFieldValue);

	const setApplicationFieldTouched = usePrefixedSetter(
		applicationProprietorDataPrefix,
		formik.setFieldTouched
	);
	const handleBlur = useBlurHandler(setApplicationFieldTouched);

	const [showAdditionalEmailField, setShowAdditionalEmailField] =
		useState<boolean>(!!values.additionalContactEmail);

	const isJuridicalEntity = ApplicationDataHelper.isJuridical(formik.values);
	const handlePersonTypeChange = useCallback(
		(newPersonType: PersonTypeOptions) => {
			const isNewPersonJuridicalEntity =
				newPersonType === PersonTypeOptions.juridical;

			if (!isNewPersonJuridicalEntity) {
				assignFieldValues(
					{
						[ApplicationProprietorDataFields.proprietorContactName]:
							values.proprietorName,
						[ApplicationProprietorDataFields.proprietorDocumentType]:
							ProprietorDocumentType.CPF,
						[ApplicationProprietorDataFields.proprietorDocument]: "",
						[ApplicationProprietorDataFields.proprietorCityRegistration]: "",
						[ApplicationProprietorDataFields.proprietorStateRegistration]: ""
					},
					setApplicationFieldValue
				);
			} else {
				assignFieldValues(
					{
						[ApplicationProprietorDataFields.proprietorContactName]: "",
						[ApplicationProprietorDataFields.proprietorDocumentType]:
							ProprietorDocumentType.CNPJ,
						[ApplicationProprietorDataFields.proprietorDocument]: "",
						[ApplicationProprietorDataFields.proprietorBirthDate]: ""
					},
					setApplicationFieldValue
				);
			}
		},
		[assignFieldValues, values.proprietorName]
	);

	const { user } = useSelector((state: RootState) => state.auth);
	useEffect(() => {
		if (user) {
			setApplicationFieldValue(
				ApplicationProprietorDataFields.applicantName,
				user.name
			);
			setApplicationFieldValue(
				ApplicationProprietorDataFields.applicantEmail,
				user.email
			);
		}
	}, [
		user,
		formik.values.applicationProprietorData.applicantName,
		formik.values.applicationProprietorData.applicantEmail
	]);

	const [loadingAddressData, setLoadingAddressData] = useState<boolean>(false);
	const applyAddressData = useCallback(
		(data: AddressDataResponse) => {
			const { address, city, district, state, ok, message } = data;
			if (!ok) {
				const fullFieldName = `${applicationProprietorDataPrefix}.${ApplicationProprietorDataFields.proprietorAddressPostalCode}`;
				formik.setFieldTouched(fullFieldName, true, false);
				formik.setFieldError(fullFieldName, message);
				return;
			}

			const proprietorAddressData = {
				[ApplicationProprietorDataFields.proprietorAddressStreet]: address,
				[ApplicationProprietorDataFields.proprietorAddressCity]: city,
				[ApplicationProprietorDataFields.proprietorAddressNeighbourhood]:
					district,
				[ApplicationProprietorDataFields.proprietorAddressState]: state
			};
			assignFieldValues(proprietorAddressData, setApplicationFieldValue);
		},
		[setApplicationFieldValue]
	);

	const onChangePostalCode = (evt: React.ChangeEvent<HTMLInputElement>) => {
		handleChange(evt, MaskHelper.cep);
		const addressPostalCode = evt.target.value;
		const shouldCallPostalCodeApi =
			addressPostalCode && addressPostalCode.length === "00000-000".length;

		if (shouldCallPostalCodeApi) {
			setLoadingAddressData(true);
			AddressService.getAddress(addressPostalCode)
				.then(applyAddressData)
				.catch(() => {
					const fullFieldName = `${applicationProprietorDataPrefix}.${ApplicationProprietorDataFields.proprietorAddressPostalCode}`;
					formik.setFieldTouched(fullFieldName, true, false);
					formik.setFieldError(
						fullFieldName,
						Constants.ErrorMessages.postalCodeApiError
					);
				})
				.finally(() => setLoadingAddressData(false));
		}
	};

	return (
		<Section>
			<SectionHeader
				title="Dados do proprietário"
				description="Preencha os dados de acordo com o interessado pelo processo"
			/>
			<SectionContent>
				<SectionItem colSpan={12}>
					<RadioInput
						name={ApplicationProprietorDataFields.proprietorType}
						options={[
							{ label: "Pessoa física", value: PersonTypeOptions.physical },
							{ label: "Pessoa jurídica", value: PersonTypeOptions.juridical }
						]}
						value={values.proprietorType}
						error={errors?.proprietorType}
						touched={touched?.proprietorType}
						onChange={(evt) => {
							handleChange(evt);
							handlePersonTypeChange(evt.target.value as PersonTypeOptions);
						}}
						onBlur={handleBlur}
					/>
				</SectionItem>
			</SectionContent>

			<SectionContent id={`section-${applicationDataSections.proprietorData}`}>
				<SectionItem colSpan={isJuridicalEntity ? 6 : 12}>
					<Input
						type="text"
						name={ApplicationProprietorDataFields.proprietorName}
						label={isJuridicalEntity ? "Razão social" : "Nome completo"}
						value={values.proprietorName}
						error={errors?.proprietorName}
						touched={touched?.proprietorName}
						onChange={handleChange}
						onBlur={handleBlur}
					/>
				</SectionItem>
				<SectionItem colSpan={6}>
					<Input
						type="text"
						name={ApplicationProprietorDataFields.proprietorDocument}
						label={isJuridicalEntity ? "CNPJ" : "CPF"}
						placeholder={
							isJuridicalEntity
								? placeholders.juridicalPersonDocument
								: placeholders.physicalPersonDocument
						}
						value={values.proprietorDocument}
						error={errors?.proprietorDocument}
						touched={touched?.proprietorDocument}
						onChange={(evt) =>
							handleChange(
								evt,
								isJuridicalEntity ? MaskHelper.cnpj : MaskHelper.cpf
							)
						}
						onBlur={handleBlur}
					/>
				</SectionItem>
				{isJuridicalEntity ? (
					<>
						<SectionItem colSpan={6}>
							<Input
								type="text"
								name={
									ApplicationProprietorDataFields.proprietorStateRegistration
								}
								label="Inscrição estadual"
								value={values.proprietorStateRegistration}
								error={errors?.proprietorStateRegistration}
								touched={touched?.proprietorStateRegistration}
								onChange={(evt) =>
									handleChange(evt, MaskHelper.stateRegistration)
								}
								onBlur={handleBlur}
								placeholder={placeholders.stateRegistration}
								required={false}
							/>
						</SectionItem>
						<SectionItem colSpan={6}>
							<Input
								type="text"
								name={
									ApplicationProprietorDataFields.proprietorCityRegistration
								}
								label="Inscrição municipal"
								value={values.proprietorCityRegistration}
								error={errors?.proprietorCityRegistration}
								touched={touched?.proprietorCityRegistration}
								onChange={(evt) =>
									handleChange(evt, MaskHelper.cityRegistration)
								}
								onBlur={handleBlur}
								placeholder={placeholders.cityRegistration}
								required={false}
							/>
						</SectionItem>
					</>
				) : (
					<SectionItem colSpan={6}>
						<Input
							type="date"
							name={ApplicationProprietorDataFields.proprietorBirthDate}
							label="Data de nascimento"
							value={values.proprietorBirthDate}
							error={errors?.proprietorBirthDate}
							touched={touched?.proprietorBirthDate}
							onChange={handleChange}
							onBlur={handleBlur}
							placeholder={placeholders.date}
							max={DateTimeHelper.currentDate()}
						/>
					</SectionItem>
				)}
			</SectionContent>

			<SectionContent
				title="Endereço cadastral"
				hint="Endereço de correspondência"
				id={`section-${applicationDataSections.proprietorAddress}`}
			>
				<SectionItem colSpan={6} mbColSpan={6}>
					<Input
						type="text"
						name={ApplicationProprietorDataFields.proprietorAddressPostalCode}
						label="CEP"
						placeholder={placeholders.postalCode}
						value={values.proprietorAddressPostalCode}
						error={errors?.proprietorAddressPostalCode}
						touched={touched?.proprietorAddressPostalCode}
						onChange={onChangePostalCode}
						onBlur={handleBlur}
					/>
				</SectionItem>
				<SectionItem colSpan={6} mbColSpan={6}>
					<SelectForm
						name={ApplicationProprietorDataFields.proprietorAddressState}
						label="Estado"
						value={values.proprietorAddressState}
						error={errors?.proprietorAddressState}
						touched={touched?.proprietorAddressState}
						isDisabled={loadingAddressData}
						options={Constants.federalUnitCodeOptions}
						getOptionValue={(option) => option.value}
						setFieldValue={setApplicationFieldValue as FieldSetter<string>}
						placeholder="Selecione"
					/>
				</SectionItem>

				<SectionItem colSpan={6}>
					<Input
						type="text"
						name={ApplicationProprietorDataFields.proprietorAddressCity}
						label="Cidade"
						value={values.proprietorAddressCity}
						error={errors?.proprietorAddressCity}
						touched={touched?.proprietorAddressCity}
						onChange={handleChange}
						onBlur={handleBlur}
						disabled={loadingAddressData}
					/>
				</SectionItem>
				<SectionItem colSpan={6}>
					<Input
						type="text"
						name={
							ApplicationProprietorDataFields.proprietorAddressNeighbourhood
						}
						label="Bairro"
						value={values.proprietorAddressNeighbourhood}
						error={errors?.proprietorAddressNeighbourhood}
						touched={touched?.proprietorAddressNeighbourhood}
						onChange={handleChange}
						onBlur={handleBlur}
						disabled={loadingAddressData}
					/>
				</SectionItem>

				<SectionItem colSpan={12}>
					<Input
						type="text"
						name={ApplicationProprietorDataFields.proprietorAddressStreet}
						label="Endereço"
						value={values.proprietorAddressStreet}
						error={errors?.proprietorAddressStreet}
						touched={touched?.proprietorAddressStreet}
						onChange={handleChange}
						onBlur={handleBlur}
						disabled={loadingAddressData}
					/>
				</SectionItem>

				<SectionItem colSpan={6}>
					<Input
						type="text"
						name={ApplicationProprietorDataFields.proprietorAddressNumber}
						label="Número"
						value={values.proprietorAddressNumber}
						error={errors?.proprietorAddressNumber}
						touched={touched?.proprietorAddressNumber}
						onChange={handleChange}
						onBlur={handleBlur}
					/>
				</SectionItem>
				<SectionItem colSpan={6}>
					<Input
						type="text"
						name={ApplicationProprietorDataFields.proprietorAddressComplement}
						label="Complemento"
						value={values.proprietorAddressComplement}
						error={errors?.proprietorAddressComplement}
						touched={touched?.proprietorAddressComplement}
						onChange={handleChange}
						onBlur={handleBlur}
						required={false}
					/>
				</SectionItem>
			</SectionContent>

			<SectionContent
				title="Dados para contato"
				hint="Contato do proprietário"
				id={`section-${applicationDataSections.proprietorContact}`}
			>
				{isJuridicalEntity && (
					<SectionItem colSpan={12}>
						<Input
							type="text"
							name={ApplicationProprietorDataFields.proprietorContactName}
							label="Nome do proprietário"
							value={
								isJuridicalEntity
									? values.proprietorContactName
									: values.proprietorName
							}
							error={errors?.proprietorContactName}
							touched={touched?.proprietorContactName}
							onChange={handleChange}
							onBlur={handleBlur}
						/>
					</SectionItem>
				)}

				<SectionItem colSpan={6}>
					<Input
						type="tel"
						name={ApplicationProprietorDataFields.proprietorPhoneNumber}
						label="Telefone"
						value={values.proprietorPhoneNumber}
						error={errors?.proprietorPhoneNumber}
						touched={touched?.proprietorPhoneNumber}
						placeholder={placeholders.tel}
						onChange={(evt) => handleChange(evt, MaskHelper.phone)}
						onBlur={handleBlur}
					/>
				</SectionItem>
				<SectionItem colSpan={6}>
					<Input
						type="email"
						name={ApplicationProprietorDataFields.proprietorEmail}
						label="E-mail"
						value={values.proprietorEmail}
						error={errors?.proprietorEmail}
						touched={touched?.proprietorEmail}
						placeholder={placeholders.email}
						onChange={handleChange}
						onBlur={handleBlur}
					/>
				</SectionItem>
			</SectionContent>

			<SectionContent
				title="Contato direto para tratar solicitação"
				hint="Contato do responsável"
				id={`section-${applicationDataSections.applicantContact}`}
			>
				<SectionItem colSpan={6}>
					<Input
						type="text"
						name={ApplicationProprietorDataFields.applicantName}
						label="Nome do solicitante"
						value={values.applicantName}
						error={errors?.applicantName}
						touched={touched?.applicantName}
						onChange={handleChange}
						onBlur={handleBlur}
						disabled
					/>
				</SectionItem>
				<SectionItem colSpan={6}>
					<Input
						type="text"
						name={ApplicationProprietorDataFields.applicantRole}
						label="Qual o seu papel na intervenção?"
						value={values.applicantRole}
						error={errors?.applicantRole}
						touched={touched?.applicantRole}
						placeholder={placeholders.role}
						onChange={handleChange}
						onBlur={handleBlur}
					/>
				</SectionItem>

				<SectionItem colSpan={6}>
					<Input
						type="tel"
						name={ApplicationProprietorDataFields.applicantPhoneNumber}
						label="Telefone"
						placeholder={placeholders.tel}
						value={values.applicantPhoneNumber}
						error={errors?.applicantPhoneNumber}
						touched={touched?.applicantPhoneNumber}
						onChange={(evt) => handleChange(evt, MaskHelper.phone)}
						onBlur={handleBlur}
					/>
				</SectionItem>
				<SectionItem colSpan={6}>
					<Input
						type="email"
						name={ApplicationProprietorDataFields.applicantEmail}
						label="E-mail"
						placeholder={placeholders.email}
						value={values.applicantEmail}
						error={errors?.applicantEmail}
						touched={touched?.applicantEmail}
						onChange={handleChange}
						onBlur={handleBlur}
						disabled
					/>
				</SectionItem>
				<SectionItem colSpan={12}>
					<Togglable
						label={
							<div className="flex flex-row items-center gap-2 w-full h-full">
								<IcoPlus />
								<span className="text-sm underline">
									Adicionar outro e-mail
								</span>
							</div>
						}
						show={showAdditionalEmailField}
						setShow={setShowAdditionalEmailField}
					>
						<Input
							type="email"
							name={ApplicationProprietorDataFields.additionalContactEmail}
							label="E-mail adicional"
							placeholder={placeholders.email}
							value={values.additionalContactEmail}
							error={errors?.additionalContactEmail}
							touched={touched?.additionalContactEmail}
							onChange={handleChange}
							onBlur={(evt) => {
								if (!values.additionalContactEmail) {
									setShowAdditionalEmailField(false);
								}
								handleBlur(evt);
							}}
							required={false}
						/>
					</Togglable>
				</SectionItem>
			</SectionContent>
		</Section>
	);
}
