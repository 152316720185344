import { Constants } from "appConstants";
import { ObjectWithAnyValues } from "utils/types";
import { BaseModel } from "../BaseModel";
import { UserInfoProps } from "./types";

export class UserInfo extends BaseModel<UserInfoProps> {
	userId: string;
	email: string;
	name: string;
	contactNumber: number;
	document: string;
	documentType: Constants.UserDocumentType;
	acceptedTerms?: boolean;

	constructor(data: UserInfoProps) {
		super(data);
		this.userId = data.userId;
		this.email = data.email;
		this.name = data.name;
		this.contactNumber = data.contactNumber;
		this.document = data.document;
		this.documentType = data.documentType;
		this.acceptedTerms = data.acceptedTerms;
	}

	static createFromResponse(response: ObjectWithAnyValues, Model: any = this) {
		if (!response) {
			return response;
		}

		return new Model({
			...response
		});
	}
}
