import { ReactNode } from "react";

export interface ContentItem {
	title: string | ReactNode;
	content: ReactNode;
}

export default function PolicyContent({
	content
}: Readonly<{ content: ContentItem[] }>): JSX.Element {
	return (
		<section className="flex box flex-col w-full gap-4 overflow-y-auto h-[calc(100vh-15rem)] md:h-[60vh] text-sm">
			{content.map((contentItem, idx) => (
				<div key={idx} className="flex flex-col gap-2 w-full">
					{contentItem.title && (
						<h5>
							<strong>{contentItem.title}</strong>
						</h5>
					)}
					{contentItem.content}
				</div>
			))}
		</section>
	);
}
