import { useDispatch, useSelector } from "react-redux";
import { Outlet, useLocation } from "react-router-dom";

import { LoggedInHeader } from "components";
import { AuthHelper } from "helpers";
import useUserToken from "hooks/useUserToken";
import { useEffect } from "react";
import { RootState } from "store";

export default function LoggedInLayout() {
	const { user: externalUser } = useSelector((state: RootState) => state.auth);
	const dispatch = useDispatch();
	const tokenInfo = useUserToken();
	const location = useLocation();
	const isInBackoffice = Boolean(/backoffice/g.exec(location.pathname)?.length);

	useEffect(() => {
		if (!tokenInfo && externalUser) {
			AuthHelper.logout(dispatch);
		}
	}, []);

	return (
		<>
			{isInBackoffice ? (
				<LoggedInHeader
					baseUrl="/backoffice/"
					isExternalUser={false}
					userData={tokenInfo ?? undefined}
					tokenInfo={tokenInfo}
				/>
			) : (
				externalUser && (
					<LoggedInHeader
						baseUrl="/"
						isExternalUser
						userData={externalUser}
						tokenInfo={tokenInfo}
					/>
				)
			)}
			<div className="h-[calc(100%-3.5rem)] sm:h-[calc(100%-4rem)] -mt-[1px]">
				<Outlet />
			</div>
		</>
	);
}
