import { IconProps } from "./types";

export default function IcoCalendar({
	color = "#1D1D1D",
	size = "16"
}: IconProps) {
	return (
		<svg
			width={size}
			height={size}
			viewBox="0 0 32 32"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M4 12H28M10 4V6M22 4V6M8 28H24C26.2091 28 28 26.2091 28 24V10C28 7.79086 26.2091 6 24 6H8C5.79086 6 4 7.79086 4 10V24C4 26.2091 5.79086 28 8 28Z"
				stroke={color}
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
}
