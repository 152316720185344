import { IcoFeedbackError, IcoInfoCircle } from "assets/icons";
import favicon from "assets/icons/favicon.png";
import { LogoWhite } from "assets/images";
import colors from "colors";
import { Helmet } from "react-helmet-async";

export default function BackofficeUserNotFound() {
	return (
		<>
			<Helmet>
				<title>{`${process.env.REACT_APP_TITLE} - Backoffice - Email não cadastrado`}</title>
				<link rel="shortcut icon" href={favicon} type="image/x-icon" />
				<link rel="icon" href={favicon} type="image/x-icon" />
			</Helmet>
			<div className="h-full w-full flex justify-center bg-neutral-low-pure-500">
				<div className="flex flex-col gap-16 mt-[20vh] items-center">
					<img src={LogoWhite} alt="logo-icon" className="logo" />
					<div className="bg-neutral-high-pure-50 p-10 w-[26.5rem] rounded-lg max-sm:w-[18rem] max-sm:py-6">
						<IcoFeedbackError />
						<p className="text-2xl leading-5 font-semibold py-4">
							Email não cadastrado
						</p>
						<p className="pb-6">
							Você não tem permissão para acessar a plataforma Faixa de Domínio.
						</p>
						<div className="flex items-center bg-neutral-high-100 gap-4 p-4 rounded-lg">
							<div>
								<IcoInfoCircle color={colors.neutral["low-pure-500"]} />
							</div>
							<p className="text-xs">
								Solicite o acesso para a pessoa gestora da sua área de trabalho
								dentro do grupo CCR.
							</p>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
