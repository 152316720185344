import QueryString from "qs";

const StringHelper = {
	getFirstName(fullName: string) {
		return fullName.split(" ")[0];
	},

	slugify(text: string) {
		return text
			.toString()
			.normalize("NFKD")
			.toLowerCase()
			.trim()
			.replace(/\s+/g, "-")
			.replace(/[^\w\\-]+/g, "")
			.replace(/\\-\\-+/g, "-");
	},

	isValidUUID(value: string) {
		const regexExp =
			/^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;
		return regexExp.test(value);
	},

	formattedPhoneNumber(phone: string) {
		if (phone.length === 10) {
			return `(${phone.slice(0, 2)}) ${phone.slice(2, 6)}-${phone.slice(6)}`;
		}
		return `(${phone.slice(0, 2)}) ${phone.slice(2, 7)}-${phone.slice(7)}`;
	},

	formattedPhoneNumber0800(phone: string) {
		return `${phone.slice(0, 4)} ${phone.slice(4, 7)} ${phone.slice(7)}`;
	},

	formattedNumber3Digits(number: number) {
		const numberToString = number.toString();
		return `000${numberToString}`.substring(numberToString.length);
	},

	serializeQueryParams(params: Record<string, unknown>) {
		return QueryString.stringify(params, { arrayFormat: "comma" });
	}
};

export default StringHelper;
