import { IcoArrowLeft } from "assets/icons";
import Button from "components/Button";
import { AuthHelper } from "helpers";
import { LoggedUser } from "models/auth/LoggedUser";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { Link, useLocation } from "react-router-dom";
import { ApplicationService } from "services/applicationService";
import { RootState } from "store";
import { Account, SideMenu } from "./modules";
import BackofficeNavigation from "./modules/BackofficeNavigation";

export interface CommonUserData {
	name: string;
	email: string;
}

export default function LoggedInHeader({
	userData,
	tokenInfo,
	baseUrl,
	isExternalUser
}: Readonly<{
	userData?: CommonUserData;
	tokenInfo: LoggedUser | null;
	baseUrl: string;
	isExternalUser: boolean;
}>) {
	const location = useLocation();
	const dispatch = useDispatch();
	const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
	const filters = useSelector(
		(state: RootState) => state.backofficeListFilters
	);
	const formattedFilters = new URLSearchParams(
		ApplicationService.makeBackofficeApplicationFilterParams(filters)
	).toString();

	const pathSlugs = location.pathname.split("/");
	const isBackoffice = pathSlugs.find((p) => p === "backoffice");

	return (
		<header className="border-b border-neutral-high-100 relative bg-neutral-high-pure-50 w-full h-14 sm:h-16">
			<div className="flex flex-row h-14 sm:h-16 max-sm:py-2 justify-center items-center mx-4 md:mx-6">
				<div className="flex flex-row items-center justify-start absolute left-0 ml-4 md:ml-6">
					<Link
						to={isBackoffice ? `${baseUrl}?${formattedFilters}` : baseUrl}
						className="font-semibold text-base sm:text-lg max-sm:mr-8 cursor-pointer"
					>
						{window.location.pathname === baseUrl || !isMobile ? (
							<p>CCR Faixa de Domínio</p>
						) : (
							<IcoArrowLeft />
						)}
					</Link>
				</div>
				{isBackoffice && <BackofficeNavigation />}
				<div className="h-min hover:h-[10rem] flex flex-row items-center justify-end absolute right-0 mr-4 md:mr-6">
					{tokenInfo && userData ? (
						<>
							<Account userData={userData} baseUrl={baseUrl} />
							<SideMenu
								tokenInfo={tokenInfo}
								userData={userData}
								baseUrl={baseUrl}
								isExternalUser={isExternalUser}
							/>
						</>
					) : (
						<Button
							kind="link"
							hierarchy="ghost"
							size="small"
							onClick={() => AuthHelper.logout(dispatch)}
						>
							Sair
						</Button>
					)}
				</div>
			</div>
		</header>
	);
}
