import colors from "colors";
import { IconProps } from "./types";

export default function IcoFile({
	color = colors.neutral["low-pure-500"],
	size = "24"
}: IconProps) {
	return (
		<svg
			width={size}
			height={size}
			viewBox="0 0 16 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M12.5665 12.5797L14 13.9999M8.42857 2V5.75H12M8.42857 2H2V14H8M8.42857 2L12 5.75M12 5.75V7.875M10.8247 8.94745C12.081 8.94745 13.0995 9.93711 13.0995 11.1579C13.0995 12.3787 12.081 13.3684 10.8247 13.3684C9.56829 13.3684 8.5498 12.3787 8.5498 11.1579C8.5498 9.93711 9.56829 8.94745 10.8247 8.94745Z"
				stroke={color}
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
}
