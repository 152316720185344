import colors from "colors";
import { IconProps } from "./types";

export default function IcoMessage({
	color = colors.neutral["low-pure-500"],
	size = "24"
}: IconProps) {
	return (
		<svg
			width={size}
			height={size}
			viewBox="0 0 32 32"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M20.5 11.5H26C27.1046 11.5 28 12.3954 28 13.5V21.875C28 22.9796 27.1046 23.875 26 23.875H22.6964V28L17.9821 23.875H13.5C12.3954 23.875 11.5 22.9796 11.5 21.875V18.5781M6 4H18.5C19.6046 4 20.5 4.89543 20.5 6V14.375C20.5 15.4796 19.6046 16.375 18.5 16.375H14.0179L9.30357 20.5V16.375H6C4.89543 16.375 4 15.4796 4 14.375V6C4 4.89543 4.89543 4 6 4Z"
				stroke={color}
				strokeMiterlimit="2.36797"
				strokeLinejoin="round"
			/>
		</svg>
	);
}
