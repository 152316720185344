import { IcoChat } from "assets/icons";
import { ApplicationStatusLabel, Button, ClickableCard } from "components";
import DateTimeHelper from "helpers/DateTimeHelper";
import ScreenHelper from "helpers/ScreenHelper";
import { ExternalUserStatusLabels } from "models/types";
import { ApplicationListData } from "pages/LoggedInHome/types";
import { useNavigate } from "react-router-dom";
import "../../styles.scss";

const ongoingStatuses = [
	ExternalUserStatusLabels.ANDAMENTO,
	ExternalUserStatusLabels.MENSAGEM_RECEBIDA
];

export function ApplicationCard({
	application
}: {
	application: ApplicationListData;
}) {
	const { id, status, applicationProcessCode, issuedAt } = application;
	const navigate = useNavigate();

	return (
		<ClickableCard
			className="application-card"
			onClick={() => navigate(`solicitacao/${id}`)}
		>
			<div className="flex justify-between items-center">
				<div className="flex gap-4">
					{ongoingStatuses.includes(status as ExternalUserStatusLabels) && (
						<Button
							hierarchy="ghost"
							kind="icon"
							onClick={(evt) => {
								evt.stopPropagation();
								navigate(`solicitacao/${id}/analises`);
							}}
						>
							<IcoChat size="24" />
						</Button>
					)}
					<div className="flex flex-col">
						<span className="font-body-regular-tiny">Nº do processo</span>
						<span className="font-body-semibold-small">
							{applicationProcessCode}
						</span>
					</div>
				</div>
				<ApplicationStatusLabel status={status} />
			</div>
			<div className="divider" />
			<div className="font-body-regular-extra-small">
				Data da solicitação: {DateTimeHelper.formatDate(new Date(issuedAt))}
			</div>
		</ClickableCard>
	);
}

export function ApplicationsMobileList({
	applications,
	onScroll
}: {
	applications: ApplicationListData[];
	onScroll: () => void;
}) {
	return (
		<div
			className="application-list"
			onScroll={(e) => ScreenHelper.triggerCallbackAtScrollEnd(e, onScroll)}
		>
			{applications.map((application) => (
				<ApplicationCard key={application.id} application={application} />
			))}
		</div>
	);
}
