import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import useMock from "mock/useMock";

import { baseConfig } from "config";
import AuthTokenHelper from "helpers/AuthTokenHelper";
import { logOutUserIfTokenInvalid } from "./token";

let api = axios.create({
	baseURL: baseConfig.baseURL,
	withCredentials: true,
	xsrfCookieName: "csrftoken",
	xsrfHeaderName: "X-CSRFToken"
});

api.interceptors.request.use(
	(config: any) => {
		const newConfig = {
			...config,
			headers: {
				...config.headers
			}
		};
		const token = AuthTokenHelper.getAuthToken();
		if (token) {
			newConfig.headers.Authorization = `Bearer ${token}`;
		}
		return newConfig;
	},
	(error: any) => Promise.reject(error)
);

api.interceptors.response.use(
	(response) => response,
	(error) => logOutUserIfTokenInvalid(error)
);

if (baseConfig.mockRequest) {
	api = axios.create();
	useMock(api);
}

const getWithResponse = async <T = any, D = any>(
	url: string,
	config?: AxiosRequestConfig
) => {
	const response: AxiosResponse<T, D> = await api.get(url, config);
	return response;
};

const get = async <T = any,>(url: string, config?: AxiosRequestConfig) => {
	const response: AxiosResponse<T> = await getWithResponse(url, config);
	return response.data;
};

const patch = async (url: string, data: any) => {
	const response: AxiosResponse = await api.patch(url, data);
	return response.data;
};

const put = async (url: string, data: any, config?: AxiosRequestConfig) => {
	const response: AxiosResponse = await api.put(url, data, config || {});
	return response.data;
};

const postWithResponse = async <T = any,>(
	url: string,
	data: any,
	config?: AxiosRequestConfig
) => {
	const response: AxiosResponse<T> = await api.post(url, data, config || {});
	return response;
};

const post = async <T = any,>(
	url: string,
	data: any,
	config?: AxiosRequestConfig
) => {
	const response: AxiosResponse<T> = await postWithResponse<T>(
		url,
		data,
		config || {}
	);
	return response.data;
};

const remove = async (url: string, config?: AxiosRequestConfig) => {
	const response: AxiosResponse = await api.delete(url, config || {});
	return response.data;
};

export { get, getWithResponse, patch, post, postWithResponse, put, remove };
