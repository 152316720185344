import { IconProps } from "./types";

export default function IcoLogout({
	color = "#0D0D0D",
	size = "24"
}: IconProps) {
	return (
		<svg
			width={size}
			height={size}
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M10 12H20M20 12L15.5 16.5M20 12L15.5 7.5M10 20H4V4H10"
				stroke={color}
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
}
