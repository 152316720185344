import { IcoClose } from "assets/icons";
import { AlertCard, BaseModal, Button } from "components";
import { DownloadHelper, ErrorHelper } from "helpers";
import { useMemo } from "react";
import { toast } from "react-toastify";
import { ApplicationDocumentService } from "services/applicationDocument";
import { DocumentFolderType } from "services/types";
import "./styles.scss";

export default function DocumentTemplateModal({
	isOpen,
	setIsOpen,
	requiredDocuments,
	optionalDocuments,
	sectionTitle
}: {
	isOpen: boolean;
	setIsOpen: (newValue: boolean) => void;
	requiredDocuments: DocumentFolderType[];
	optionalDocuments: DocumentFolderType[];
	sectionTitle: string;
}) {
	const applicationDocumentService = useMemo(
		ApplicationDocumentService.getInstance,
		[]
	);

	const downloadTemplate = async (blobName: string) => {
		applicationDocumentService
			.download(blobName, "templates")
			.then((response) => {
				const { downloadUrl } = response;
				DownloadHelper.performDownload(downloadUrl, blobName);
			})
			.catch((error) =>
				toast.error(ErrorHelper.getResponseErrorMessage(error))
			);
	};

	const renderDocumentList = (
		title: string,
		documents: DocumentFolderType[]
	) => (
		<>
			<div className="font-body-regular-medium pt-5">{title}</div>
			<ul className="document-template-modal mt-2">
				{documents.map((document) => (
					<li
						key={document.id}
						className="font-body-regular-small [&:not(:last-child)]:mb-2"
					>
						{`"${document.documentType.name}" `}
						{document.expectedFormatsText
							? `em ${document.expectedFormatsText} `
							: ""}
						{document.documentType.details
							? `(${document.documentType.details})`
							: ""}
						<ul>
							{document.documentTemplates.map((template) => (
								<li key={template.id}>
									<Button
										className="p-0 font-body-regular-small underline underline-offset-2 hover:bg-transparent"
										hierarchy="ghost"
										size="small"
										onClick={() => downloadTemplate(template.blobName)}
									>
										Baixar{" "}
										{template.description ? template.description : "Modelo"}
									</Button>
								</li>
							))}
						</ul>
					</li>
				))}
			</ul>
		</>
	);

	return (
		<BaseModal
			onClickOutsideModal={() => setIsOpen(false)}
			isOpen={isOpen}
			contentClassName="rounded-lg md:max-w-screen-md max-h-[85vh]"
			bodyClassName="px-0 py-6 gap-6 max-h-[85vh]"
		>
			<div className="flex justify-between items-center border-b border-b-neutral-high-100 px-8 pb-4">
				<div className="font-heading-semibold-tiny">Lista de documentos</div>
				<Button kind="icon" hierarchy="ghost" onClick={() => setIsOpen(false)}>
					<IcoClose />
				</Button>
			</div>
			<div className="flex flex-col gap-3 px-8 overflow-y-auto">
				<div className="font-body-regular-medium">{sectionTitle}</div>
				<div className="font-body-regular-small text-neutral-low-400">
					Consulte os documentos necessários que você precisa enviar para esse
					tipo de solicitação.
				</div>
				<div className="font-body-regular-small">
					Acesse o site{" "}
					<a
						href="https://rodovias.grupoccr.com.br/"
						target="_blank"
						className="underline"
						rel="noreferrer"
					>
						CCR Rodovias
					</a>
					{`, selecione sua concessionária e obtenha mais informações em "Serviços" > "Faixa de Domínio".`}
				</div>
				<AlertCard>
					<div className="font-body-regular-small">
						Certifique-se de enviar todos os documentos necessários dessa lista.
						Isso nos permitirá atender a sua solicitação com maior agilidade.
					</div>
				</AlertCard>
				{renderDocumentList("Obrigatórios:", requiredDocuments)}
				{renderDocumentList("Complementares:", optionalDocuments)}
			</div>
		</BaseModal>
	);
}
