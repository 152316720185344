import { Constants } from "appConstants";
import clsx from "clsx";
import { AnimatePresence, motion, Variants } from "framer-motion";
import { ReactNode, useMemo } from "react";

const transition = { duration: 0.2, ease: "easeInOut" };

const overlayVariants = {
	open: { opacity: 1, transition },
	closed: { opacity: 0, transition: { ...transition, delay: 0.25 } }
};

export default function AsideOverlay({
	isOpen,
	onClickOutside,
	children,
	from = "right",
	className
}: Readonly<{
	isOpen: boolean;
	onClickOutside?: () => void;
	children: ReactNode;
	from?: "left" | "right";
	className?: string;
}>): JSX.Element {
	const asideVariants = useMemo<Variants>(() => {
		return {
			open: {
				opacity: 1,
				translateX: 0,
				transition: { ...transition, delay: 0.25 }
			},
			closed: {
				opacity: 0,
				translateX:
					Math.max(window.innerWidth * 0.35, 30 * Constants.ONE_REM) *
					(from === "left" ? -1 : 1),
				transition: { ...transition }
			}
		};
	}, [from]);
	return (
		<AnimatePresence>
			{isOpen && (
				<div
					className={clsx(
						"w-screen h-screen fixed top-0 left-0 flex",
						from === "left" ? "justify-start" : "justify-end"
					)}
				>
					<motion.div
						initial="closed"
						animate="open"
						exit="closed"
						variants={overlayVariants}
						className="overlay fixed w-full h-full absolute top-0 left-0 bg-neutral-low-pure-500 bg-opacity-50"
						onClick={onClickOutside}
					/>
					<motion.aside
						initial="closed"
						animate="open"
						exit="closed"
						className={clsx(
							"flex flex-col w-full sm:w-[max(35%,30rem)] h-full absolute bg-neutral-high-pure-50",
							className
						)}
						variants={asideVariants}
					>
						{children}
					</motion.aside>
				</div>
			)}
		</AnimatePresence>
	);
}
