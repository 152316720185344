import { IcoPlus } from "assets/icons";
import colors from "colors";
import { Loading, NewApplicationButton } from "components";
import { ErrorHelper } from "helpers";
import { MOBILE_MINIMUM_WIDTH, useIsMobile } from "hooks/useIsMobile";
import { LoggedUser } from "models/auth/LoggedUser";
import { FeatureFlag } from "models/auth/types";
import { useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import { ApplicationService } from "services/applicationService";
import { ApplicationsMobileList } from "./modules/ApplicationsMobileList";
import ApplicationsTable from "./modules/ApplicationsTable";
import { EmptyState } from "./modules/EmptyState";
import "./styles.scss";
import { ApplicationListData } from "./types";

const INITIAL_PAGE = 1;

export default function LoggedInHome({
	userTokenInfo
}: {
	userTokenInfo: LoggedUser | null;
}) {
	const isMobile = useIsMobile(MOBILE_MINIMUM_WIDTH.SMALL);
	const applicationService = ApplicationService.getInstance();
	const [applications, setApplications] = useState<ApplicationListData[]>([]);
	const [loading, setLoading] = useState(true);
	const [scrolling, setScrolling] = useState(false);
	const [totalPages, setTotalPages] = useState(0);
	const [filters, setFilters] = useState({
		size: 10,
		page: INITIAL_PAGE
	});
	const createApplicationFeatureEnabled = useMemo(
		() => !!userTokenInfo?.hasFeatureEnabled(FeatureFlag.CREATE_APPLICATION),
		[userTokenInfo]
	);
	const hasApplications = useMemo(
		() => applications.length !== 0,
		[applications]
	);

	const getApplications = () => {
		setLoading(true);
		applicationService
			.myApplications({ ...filters })
			.then((response) => {
				const newApplications = isMobile
					? applications.concat(response.content)
					: response.content;
				setApplications(newApplications);
				setTotalPages(response.totalPages);
			})
			.catch((error) => toast.error(ErrorHelper.getResponseErrorMessage(error)))
			.finally(() => {
				setLoading(false);
				setScrolling(false);
			});
	};

	useEffect(() => {
		getApplications();
	}, [filters]);

	const onPageChange = (selectedPage: number, pageSize: number) => {
		const newFilters = {
			size: pageSize,
			page: selectedPage
		};
		setFilters(newFilters);
	};

	const loadMoreApplicationsOnScroll = () => {
		if (filters.page >= totalPages) return;
		setScrolling(true);
		onPageChange(filters.page + 1, filters.size);
	};

	const showEmptyState = useMemo(
		() =>
			(!hasApplications && filters.page === INITIAL_PAGE) ||
			!createApplicationFeatureEnabled,
		[hasApplications, filters.page, createApplicationFeatureEnabled]
	);

	return (
		<div className="h-full flex flex-col">
			<div className="h-10 px-4 md:px-6 py-6 flex-1 flex flex-col">
				<div className="mb-8 flex items-center justify-between">
					<h1 className="text-xl font-semibold">Minhas intervenções</h1>
					{!isMobile && hasApplications && createApplicationFeatureEnabled && (
						<NewApplicationButton
							leading={
								<IcoPlus color={colors.neutral["high-pure-50"]} size="16" />
							}
							label="Nova solicitação"
						/>
					)}
				</div>
				{loading && !scrolling ? (
					<div className="flex flex-1 items-center">
						<Loading size={50} />
					</div>
				) : showEmptyState ? (
					<EmptyState
						allowCreateApplication={createApplicationFeatureEnabled}
					/>
				) : isMobile ? (
					<ApplicationsMobileList
						applications={applications}
						onScroll={loadMoreApplicationsOnScroll}
					/>
				) : (
					<ApplicationsTable
						applications={applications}
						currentPage={filters.page}
						pageSize={filters.size}
						totalPages={totalPages}
						onPageChange={onPageChange}
					/>
				)}
			</div>
		</div>
	);
}
