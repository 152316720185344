import { ObjectWithAnyValues } from "utils/types";
import { Application } from "./Application";
import { BaseModel } from "./BaseModel";
import { ApplicationEventData, ApplicationEventType } from "./types";

export class ApplicationEvent extends BaseModel<ApplicationEventData> {
	id: string;
	application: Application;
	type: ApplicationEventType;
	description: string;
	title: string;
	isPublic: boolean;
	analysis: ObjectWithAnyValues; // TODO: Create ApplicationAnalysis model
	createdByGroup: string;
	createdAt: string;
	createdBy: string;

	constructor(data: ApplicationEventData) {
		super(data);
		this.id = data.id;
		this.application = Application.getValueOrNew(data.application);
		this.type = data.type;
		this.description = data.description;
		this.title = data.title;
		this.isPublic = data.isPublic;
		this.analysis = data.analysis;
		this.createdByGroup = data.createdByGroup;
		this.createdAt = data.createdAt;
		this.createdBy = data.createdBy;
	}
}
