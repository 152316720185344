import { IcoArrowLeft } from "assets/icons";
import Button from "components/Button";
import { useEffect, useMemo, useState } from "react";
import { StepperProps } from "./types";

export default function Stepper({
	children,
	title,
	currentStep,
	handlePrev,
	handleNext,
	nextEnabled = true,
	stepsConfig
}: Readonly<StepperProps>) {
	const currentStepDisplay = stepsConfig[currentStep];
	const { hideStepsIndicator, nextStepLabel } = currentStepDisplay;

	const totalSteps = useMemo(
		() =>
			Object.values(stepsConfig).filter((item) => !item.hideStepsIndicator)
				.length,
		[stepsConfig]
	);

	const currentStepCount = useMemo(
		() =>
			Object.values(stepsConfig)
				.slice(0, currentStep)
				.filter((item) => !item.hideStepsIndicator).length,
		[stepsConfig, currentStep]
	);

	const [containerWidth, setContainerWidth] = useState<string>("999px");
	useEffect(() => {
		setContainerWidth("9999px");

		const content = document?.getElementById("contents")?.firstElementChild;
		if (content) {
			let contentWidth = window
				.getComputedStyle(content)
				.getPropertyValue("max-width");
			contentWidth = contentWidth === "none" ? "9999px" : contentWidth;
			setContainerWidth(contentWidth);
		}
	}, [currentStep]);

	return (
		<div
			className="h-full md:h-[calc(100%-2rem)] bg-neutral-high-pure-50 md:m-4 rounded-lg md:max-w-[calc(100vw-3rem)] self-center transition-all duration-200 max-md:w-screen"
			style={{ width: containerWidth }}
		>
			<div className="flex flex-col justify-between h-full">
				<div className="flex justify-between items-center p-8 border-b-2 border-neutral-high-100 max-md:hidden">
					<div className="flex gap-2 items-center">
						<button
							aria-label="Voltar"
							onClick={handlePrev}
							className="cursor-pointer h-fit"
						>
							<IcoArrowLeft />
						</button>
						<p className="font-semibold">{title}</p>
					</div>
					<div
						className={`flex items-center  ${
							hideStepsIndicator ? "hidden" : ""
						}`}
					>
						<div className="flex gap-1 mr-4">
							{children.map(
								(_, idx) =>
									!stepsConfig[idx].hideStepsIndicator && (
										<div
											key={`step-${idx}`}
											className={`w-8 h-1 rounded-full ${
												currentStep === idx
													? "bg-neutral-low-pure-500"
													: currentStep > idx
													? "bg-feedback-positive-pure-500"
													: "bg-neutral-high-200"
											}`}
										/>
									)
							)}
						</div>
						<div className="w-28 text-end">
							Passo {currentStepCount + 1} de {totalSteps}
						</div>
					</div>
				</div>
				{children.map((stepContent, idx) => {
					return (
						idx === currentStep && (
							<div
								className="h-[calc(100%-11rem)] animate-fadein"
								id="contents"
								key={`step-${idx}`}
							>
								{stepContent}
							</div>
						)
					);
				})}
				<div className="h-24 flex md:flex-row flex-col-reverse md:justify-end items-center gap-4 p-6 md:py-4 md:border-t-2 md:border-neutral-high-100">
					<Button
						hierarchy="secondary"
						onClick={handlePrev}
						className="px-8 max-md:w-full"
					>
						Voltar
					</Button>
					<Button
						onClick={handleNext}
						className="px-8 max-md:w-full"
						disabled={!nextEnabled}
					>
						{nextStepLabel ?? "Continuar"}
					</Button>
				</div>
			</div>
		</div>
	);
}
