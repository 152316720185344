import { Constants } from "appConstants";
import { IcoChevronDown } from "assets/icons";
import clsx from "clsx";
import { Button, Checkbox, Loading } from "components";
import { ExpandableLine, FolderDisplayItem } from "components/FolderDisplay";
import { DateTimeHelper, DownloadHelper, ErrorHelper } from "helpers";
import { OptionLike } from "helpers/OptionHelper";
import { useCallback, useMemo, useState } from "react";
import Select, { DropdownIndicatorProps, components } from "react-select";
import { toast } from "react-toastify";
import { Tooltip } from "react-tooltip";
import { ApplicationDocumentService } from "services/applicationDocument";
import { ApplicationService } from "services/applicationService";
import {
	ApplicationDocumentFolder,
	ApplicationFormDocument
} from "services/types";
import "./styles.scss";

// eslint-disable-next-line react/function-component-definition
export const ChevronDropdownIndicator: React.FC<DropdownIndicatorProps> = (
	props
) => {
	return (
		<components.DropdownIndicator {...props}>
			<IcoChevronDown color="#1d1d1d" />
		</components.DropdownIndicator>
	);
};

function FolderItem({
	activeFolderVolume,
	selectedFolders,
	handleSelectFolders,
	diferentVolumesSelected,
	folder,
	applicationDocumentService,
	currentVersion,
	applicationId,
	replaceDocumentFolderInList,
	expanded,
	defaultOpen,
	toggleExpanded,
	volumeId
}: {
	activeFolderVolume: string | null;
	selectedFolders: {
		[key: string]: boolean;
	};
	handleSelectFolders: (
		isChecked: boolean,
		folder: ApplicationDocumentFolder,
		volumeId: string
	) => void;
	diferentVolumesSelected: () => void;
	folder: ApplicationDocumentFolder;
	applicationDocumentService: ApplicationDocumentService;
	currentVersion: number;
	applicationId: string;
	replaceDocumentFolderInList: (
		interventionDocumentFolderTypeId: string,
		folder: ApplicationDocumentFolder
	) => void;
	expanded?: boolean;
	defaultOpen?: string[];
	toggleExpanded?: (expanded: boolean) => void;
	volumeId?: string;
}) {
	const [versionSelected, setVersionSelected] = useState<OptionLike>();
	const [loading, setLoading] = useState(false);
	const canSelectVersion = useMemo(() => currentVersion > 1, [currentVersion]);
	const applicationService = useMemo(ApplicationService.getInstance, []);
	const folderVersionOptions = useMemo(
		() =>
			Array.from({ length: currentVersion }, (_, i) => ({
				value: (i + 1).toString(),
				label: i + 1
			})),
		[folder.version]
	);
	const defaultVersionOption = useMemo(
		() =>
			folderVersionOptions.find(
				(option) => option.value === folder.version.toString()
			),
		[folderVersionOptions]
	);

	const getFolderVersion = async (versionOption: OptionLike) => {
		setLoading(true);
		try {
			const newFolder =
				await applicationService.getApplicationDocumentFolderByVersion(
					applicationId,
					folder.interventionDocumentFolderType.id,
					versionOption.value
				);
			replaceDocumentFolderInList(
				folder.interventionDocumentFolderType.id,
				newFolder
			);
		} catch (error) {
			toast.error(ErrorHelper.getResponseErrorMessage(error));
		} finally {
			setLoading(false);
		}
	};

	const batchDownload = useCallback(
		async (documentList: ApplicationFormDocument[]) => {
			const blobNames = documentList.map((doc) => doc.blobName);
			const names = documentList.map((doc) => doc.name);
			if (applicationDocumentService) {
				const { downloadUrls } = await applicationDocumentService.batchDownload(
					blobNames
				);
				downloadUrls.forEach((url, i) => {
					DownloadHelper.performDownload(url, names[i]);
				});
			}
		},
		[applicationDocumentService]
	);

	const volumeFolderNumber = `volume-${folder.interventionDocumentFolderType.rootFolder}`;

	const disableCheckbox =
		activeFolderVolume !== null && activeFolderVolume !== volumeFolderNumber;

	const folderColumns = useMemo(
		() => [
			{
				name: (
					<div
						className="flex flex-column gap-2 items-center pr-2"
						onClick={() => disableCheckbox && diferentVolumesSelected()}
					>
						<Checkbox
							checked={
								selectedFolders[
									folder.interventionDocumentFolderType.documentType.id
								] || false
							}
							disabled={disableCheckbox}
							name={folder.interventionDocumentFolderType.documentType.name}
							label={folder.interventionDocumentFolderType.documentType.name}
							id={folder.interventionDocumentFolderType.documentType.id}
							value={folder.interventionDocumentFolderType.documentType.name}
							onChange={(e: { target: { checked: boolean } }) =>
								handleSelectFolders(
									e.target.checked,
									folder,
									volumeId as string
								)
							}
						/>
					</div>
				),
				size: 6
			},
			{
				name: (
					<div className="flex items-center h-full">
						{loading ? (
							<div className="w-20">
								<Loading />
							</div>
						) : (
							DateTimeHelper.formatDate(
								new Date(folder.createdAt),
								Constants.date.LOCALE_DATE_FORMAT
							)
						)}
					</div>
				),
				size: 2
			},
			{
				name: !canSelectVersion ? (
					folder.version
				) : (
					<div className="select-folder-version">
						<Tooltip anchorSelect={`.select-folder-version-${folder.id}`}>
							Selecionar versão
						</Tooltip>
						<Select
							className={`select-folder-version-${folder.id}`}
							classNamePrefix="select"
							name="version"
							defaultValue={defaultVersionOption}
							isDisabled={false}
							isClearable={false}
							value={versionSelected}
							isSearchable={false}
							options={folderVersionOptions}
							onChange={(e) => {
								if (e) setVersionSelected(e as OptionLike);
								getFolderVersion(e as OptionLike);
							}}
							components={{
								DropdownIndicator: ChevronDropdownIndicator,
								IndicatorSeparator: () => null
							}}
							placeholder=""
						/>
					</div>
				),
				size: 2
			},
			{
				name: (
					<Button
						kind="link"
						hierarchy="ghost"
						size="small"
						className="w-full justify-start overflow-hidden text-ellipsis pl-0"
						childrenWrapperClassName="w-full justify-start overflow-hidden text-ellipsis"
						onClick={() => batchDownload(folder.documents)}
					>
						Download
					</Button>
				),
				size: 2
			}
		],
		[folder, loading, selectedFolders]
	);

	return (
		<FolderDisplayItem
			key={folder.id}
			applicationDocumentService={applicationDocumentService}
			columns={folderColumns}
			folder={folder}
			expandOnlyOnIconClick
			loading={loading}
			expanded={expanded}
			defaultOpen={defaultOpen}
			toggleExpanded={toggleExpanded}
		/>
	);
}

export function FolderList({
	activeFolderVolume,
	selectedFolders,
	handleSelectFolders,
	diferentVolumesSelected,
	id,
	folders,
	applicationDocumentService,
	foldersCurrentVersions,
	applicationId,
	replaceDocumentFolderInList
}: Readonly<{
	activeFolderVolume: string | null;
	selectedFolders: { [key: string]: boolean };
	handleSelectFolders: (
		isChecked: boolean,
		folder: ApplicationDocumentFolder,
		volumeId: string
	) => void;
	diferentVolumesSelected: () => void;
	id: string;
	folders: ApplicationDocumentFolder[];
	applicationDocumentService: ApplicationDocumentService;
	foldersCurrentVersions: { [key: string]: number };
	applicationId: string;
	replaceDocumentFolderInList: (
		interventionDocumentFolderTypeId: string,
		folder: ApplicationDocumentFolder
	) => void;
}>): JSX.Element {
	const hasFolders = folders.length > 0;
	const [values, setValues] = useState<string[]>([]);
	const getItemId = (folder: ApplicationDocumentFolder) =>
		`folder-${folder.interventionDocumentFolderType.id}`;
	const allFoldersIds = useMemo(
		() => folders.map((folder) => getItemId(folder)),
		[folders]
	);
	const isAllExpanded = useMemo(
		() => values.length === allFoldersIds.length,
		[values, folders]
	);
	const expandOrFoldAll = () => {
		setValues(isAllExpanded ? [] : allFoldersIds);
	};
	const expandItem = (itemId: string) => {
		setValues([...values, itemId]);
	};
	const foldItem = (itemId: string) => {
		setValues(values.filter((value) => value !== itemId));
	};
	const toggleItem = (expanded: boolean, itemId: string) => {
		if (expanded) expandItem(itemId);
		else foldItem(itemId);
	};

	return (
		<div className={clsx("backoffice-folders-list")}>
			{hasFolders && (
				<>
					<ExpandableLine
						accordionTriggerClassName="font-bold hover:bg-neutral-high-pure-50"
						accordionItemClassName={clsx(`folder-list-header-${id} border-b-2`)}
						columns={[
							{ name: "Nome da pasta", size: 6 },
							{ name: "Data de envio", size: 2 },
							{ name: "Versão", size: 2 },
							{ name: "Ação", size: 2 }
						]}
						onExpand={expandOrFoldAll}
						expanded={isAllExpanded}
					/>
					{folders.map((folder) => (
						<FolderItem
							activeFolderVolume={activeFolderVolume}
							selectedFolders={selectedFolders}
							handleSelectFolders={handleSelectFolders}
							diferentVolumesSelected={diferentVolumesSelected}
							key={folder.id}
							folder={folder}
							currentVersion={
								foldersCurrentVersions[folder.interventionDocumentFolderType.id]
							}
							applicationDocumentService={applicationDocumentService}
							applicationId={applicationId}
							replaceDocumentFolderInList={replaceDocumentFolderInList}
							expanded={values.includes(getItemId(folder))}
							defaultOpen={values}
							toggleExpanded={(expanded: boolean) =>
								toggleItem(expanded, getItemId(folder))
							}
							volumeId={id}
						/>
					))}
				</>
			)}
			{!hasFolders && (
				<div className="flex flex-col items-start justify-end">
					Nenhuma pasta deste volume anexada.
				</div>
			)}
		</div>
	);
}
