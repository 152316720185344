import { OptionLike } from "helpers/OptionHelper";
import { BaseModel } from "./BaseModel";
import { InterventionTypeCategory } from "./InterventionTypeCategory";
import { InterventionTypeData } from "./types";

export class InterventionType extends BaseModel<InterventionTypeData> {
	id: string;
	name: string;
	description: string;
	detailsUrl: string;
	jurisdictionId: string;
	categories?: InterventionTypeCategory[];

	constructor(data: InterventionTypeData) {
		super(data);
		this.id = data.id;
		this.name = data.name;
		this.description = data.description;
		this.detailsUrl = data.detailsUrl;
		this.jurisdictionId = data.jurisdictionId;
		this.categories = data.categories?.map(
			(category) => new InterventionTypeCategory(category)
		);
	}

	toOption = (): OptionLike => {
		return { ...this, label: this.name, value: this.id };
	};
}
