import { useEffect, useRef } from "react";

type genericfunction = () => void;

export default function useInterval(
	callbackFn: genericfunction,
	delay: number
): void {
	const intervalRef = useRef<ReturnType<typeof setInterval> | null>(null);
	const callbackRef = useRef<genericfunction>(callbackFn);
	callbackRef.current = callbackFn;

	useEffect(() => {
		if (intervalRef.current) {
			clearInterval(intervalRef.current);
		}

		function tick() {
			callbackRef.current?.();
		}

		const intervalId = setInterval(tick, delay);
		intervalRef.current = intervalId;
		return () => clearInterval(intervalId);
	}, [delay]);
}
