import { IcoArrowLeft } from "assets/icons";
import Button from "components/Button";
import { useNavigate } from "react-router-dom";

export default function BackButton({
	prevRoute
}: Readonly<{ prevRoute?: string }>) {
	const navigate = useNavigate();
	return (
		<Button
			kind="icon"
			type="button"
			hierarchy="ghost"
			className="bg-transparent hover:bg-neutral-high-200 hidden md:flex"
			onClick={() => {
				if (prevRoute) {
					navigate(prevRoute);
				} else {
					window.history.back();
				}
			}}
		>
			<IcoArrowLeft size="24" />
		</Button>
	);
}
