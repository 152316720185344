const DownloadHelper = {
	performDownload(downloadUrl: string, fileName: string) {
		const link = document.createElement("a");
		link.href = downloadUrl;
		link.target = "_blank";
		link.setAttribute("download", fileName);
		link.click();
	}
};

export default DownloadHelper;
