import { AxiosProgressEvent } from "axios";
import { get, post } from "./axios";

interface DocumentUploadResponse {
	filename: string;
}

export class StorageService {
	private static baseURL = "blobs";
	private static endpoints = {
		upload: "/documents/upload",
		download: "/documents/download"
	};
	static async uploadFile(
		fileObject: File,
		onUploadProgress?: (evt: AxiosProgressEvent) => void
	) {
		return post<DocumentUploadResponse>(
			`${StorageService.baseURL}${StorageService.endpoints.upload}`,
			{
				file: fileObject
			},
			{
				onUploadProgress,
				headers: {
					"Content-Type": "multipart/form-data"
				}
			}
		);
	}

	static async retrieveFile(fileBlobName: string) {
		const fileUrl = `${StorageService.baseURL}${this.endpoints.download}`;
		return get(fileUrl, {
			params: { fileName: fileBlobName }
		});
	}
}
