import { IcoFolder, IcoLogout, IcoMenu, IcoPlus } from "assets/icons";
import { Logo } from "assets/images";
import clsx from "clsx";
import { Button, ExistingDraftModal } from "components";
import { CommonUserData } from "components/LoggedInHeader";
import { AnimatePresence, motion } from "framer-motion";
import { AuthHelper } from "helpers";
import { useCurrentDraft } from "hooks/useCurrentDraft";
import { LoggedUser } from "models/auth/LoggedUser";
import { FeatureFlag } from "models/auth/types";
import { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

function SideMenuButton({
	children,
	className,
	...rest
}: {
	children: JSX.Element | JSX.Element[];
	className?: string;
	[x: string]: any;
}) {
	return (
		<Button
			hierarchy="ghost"
			size="small"
			className={clsx(
				"bg-transparent w-full justify-start overflow-hidden",
				className
			)}
			{...rest}
		>
			{children}
		</Button>
	);
}

export default function SideMenu({
	tokenInfo,
	userData,
	baseUrl,
	isExternalUser
}: Readonly<{
	tokenInfo: LoggedUser;
	userData?: CommonUserData;
	baseUrl: string;
	isExternalUser: boolean;
}>) {
	const [isOpen, setIsOpen] = useState<boolean>(false);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const {
		isOpen: isDraftModalOpen,
		onClose: onCloseDraftModal,
		checkExistingDraft,
		goToNewApplication,
		deleteDraft
	} = useCurrentDraft();

	const handleLogout = useCallback(() => {
		setIsOpen(false);
		AuthHelper.logout(dispatch);
		navigate(baseUrl);
	}, [dispatch, navigate, baseUrl]);

	const onNewApplicationClick = useCallback(() => {
		checkExistingDraft().then(() => setIsOpen(false));
	}, [checkExistingDraft]);

	return (
		<div className="sm:hidden">
			<div onClick={() => setIsOpen(true)}>
				<IcoMenu />
			</div>
			<AnimatePresence>
				{isOpen && (
					<motion.div
						className="bg-neutral-low-pure-500 fixed left-0 top-0 bottom-0 right-0"
						onClick={() => setIsOpen(false)}
						initial={{ opacity: 0 }}
						animate={{ opacity: 0.4, zIndex: 1 }}
						transition={{ duration: 0.4 }}
						exit={{ opacity: 0 }}
					/>
				)}
			</AnimatePresence>
			<AnimatePresence>
				{isOpen && (
					<motion.div
						className="w-fit max-w-[85vw] bg-neutral-high-pure-50 w-4/5 fixed left-0 top-0 bottom-0 right-0 z-10"
						initial={{ translateX: "-100%" }}
						animate={{ translateX: 0 }}
						transition={{ ease: "easeOut", duration: 0.2 }}
						exit={{ translateX: "-100%" }}
					>
						<div className="flex flex-col h-full">
							<Link to="/">
								<img className="py-6 px-4" src={Logo} alt="CCR" />
							</Link>
							<div className="grow pt-4 border-2 border-x-0 border-neutral-high-100">
								{isExternalUser &&
									tokenInfo.hasFeatureEnabled(
										FeatureFlag.CREATE_APPLICATION
									) && (
										<SideMenuButton onClick={onNewApplicationClick}>
											<IcoPlus />
											<p>Nova solicitação</p>
										</SideMenuButton>
									)}
								<SideMenuButton
									onClick={() => {
										setIsOpen(false);
										navigate(baseUrl);
									}}
								>
									<IcoFolder />
									<p>Minhas intervenções</p>
								</SideMenuButton>
								<SideMenuButton onClick={handleLogout}>
									<IcoLogout />
									<p>Sair</p>
								</SideMenuButton>
							</div>
							<div className="w-full flex py-8 px-4 gap-2 items-center">
								<div className="border-2 h-8 w-8 shrink-0 rounded-full flex items-center justify-center">
									<div className="text-center leading-4 ">
										{userData?.name[0]}
									</div>
								</div>
								<div className="w-full overflow-hidden flex flex-col gap-1 mr-4">
									<div className="text-xs leading-3 font-semibold">
										{userData?.name}
									</div>
									<div className="text-[0.625rem] leading-[0.625rem] truncate">
										{userData?.email}
									</div>
								</div>
							</div>
						</div>
					</motion.div>
				)}
			</AnimatePresence>
			<ExistingDraftModal
				isOpen={isDraftModalOpen}
				onClose={onCloseDraftModal}
				onContinueToNewApplication={goToNewApplication}
				onDeleteDraft={deleteDraft}
			/>
		</div>
	);
}
