import { Constants } from "appConstants";
import { ApplicationEventType } from "models/types";
import {
	AnalysisStatusLabel,
	RedirectApplicationStatusLabel
} from "services/types";

export interface ApplicationNewRecordOption {
	label: string;
	value: string;
	status?: { label: string; value: string }[];
}

const ApplicationEventRespondToApplicantStatus = [
	{
		label: AnalysisStatusLabel.APROVADO,
		value: ApplicationEventType.APROVADO
	},
	{
		label: AnalysisStatusLabel.APROVADO_RESSALVAS,
		value: ApplicationEventType.APROVADO_RESSALVAS
	},
	{
		label: AnalysisStatusLabel.REPROVADO,
		value: ApplicationEventType.REPROVADO
	}
];

const ApplicationEventDirectToGroupStatus = [
	{
		label: RedirectApplicationStatusLabel.APTO_ANALISE,
		value: ApplicationEventType.APTO_ANALISE
	},
	{
		label: RedirectApplicationStatusLabel.DEVOLVIDO,
		value: ApplicationEventType.DEVOLVIDO
	},
	...ApplicationEventRespondToApplicantStatus
];

const ApplicationEventDirectToFocalStatus = [
	{
		label: RedirectApplicationStatusLabel.APTO_ANALISE,
		value: ApplicationEventType.RETORNA_PARA_FOCAL_APTO_ANALISE
	},
	{
		label: RedirectApplicationStatusLabel.DEVOLVIDO,
		value: ApplicationEventType.RETORNA_PARA_FOCAL_DEVOLVIDO
	},
	{
		label: RedirectApplicationStatusLabel.APROVADO,
		value: ApplicationEventType.RETORNA_PARA_FOCAL_APROVADO
	},
	{
		label: RedirectApplicationStatusLabel.APROVADO_RESSALVAS,
		value: ApplicationEventType.RETORNA_PARA_FOCAL_APROVADO_RESSALVAS
	},
	{
		label: RedirectApplicationStatusLabel.REPROVADO,
		value: ApplicationEventType.RETORNA_PARA_FOCAL_REPROVADO
	}
];

const RecordAddComment = {
	label: "Adicionar comentário",
	value: ApplicationEventType.ADICIONAR_COMENTARIO
};

const RecordRemoveResponsibility = {
	label: "Devolver para a fila",
	value: ApplicationEventType.REMOVER_RESPONSABILIDADE
};

export enum ApplicationEventOnlyFormType {
	// Values only used in the form, not in the API/State Machine
	DIRECIONAR_CENTRAL = "DIRECIONAR_CENTRAL",
	DIRECIONAR_ENGENHARIA = "DIRECIONAR_ENGENHARIA",
	DIRECIONAR_FOCAL = "DIRECIONAR_FOCAL"
}

const ApplicationNewRecordOptions: {
	[key in Constants.UserRoleGroups]: ApplicationNewRecordOption[];
} = {
	[Constants.UserRoleGroups.FOCAL]: [
		RecordAddComment,
		{
			label: "Direcionar para Central",
			value: ApplicationEventOnlyFormType.DIRECIONAR_CENTRAL,
			status: ApplicationEventDirectToGroupStatus
		},
		RecordRemoveResponsibility,
		{
			label: "Responder interessado",
			value: ApplicationEventType.RESPOSTA_PARA_SOLICITANTE,
			status: ApplicationEventRespondToApplicantStatus
		}
	],
	[Constants.UserRoleGroups.CENTRAL]: [
		RecordAddComment,
		{
			label: "Direcionar para Engenharia",
			value: ApplicationEventOnlyFormType.DIRECIONAR_ENGENHARIA,
			status: ApplicationEventDirectToGroupStatus
		},
		{
			label: "Direcionar para Focal",
			value: ApplicationEventOnlyFormType.DIRECIONAR_FOCAL,
			status: ApplicationEventDirectToFocalStatus
		},
		RecordRemoveResponsibility
	],
	[Constants.UserRoleGroups.ENGENHARIA]: [
		RecordAddComment,
		{
			label: "Direcionar para Central",
			value: ApplicationEventOnlyFormType.DIRECIONAR_CENTRAL,
			status: ApplicationEventDirectToGroupStatus
		},
		RecordRemoveResponsibility
	],
	[Constants.UserRoleGroups.SOLICITANTE]: []
};

export default ApplicationNewRecordOptions;
