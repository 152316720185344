import { ColumnDef, SortingState } from "@tanstack/react-table";
import clsx from "clsx";
import { TailwindColSpan } from "styles/helpers";

export type TableColumnDef<D, V> = ColumnDef<D, V> & {
	size: number;
	meta?: { cellClass?: string; headerClass?: string };
};

const TableHelper = {
	addClassNames: <D, V>(
		columnDefinitions: TableColumnDef<D, V>[],
		cellClassName: string,
		headerClassName: string
	): TableColumnDef<D, V>[] =>
		columnDefinitions.map((columnDef) => ({
			...columnDef,
			meta: {
				cellClass: clsx(
					cellClassName,
					columnDef.meta?.cellClass,
					TailwindColSpan[columnDef.size]
				),
				headerClass: clsx(
					headerClassName,
					columnDef.meta?.headerClass,
					TailwindColSpan[columnDef.size]
				)
			}
		})),
	sortingStateToParam(sorting: SortingState, defaultSort = "") {
		return sorting && sorting.length > 0
			? {
					sort: `${sorting[0].id}${sorting[0].desc ? ",desc" : ""}`
			  }
			: { sort: defaultSort };
	}
};

export default TableHelper;
