import { OptionLike } from "helpers/OptionHelper";
import { BaseModel } from "./BaseModel";
import { InterventionTypeCategoryData } from "./types";

export class InterventionTypeCategory extends BaseModel<InterventionTypeCategoryData> {
	id: string;
	name: string;

	constructor(data: InterventionTypeCategoryData) {
		super(data);
		this.id = data.id;
		this.name = data.name;
	}

	toOption = (): OptionLike => {
		return { ...this, label: this.name, value: this.id };
	};
}
