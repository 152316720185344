import { Constants } from "appConstants";
import { AxiosError } from "axios";
import { FileExtensionData } from "services/types";

const ErrorHelper = {
	getObjMessageAsArray(obj: any) {
		if (typeof obj === "string") {
			return [obj];
		}
		return Object.keys(obj).map((key) => {
			let message = obj[key];
			if (typeof message === "object") {
				message =
					message instanceof Array
						? message
								.map((msgItem) => this.getObjMessageAsArray(msgItem))
								.join(", ")
						: ErrorHelper.getObjMessageAsArray(message);
			}
			return `${key}: ${message}`;
		});
	},

	getResponseErrorMessage(error: any) {
		const { response } = error;
		if (response?.status === 0) {
			return "Ocorreu um erro de conexão. Tente novamente.";
		}
		if (response?.status === 502) {
			return "Falha na conexão, tente novamente em alguns instantes";
		}
		if (response?.data) {
			if (typeof response.data === "string" && response.data !== "None") {
				return response.data;
			}

			if (typeof response.data === "object") {
				if (typeof response.data.message === "string") {
					return response.data.message;
				}

				const dataToParse = response.data?.detail || response.data;
				const parsed =
					dataToParse instanceof Array
						? dataToParse
						: ErrorHelper.getObjMessageAsArray(dataToParse);
				return parsed.join("\n").replace("non_field_errors: ", "");
			}
		}
		return "OPS! Alguma coisa deu errado. Tente novamente.";
	},

	getFileUploadErrorMessage(error: AxiosError): string {
		const { response } = error;
		if (!response) return "Erro ao anexar arquivo";

		const responseData =
			typeof response.data === "object"
				? (response.data as Record<string, any>)
				: null;

		if (response.status === 400) {
			return responseData?.errors.file[0].message;
		}

		return "Erro ao anexar arquivo";
	},

	getFileUploadErrorTooltipText(fileExtensions: FileExtensionData[]): string[] {
		const messages: string[] = [];
		messages.push(
			`Verifique sua conexão com a internet, o tamanho do arquivo (máximo de ${
				Constants.MAX_DOCUMENT_SIZE / Constants.ONE_MB
			}MB) e o formato do arquivo inserido.`
		);
		const extensionTypes = fileExtensions.map((fileExt) => fileExt.extension);
		if (extensionTypes.length) {
			messages.push(
				`São aceitos os formatos: ${extensionTypes
					.slice(0, extensionTypes.length - 1)
					.map((s) => s.toUpperCase())
					.join(", ")} e ${extensionTypes[
					extensionTypes.length - 1
				].toUpperCase()}`
			);
		}
		return messages;
	}
};

export default ErrorHelper;
