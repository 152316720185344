import { IcoArrowDown } from "assets/icons";
import clsx from "clsx";
import Button from "components/Button";
import DocumentChip from "components/DocumentChip";
import { DownloadHelper } from "helpers";
import { useCallback, useState } from "react";
import { ApplicationDocumentService } from "services/applicationDocument";
import { AnalysisEventDocument } from "services/types";
import { TailwindGridCols } from "styles/helpers";

export default function EventDocumentList({
	eventDocuments,
	allowExpansion = false
}: Readonly<{
	eventDocuments: AnalysisEventDocument[];
	allowExpansion?: boolean;
}>): JSX.Element {
	const applicationDocumentService = ApplicationDocumentService.getInstance();
	const [expanded, setExpanded] = useState<boolean>(false);
	const [head, tail] = [eventDocuments.slice(0, 4), eventDocuments.slice(4)];
	const hasEnoughDocumentsToExpand = eventDocuments.length > 4;
	const expansible = hasEnoughDocumentsToExpand && allowExpansion;
	const downloadDocument = useCallback((blobName: string) => {
		applicationDocumentService.download(blobName).then((response) => {
			const { downloadUrl } = response;
			if (downloadUrl) DownloadHelper.performDownload(downloadUrl, blobName);
		});
	}, []);
	return (
		<div className="flex flex-col w-full gap-3">
			<div
				className={clsx(
					"grid gap-3 overflow-display md:overflow-auto scrollbar-width--thin",
					TailwindGridCols[2]
				)}
			>
				{head.map((eventDocument) => (
					<DocumentChip
						key={eventDocument.id}
						eventDocument={eventDocument}
						downloadDocument={downloadDocument}
					/>
				))}
				{(expanded || !expansible) &&
					tail.map((eventDocument) => (
						<DocumentChip
							key={eventDocument.id}
							eventDocument={eventDocument}
							downloadDocument={downloadDocument}
						/>
					))}
			</div>
			{expansible && (
				<div className="flex items-center w-full">
					<Button
						className="md:w-fit w-full"
						hierarchy="ghost"
						onClick={() => setExpanded(!expanded)}
					>
						<div
							className={clsx("transition ease-in-out", {
								"rotate-[180deg]": expanded
							})}
						>
							<IcoArrowDown size="20" />
						</div>
						<span> Mostrar {expanded ? "menos" : "mais"}</span>
					</Button>
				</div>
			)}
		</div>
	);
}
