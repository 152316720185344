import clsx from "clsx";
import { InputHTMLAttributes } from "react";

import "./styles.scss";

type BaseInputHTMLProps = Omit<
	InputHTMLAttributes<HTMLTextAreaElement>,
	"value"
>;

type InputProps = BaseInputHTMLProps & {
	label: string;
	error?: string | boolean;
	value: string | undefined | null;
	textError?: string;
	maxLength: number;
};

function TextArea({
	label,
	placeholder,
	type,
	name,
	value,
	required,
	disabled,
	error,
	touched,
	id,
	maxLength,
	minLengthText,
	onChange,
	textError,
	showIncreasingCharCount,
	className,
	...rest
}: InputProps & {
	touched: boolean | undefined;
	minLengthText?: string;
	showIncreasingCharCount?: boolean;
}) {
	const textAreaUsed = value ? maxLength - value.length : maxLength;
	const displayedError =
		typeof error === "string" && error !== minLengthText ? error : "";

	return (
		<div
			className={clsx("container", {
				disabled
			})}
		>
			<textarea
				name={name}
				value={value ?? ""}
				placeholder={placeholder}
				id={id}
				maxLength={maxLength}
				onChange={onChange}
				disabled={disabled}
				className={clsx("textarea-input-component", className, {
					error: error && touched
				})}
				required={required}
				{...rest}
			/>
			{displayedError && touched && (
				<div className="white-space--pre-line mt-1 text-xs text-feedback-negative-pure-500">
					{Array.isArray(displayedError)
						? displayedError.join("\n")
						: displayedError}
				</div>
			)}
			<div className="flex justify-between">
				{minLengthText && (
					<p className="mt-2 font-body-regular-extra-small">{minLengthText}</p>
				)}
				{!disabled && (
					<p className="flex-justify--end mt-2 text-right text-xs">
						{showIncreasingCharCount
							? `${value?.length ?? 0}/${maxLength} caracteres`
							: `${textAreaUsed}/${maxLength} caracteres restantes`}
					</p>
				)}
			</div>
		</div>
	);
}

export default TextArea;
