import { IcoArrowDown } from "assets/icons";
import clsx from "clsx";
import { ReactNode, useEffect, useState } from "react";
import "./styles.scss";

export interface AccordionItemProps {
	title: string | ReactNode;
	value?: string;
	content: string | ReactNode;
}

export interface AccordionSectionProps {
	title?: string | ReactNode;
	items: AccordionItemProps[];
}

export interface AccordionProps {
	sections: AccordionSectionProps[];
	defaultValue?: string[];
	accordionTriggerClassName?: string;
	accordionItemClassName?: string;
}

export function BaseAccordion({
	item,
	accordionItemClassName,
	accordionTriggerClassName,
	accordionContentClassName,
	onExpand,
	expandOnlyOnIconClick = false,
	defaultOpen,
	expandedProp,
	toggleExpandedProp
}: {
	item: AccordionItemProps;
	accordionItemClassName?: string;
	accordionTriggerClassName?: string;
	accordionContentClassName?: string;
	onExpand?: (evt: React.MouseEvent) => void;
	expandOnlyOnIconClick?: boolean;
	defaultOpen?: string[];
	expandedProp?: boolean;
	toggleExpandedProp?: (expanded: boolean) => void;
}) {
	const { title, value, content } = item;
	const [expanded, setExpanded] = useState<boolean>(
		defaultOpen !== undefined ? !!(value && defaultOpen.includes(value)) : true
	);

	const toggleExpand = (expand: boolean) => {
		setExpanded(expand);
		toggleExpandedProp?.(expand);
	};

	const onClickExpand = (evt: React.MouseEvent) => {
		toggleExpand(!expanded);
		onExpand?.(evt);
	};

	useEffect(() => {
		if (expandedProp !== undefined) {
			setExpanded(expandedProp);
			// toggleExpand(expandedProp);
		}
	}, [expandedProp]);

	return (
		<div
			key={`accordion-item-${value}`}
			className={clsx("base-accordion-item block", accordionItemClassName, {
				"base-accordion-item-expanded": expanded,
				"base-accordion-item-collapsed": !expanded,
				"cursor-default": expandOnlyOnIconClick
			})}
		>
			<div
				onClick={(evt) => {
					if (expandOnlyOnIconClick) return;
					onClickExpand(evt);
				}}
				className={clsx("base-accordion-trigger", accordionTriggerClassName)}
			>
				{title}
				<div
					className={clsx("base-accordion-icon", {
						"cursor-pointer": expandOnlyOnIconClick
					})}
					onClick={onClickExpand}
				>
					<IcoArrowDown size="24" />
				</div>
			</div>
			{expanded && (
				<div
					className={clsx("base-accordion-content", accordionContentClassName)}
				>
					{content}
				</div>
			)}
		</div>
	);
}

function AccordionItems({
	items,
	accordionTriggerClassName,
	accordionItemClassName,
	defaultOpen = []
}: {
	items: AccordionItemProps[];
	accordionTriggerClassName?: string;
	accordionItemClassName?: string;
	defaultOpen?: string[];
}) {
	return (
		<div className="accordion-items">
			{items.map((item) => (
				<BaseAccordion
					key={item.value}
					item={item}
					accordionTriggerClassName={clsx(
						"accordion-trigger",
						accordionTriggerClassName
					)}
					accordionItemClassName={clsx(
						"accordion-item",
						accordionItemClassName
					)}
					defaultOpen={defaultOpen}
				/>
			))}
		</div>
	);
}

export default function Accordion({
	sections,
	defaultValue = [],
	accordionTriggerClassName,
	accordionItemClassName
}: AccordionProps) {
	return (
		<div className="accordion-component">
			{sections.map(({ title, items }) => (
				<div className="accordion-section" key={`accordion-section-${title}`}>
					{title && <div className="accordion-section-title">{title}</div>}
					<AccordionItems
						items={items}
						accordionTriggerClassName={accordionTriggerClassName}
						accordionItemClassName={accordionItemClassName}
						defaultOpen={defaultValue}
					/>
				</div>
			))}
		</div>
	);
}
