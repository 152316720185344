import {
	ColumnDef,
	OnChangeFn,
	Row,
	SortingState,
	flexRender,
	getCoreRowModel,
	getSortedRowModel,
	useReactTable
} from "@tanstack/react-table";
import { IcoArrowDown, IcoArrowUp } from "assets/icons";
import colors from "colors";
import Loading from "components/Loading";

type TableProps<TData> = {
	isLoading?: boolean;
	columns: ColumnDef<TData, TData[keyof TData]>[];
	data: TData[];
	bodyRowClassName?: string;
	headerRowClassName?: string;
	emptyListCellClassName?: string;
	tableHeadClassName?: string;
	onRowClick?: (row: Row<TData>) => void;
	sorting?: SortingState;
	setSorting?: OnChangeFn<SortingState>;
};

type ColumnMetaProps = {
	columnClass: string;
	headerClass: string;
	cellClass: string;
	footerClass: string;
};

export default function Table<TData>({
	columns,
	data,
	bodyRowClassName,
	headerRowClassName,
	emptyListCellClassName,
	tableHeadClassName,
	onRowClick,
	isLoading = false,
	sorting,
	setSorting
}: Readonly<TableProps<TData>>) {
	const table = useReactTable({
		data,
		columns,
		getCoreRowModel: getCoreRowModel(),
		...(setSorting && { onSortingChange: setSorting }),
		getSortedRowModel: getSortedRowModel(),
		state: {
			...(sorting && { sorting })
		},
		manualSorting: true,
		enableSortingRemoval: false
	});

	return (
		<table className="w-full">
			<thead className={tableHeadClassName ?? ""}>
				{table.getHeaderGroups().map((headerGroup) => (
					<tr key={headerGroup.id} className={headerRowClassName ?? ""}>
						{headerGroup.headers.map((header) => (
							<th
								key={header.id}
								className={
									`${
										(header.column.columnDef.meta as ColumnMetaProps)
											?.columnClass || ""
									}` +
									` ${
										(header.column.columnDef.meta as ColumnMetaProps)
											?.headerClass || ""
									} ${
										header.column.getCanSort()
											? "cursor--pointer select-none"
											: ""
									}`
								}
								onClick={(e) => {
									const sortingHandler =
										header.column.getToggleSortingHandler();
									if (sortingHandler) sortingHandler(e);
								}}
							>
								{header.isPlaceholder
									? null
									: flexRender(
											header.column.columnDef.header,
											header.getContext()
									  )}

								{header.column.getCanSort() && (
									<span className="sort-icon m-2">
										{(header.column.getIsSorted() as string) === "desc" ? (
											<IcoArrowDown size="16px" />
										) : (
											<IcoArrowUp
												color={colors.neutral["low-400"]}
												size="18px"
											/>
										)}
									</span>
								)}
							</th>
						))}
					</tr>
				))}
			</thead>
			<tbody>
				{isLoading ? (
					<tr>
						<td aria-label="loading">
							<div className="w-full h-full p-8 flex flex-col items-center">
								<Loading type="primary" size={48} />
							</div>
						</td>
					</tr>
				) : data.length ? (
					table.getRowModel().rows.map((row) => (
						<tr
							key={row.id}
							className={bodyRowClassName ?? ""}
							onClick={() => onRowClick?.(row)}
						>
							{row.getVisibleCells().map((cell) => (
								<td
									key={cell.id}
									className={
										`${
											(cell.column.columnDef.meta as ColumnMetaProps)
												?.columnClass || ""
										}` +
										` ${
											(cell.column.columnDef.meta as ColumnMetaProps)
												?.cellClass || ""
										}`
									}
								>
									{flexRender(cell.column.columnDef.cell, cell.getContext())}
								</td>
							))}
						</tr>
					))
				) : (
					<tr className={bodyRowClassName ?? ""}>
						<td
							className={emptyListCellClassName ?? ""}
							colSpan={columns.length}
						>
							Sem conteúdo.
						</td>
					</tr>
				)}
			</tbody>
			<tfoot>
				{table.getFooterGroups().map((footerGroup) => (
					<tr key={footerGroup.id}>
						{footerGroup.headers.map((header) => (
							<th key={header.id}>
								{header.isPlaceholder
									? null
									: flexRender(
											header.column.columnDef.footer,
											header.getContext()
									  )}
							</th>
						))}
					</tr>
				))}
			</tfoot>
		</table>
	);
}
