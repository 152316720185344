import { IcoFeedbackWarning, IcoUpload } from "assets/icons";
import colors from "colors";
import {
	Accordion,
	Button,
	Checkbox,
	Loading,
	SelectionFooter
} from "components";
import { AccordionSectionProps } from "components/Accordion";
import { DownloadHelper, ErrorHelper } from "helpers";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useBeforeUnload, useBlocker } from "react-router-dom";
import { toast } from "react-toastify";
import { ApplicationDocumentService } from "services/applicationDocument";
import { ApplicationService } from "services/applicationService";
import {
	ApplicationDocumentFolder,
	RootFolder,
	ZipVolumeDownloadRequest
} from "services/types";
import { DownloadWarningModal } from "../../components";
import { TabComponentProps } from "../../types";
import { FolderList } from "./components";

enum FolderVolumesIds {
	VOLUME_1 = "volume-1",
	VOLUME_2 = "volume-2"
}

enum FolderVolumesNames {
	VOLUME_1 = "Volume 1 - Relatórios técnicos e documentos de identificação",
	VOLUME_2 = "Volume 2 - Projeto e cronograma"
}

export default function ApplicationPageDocuments(
	props: Readonly<TabComponentProps>
): JSX.Element {
	const { application } = props;
	const applicationId = application?.id;
	const applicationService = useMemo(ApplicationService.getInstance, []);
	const applicationDocumentService = useMemo(
		ApplicationDocumentService.getInstance,
		[]
	);

	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [applicationDocumentFolders, setApplicationDocumentFolders] = useState<
		ApplicationDocumentFolder[]
	>([]);
	const [
		applicationDocumentFolderVersions,
		setApplicationDocumentFolderVersions
	] = useState<{
		[key: string]: number;
	}>({});
	const [isZipDownloadWarningModalOpen, setIsZipDownloadWarningModalOpen] =
		useState(false);
	const [volumesSelected, setVolumesSelected] = useState<{
		[key: string]: boolean;
	}>({});
	const [foldersSelected, setFoldersSelected] = useState<{
		[key: string]: boolean;
	}>({});
	const [activeFolderVolume, setActiveFolderVolume] = useState<string | null>(
		null
	);
	const [hasSomeSelected, setHasSomeSelected] = useState(false);
	const [isLoadingDownloadZip, setIsLoadingDownloadZip] = useState(false);

	let toastMessageId: string | number | null = null;

	const updateFoldersSelected = (
		prevState: Record<string, boolean>,
		folderId: string,
		isChecked: boolean
	) => ({
		...prevState,
		[folderId]: isChecked
	});

	const isAllFoldersSelected = (
		folderId: string[],
		updateFolder: Record<string, boolean>
	) => folderId.every((id) => updateFolder[id]);

	const updateVolumesState = useCallback(
		(volumeId: string, isAllSelected: boolean, isAllUnSelected: boolean) => {
			const isVolumeSelected = isAllSelected
				? true
				: isAllUnSelected
				? false
				: null;

			if (isVolumeSelected !== null) {
				setVolumesSelected((prevVolume) => ({
					...prevVolume,
					[volumeId]: isVolumeSelected
				}));
			}

			setActiveFolderVolume(isVolumeSelected === false ? null : volumeId);
			setHasSomeSelected(isVolumeSelected === null);
		},
		[
			setVolumesSelected,
			setActiveFolderVolume,
			setHasSomeSelected,
			volumesSelected,
			activeFolderVolume
		]
	);

	const handleSelectVolumes = useCallback(
		(
			volumeId: string,
			isChecked: boolean,
			content: ApplicationDocumentFolder[]
		) => {
			setVolumesSelected((preValue) => ({
				...preValue,
				[volumeId]: isChecked
			}));

			const newCheckedFolders = content.reduce(
				(acc: Record<string, boolean>, folder: ApplicationDocumentFolder) => {
					const folderId =
						folder.interventionDocumentFolderType.documentType.id;
					return updateFoldersSelected(acc, folderId, isChecked);
				},
				{ ...foldersSelected }
			);

			setFoldersSelected(newCheckedFolders);

			if (isChecked) {
				setActiveFolderVolume(volumeId);
			} else {
				setActiveFolderVolume(null);
				setHasSomeSelected(false);
			}
		},
		[
			volumesSelected,
			activeFolderVolume,
			setVolumesSelected,
			setActiveFolderVolume,
			setHasSomeSelected
		]
	);

	const handleSelectFolders = useCallback(
		(
			isChecked: boolean,
			value: ApplicationDocumentFolder,
			volumeId: string
		) => {
			setFoldersSelected((prevFolder) =>
				updateFoldersSelected(
					prevFolder,
					value.interventionDocumentFolderType.documentType.id,
					isChecked
				)
			);

			if (isChecked) {
				setActiveFolderVolume(volumeId);
			}

			const selectedFoldersIds = applicationDocumentFolders
				.filter(
					(folder) =>
						folder.interventionDocumentFolderType.rootFolder ===
						value.interventionDocumentFolderType.rootFolder
				)
				.map((folder) => folder.interventionDocumentFolderType.documentType.id);

			const updatedFolders = updateFoldersSelected(
				foldersSelected,
				value.interventionDocumentFolderType.documentType.id,
				isChecked
			);

			const isAllSelected = isAllFoldersSelected(
				selectedFoldersIds,
				updatedFolders
			);

			const isAllUnSelected = selectedFoldersIds.every(
				(id) => !updatedFolders[id]
			);

			updateVolumesState(volumeId, isAllSelected, isAllUnSelected);
		},
		[
			applicationDocumentFolders,
			setFoldersSelected,
			setActiveFolderVolume,
			foldersSelected
		]
	);

	const volumeOneFolders = useMemo(
		() =>
			applicationDocumentFolders.filter(
				(folder) =>
					folder?.interventionDocumentFolderType?.rootFolder ===
					RootFolder.VOLUME_1
			),
		[applicationDocumentFolders]
	);

	const volumeTwoFolders = useMemo(
		() =>
			applicationDocumentFolders.filter(
				(folder) =>
					folder?.interventionDocumentFolderType?.rootFolder ===
					RootFolder.VOLUME_2
			),
		[applicationDocumentFolders]
	);

	const loadInfo = useCallback(() => {
		if (!applicationId) return;
		setIsLoading(true);
		applicationService
			.listApplicationFolders(applicationId)
			.then((responseData) => {
				setApplicationDocumentFolders(responseData);
				setApplicationDocumentFolderVersions(
					responseData.reduce(
						(acc, folder) => ({
							...acc,
							[folder.interventionDocumentFolderType.id]: folder.version
						}),
						{}
					)
				);
			})
			.catch((err) => toast.error(ErrorHelper.getResponseErrorMessage(err)))
			.finally(() => setIsLoading(false));
	}, [applicationId]);

	useEffect(() => {
		loadInfo();
	}, [applicationId]);

	const replaceDocumentFolderInList = useCallback(
		(
			interventionDocumentFolderTypeId: string,
			newFolder: ApplicationDocumentFolder
		) => {
			setApplicationDocumentFolders((folders) =>
				folders.map((folder) =>
					folder.interventionDocumentFolderType.id ===
					interventionDocumentFolderTypeId
						? newFolder
						: folder
				)
			);
		},
		[]
	);
	const blocker = useBlocker(
		({ currentLocation, nextLocation }) =>
			isLoadingDownloadZip && currentLocation.pathname !== nextLocation.pathname
	);

	useBeforeUnload((event) => {
		if (isLoadingDownloadZip) {
			event.preventDefault();
			return "Processamento dos arquivos em andamento. Tem certeza que deseja cancelar?";
		}
		return undefined;
	});

	useEffect(() => {
		if (blocker.state === "blocked") {
			setIsZipDownloadWarningModalOpen(true);
		} else {
			setIsZipDownloadWarningModalOpen(false);
		}
	}, [blocker]);

	const selectedFolders = () => {
		const filterSelectedFolders = applicationDocumentFolders.filter(
			(folder) =>
				foldersSelected[folder.interventionDocumentFolderType.documentType.id]
		);

		return {
			containerName: "documents",
			applicationProcessCode: `${application?.applicationProcessCode}`,
			rootFolder:
				filterSelectedFolders[0].interventionDocumentFolderType.rootFolder,
			downloadedFolders: filterSelectedFolders.map((folder) => ({
				folderName: folder.interventionDocumentFolderType.documentType.name,
				fileNames: folder.documents.map((document) => document.blobName)
			}))
		};
	};

	const zipDownload = useCallback(
		async (zipVolumeDownloadRequest: ZipVolumeDownloadRequest) => {
			if (!applicationDocumentService) {
				return;
			}
			setIsLoadingDownloadZip(true);

			applicationDocumentService
				.zipDownload(zipVolumeDownloadRequest)
				.then((response) => {
					toast.success(
						<div>
							<div className="text-base font-semibold">Download realizado!</div>
							<div className="text-sm/6">
								O download dos arquivos foram feitos com sucesso.
							</div>
						</div>
					);
					const { downloadUrls } = response;
					downloadUrls.forEach((url, i) =>
						DownloadHelper.performDownload(url, `Parte ${i}`)
					);
				})
				.catch((error) =>
					toast.error(ErrorHelper.getResponseErrorMessage(error))
				)
				.finally(() => {
					setIsLoadingDownloadZip(false);
					blocker.reset?.();
					setIsZipDownloadWarningModalOpen(false);
				});
		},
		[applicationDocumentService, applicationDocumentFolders, foldersSelected]
	);

	const selectedFoldersNumber =
		Object.values(foldersSelected).filter(Boolean).length;

	const selectionLabel = () => {
		const numberRows = selectedFoldersNumber;
		const labelName = numberRows > 1 ? "Pastas" : "Pasta";
		const labelSelected = numberRows > 1 ? "selecionadas" : "selecionada";
		return (
			<>
				<b>{`${numberRows}`}</b> <span>{`${labelName} `}</span>
				{labelSelected}
			</>
		);
	};

	const diferentVolumesSelected = () => {
		if (!toast.isActive(toastMessageId as number)) {
			toastMessageId = toast.dark(
				<div>
					<div className="flex align-center gap-3">
						<IcoFeedbackWarning
							size="18"
							color={colors["feedback-alert"].alerts}
						/>
						<div className="text-base font-semibold">Atenção!</div>
					</div>
					<div className="text-sm/6">
						Você só pode baixar um volume por vez. Conclua o download atual
						antes de iniciar outro.
					</div>
				</div>,
				{ position: toast.POSITION.TOP_CENTER, style: { width: "30.5rem" } }
			);
			return toastMessageId;
		}

		return null;
	};

	const accordionContentSections: AccordionSectionProps[] = useMemo(
		() => [
			{
				items: [
					{
						title: (
							<div
								onClick={() =>
									activeFolderVolume &&
									activeFolderVolume !== FolderVolumesIds.VOLUME_1 &&
									diferentVolumesSelected()
								}
							>
								<Checkbox
									partialSelected={
										hasSomeSelected &&
										activeFolderVolume === FolderVolumesIds.VOLUME_1
									}
									iconSize="16"
									name={FolderVolumesNames.VOLUME_1}
									label={FolderVolumesNames.VOLUME_1}
									id={FolderVolumesIds.VOLUME_1}
									value={FolderVolumesNames.VOLUME_1}
									checked={
										volumesSelected[
											(FolderVolumesIds.VOLUME_1 as keyof typeof volumesSelected) ||
												""
										] || false
									}
									onChange={(e: { target: { checked: boolean } }) =>
										handleSelectVolumes(
											FolderVolumesIds.VOLUME_1,
											e.target.checked,
											volumeOneFolders
										)
									}
									onClick={(e) => e.preventDefault()}
									disabled={
										activeFolderVolume !== null &&
										activeFolderVolume !== FolderVolumesIds.VOLUME_1
									}
								/>
							</div>
						),
						value: FolderVolumesIds.VOLUME_1,
						content: (
							<FolderList
								activeFolderVolume={activeFolderVolume}
								selectedFolders={foldersSelected}
								handleSelectFolders={handleSelectFolders}
								diferentVolumesSelected={diferentVolumesSelected}
								id={FolderVolumesIds.VOLUME_1}
								folders={volumeOneFolders}
								applicationDocumentService={applicationDocumentService}
								foldersCurrentVersions={applicationDocumentFolderVersions}
								applicationId={applicationId!}
								replaceDocumentFolderInList={replaceDocumentFolderInList}
							/>
						)
					},
					{
						title: (
							<div
								onClick={() =>
									activeFolderVolume &&
									activeFolderVolume !== FolderVolumesIds.VOLUME_2 &&
									diferentVolumesSelected()
								}
							>
								<Checkbox
									partialSelected={
										hasSomeSelected &&
										activeFolderVolume === FolderVolumesIds.VOLUME_2
									}
									iconSize="16"
									name={FolderVolumesNames.VOLUME_2}
									label={FolderVolumesNames.VOLUME_2}
									id={FolderVolumesNames.VOLUME_2}
									value={FolderVolumesNames.VOLUME_2}
									checked={
										volumesSelected[
											(FolderVolumesIds.VOLUME_2 as keyof typeof volumesSelected) ||
												""
										] || false
									}
									onChange={(e: { target: { checked: boolean } }) =>
										handleSelectVolumes(
											FolderVolumesIds.VOLUME_2,
											e.target.checked,
											volumeTwoFolders
										)
									}
									disabled={
										activeFolderVolume !== null &&
										activeFolderVolume !== FolderVolumesIds.VOLUME_2
									}
								/>
							</div>
						),
						value: FolderVolumesIds.VOLUME_2,
						content: (
							<FolderList
								activeFolderVolume={activeFolderVolume}
								selectedFolders={foldersSelected}
								handleSelectFolders={handleSelectFolders}
								diferentVolumesSelected={diferentVolumesSelected}
								id={FolderVolumesIds.VOLUME_2}
								folders={volumeTwoFolders}
								applicationDocumentService={applicationDocumentService}
								foldersCurrentVersions={applicationDocumentFolderVersions}
								applicationId={applicationId!}
								replaceDocumentFolderInList={replaceDocumentFolderInList}
							/>
						)
					}
				]
			}
		],
		[
			volumeOneFolders,
			volumeTwoFolders,
			applicationDocumentService,
			foldersSelected,
			volumesSelected
		]
	);

	return isLoading ? (
		<div className="bg-neutral-high-pure-50 rounded-lg flex flex-col justify-start grow overflow-hidden">
			<div className="h-full content-center">
				<Loading size={50} />
			</div>
		</div>
	) : (
		<div className="bg-neutral-high-pure-50 rounded-lg flex py-6 flex-col justify-start grow overflow-hidden">
			<div className="w-full px-6 overflow-auto overflow-wrap--anywhere scrollbar-width--thin scrollbar-gutter--stable">
				<Accordion
					sections={accordionContentSections}
					defaultValue={[FolderVolumesIds.VOLUME_1, FolderVolumesIds.VOLUME_2]}
				/>
				<SelectionFooter
					open={selectedFoldersNumber > 0}
					label={selectionLabel()}
				>
					<Button
						className="first-icon"
						onClick={() => zipDownload(selectedFolders())}
						isLoading={isLoadingDownloadZip}
					>
						<IcoUpload color="#FFF" size="16" />
						<span>Exportar</span>
					</Button>
				</SelectionFooter>
			</div>

			<DownloadWarningModal
				isOpen={isZipDownloadWarningModalOpen}
				onClose={() => blocker.reset?.()}
			/>
		</div>
	);
}
