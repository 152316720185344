import { IconProps } from "./types";

export default function IcoEdit({ color = "#1D1D1D", size = "24" }: IconProps) {
	return (
		<svg
			width={size}
			height={size}
			viewBox="0 0 24 25"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M7.5 20L20.3058 7.2822C20.6916 6.89902 20.7006 6.27777 20.3259 5.88366L19.5299 5.04638L18.765 4.2417C18.3782 3.83487 17.7325 3.82598 17.3347 4.22202L4.5 17L3 21.5L7.5 20Z"
				stroke={color}
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
}
