import { IcoArrowDown, IcoCloseError, IcoHint } from "assets/icons";
import clsx from "clsx";
import { Loading } from "components";
import { useMemo } from "react";
import { Tooltip } from "react-tooltip";
import { TailwindColSpan, TailwindMaxMdColSpan } from "styles/helpers";

export function SectionErrorMessage({
	error
}: Readonly<{ error: string | string[] }>): JSX.Element {
	return (
		<div className="bg-feedback-negative-high-50 text-feedback-negative-pure-500 rounded-lg p-4 flex items-center justify-left gap-4 text-sm">
			<IcoCloseError size="20" />
			<span>{typeof error === "string" ? error : error.join("\n")}</span>
		</div>
	);
}

export function Section({
	children
}: {
	children: React.ReactNode;
}): JSX.Element {
	return (
		<div className="flex flex-col pb-6 border-solid border-neutral-high-200 [&:not(:last-of-type)]:border-b-2">
			{children}
		</div>
	);
}

export function CollapsibleSectionHeader({
	title,
	expanded,
	setExpanded
}: Readonly<{
	title: string;
	expanded: boolean;
	setExpanded: (newExpanded: boolean) => void;
}>): JSX.Element {
	return (
		<button
			onClick={() => setExpanded(!expanded)}
			className={clsx(
				"px-4 py-2 min-h-14 flex items-center justify-between",
				"border border-neutral-high-200 bg-neutral-high-200 rounded-lg"
			)}
		>
			<h2 className="font-semibold text-left">{title}</h2>
			<span
				className={clsx(
					"transition ease-in-out p-2 border border-transparent",
					{
						"rotate-[-180deg]": expanded
					}
				)}
			>
				<IcoArrowDown size="24" />
			</span>
		</button>
	);
}

export function SectionHeader({
	title,
	description
}: Readonly<{
	title: string;
	description?: string;
}>): JSX.Element {
	return (
		<div className="flex flex-col gap-3">
			<h2 className="mb-8 md:mb-4 text-2xl font-semibold md:text-lg md:font-normal">
				{title}
			</h2>
			{description && <p> {description} </p>}
		</div>
	);
}

export function SectionContent({
	children,
	title,
	hint,
	id
}: Readonly<{
	children?: React.ReactNode;
	title?: string;
	hint?: string;
	id?: string;
}>): JSX.Element {
	const nodeId = useMemo(() => Math.trunc(Math.random() * 100000), []);
	return (
		<div className="relative grid gap-4 grid-cols-12 my-4" id={id}>
			{title && (
				<div className="flex flex-row gap-2 justify-start flex-items--center col-span-12">
					<h3 className="font-semibold text-neutral-low-400">{title}</h3>
					{hint && (
						<>
							<span id={`section-${nodeId}`}>
								<IcoHint />
							</span>
							<Tooltip content={hint} anchorSelect={`#section-${nodeId}`} />
						</>
					)}
				</div>
			)}
			{children}
		</div>
	);
}

export function SectionItem({
	children,
	colSpan = 12,
	mbColSpan = 12,
	itemElement: El = "div"
}: Readonly<{
	children: React.ReactNode;
	colSpan?: number;
	mbColSpan?: number;
	itemElement?: keyof JSX.IntrinsicElements;
}>): JSX.Element {
	const className = [
		TailwindMaxMdColSpan[mbColSpan],
		TailwindColSpan[colSpan]
	].join(" ");
	return <El className={className}>{children}</El>;
}

export function StepLoading() {
	return (
		<div className="py-4 w-full h-full center-content">
			<Loading size={44} />
		</div>
	);
}
