import { IcoClose, IcoFilter, IcoTrash } from "assets/icons";
import colors from "colors";
import AsideOverlay from "components/AsideOverlay";
import Button from "components/Button";
import Loading from "components/Loading";
import { FormEvent, ReactNode } from "react";

export function FilterForm({
	children,
	onSubmit,
	isLoading = false
}: Readonly<{
	children: ReactNode;
	onSubmit: (e?: FormEvent<HTMLFormElement>) => void;
	isLoading?: boolean;
}>): JSX.Element {
	return (
		<form
			className="flex flex-col grow w-full overflow-y-auto scrollbar-width--thin scrollbar-gutter--stable"
			onSubmit={onSubmit}
		>
			{isLoading && (
				<div className="w-full h-full p-8 flex flex-col items-center">
					<Loading type="primary" size={48} />
				</div>
			)}
			{!isLoading && (
				<div className="flex flex-col p-4 sm:py-6 sm:px-8 gap-4 grow">
					{children}
				</div>
			)}
		</form>
	);
}

export function FilterFormHeader({
	title,
	onClose
}: Readonly<{ title: string; onClose: () => void }>) {
	return (
		<header className="flex flex-row items-center justify-between w-full p-4 sm:py-6 sm:px-8 border-b border-neutral-high-200">
			<h2 className="font-semibold text-lg">{title}</h2>
			<Button kind="icon" hierarchy="ghost" onClick={onClose}>
				<IcoClose />
			</Button>
		</header>
	);
}

export function FilterFormFooter({
	dirty,
	hasErrors,
	onClear,
	onSubmit
}: Readonly<{
	dirty: boolean;
	hasErrors: boolean;
	onClear: () => void;
	onSubmit: () => void;
}>): JSX.Element {
	return (
		<footer className="flex w-full gap-4 p-4 sm:py-6 sm:px-8 sticky bottom-0 items-center justify-between sm:justify-end border-t border-neutral-high-200 bg-neutral-high-pure-50">
			<Button
				hierarchy="secondary"
				onClick={onClear}
				className="flex flex-row items-center text-sm max-h-10"
			>
				<IcoTrash />
				<span>Limpar filtros</span>
			</Button>
			<Button
				onClick={onSubmit}
				hierarchy="primary"
				disabled={!dirty || hasErrors}
				className="flex flex-row items-center text-sm max-h-10"
			>
				<IcoFilter
					color={
						!dirty || hasErrors
							? colors.neutral["high-200"]
							: colors.neutral["high-pure-50"]
					}
				/>
				<span>Filtrar</span>
			</Button>
		</footer>
	);
}

export function BackofficeFilterForm({
	isOpen,
	onClickOutside,
	children
}: Readonly<{
	isOpen: boolean;
	onClickOutside: () => void;
	children: ReactNode;
}>): JSX.Element {
	return (
		<AsideOverlay
			isOpen={isOpen}
			onClickOutside={onClickOutside}
			className="flex flex-col items-stretch"
			from="right"
		>
			{children}
		</AsideOverlay>
	);
}
