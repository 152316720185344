import { IconProps } from "./types";

export default function IcoArrowUp({
	color = "#5E1CCA",
	size = "20"
}: IconProps) {
	return (
		<svg
			width={size}
			height={size}
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M5.1875 12.1875L5.78125 12.8125C5.9375 12.9688 6.1875 12.9688 6.3125 12.8125L11.1875 7.96875V18.625C11.1875 18.8438 11.3438 19 11.5625 19H12.4375C12.625 19 12.8125 18.8438 12.8125 18.625V7.96875L17.6562 12.8125C17.7812 12.9688 18.0312 12.9688 18.1875 12.8125L18.7812 12.1875C18.9375 12.0625 18.9375 11.8125 18.7812 11.6562L12.25 5.125C12.0938 4.96875 11.875 4.96875 11.7188 5.125L5.1875 11.6562C5.03125 11.8125 5.03125 12.0625 5.1875 12.1875Z"
				fill={color}
			/>
		</svg>
	);
}
