import { IconProps } from "./types";

export default function IcoDownload({
	color = "#1d1d1d",
	size = "24"
}: IconProps): JSX.Element {
	return (
		<svg
			width={size}
			height={size}
			viewBox="0 0 25 25"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M12.75 3.5L12.75 15.5M12.75 15.5L18.75 10.1M12.75 15.5L6.75 10.1M21.75 15.5059V21.5059H3.75L3.75 15.5059"
				stroke={color}
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
}
