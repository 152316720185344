import { useRef, useState } from "react";
import { PlacesType, Tooltip } from "react-tooltip";

import "./styles.scss";

export default function TooltipWrapper({
	place,
	value
}: {
	place: PlacesType;
	value: any;
}) {
	const [displayTooltip, setDisplayTooltip] = useState(false);
	const valueRef = useRef<HTMLDivElement>(null);

	const handleDisplayTooltip = () => {
		if (valueRef.current) {
			const overflow =
				valueRef.current.scrollWidth > valueRef.current.clientWidth;

			setDisplayTooltip(overflow);
		}
	};

	return (
		<div
			onMouseEnter={handleDisplayTooltip}
			onMouseLeave={() => setDisplayTooltip(false)}
		>
			<div className="overflow-element" id="tooltip" ref={valueRef}>
				{value}
			</div>
			<Tooltip anchorSelect="#tooltip" hidden={!displayTooltip} place={place}>
				{value}
			</Tooltip>
		</div>
	);
}
