import { Constants } from "appConstants";
import { DateTimeHelper } from "helpers";
import {
	ApplicationProprietorData,
	PersonTypeOptions,
	ProprietorDocumentType
} from "pages/NewApplication/steps/types";
import { BaseModel } from "./BaseModel";

export class ApplicationProprietor extends BaseModel<ApplicationProprietorData> {
	id: string;
	proprietorType: PersonTypeOptions;
	proprietorAddressPostalCode: string;
	proprietorAddressState: Constants.FederalUnits;
	proprietorAddressCity: string;
	proprietorAddressNeighbourhood: string;
	proprietorAddressStreet: string;
	proprietorAddressNumber: string;
	proprietorAddressComplement: string;
	proprietorName: string;
	proprietorPhoneNumber: string;
	proprietorEmail: string;
	proprietorDocument: string;
	proprietorDocumentType: ProprietorDocumentType;
	proprietorBirthDate: string;
	proprietorStateRegistration: string;
	proprietorCityRegistration: string;
	proprietorContactName: string;
	applicantName: string;
	applicantPhoneNumber: string;
	applicantRole: string;
	applicantEmail: string;
	additionalContactEmail: string;

	constructor(data: ApplicationProprietorData) {
		super(data);
		this.id = data.id ?? "";
		this.proprietorType = data.proprietorType;
		this.proprietorAddressPostalCode = data.proprietorAddressPostalCode ?? "";
		this.proprietorAddressState =
			data.proprietorAddressState ?? Constants.FederalUnits.AC;
		this.proprietorAddressCity = data.proprietorAddressCity ?? "";
		this.proprietorAddressNeighbourhood =
			data.proprietorAddressNeighbourhood ?? "";
		this.proprietorAddressStreet = data.proprietorAddressStreet ?? "";
		this.proprietorAddressNumber = data.proprietorAddressNumber ?? "";
		this.proprietorAddressComplement = data.proprietorAddressComplement ?? "";
		this.proprietorName = data.proprietorName ?? "";
		this.proprietorPhoneNumber = data.proprietorPhoneNumber ?? "";
		this.proprietorEmail = data.proprietorEmail ?? "";
		this.proprietorDocument = data.proprietorDocument ?? "";
		this.proprietorDocumentType = data.proprietorDocumentType ?? "";
		this.proprietorBirthDate = data.proprietorBirthDate ?? "";
		this.proprietorStateRegistration = data.proprietorStateRegistration ?? "";
		this.proprietorCityRegistration = data.proprietorCityRegistration ?? "";
		this.proprietorContactName = data.proprietorContactName ?? "";
		this.applicantName = data.applicantName ?? "";
		this.applicantPhoneNumber = data.applicantPhoneNumber ?? "";
		this.applicantRole = data.applicantRole ?? "";
		this.applicantEmail = data.applicantEmail ?? "";
		this.additionalContactEmail = data.additionalContactEmail ?? "";
	}

	get proprietorTypeLabel() {
		switch (this.proprietorType) {
			case "PJ":
				return "Pessoa Jurídica";
			case "PF":
				return "Pessoa Física";
			default:
				return "-";
		}
	}

	get proprietorBirthDateLabel() {
		return this.proprietorBirthDate
			? DateTimeHelper.formatDateString(this.proprietorBirthDate)
			: "-";
	}

	get isPF() {
		return this.proprietorType === "PF";
	}

	get isPJ() {
		return this.proprietorType === "PJ";
	}
}
