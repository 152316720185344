import { IcoFlagCircle, IcoPadLock } from "assets/icons";
import { NewApplicationButton } from "components";

export function EmptyState({
	allowCreateApplication
}: {
	allowCreateApplication: boolean;
}): JSX.Element {
	return (
		<div className="bg-neutral-high-pure-50 p-5 pb-14 grow">
			<div className="flex flex-col justify-center items-center h-full">
				{allowCreateApplication ? <IcoFlagCircle /> : <IcoPadLock size="56" />}
				<h1 className="text-2xl max-sm:text-large font-bold mt-6 text-center">
					{allowCreateApplication
						? "Crie sua primeira solicitação"
						: "Solicitar intervenção"}
				</h1>
				<p className="text-neutral-high-1005 mt-4 mb-6 max-w-sm text-center">
					{allowCreateApplication
						? "Preencha o formulário e envie os documentos de acordo com o tipo de intervenção."
						: "A funcionalidade de protocolar processos será disponibilizada em breve."}
				</p>
				<NewApplicationButton disabled={!allowCreateApplication} />
			</div>
		</div>
	);
}
