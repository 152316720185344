import { IconProps } from "./types";

export default function IcoMenu({ color = "#1D1D1D", size = "24" }: IconProps) {
	return (
		<svg
			width={size}
			height={size}
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M4 6L20 6M4 12L20 12M4 18L20 18"
				stroke={color}
				strokeLinecap="round"
			/>
		</svg>
	);
}
