import clsx from "clsx";
import {
	AnalysisStatusLabel,
	RedirectApplicationStatusLabel
} from "services/types";

const POSITIVE_STYLE =
	"bg-feedback-positive-high-50 text-feedback-positive-low-950";
const NEUTRAL_STYLE = "bg-feedback-alert-high-50 text-feedback-alert-low-950";
const NEGATIVE_STYLE =
	"bg-feedback-negative-high-50 text-feedback-negative-low-950";

export const statusStyles: Record<
	AnalysisStatusLabel | RedirectApplicationStatusLabel,
	string
> = {
	[AnalysisStatusLabel.EM_ANDAMENTO]: NEUTRAL_STYLE,
	[AnalysisStatusLabel.APROVADO]: POSITIVE_STYLE,
	[AnalysisStatusLabel.APROVADO_RESSALVAS]: POSITIVE_STYLE,
	[AnalysisStatusLabel.REPROVADO]: NEGATIVE_STYLE,
	[AnalysisStatusLabel.CANCELADO]: NEGATIVE_STYLE,
	[RedirectApplicationStatusLabel.DEVOLVIDO]: NEUTRAL_STYLE,
	[RedirectApplicationStatusLabel.APTO_ANALISE]: POSITIVE_STYLE
};

export default function StatusTag({
	status,
	small
}: Readonly<{
	status: AnalysisStatusLabel | RedirectApplicationStatusLabel;
	small?: boolean;
}>): JSX.Element {
	return (
		<div
			className={clsx(
				statusStyles[status],
				small ? "px-2 sm:min-h-6 py-1 text-xs" : "px-3 sm:h-10 py-2 text-sm",
				"flex items-center text-center font-semibold rounded-lg"
			)}
		>
			{status}
		</div>
	);
}
