import { IconProps } from "./types";

export default function IcoFeedbackError({
	color = "#F83446",
	size = "40"
}: IconProps) {
	return (
		<svg
			width={size}
			height={size}
			viewBox="0 0 40 41"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<rect y="0.0965576" width="40" height="40" rx="20" fill={color} />
			<path
				d="M28.0001 12.0966L19.77 20.323M19.77 20.323L12.4519 27.6379M19.77 20.323L27.547 28.0966M19.77 20.323L11.9988 12.5552"
				stroke="white"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
}
