import { IconProps } from "./types";

export default function IcoFlagCircle({ size = "56" }: IconProps) {
	return (
		<svg
			width={size}
			height={size}
			viewBox="0 0 56 57"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<rect y="0.5" width="56" height="56" rx="28" fill="#F0F0F0" />
			<path
				d="M18.5508 41.1002V17.0249C18.5508 17.0249 19.732 15.8999 23.2758 15.8999C26.8195 15.8999 29.182 18.1499 32.7258 18.1499C36.2695 18.1499 37.4508 17.0249 37.4508 17.0249V30.5249C37.4508 30.5249 36.2695 31.6499 32.7258 31.6499C29.182 31.6499 26.8195 29.3999 23.2758 29.3999C19.732 29.3999 18.5508 30.5249 18.5508 30.5249"
				stroke="#1D1D1D"
				strokeWidth="1.4"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
}
