import { useEffect, useMemo } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";

import { Home, LoggedInHome, Page404, SignUp } from "pages";

import { baseConfig } from "config";
import AuthTokenHelper from "helpers/AuthTokenHelper";
import useUserToken from "hooks/useUserToken";
import { DefaultLayout, LoggedInLayout, SignUpLayout } from "layouts";
import { FeatureFlag } from "models/auth/types";
import AADB2CConfirmLogin from "pages/Login/AADB2CConfirmLogin";
import PageSystemDisabled from "pages/SystemDisabled";
import { useSelector } from "react-redux";
import { RootState } from "store";
import ApplicationRoutes from "./application";
import BackofficeRoutes from "./backoffice";

// This path is sent in the POST so the backend can compose the redirect URL
export const RESET_PASSWORD_PATH = "resetar-senha";

// DO NOT CHANGE THIS PATH. This is used as the redirect URL and is set in the B2C configuration.
export const AADB2C_CONFIRM_LOGIN_PAGE_PATH = "/confirm-login";

function Routers() {
	const { pathname } = useLocation();
	const { user: externalUser } = useSelector((state: RootState) => state.auth);
	const userTokenInfo = useUserToken();
	const isSystemEnabled = baseConfig.systemEnabled;
	const externalUserCompletedRegistration = useMemo(
		() => !!externalUser?.name,
		[externalUser]
	);

	useEffect(() => {
		setTimeout(() => {
			window.scrollTo({
				top: 0,
				left: 0,
				behavior: "smooth"
			});
		}, 100);
	}, [pathname]);

	useEffect(() => {
		const tokenCookie = AuthTokenHelper.getCookieToken();
		if (tokenCookie) {
			AuthTokenHelper.setAuthToken(tokenCookie);
		}
	}, []);

	return !isSystemEnabled ? (
		<Routes>
			<Route path="/" element={<PageSystemDisabled />} />
			<Route path="/*" element={<Navigate to="/" />} />
		</Routes>
	) : (
		<Routes>
			{externalUser ? (
				<Route path="/">
					<Route
						path="/"
						element={
							externalUserCompletedRegistration ? (
								<LoggedInLayout />
							) : (
								<Navigate to="/cadastro" />
							)
						}
					>
						<Route
							path="/"
							element={<LoggedInHome userTokenInfo={userTokenInfo} />}
						/>
						{userTokenInfo?.hasFeatureEnabled(
							FeatureFlag.CREATE_APPLICATION
						) && (
							<Route path="/solicitacao/*" element={<ApplicationRoutes />} />
						)}
						<Route path="/*" element={<Page404 />} />
					</Route>
					<Route path="/cadastro" element={<SignUpLayout />}>
						<Route
							path="/cadastro"
							element={
								externalUserCompletedRegistration ? (
									<Navigate to="/" />
								) : (
									<SignUp />
								)
							}
						/>
					</Route>
				</Route>
			) : (
				<Route path="/" element={<DefaultLayout />}>
					<Route path="/" element={<Home />} />
					<Route path="/*" element={<Navigate to="/" />} />
				</Route>
			)}

			<Route
				path={AADB2C_CONFIRM_LOGIN_PAGE_PATH}
				element={<AADB2CConfirmLogin />}
			/>
			<Route
				path="/backoffice/*"
				element={<BackofficeRoutes userTokenInfo={userTokenInfo} />}
			/>
			<Route path="/*" element={<Page404 />} />
		</Routes>
	);
}

export default Routers;
