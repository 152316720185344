import clsx from "clsx";
import { AccordionItemProps, BaseAccordion } from "components/Accordion";
import Button from "components/Button";
import Loading from "components/Loading";
import { DownloadHelper, ErrorHelper } from "helpers";
import { ReactNode, useCallback, useMemo } from "react";
import { toast } from "react-toastify";
import { ApplicationDocumentService } from "services/applicationDocument";
import {
	ApplicationDocument,
	ApplicationDocumentFolder,
	ApplicationFormDocument,
	ApplicationFormDocumentFolder
} from "services/types";
import "./styles.scss";

interface ExpandableColumn {
	name: string | ReactNode;
	size: number;
}

export function ExpandableLine({
	id,
	columns,
	accordionTriggerClassName,
	accordionItemClassName,
	accordionContentClassName,
	children,
	onExpand,
	expandOnlyOnIconClick = false,
	expanded,
	defaultOpen,
	toggleExpanded
}: {
	id?: string;
	columns: ExpandableColumn[];
	accordionTriggerClassName?: string;
	accordionItemClassName?: string;
	accordionContentClassName?: string;
	children?: React.ReactNode;
	onExpand?: () => void;
	expandOnlyOnIconClick?: boolean;
	expanded?: boolean;
	defaultOpen?: string[];
	toggleExpanded?: (expanded: boolean) => void;
}) {
	const item: AccordionItemProps = useMemo(
		() => ({
			...(id && { value: id }),
			title: (
				<div className="grid grid-cols-12 h-full w-full bg-white text-md">
					{columns.map((column, index) => (
						<div
							key={`column-${id}-${index}`}
							className={clsx({
								[`col-span-${column.size}`]: column.size
							})}
						>
							{column.name}
						</div>
					))}
				</div>
			),
			content: children
		}),
		[columns]
	);
	return (
		<BaseAccordion
			item={item}
			accordionTriggerClassName={accordionTriggerClassName}
			accordionItemClassName={clsx(
				"expandable-line-item",
				accordionItemClassName
			)}
			accordionContentClassName={accordionContentClassName}
			onExpand={onExpand}
			expandOnlyOnIconClick={expandOnlyOnIconClick}
			expandedProp={expanded}
			defaultOpen={defaultOpen}
			toggleExpandedProp={toggleExpanded}
		/>
	);
}

export function DocumentDisplayList({
	documents,
	applicationDocumentService
}: {
	documents: ApplicationDocument[] | ApplicationFormDocument[];
	applicationDocumentService: ApplicationDocumentService;
}) {
	const performDownload = useCallback(
		(document: ApplicationDocument | ApplicationFormDocument) => {
			applicationDocumentService
				.download(document.blobName)
				.then((response) => {
					const { downloadUrl } = response;
					DownloadHelper.performDownload(downloadUrl, document.name);
				})
				.catch((error) =>
					toast.error(ErrorHelper.getResponseErrorMessage(error))
				);
		},
		[applicationDocumentService]
	);

	return (
		<div>
			{documents.map((document) => (
				<div key={document.id} className="document-item">
					<Button
						kind="link"
						hierarchy="ghost"
						size="small"
						className="w-full justify-start overflow-hidden text-ellipsis"
						childrenWrapperClassName="w-full justify-start overflow-hidden text-ellipsis"
						onClick={() => performDownload(document)}
					>
						<p className="overflow-hidden text-ellipsis">{document.name}</p>
					</Button>
				</div>
			))}
		</div>
	);
}

export function FolderDisplayItem({
	folder,
	columns,
	applicationDocumentService,
	expandOnlyOnIconClick = false,
	loading,
	expanded,
	defaultOpen,
	toggleExpanded
}: {
	folder: ApplicationDocumentFolder | ApplicationFormDocumentFolder;
	columns: ExpandableColumn[];
	applicationDocumentService: ApplicationDocumentService;
	expandOnlyOnIconClick?: boolean;
	loading?: boolean;
	expanded?: boolean;
	defaultOpen?: string[];
	toggleExpanded?: (expanded: boolean) => void;
}) {
	const hasDocuments = folder.documents.length > 0;
	const content = useMemo(
		() =>
			hasDocuments ? (
				<DocumentDisplayList
					applicationDocumentService={applicationDocumentService}
					documents={folder.documents}
				/>
			) : (
				<div className="px-4 py-2 font-body-regular-extra-small">
					Nenhum documento anexado nesta pasta.
				</div>
			),
		[folder.documents]
	);
	const id = useMemo(() => {
		if (folder.interventionDocumentFolderType instanceof Object) {
			return folder.interventionDocumentFolderType.id;
		}
		return folder.id;
	}, [folder.interventionDocumentFolderType]);

	return (
		<div className="gap-[1px]">
			<ExpandableLine
				id={`folder-${id}`}
				accordionItemClassName="folder-item"
				accordionTriggerClassName="folder-header"
				accordionContentClassName="folder-content"
				columns={columns}
				expandOnlyOnIconClick={expandOnlyOnIconClick}
				expanded={expanded}
				defaultOpen={defaultOpen}
				toggleExpanded={toggleExpanded}
			>
				{loading ? (
					<div className="py-2">
						<Loading />
					</div>
				) : (
					content
				)}
			</ExpandableLine>
		</div>
	);
}
