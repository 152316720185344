import { ReactNode, useEffect, useRef, useState } from "react";

export default function Drawer({
	showDrawer,
	children
}: Readonly<{
	showDrawer?: boolean;
	children: ReactNode;
}>): JSX.Element {
	const drawerRef = useRef<HTMLDivElement | null>(null);
	const [innerShowDrawer, setInnerShowDrawer] = useState<boolean>(!!showDrawer);
	useEffect(() => {
		if (showDrawer) {
			setInnerShowDrawer(true);
			setTimeout(() => {
				if (!drawerRef.current) return;
				const { current } = drawerRef;
				current.style.transform = "translateY(-20rem)";
			}, 50);
		} else {
			if (!drawerRef.current) return;
			const { current } = drawerRef;
			current.style.transform = "translateY(10rem)";
			setTimeout(() => setInnerShowDrawer(false), 150);
		}
	}, [showDrawer]);

	return (
		<>
			{innerShowDrawer && (
				<div className="overlay fixed top-0 left-0 w-full h-full z-index-2 bg-neutral-low-pure-500 opacity-15" />
			)}
			{innerShowDrawer && (
				<div
					ref={drawerRef}
					className="drawer fixed -bottom-80 left-0 w-full p-6 z-index-3 bg-neutral-high-pure-50 flex flex-col gap-6 rounded-t-xl transition-all ease-in-out"
				>
					<div className="absolute w-full left-0 top-0 p-2 flex justify-center items-center">
						<div className="h-1 w-10 rounded bg-neutral-high-200" />
					</div>
					{children}
				</div>
			)}
		</>
	);
}
