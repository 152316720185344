import { HeaderInfo } from "components";

import { IcoCalendar } from "assets/icons";

export default function PageSystemDisabled() {
	return (
		<div className="h-full">
			<HeaderInfo
				icon={<IcoCalendar size="160" />}
				title="Nosso novo canal de atendimento está chegando!"
				subtitle="Informamos que o site Faixa de Domínio estará disponível para utilização a partir do dia 22/04"
				className="h-screen px-5 py-0 pb-20 justify-center"
			/>
		</div>
	);
}
