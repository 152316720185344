import { FormikErrors } from "formik";
import { ChangeEvent, FocusEvent, FocusEventHandler, useCallback } from "react";

export type FieldSetter<Values> = (
	field: string,
	value: any,
	shouldValidate?: boolean
) => Promise<void | FormikErrors<Values>>;

export type TouchedSetter<Values> = (
	field: string,
	isTouched?: boolean,
	shouldValidate?: boolean
) => Promise<void | FormikErrors<Values>>;

export type Setter<V> = FieldSetter<V> | TouchedSetter<V>;

export function usePrefixedSetter<V>(
	prefix: string,
	setter: Setter<V>
): Setter<V> {
	return useCallback(
		(fieldName: string, newValue: unknown) =>
			setter(`${prefix}.${fieldName}`, newValue),
		[prefix, setter]
	);
}

type HtmlFieldElement =
	| HTMLInputElement
	| HTMLSelectElement
	| HTMLTextAreaElement;

export function useChangeHandler<V>(
	setFieldValue: FieldSetter<V>
): (
	event: ChangeEvent<HtmlFieldElement>,
	getMaskedValue?: (value: any) => any
) => void {
	return useCallback(
		(
			evt: ChangeEvent<HtmlFieldElement>,
			getMaskedValue?: (value: any) => any
		) => {
			setFieldValue(
				evt.target.name,
				getMaskedValue ? getMaskedValue(evt.target.value) : evt.target.value
			);
		},
		[setFieldValue]
	);
}

export function useBlurHandler<V>(
	setFieldTouched: TouchedSetter<V>
): FocusEventHandler<HtmlFieldElement> {
	return useCallback(
		(evt: FocusEvent<HtmlFieldElement>) => {
			setFieldTouched(evt.target.name, true);
		},
		[setFieldTouched]
	);
}

export function assignFieldValues<V>(
	fieldValueRecord: Record<string, unknown>,
	setFieldValue: FieldSetter<V>
): void {
	Object.entries(fieldValueRecord).forEach(([key, value]) => {
		setFieldValue(key, value);
	});
}
