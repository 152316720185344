const MaskHelper = {
	mixedCpfCnpj(cpfCnpj: string | null) {
		if (!cpfCnpj) {
			return cpfCnpj;
		}
		let value = cpfCnpj.replace(/\D/g, "").slice(0, 14);

		if (value.length <= 11) {
			value = value.replace(/(\d{3})(\d)/, "$1.$2");
			value = value.replace(/(\d{3})(\d)/, "$1.$2");
			value = value.replace(/(\d{3})(\d{1,2})$/, "$1-$2");
		} else {
			value = value.replace(/^(\d{2})(\d)/, "$1.$2");
			value = value.replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3");
			value = value.replace(/\.(\d{3})(\d)/, ".$1/$2");
			value = value.replace(/(\d{4})(\d)/, "$1-$2");
		}
		return value;
	},

	cpf(cpf: string | null) {
		if (!cpf) return cpf;

		let value = cpf.replace(/\D/g, "").slice(0, 11);
		value = value.replace(/(\d{3})(\d)/, "$1.$2");
		value = value.replace(/(\d{3})(\d)/, "$1.$2");
		value = value.replace(/(\d{3})(\d{1,2})$/, "$1-$2");
		return value;
	},

	cnpj(cnpj: string | null) {
		if (!cnpj) return cnpj;

		let value = cnpj.replace(/\D/g, "").slice(0, 14);
		value = value.replace(/^(\d{2})(\d)/, "$1.$2");
		value = value.replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3");
		value = value.replace(/\.(\d{3})(\d)/, ".$1/$2");
		value = value.replace(/(\d{4})(\d)/, "$1-$2");
		return value;
	},

	date(date: string | null) {
		if (!date) {
			return date;
		}
		return date
			.replace(/\D/g, "")
			.replace(/(\d{2})(\d{2})(\d{4}$)/, "$1/$2/$3")
			.substring(0, 10);
	},

	cep(cep: string | null) {
		if (!cep) {
			return cep;
		}
		return cep
			.replace(/\D/g, "")
			.replace(/(\d{5})(\d)/, "$1-$2")
			.substring(0, 9);
	},

	phone(value: string | null | null, country = "55") {
		if (!value) {
			return value;
		}

		const COUNTRY_BRAZIL = "55";
		const numbers = value.toString().replace(/\D/g, "");
		if (country.toString() === COUNTRY_BRAZIL) {
			let matches: RegExpMatchArray | null;
			if (numbers.length > 10) {
				matches = numbers.match(/(\d{0,2})(\d{0,5})(\d{0,4})/);
			} else {
				matches = numbers.match(/(\d{0,2})(\d{0,4})(\d{0,4})/);
			}
			if (matches) {
				const ddd = matches[1];
				const firstHalf = matches[2];
				const secondHalf = matches[3];

				if (numbers.length > 6) {
					return `(${ddd}) ${firstHalf}-${secondHalf}`;
				}

				if (numbers.length > 2) {
					return `(${ddd}) ${firstHalf}`;
				}

				return `(${ddd}`;
			}
		}
		return numbers;
	},

	cityRegistration(value: string | null) {
		if (!value) return value;
		const maxDigits = 8;
		const digitSequence = value
			.replace(/\D/g, "")
			.split("")
			.slice(0, maxDigits);

		return digitSequence.reduce((acc, val, idx) => {
			if (idx === 1 || idx === 4) {
				return `${acc}.${val}`;
			}
			if (idx === 7) {
				return `${acc}-${val}`;
			}
			return `${acc}${val}`;
		}, "");
	},

	stateRegistration(value: string | null) {
		if (!value) return value;

		const maxDigits = 12;
		const digitSequence = value
			.replace(/\D/g, "")
			.split("")
			.slice(0, maxDigits);
		return digitSequence.reduce((acc, val, idx) => {
			if (idx > 0 && idx % 3 === 0) {
				return `${acc}.${val}`;
			}
			return `${acc}${val}`;
		}, "");
	}
};

export default MaskHelper;
