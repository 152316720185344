import { ObjectWithAnyValues } from "utils/types";
/**
 * Base class that represents a resource from our backend.
 */
export class BaseModel<D extends ObjectWithAnyValues> {
	props: any;

	public constructor(data: D) {
		/* BaseModel deve ser abstract, mas isso impede os métodos createFromResponse e getValueOrNew */
		if (this.constructor === BaseModel) {
			throw new Error("Abstract classes can't be instantiated.");
		}

		this.props = data;
	}

	/**
	 * Parse self object of the class to a plain json object, with values accepted by the API.
	 */
	toJson(): any {
		return {
			...this
		};
	}

	/* Method to return an instance of Model for the data provided */
	static getValueOrNew(data: ObjectWithAnyValues, Model: any = this) {
		if (!data) {
			return data;
		}
		return data instanceof Model ? data : new Model(data);
	}

	/* Method to create an instance based on the response received from Backend */
	static createFromResponse(response: ObjectWithAnyValues, Model: any = this) {
		if (!response) {
			return response;
		}

		if (Model === this) {
			return new Model(response);
		}
		return Model.createFromResponse(response);
	}
}
