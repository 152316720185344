import { Constants } from "appConstants";
import { IcoHint } from "assets/icons";
import ObjectHelper from "helpers/ObjectHelper";
import { OptionLike } from "helpers/OptionHelper";
import { useCallback, useMemo } from "react";
import Select, {
	OptionProps as ReactSelectOptionProps,
	createFilter
} from "react-select";
import { Tooltip } from "react-tooltip";
import { ArrowDropdownIndicator, stylesSelect, themeSelect } from ".";
import { MultiSelectProps } from "./types";

export default function MultiSelect<O extends OptionLike, V = string[] | O[]>(
	props: Readonly<MultiSelectProps<O, V>>
): JSX.Element {
	const {
		label,
		className,
		name,
		tooltip,
		error,
		touched,
		short = false,
		noOptionsMessage = "Nenhuma opção",
		placeholder = "Selecione",
		setFieldValue,
		isDisabled,
		value,
		options,
		searchFields,
		getOptionValue,
		asSimpleValues = false,
		useOuterScroll = false,
		tooltipId
	} = props;

	const selectedValues = useMemo<O[]>(() => {
		if (!asSimpleValues) return value as O[];
		return (value as string[])
			?.map((v) => options.find((opt) => opt.value === v))
			.filter((i) => i !== undefined) as O[];
	}, [value, options, asSimpleValues]);

	const onSelectChange = useCallback(
		(newValue: readonly O[]): void => {
			const newFieldValues: V = (
				asSimpleValues ? newValue.map(getOptionValue) : [...newValue]
			) as V;
			setFieldValue(name, newFieldValues);
		},
		[name, asSimpleValues]
	);

	const filterConfig: any = {
		ignoreCase: true,
		ignoreAccents: true,
		trim: true,
		matchFrom: "any",
		stringify: (option: ReactSelectOptionProps) => {
			const { data } = option || {};
			if (!searchFields) return option.label;

			const dataValues = searchFields
				.map((fieldName: string) =>
					ObjectHelper.getPropertyFromObject(data, fieldName)
				)
				.join(" ");
			return dataValues;
		}
	};

	const innerTooltipId = useMemo(
		() => tooltipId ?? `tooltip-${Math.trunc(Math.random() * 100000)}`,
		[]
	);

	return (
		<div className={`container ${className}`}>
			{label && (
				<div className="flex gap-1 items-center pb-1">
					<label className="text-sm" htmlFor={name}>
						{label}
					</label>
					{tooltip && (
						<>
							<span id={innerTooltipId}>
								<IcoHint size="18" />
							</span>
							<Tooltip
								anchorSelect={`#${innerTooltipId}`}
								style={{ zIndex: Constants.zIndexes.tooltip }}
							>
								<div className="max-w-[96vw] md:max-w-[30rem] text-wrap">
									{tooltip}
								</div>
							</Tooltip>
						</>
					)}
				</div>
			)}
			<Select
				{...props}
				className={`basic-single ${error && touched ? "error" : ""} ${
					short ? "short" : ""
				}`}
				classNamePrefix="select"
				menuPlacement="auto"
				menuPosition="fixed"
				minMenuHeight={200}
				isDisabled={isDisabled || !setFieldValue}
				options={options}
				value={selectedValues}
				onChange={(n) => {
					onSelectChange(n as readonly O[]);
				}}
				components={{
					DropdownIndicator: ArrowDropdownIndicator as any,
					IndicatorSeparator: () => null
				}}
				styles={stylesSelect<OptionLike, true>(short, useOuterScroll)}
				theme={themeSelect()}
				noOptionsMessage={() => noOptionsMessage}
				placeholder={placeholder}
				filterOption={createFilter(filterConfig)}
				closeMenuOnSelect={false}
				isMulti
			/>
			{error && touched && (
				<div className="white-space--pre-line mt-1 text-xs">
					{Array.isArray(error) ? error.join("\n") : error}
				</div>
			)}
		</div>
	);
}
