import { BaseModel } from "../BaseModel";
import {
	FeatureFlag,
	LoggedUserData,
	UserAuthorities,
	UserOrigin
} from "./types";

export class LoggedUser extends BaseModel<LoggedUserData> {
	name: string;
	userOrigin: UserOrigin;
	email: string;
	authorities: UserAuthorities[];
	features: Record<FeatureFlag, boolean>;

	constructor(data: LoggedUserData) {
		super(data);
		this.name = data.name;
		this.userOrigin = data.userOrigin;
		this.email = data.email;
		this.authorities = data.authorities;
		this.features = data.features;
	}

	hasPermission(authority: UserAuthorities) {
		return this.authorities?.includes(authority);
	}

	hasBackofficePermission() {
		return this.hasPermission(UserAuthorities.ACESSO_SSO);
	}

	hasFeatureEnabled(feature: FeatureFlag) {
		return !!this.features?.[feature];
	}
}
