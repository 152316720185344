import { ButtonSegment } from "components/SegmentedButton/types";
import { Application } from "models/Application";

export type BackofficeApplicationPageParams = {
	applicationId: string;
	selectedTab: ApplicationPageTabs;
};

export enum ApplicationPageTabs {
	DETAILS = "detalhes",
	DOCUMENTS = "documentos",
	HISTORY = "historico",
	NEW_RECORD = "registro"
}

export interface TabComponentProps {
	application: Application | undefined;
	reloadApplication: () => void;
}
export interface TabContent extends ButtonSegment {
	component: (props: TabComponentProps) => JSX.Element;
}
