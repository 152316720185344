import { BaseModel } from "models/BaseModel";
import { useMemo } from "react";
import { ObjectWithAnyValues } from "utils/types";

type ModelConstructorSignature<D, T> = new (data: D) => T;

export function useDataModel<
	D extends ObjectWithAnyValues,
	T extends BaseModel<D>
>(modelData: D | null, modelClass: ModelConstructorSignature<D, T>): T | null {
	return useMemo(
		() => (modelData ? new modelClass(modelData) : null),
		[modelData, modelClass]
	);
}
