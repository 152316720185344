import { IcoFeedbackCheck } from "assets/icons";
import { Button } from "components";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ApplicationFormData } from "../steps/types";

export function NewApplicationSuccess({
	savedApplicationData
}: Readonly<{
	savedApplicationData: ApplicationFormData | null;
}>): JSX.Element {
	const navigate = useNavigate();

	useEffect(() => {
		if (savedApplicationData === null) {
			navigate("/home");
		}
	}, [navigate, savedApplicationData]);

	return savedApplicationData ? (
		<div className="flex flex-col h-full justify-start items-center">
			<div className="w-full sm:w-[26.5rem] flex flex-col gap-10 p-10 pb-8 mt-10 justify-start bg-neutral-high-pure-50 rounded">
				<IcoFeedbackCheck size="40" />
				<div className="w-full flex flex-col gap-3">
					<h1 className="text-3xl font-semibold">Solicitação enviada</h1>
					<p>
						Sua intervenção foi solicitada com sucesso. Enviamos para seu e-mail
						um resumo da solicitação e o prazo para análise iniciará no próximo
						dia útil.
					</p>
					<p>
						<span>Nº do processo: </span>{" "}
						<strong>{savedApplicationData.applicationProcessCode}</strong>
					</p>
				</div>
				<div className="flex flex-col gap-2 w-full">
					<Button
						hierarchy="primary"
						onClick={() => navigate(`/solicitacao/${savedApplicationData.id}`)}
					>
						Exibir solicitação
					</Button>
					<Button hierarchy="ghost" onClick={() => navigate("/")}>
						Voltar para tela inicial
					</Button>
				</div>
			</div>
		</div>
	) : (
		<div />
	);
}
