import clsx from "clsx";
import { AnimatePresence, motion } from "framer-motion";
import { BaseModalProps } from "./types";

import "./styles.scss";

const transition = { duration: 0.2, ease: "easeInOut" };
const modalVariants = {
	open: {
		y: "0%",
		opacity: 1,
		translateX: "-50%",
		translateY: "-50%",
		transition: { ...transition, delay: 0.25 }
	},
	closed: {
		y: "100%",
		opacity: 0,
		translateX: "-50%",
		translateY: "-50%",
		transition
	}
};

const overlayVariants = {
	open: { opacity: 1, transition },
	closed: { opacity: 0, transition: { ...transition, delay: 0.25 } }
};

export default function BaseModal({
	isOpen,
	contentClassName,
	bodyClassName,
	onClickOutsideModal,
	children
}: Readonly<BaseModalProps>) {
	return (
		<AnimatePresence>
			{isOpen && (
				<div className="container-modal-gateway">
					<motion.div
						initial="closed"
						animate="open"
						exit="closed"
						variants={modalVariants}
						className={clsx(
							"container-modal-gateway__content",
							contentClassName
						)}
					>
						<div
							className={clsx("container-modal-gateway__body", bodyClassName)}
						>
							{children}
						</div>
					</motion.div>
					<motion.div
						initial="closed"
						animate="open"
						exit="closed"
						variants={overlayVariants}
						className="container-modal-gateway__overlay"
						onClick={() => onClickOutsideModal?.()}
					/>
				</div>
			)}
		</AnimatePresence>
	);
}
