import { baseConfig } from "config";
import { ErrorHelper } from "helpers";
import AuthTokenHelper from "helpers/AuthTokenHelper";
import { LoggedUser } from "models/auth/LoggedUser";
import { LoggedUserData } from "models/auth/types";
import { toast } from "react-toastify";
import { BaseServices } from "./baseServices";

export class BackofficeAuthService extends BaseServices<
	LoggedUserData,
	LoggedUser
> {
	constructor() {
		super(LoggedUser);
	}

	async loginAD() {
		AuthTokenHelper.removeAuthToken();

		const redirectURI = `${window.location.protocol}//${window.location.host}`;
		const loginURL = `${baseConfig.baseURL}/oauth2/authorize/azure?redirect_uri=${redirectURI}/backoffice/`;
		window.location.assign(loginURL);
	}

	async logout() {
		return this.get("auth/logout/").catch((error) =>
			toast.error(ErrorHelper.getResponseErrorMessage(error))
		);
	}

	static getInstance() {
		return new BackofficeAuthService();
	}
}
