import { useCallback, useRef, useState } from "react";

export default function useStabilizedState<T>(
	initialValue: T | (() => T),
	delay = 500
): {
	unstableValue: T;
	stableValue: T;
	setUnstableValue: (newValue: T) => void;
	setValue: (newValue: T) => void;
} {
	const [unstableValue, setUnstableValue] = useState<T>(initialValue);
	const [stableValue, setStableValue] = useState<T>(unstableValue);

	const timeoutRef = useRef<ReturnType<typeof setTimeout>>();

	const setValue = useCallback(
		(newValue: T) => {
			if (timeoutRef.current) clearTimeout(timeoutRef.current);

			setUnstableValue(newValue);
			timeoutRef.current = setTimeout(() => setStableValue(newValue), delay);
		},
		[setUnstableValue, delay]
	);

	return { unstableValue, stableValue, setValue, setUnstableValue };
}
