import LocalStorageHelper from "helpers/LocalStorageHelper";
import { ApplicationDetail, NewApplication } from "pages";
import ApplicationAnalyses from "pages/ApplicationAnalyses";
import { NewApplicationSuccess } from "pages/NewApplication/outcomes/success";
import { ApplicationFormData } from "pages/NewApplication/steps/types";
import { Route, Routes } from "react-router-dom";

export default function ApplicationRoutes(): JSX.Element {
	const [savedApplicationData, setSavedApplicationData] =
		LocalStorageHelper.useLocalStorageState<ApplicationFormData>(
			LocalStorageHelper.applicationDraftKey
		);

	return (
		<Routes>
			<Route
				path="/sucesso"
				element={
					<NewApplicationSuccess savedApplicationData={savedApplicationData} />
				}
			/>
			<Route
				path="/:applicationId/analises"
				element={<ApplicationAnalyses />}
			/>
			<Route path="/:applicationId" element={<ApplicationDetail />} />
			<Route
				path="/"
				element={
					<NewApplication
						savedApplicationData={savedApplicationData}
						setSavedApplicationData={setSavedApplicationData}
					/>
				}
			/>
		</Routes>
	);
}
