import { Button, ModalRedirect, WrapperGeneral } from "components";

import { IcoExternalLink } from "assets/icons";
import { LogoWhite } from "assets/images";
import { useCallback, useState } from "react";

import { Constants } from "appConstants";
import EulaModal, { EulaSections } from "components/EulaModal";
import { ExternalAuthService } from "services/externalAuth";
import "./styles.scss";

export default function Footer() {
	const [redirectModalOpen, setRedirectModalOpen] = useState<boolean>(false);
	const [isEulaModalOpen, setIsEulaModalOpen] = useState<boolean>(false);
	const [selectedEulaSection, setSelectedEulaSection] = useState<EulaSections>(
		EulaSections.TERMS
	);
	const extAuthService = new ExternalAuthService();

	const openEulaSection = useCallback((section: EulaSections) => {
		setIsEulaModalOpen(true);
		setSelectedEulaSection(section);
	}, []);

	const handleRedirect = () => {
		setRedirectModalOpen(true);
		setTimeout(() => extAuthService.loginB2C(), 1000);
	};

	return (
		<>
			<div className="container-footer">
				<div className="content-list-links">
					<WrapperGeneral>
						<div className="links">
							<h3 className="headline-3">CCR Faixa de Domínio</h3>
							<Button onClick={handleRedirect} kind="link" hierarchy="ghost">
								Acessar plataforma <IcoExternalLink size="16" color="#fff" />
							</Button>
							<Button kind="link" hierarchy="ghost" onClick={handleRedirect}>
								Acompanhar minhas solicitações
								<IcoExternalLink size="16" color="#fff" />
							</Button>
						</div>
						<div className="links">
							<h3 className="headline-3">Institucional</h3>
							<Button kind="link" hierarchy="ghost">
								<a
									href="https://rodovias.grupoccr.com.br/"
									target="_blank"
									rel="noreferrer"
								>
									CCR Rodovias
								</a>
								<IcoExternalLink size="16" color="#fff" />
							</Button>
							<Button kind="link" hierarchy="ghost">
								<a
									href="https://www.grupoccr.com.br/"
									target="_blank"
									rel="noreferrer"
								>
									Grupo CCR
								</a>
								<IcoExternalLink size="16" color="#fff" />
							</Button>
						</div>
					</WrapperGeneral>
				</div>
				<div className="content-about">
					<WrapperGeneral>
						<div className="links-shared gap-8">
							<div className="mt-10">
								<div className="logo">
									<img src={LogoWhite} alt="Logo CCR" />
								</div>
								<div className="address-link">
									<address className="body-1 not-italic">
										<br />
										Avenida Professora Maria do Carmo Guimaraes Pellegrini, 200,
										Bloco A B C D - Retiro, Jundiaí - SP, 13209-500
									</address>
									<ul>
										<li>
											<Button
												kind="link"
												hierarchy="ghost"
												onClick={() => openEulaSection(EulaSections.TERMS)}
											>
												Termos de uso
											</Button>
										</li>
										<li>•</li>
										<li>
											<Button
												kind="link"
												hierarchy="ghost"
												onClick={() => openEulaSection(EulaSections.PRIVACY)}
											>
												Política de privacidade
											</Button>
										</li>
										<li>•</li>
										<li>
											<Button
												kind="link"
												hierarchy="ghost"
												onClick={() => openEulaSection(EulaSections.COOKIES)}
											>
												Política de cookies
											</Button>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</WrapperGeneral>
				</div>
				<div className="content-copyright">
					<WrapperGeneral>
						<p className="body-1">
							© CCR Rodovias - CNPJ: 02.846.056/0003-59 – Todos os direitos
							reservados.
						</p>
					</WrapperGeneral>
				</div>
				<ModalRedirect
					isOpen={redirectModalOpen}
					text={Constants.textRedirectLogin}
				/>
			</div>
			<EulaModal
				displayedPolicy={selectedEulaSection}
				isOpen={isEulaModalOpen}
				onClose={() => setIsEulaModalOpen(false)}
			/>
		</>
	);
}
