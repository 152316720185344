import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";

interface ApplicationRegistryFilters extends Record<string, unknown> {
	from: string;
	to: string;
	lastAnalysisStatuses?: string[];
	interventionTypes: string[];
	concessionaires: string[];
	responsibleGroups: string[];
}

export const initialStatebackofficeRegistryFilters: ApplicationRegistryFilters =
	{
		from: "",
		to: "",
		lastAnalysisStatuses: [],
		interventionTypes: [],
		concessionaires: [],
		responsibleGroups: []
	};

export const backofficeRegistryFiltersSlice = createSlice({
	name: "backofficeRegistryFilters",
	initialState: initialStatebackofficeRegistryFilters,
	reducers: {
		setRegistryFilters: (
			state,
			action: PayloadAction<ApplicationRegistryFilters>
		) => {
			state.from = action.payload.from;
			state.to = action.payload.to;
			state.lastAnalysisStatuses = action.payload.lastAnalysisStatuses;
			state.interventionTypes = action.payload.interventionTypes;
			state.concessionaires = action.payload.concessionaires;
			state.responsibleGroups = action.payload.responsibleGroups;
		},
		resetRegistryFilters: () => initialStatebackofficeRegistryFilters
	}
});

export const { setRegistryFilters, resetRegistryFilters } =
	backofficeRegistryFiltersSlice.actions;
export default backofficeRegistryFiltersSlice.reducer;
