import { Helmet } from "react-helmet-async";
import { useLocation } from "react-router-dom";

import favicon from "assets/icons/favicon.png";
import {
	BoxHowItWorks,
	BoxPlatformAcess,
	Button,
	ModalRedirect
} from "components";

import { Constants } from "appConstants";
import { IcoExternalLink } from "assets/icons";
import { HomeHero } from "assets/images";
import colors from "colors";
import { SectionHelper } from "helpers";
import { useIsMobile } from "hooks/useIsMobile";
import { useEffect, useState } from "react";
import { ExternalAuthService } from "services/externalAuth";
import "./styles.scss";

export default function Home() {
	const location = useLocation();
	const extAuthService = new ExternalAuthService();
	const isMobile = useIsMobile();

	const [redirectOpen, setRedirectOpen] = useState<boolean>(false);

	const handleRedirect = () => {
		setRedirectOpen(true);
		setTimeout(() => extAuthService.loginB2C(), 1000);
	};

	useEffect(() => {
		setTimeout(() => {
			if (location.hash) {
				const idSection = SectionHelper.splitHash(location.hash);
				SectionHelper.goToSection(idSection, isMobile ? 64 : 96);
			}
		}, 200);
	}, [location]);

	return (
		<>
			<Helmet>
				<title>{`${process.env.REACT_APP_TITLE} - Página inicial`}</title>
				<link rel="shortcut icon" href={favicon} type="image/x-icon" />
				<link rel="icon" href={favicon} type="image/x-icon" />
			</Helmet>
			<div className="container-common">
				<div className="flex justify-between items-end mx-auto px-6 mb-8 max-sm:flex-col max-w-[85rem] max-sm:items-start">
					<div>
						<h1 className="max-w-[52rem] text-3xl sm:text-7xl font-light sm:font-extralight mt-32 sm:mt-40">
							Sistema de gestão para Faixa de Domínio
						</h1>
						<p className="text-lg mt-2 sm:mt-4 max-sm:text-neutral-low-400">
							Solicite uma nova intervenção de faixa de domínio em rodovias sob
							a concessão da CCR.
						</p>
					</div>
					<Button
						kind="link"
						hierarchy="ghost"
						className="cursor-pointer flex items-center mt-8 no-underline"
						onClick={handleRedirect}
					>
						<p className="font-bold mr-2">Acessar a plataforma</p>
						<div className="bg-neutral-low-pure-500 rounded-full w-6 h-6 flex justify-center items-center">
							<IcoExternalLink
								color={colors.neutral["high-pure-50"]}
								size="16"
							/>
						</div>
					</Button>
				</div>
				<div className="h-[15rem] md:h-[34rem] w-screen">
					<img
						src={HomeHero}
						alt="Faixa de domínio"
						className="w-full h-full object-cover object-center"
					/>
				</div>
			</div>
			<BoxHowItWorks />
			<BoxPlatformAcess handleRedirect={handleRedirect} />
			<ModalRedirect isOpen={redirectOpen} text={Constants.textRedirectLogin} />
		</>
	);
}
