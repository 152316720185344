import React, { ReactNode } from "react";
import "./styles.scss";

export type FieldsSection = {
	title: string;
	nameValueFields: NameValueField[];
};

type NameValueField = {
	name?: string | ReactNode;
	value?: string | null;
	condition?: boolean;
};

function NameValueDisplay({ name, value, condition = true }: NameValueField) {
	return condition ? (
		<div className="name-value-display">
			<div className="name-display">{name || ""}</div>
			<div className="value-display">{value || "-"}</div>
		</div>
	) : null;
}

export default function ApplicationDetailData({
	applicationData
}: {
	applicationData: FieldsSection[];
}) {
	return (
		<div>
			{applicationData.map((section, index) => (
				<React.Fragment key={`display-data-${index}`}>
					{section.title && (
						<div className="data-section-title">{section.title}</div>
					)}
					{section.nameValueFields.map((field, valueIndex) => (
						<NameValueDisplay
							key={`${field.name}-${valueIndex}`}
							name={field.name}
							value={field.value}
							condition={field.condition}
						/>
					))}
				</React.Fragment>
			))}
		</div>
	);
}
