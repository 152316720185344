import { AxiosInstance } from "axios";
import MockAdapter from "axios-mock-adapter";
import { interventionApplicationMock } from "./interventionApplicationMock";
import { searchMock } from "./searchMock";

const useMock = (axios: AxiosInstance) => {
	const mock = new MockAdapter(axios);

	mock.onPost("search/").reply(200, searchMock);
	mock.onGet("application").reply(200, interventionApplicationMock);
};

export default useMock;
