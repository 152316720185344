import { IcoEdit, IcoRoute } from "assets/icons";
import { InputHTMLAttributes } from "react";

type SelectedDisplayProps = InputHTMLAttributes<HTMLInputElement> & {
	text: string;
	selected: string;
	isIconVisible?: boolean;
	isEdit?: boolean;
	onGoBack?: () => void;
};

export default function SelectedDisplay({
	text,
	selected,
	isIconVisible,
	isEdit,
	onGoBack
}: SelectedDisplayProps): JSX.Element {
	return (
		<>
			{isIconVisible && <IcoRoute />}
			<div className="grow">
				<p className="text-xs leading-3 pb-2">{text}</p>
				<h1 className="text-sm font-semibold leading-[0.875rem]">{selected}</h1>
			</div>
			{isEdit && (
				<div className="cursor-pointer" onClick={onGoBack}>
					<IcoEdit />
				</div>
			)}
		</>
	);
}
