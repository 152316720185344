import clsx from "clsx";

import { IcoCheckbox, IcoMinus } from "assets/icons";
import { InputHTMLAttributes } from "react";
import { CheckboxProps } from "./types";

import "./styles.scss";

const checkboxColors = {
	disabled: "#595959",
	error: "#E50000",
	partialSelected: "#1D1D1D"
};

export default function Checkbox({
	name,
	id,
	label,
	value,
	checked,
	disabled,
	onChange,
	error,
	touched,
	onBlur,
	className,
	iconSize,
	labelSpanClassName,
	partialSelected
}: Readonly<CheckboxProps & InputHTMLAttributes<HTMLInputElement>>) {
	const color = disabled
		? checkboxColors.disabled
		: error && touched
		? checkboxColors.error
		: undefined;

	return (
		<div
			className={clsx(
				"container-checkbox",
				disabled ? "hover:cursor-not-allowed" : "hover:cursor-pointer",
				{
					error: error && touched
				},
				className
			)}
		>
			<input
				type="checkbox"
				id={id}
				name={name}
				value={value}
				checked={checked}
				disabled={disabled}
				onChange={onChange}
				onBlur={onBlur}
				hidden
			/>
			<label
				className="flex flex-row items-center gap-2 border-2 border-solid rounded"
				htmlFor={id}
			>
				{partialSelected ? (
					<IcoMinus
						size={iconSize}
						checked={checked}
						color={checkboxColors.partialSelected}
					/>
				) : (
					<IcoCheckbox size={iconSize} checked={checked} color={color} />
				)}
				<span
					className={clsx("flex-1", {
						[labelSpanClassName || ""]: !!labelSpanClassName,
						"text-sm": !labelSpanClassName
					})}
				>
					{label}
				</span>
			</label>
			{error && touched && (
				<div className="white-space--pre-line mt-1 text-xs">
					{Array.isArray(error) ? error.join("\n") : error}
				</div>
			)}
		</div>
	);
}
