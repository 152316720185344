import { Button, Modal, ModalFooter } from "components";
import { useCurrentDraft } from "hooks/useCurrentDraft";

export function ExistingDraftModal({
	isOpen,
	onClose,
	onContinueToNewApplication,
	onDeleteDraft
}: {
	isOpen: boolean;
	onClose: () => void;
	onContinueToNewApplication: () => void;
	onDeleteDraft: () => void;
}) {
	return (
		<Modal
			isOpen={isOpen}
			onClose={onClose}
			contentClassName="rounded-lg md:max-w-xl"
			bodyClassName="p-8"
			headerText="Olá, seja bem vindo!"
		>
			<div>
				Notamos que você já iniciou o preenchimento de uma solicitação, mas não
				concluiu. Caso deseje prosseguir de onde parou, clique no botão
				<strong> Continuar </strong> logo abaixo.
			</div>
			<ModalFooter>
				<Button
					hierarchy="secondary"
					size="medium"
					className="w-full md:w-auto"
					onClick={() => onDeleteDraft()}
				>
					Reiniciar
				</Button>
				<Button
					hierarchy="primary"
					size="medium"
					className="w-full md:w-auto"
					onClick={() => onContinueToNewApplication()}
				>
					Continuar
				</Button>
			</ModalFooter>
		</Modal>
	);
}

export function NewApplicationButton({
	label = "Solicitar intervenção",
	leading,
	disabled
}: {
	label?: string;
	leading?: string | React.ReactElement;
	disabled?: boolean;
}) {
	const {
		isLoading,
		checkExistingDraft,
		deleteDraft,
		goToNewApplication,
		isOpen,
		onClose
	} = useCurrentDraft();

	return (
		<>
			<Button
				hierarchy="primary"
				size="small"
				onClick={checkExistingDraft}
				leading={leading}
				isLoading={isLoading}
				disabled={disabled}
			>
				{label}
			</Button>
			<ExistingDraftModal
				isOpen={isOpen}
				onClose={onClose}
				onContinueToNewApplication={goToNewApplication}
				onDeleteDraft={deleteDraft}
			/>
		</>
	);
}

export default ExistingDraftModal;
