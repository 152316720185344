import { BaseModal, Loading } from "components";

import { ModalRedirectProps } from "./types";

export default function ModalRedirect({ text, isOpen }: ModalRedirectProps) {
	return (
		<BaseModal
			isOpen={isOpen}
			bodyClassName="justify-center items-center text-center"
		>
			<Loading size={64} type="secondary" />
			<h3 className="headline-3 text-neutral-low-pure-500 font-normal">
				{text}
			</h3>
		</BaseModal>
	);
}
