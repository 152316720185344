import Button from "components/Button";
import Modal from "components/Modal";
import ModalFooter from "components/ModalFooter";

import CookiePolicy from "./CookiePolicy";
import PrivacyPolicy from "./PrivacyPolicy";
import TermsOfUse from "./TermsOfUse";

export enum EulaSections {
	TERMS = "TERMS",
	PRIVACY = "PRIVACY",
	COOKIES = "COOKIES"
}

const eulaSectionTitles: Record<EulaSections, string> = {
	[EulaSections.COOKIES]: "Política de cookies",
	[EulaSections.PRIVACY]: "Política de privacidade",
	[EulaSections.TERMS]: "Termos de uso"
};

const eulaSectionComponents: Record<EulaSections, () => JSX.Element> = {
	[EulaSections.COOKIES]: CookiePolicy,
	[EulaSections.TERMS]: TermsOfUse,
	[EulaSections.PRIVACY]: PrivacyPolicy
};

export default function EulaModal({
	isOpen,
	onClose,
	displayedPolicy
}: Readonly<{
	isOpen: boolean;
	onClose: () => void;
	displayedPolicy: EulaSections;
}>): JSX.Element {
	const title = eulaSectionTitles[displayedPolicy];
	const ContentComponent = eulaSectionComponents[displayedPolicy];
	return (
		<Modal
			isOpen={isOpen}
			onClose={onClose}
			headerText={title}
			contentClassName="max-h-[98vh] md:max-h-[90vh] max-w-[98vw] md:max-w-[60%] w-fit-content rounded-lg shadow p-8"
			bodyClassName="w-full h-full p-0"
		>
			<div className="w-full h-full flex flex-col items-stretch">
				<ContentComponent />
			</div>
			<ModalFooter>
				<Button onClick={onClose} hierarchy="primary">
					Fechar
				</Button>
			</ModalFooter>
		</Modal>
	);
}
