import axios, { AxiosRequestConfig } from "axios";
import { AddressDataResponse } from "./types";

const CEP_API_URL = "https://ws.apicep.com/cep/";

const api = axios.create({
	baseURL: CEP_API_URL
});

const get = (url: string, config?: AxiosRequestConfig) =>
	api.get<AddressDataResponse>(url, config).then((response) => response.data);

const AddressService = {
	getAddress(cep: string) {
		return get(`/${cep}.json`);
	}
};

export default AddressService;
