import { IconProps } from "./types";

export default function IcoFeedbackCheck(
	props: Readonly<IconProps>
): JSX.Element {
	const { size = 128 } = props;
	return (
		<svg
			width={size}
			height={size}
			viewBox="0 0 128 128"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<rect width="128" height="128" rx="64" fill="#00D4A1" />
			<path
				d="M90.6666 44L53.9999 80.6667L37.3333 64"
				stroke="white"
				strokeWidth="4"
				strokeLinecap="square"
			/>
		</svg>
	);
}
