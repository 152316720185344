import { useState } from "react";
import { useMediaQuery } from "react-responsive";
import { Link, useLocation } from "react-router-dom";

import { Button, ModalRedirect, WrapperGeneral } from "components";

import { Logo } from "assets/images";

import { Constants } from "appConstants";
import { SectionHelper } from "helpers";
import { ExternalAuthService } from "services/externalAuth";
import "./styles.scss";
import { MenuProps } from "./types";

function Menu({ isMobile, onClose }: MenuProps) {
	const location = useLocation();
	const isHome = location.pathname === "/";

	const handleCloseSideMenu = () => {
		if (onClose) {
			onClose();
		}
	};

	return (
		<li>
			{isHome ? (
				<Button
					kind="link"
					hierarchy="ghost"
					onClick={() => {
						handleCloseSideMenu();
						SectionHelper.goToSection("como-funciona", isMobile ? 64 : 96);
					}}
					className="no-underline"
				>
					Como funciona
				</Button>
			) : (
				<Link to="/#como-funciona" onClick={handleCloseSideMenu}>
					Como funciona
				</Link>
			)}
		</li>
	);
}

function Account() {
	const [redirectModalOpen, setRedirectModalOpen] = useState<boolean>(false);
	const extAuthService = new ExternalAuthService();

	const handleRedirect = () => {
		setRedirectModalOpen(true);
		setTimeout(() => extAuthService.loginB2C(), 1000);
	};

	return (
		<>
			<Button
				kind="link"
				hierarchy="ghost"
				onClick={handleRedirect}
				className="no-underline min-w-fit"
			>
				Entrar
			</Button>
			<ModalRedirect
				isOpen={redirectModalOpen}
				text={Constants.textRedirectLogin}
			/>
		</>
	);
}

export default function Header() {
	const [toggleSideMenu, setToggleSideMenu] = useState<boolean>(false);
	const isMobile = useMediaQuery({ query: "(max-width: 768px)" });

	const handleToggleSideMenu = () => {
		if (!toggleSideMenu) {
			document.body.style.overflow = "hidden";
		} else {
			document.body.style.overflow = "auto";
		}
		setToggleSideMenu(!toggleSideMenu);
	};

	return (
		<header className="header">
			<WrapperGeneral>
				<div className="flex items-center">
					<div className="block md:hidden" />
					<Link to="/">
						<img className="logo" src={Logo} alt="CCR" />
					</Link>
				</div>
				<nav>
					<ul className="hidden md:flex">
						<Menu isMobile={isMobile} onClose={() => handleToggleSideMenu} />
					</ul>
					<div className="account-wrapper">
						<Account />
					</div>
				</nav>
			</WrapperGeneral>
		</header>
	);
}
