import colors from "colors";
import { IconProps } from "./types";

export default function IcoArrowDown({
	color = colors.neutral["low-pure-500"],
	size = "20"
}: IconProps) {
	return (
		<svg
			width={size}
			height={size}
			viewBox="0 0 24 25"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M12 4.5L12 20.5M12 20.5L4 12.5M12 20.5L20 12.5"
				stroke={color}
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
}
