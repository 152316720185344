import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import { NewRecordForm } from "pages/Backoffice/ApplicationPage/tabs/NewRecord";

type NewRecordStateType = {
	applicationId?: string;
} & NewRecordForm;

export const initialStateNewRecord: NewRecordStateType = {
	applicationId: "",
	action: null,
	status: null,
	comment: "",
	commentDocuments: [],
	subject: "",
	response: "",
	responseDocuments: []
};

export const newRecordSlice = createSlice({
	name: "newRecord",
	initialState: initialStateNewRecord,
	reducers: {
		setValues: (state, action: PayloadAction<NewRecordStateType>) => {
			state.applicationId = action.payload.applicationId;
			state.action = action.payload.action;
			state.status = action.payload.status;
			state.comment = action.payload.comment;
			state.commentDocuments = action.payload.commentDocuments;
			state.subject = action.payload.subject;
			state.response = action.payload.response;
			state.responseDocuments = action.payload.responseDocuments;
		},
		reset: () => initialStateNewRecord
	}
});

export const { setValues, reset } = newRecordSlice.actions;
export default newRecordSlice.reducer;
