import { Constants } from "appConstants";
import clsx from "clsx";
import StatusTag from "components/StatusTag";
import { DateTimeHelper } from "helpers";
import { ApplicationEventType, ApplicationStatus } from "models/types";
import React from "react";
import { AnalysisStatusLabel, ApplicationAnalysisData } from "services/types";

interface ApplicationListItemProps {
	analysis: ApplicationAnalysisData;
	selectAnalysisEvent: (eventId: string) => void;
	selectedEventId: string | null;
}

export default function AnalysisListItem({
	analysis,
	selectAnalysisEvent,
	selectedEventId
}: Readonly<ApplicationListItemProps>): JSX.Element {
	const { events } = analysis;
	return (
		<div
			className={clsx([
				"flex flex-col border-neutral-high-200 border rounded-lg overflow-hidden"
			])}
		>
			{events.map((analysisEvent, idx) => {
				const hasEndDate = !!analysis.analysisEndDatetime;
				const applicationCancelled =
					analysis.applicationStatus === ApplicationStatus.CANCELADO;
				const currentEventIsCancellation =
					analysisEvent.type === ApplicationEventType.SOLICITACAO_CANCELADA;
				const newestEventIsCancellation =
					events[0].type === ApplicationEventType.SOLICITACAO_CANCELADA;
				const isOldestEvent = idx === events.length - 1;
				const isIndexShowEndDate =
					applicationCancelled && newestEventIsCancellation
						? idx === 1
						: idx === 0;

				const showStartDate = isOldestEvent;
				const showEndDate =
					hasEndDate &&
					isIndexShowEndDate &&
					!isOldestEvent &&
					!currentEventIsCancellation;
				const showCancelDate =
					currentEventIsCancellation && analysisEvent.createdAt;
				const createdByApplicant =
					analysisEvent.createdByGroup === Constants.UserRoleGroups.SOLICITANTE;
				const showStatusTag =
					!hasEndDate ||
					!createdByApplicant ||
					(showCancelDate && analysis.status === AnalysisStatusLabel.CANCELADO);
				return (
					<React.Fragment key={analysisEvent.id}>
						{idx > 0 && <div className="w-full h-[1px] bg-neutral-high-200" />}
						<button
							className={clsx(
								"p-3 w-full flex flex-col gap-2 cursor-pointer text-left hover:bg-neutral-high-200",
								{
									"bg-neutral-high-100": analysisEvent.id === selectedEventId
								}
							)}
							onClick={() => selectAnalysisEvent(analysisEvent.id)}
						>
							<div className="flex justify-between items-center w-full">
								<div className="flex flex-col items-start overflow-hidden mr-2">
									<strong className="text-align-left overflow-hidden text-ellipsis w-full text-nowrap md:text-base text-sm">
										{analysisEvent.title}
									</strong>
									<span className="md:text-sm text-xs">
										Protocolo: {analysis.analysisCode}
									</span>
								</div>
								<div className="md:w-[45%] flex justify-end">
									{showStatusTag && (
										<StatusTag status={analysis.status} small />
									)}
								</div>
							</div>

							{showCancelDate && (
								<div className="text-sm">
									{`Data de cancelamento: ${DateTimeHelper.formatDateString(
										analysisEvent.createdAt
									)}`}
								</div>
							)}
							{showEndDate && analysis.analysisEndDatetime && (
								<div className="md:text-sm text-xs">
									{`Data de conclusão: ${DateTimeHelper.formatDateString(
										analysis.analysisEndDatetime
									)}`}
								</div>
							)}
							{showStartDate && (
								<div className="md:text-sm text-xs">
									{`Data de início: ${DateTimeHelper.formatDateString(
										analysis.analysisStartDatetime
									)}`}
								</div>
							)}
						</button>
					</React.Fragment>
				);
			})}
		</div>
	);
}
