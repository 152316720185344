import { IcoArrowRight } from "assets/icons";
import { WrapperGeneral } from "components";
import { useMediaQuery } from "react-responsive";

export default function BoxPlatformAccess({
	handleRedirect
}: {
	handleRedirect: () => void;
}) {
	const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
	return (
		<div className="container-common pb-20">
			<WrapperGeneral>
				<div
					onClick={handleRedirect}
					className="w-full border cursor-pointer border-neutral-high-200 px-8 py-10 flex justify-between"
				>
					<div>
						<h5 className="text-lg md:text-[1.75rem] md:leading-[2.275rem] mb-2">
							Acessar a plataforma
						</h5>
						<p className="text-sm md:text-lg text-neutral-low-400">
							Clique aqui para se cadastrar e acessar a plataforma de Faixa de
							Domínio
						</p>
					</div>
					<div>
						<IcoArrowRight size={isMobile ? "24" : "48"} />
					</div>
				</div>
			</WrapperGeneral>
		</div>
	);
}
