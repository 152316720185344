import { Constants } from "appConstants";
import { IcoArrowLeft, IcoClose } from "assets/icons";
import clsx from "clsx";
import { BaseModal, Button, ModalFooter, TextArea } from "components";
import { ErrorHelper } from "helpers";
import { useIsMobile } from "hooks/useIsMobile";
import { ApplicationEventType } from "models/types";
import { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { ApplicationService } from "services/applicationService";
import "./styles.scss";

const minLength = Constants.MIN_APPLICATION_CANCELLATION_LENGTH;

export default function CancelApplicationModal({
	applicationId,
	isOpen,
	onClose
}: {
	applicationId: string;
	isOpen: boolean;
	onClose: () => void;
}) {
	const navigate = useNavigate();
	const isMobile = useIsMobile();
	const applicationService = useMemo(ApplicationService.getInstance, []);
	const [description, setDescription] = useState("");
	const [loadingCancel, setLoadingCancel] = useState(false);

	const cancelApplication = async () => {
		setLoadingCancel(true);
		try {
			await applicationService.registerEvent(applicationId, {
				event: {
					type: ApplicationEventType.SOLICITACAO_CANCELADA,
					description
				}
			});
			toast.success("Solicitação cancelada com sucesso");
			navigate("/");
		} catch (error) {
			toast.error(ErrorHelper.getResponseErrorMessage(error));
		} finally {
			setLoadingCancel(false);
		}
	};

	return (
		<BaseModal
			onClickOutsideModal={onClose}
			isOpen={isOpen}
			contentClassName="cancel-application-modal-content"
			bodyClassName="cancel-application-modal-body"
		>
			{isMobile && (
				<div className="flex justify-start px-6 py-2 border-b-2 border-neutral-high-100">
					<button onClick={onClose} aria-label="voltar">
						<IcoArrowLeft size="24" />
					</button>
				</div>
			)}
			<div className="flex justify-between items-center px-6">
				<div className="font-heading-semibold-tiny">Cancelar solicitação</div>
				{!isMobile && (
					<Button kind="icon" hierarchy="ghost" onClick={onClose}>
						<IcoClose />
					</Button>
				)}
			</div>
			<div className="flex flex-col gap-6 px-6 overflow-y-auto">
				<div
					className={clsx("font-neutral-low-400", {
						"font-body-regular-small": isMobile
					})}
				>
					Tem certeza de que deseja cancelar esta solicitação? Esta é uma
					operação irreversível e não será possível desfazê-la posteriormente.
				</div>
				<TextArea
					label="Motivo do cancelamento"
					placeholder="Digite"
					value={description}
					onChange={(e) => setDescription(e.target.value)}
					maxLength={150}
					minLength={minLength}
					minLengthText={
						isMobile
							? `Min de ${minLength} caracteres`
							: `Sua avaliação deve possuir no min. ${minLength} caracteres`
					}
					touched
					showIncreasingCharCount
				/>
				<ModalFooter>
					<Button hierarchy="secondary" size="medium" onClick={onClose}>
						Fechar
					</Button>
					<Button
						hierarchy="primary"
						size="medium"
						onClick={cancelApplication}
						isLoading={loadingCancel}
						disabled={description.length < minLength}
						className="bg-feedback-negative-pure-500 border-feedback-negative-pure-500"
					>
						Cancelar solicitação
					</Button>
				</ModalFooter>
			</div>
		</BaseModal>
	);
}
